import React from "react";
import {WeekDayOptions} from "../../api/model/Enums";
import {Image} from "semantic-ui-react";
import './styles.css';
import declOfNum from "../../services/declOfNum";

function ucFirst(str) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
}

const ScheduleShema = ({points, transit}) => {
    return (
        <div className="schedule-schema">
            <div>
                <div className="schedule-schema__block">
                    <div>
                        <b>{ucFirst(WeekDayOptions.getText(points.consolidationPoint.day))}</b>
                    </div>
                    <div className="schedule-schema__day">
                        неделя
                        <div className="schedule-schema__day-num">
                            <b>{points.consolidationPoint.weekNumber}</b>
                        </div>
                    </div>
                </div>
                <div className="schedule-schema__images">
                    <Image className="schedule-schema__image" src="/img/m1.svg"/>
                    <div className="schedule-schema__line schedule-schema__line_green-blue"/>
                </div>
                <div className="schedule-schema__bottom-text schedule-text__color_grey">
                    <b>Консолидация</b>
                </div>
            </div>
            <div>
                <div className="schedule-schema__block">
                    <div>
                        <b>{ucFirst(WeekDayOptions.getText(points.shipmentPoint.day))}</b>
                    </div>
                    <div className="schedule-schema__day">
                        неделя
                        <div className="schedule-schema__day-num">
                            <b>{points.shipmentPoint.weekNumber}</b>
                        </div>
                    </div>
                </div>
                <div className="schedule-schema__images">
                    <div className='schedule-schema__in-transit'>В пути: <b>{declOfNum(transit && transit.daysRoad, ['день', 'дня', 'дней'])}</b></div>
                    <Image className="schedule-schema__image" src="/img/m2.svg"/>
                    <div className="schedule-schema__line schedule-schema__line_blue-orange"/>
                </div>
                <div className="schedule-schema__bottom-text schedule-text__color_grey">
                    <b>Отправка</b>
                </div>
            </div>
            <div>
                <div className="schedule-schema__block">
                    <div>
                        <b>{ucFirst(WeekDayOptions.getText(points.deliveryPoint.day))}</b>
                    </div>
                    <div className="schedule-schema__day">
                        неделя
                        <div className="schedule-schema__day-num">
                            <b>{points.deliveryPoint.weekNumber}</b>
                        </div>
                    </div>
                </div>
                <div className="schedule-schema__images">
                    <Image className="schedule-schema__image" src="/img/m3.svg"/>
                </div>
                <div className="schedule-schema__bottom-text schedule-text__color_grey">
                    <b>Доставка</b>
                </div>
            </div>

        </div>
    );
};

export default ScheduleShema;
