import React from 'react';
import { withRouter } from "react-router-dom";
import UrlFilter from './models/UrlFilter';
import FilterInputClearButton from './FilterInputClearButton';
import FilterMenuList from './FilterMenuList';
import {isEmpty} from "../../../../services/utils";


class WbGridFilterList extends React.Component {

    handleInputClick() {
        console.log('initial input click (to be reassined.)');
    }

    handleOptionClick(name) {
        this.props.applyFilter({
            [this.props.field]: name
        });
    };

    handleClearClick() {
        this.props.removeFilter(this.props.field);
    };

    getText(value) {
        const option = !isEmpty(value) && this.props.options
            .find(o => (typeof o.key !== "string" ? o.key.toString() : o.key) === value.toString());
        return option ? option.text : '';
    }

    render() {
        const urlState = new UrlFilter(this.props.location.search, this.props.field);
        const value = urlState.value;

        return (
            <div
                className="filter-flex-container"
                style={{ backgroundColor: value ? '#ffface' : null }}>
                <div className="ui input filter-flex-center">
                    <input
                        id={`filter-input-${this.props.field}`}
                        className="filter-date-input"
                        style={ { backgroundColor: value ? '#ffface' : null } }
                        type="text"
                        readOnly
                        value={this.getText(!isEmpty(this.props.value) ? this.props.value : value)}
                        onClick={() => this.handleInputClick && this.handleInputClick()}
                    />
                </div>
                <div className={value ? "filter-flex-right filter-flex-right-active" : "filter-flex-right"}>
                    {
                        (this.props.value || value)
                            ? <FilterInputClearButton
                                handleInputClearClick={this.handleClearClick.bind(this)} />
                            : <FilterMenuList
                                options={this.props.options}
                                handleExternalClick={click => this.handleInputClick = click}
                                handleOptionClick={this.handleOptionClick.bind(this)} />
                    }
                </div>

            </div>
        );
    }
}

export default withRouter(WbGridFilterList);
