import {
    WaybillStatusOptions,
    WaybillStatusEnum,
    RouteStatusOptions,
    WaybillStatusAnomalyOptions,
    WaybillStatusAvizationOptions,
    WaybillTypeEnum,
    WaybillStatusAnomalyOptionsIsRefund,
    WaybillStatusAnomaly,
    AuctionStatusOptions
} from "./model/Waybill";


export function getWaybillStatusTexts(wb) {

    let statusOrder = null;

    let statusAnomaly = null;

    let statusShipping = '';

    let statusAvisation = '';

    let statusAuction = '';

    if (wb.id) {

        statusOrder = WaybillStatusOptions.find(s => s.key === wb.status);
        if (wb.auction) statusAuction = AuctionStatusOptions.find(s => s.key === wb.auction.auctionStatus);
        statusAvisation = WaybillStatusAvizationOptions.find(s => s.key === wb.avisationStatus);

        if (statusOrder && (statusOrder.key === WaybillStatusEnum.CARGO_DELIVERED || statusOrder.key === WaybillStatusEnum.EXECUTING)) {
            const wbStatusAnomaly = (wb.shippingType !== WaybillTypeEnum.Return && WaybillStatusAnomalyOptionsIsRefund.includes(wb.statusAnomaly)) ? WaybillStatusAnomaly.REFUND : wb.statusAnomaly;
            statusAnomaly = WaybillStatusAnomalyOptions.find(s => s.key === wbStatusAnomaly);
        }
        if (statusOrder && statusOrder.key !== WaybillStatusEnum.DRAFT) {

            if (statusOrder.key === WaybillStatusEnum.EXECUTING) {

                if (wb.statusShipping) {

                    const statusShippingOption = RouteStatusOptions.find(ss => ss.key === wb.statusShipping);

                    if (statusShippingOption) {
                        statusShipping = statusShippingOption.text;
                    }
                }
            }
        }
    }

    return {
        statusOrder,
        statusShipping,
        statusAnomaly,
        statusAvisation,
        statusAuction
    };
}
