import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Grid, Modal, Table } from 'semantic-ui-react';
import PointAddress from '../../../../api/model/PointAddress';
import T from '../../../../components/Translate';


const PointFormSimilarModal = ({ addAddress, isOpen, onOpen, fromWaybill, onSimilarSubmit, similarPoints, onCancel, submitSimilar }) => {
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (similarPoints.length === 1) {
            setSelected(similarPoints[0]);
        }
    }, []);

    const styleContent = {
        height: '315px'
    };


    const renderLink = (text, id) => {
        return <Link to={`/admin/points/${id}/location`} target="_blank" className="point-form-link-style">{text}</Link>;
    };

    const selectItem = (point) => {
        if (!selected || point.id !== selected.id) {
            setSelected(point);
        } else {
            setSelected(null);
        }
    };

    return <Modal centered={true}
        dimmer="inverted"
        size={"small"}
        open={isOpen}
        closeIcon
        onOpen={onOpen}
        onClose={onCancel}
        closeOnEscape={true}
        closeOnDimmerClick={false}
    >
        <Modal.Header>
            <T>{(onSimilarSubmit && !addAddress) ?
                "Использовать один из существующих адресов?" :
                "Похожий адрес уже существует. Вы точно хотите продолжить?"
            } </T>
        </Modal.Header>
        <Modal.Content style={styleContent}>
            <div className="table-wrapper">
                <div className="table-scroll point-form-table">
                    <Table celled fixed singleLine
                        className="table-data-inner"
                        selectable
                        style={{ borderTop: 0 }}
                    >
                        <Table.Header>
                            <Table.Row key="headers">
                                {onSimilarSubmit && <Table.HeaderCell width="1" className="point-form-header-cell"></Table.HeaderCell>}
                                <Table.HeaderCell
                                    width="5"
                                    className="point-form-header-cell"
                                >
                                    <span><T>Название клиента</T></span>
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width="10"
                                    className="point-form-header-cell"
                                >
                                    <span><T>Адрес</T></span>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {similarPoints.map((item) => {
                                const { address, companyName, id } = item;
                                return <Table.Row
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => onSimilarSubmit && selectItem(item)}
                                >
                                    {onSimilarSubmit && <Table.Cell width="1">
                                        <Checkbox checked={selected && item.id === selected.id} />
                                    </Table.Cell>}
                                    <Table.Cell width="5">
                                        {onSimilarSubmit ? companyName : renderLink(companyName, id)}
                                    </Table.Cell>
                                    <Table.Cell width="10">
                                        {onSimilarSubmit ?
                                            new PointAddress(address).toString() :
                                            renderLink(new PointAddress(address).toString(), id)
                                        }
                                    </Table.Cell>
                                </Table.Row>;
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </Modal.Content>
        <Modal.Actions>
            <Grid>

                <Grid.Column floated='right' width={10}>

                    {onSimilarSubmit &&
                        <Button
                            onClick={onCancel}
                            style={{ marginRight: 15 }}
                        >
                            <T>{fromWaybill ? 'Закрыть' : 'Отмена'}</T>
                        </Button>
                    }
                    {onSimilarSubmit ?
                        <Button
                            onClick={() => submitSimilar(selected)}
                            disabled={!selected}
                            primary
                        >
                            <T>{fromWaybill ? 'Ок' : 'Использовать'}</T>
                        </Button> :
                        <Button
                            onClick={() => onCancel()}
                        >
                            <T>{fromWaybill ? 'Закрыть' : 'Отмена'}</T>
                        </Button>
                    }
                </Grid.Column>
            </Grid>
        </Modal.Actions>
    </Modal>;
};

export default PointFormSimilarModal;