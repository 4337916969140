import React, {useContext, useEffect, useState} from 'react';
import AppTableHeader from "../../../components/tables/AppTableHeader";
import {withRouter} from "react-router-dom";
import qs from "query-string";
import {getProviders} from "../../../api/providers";
import Shield from "../../../layout/page/shield/Shield";
import PointGridHeader from "../points/pointsGrid/PointGridHeader";
import PointsScrollPreloader from "../../_shared/pointsScrollPreloader/PointsScrollPreloader";
import {ContextNavi} from "../../../services/context";
import {LinkInfo} from "../../../layout/navbar/Navbar";
import AppTableToolbar from "../../../components/tables/AppTableToolbar";
import AppToolbarButton from "../../../components/buttons/AppToolbarButton";
import ProviderFormModal from "./providerFormModal/ProviderFormModal";
import T from "../../../components/Translate";
import {getCompaniesList} from "../../../api/companies";
import {Container, Table} from "semantic-ui-react";
import PointGridCell from "../points/pointsGrid/PointGridCell";
import TableSearch from "../../../components/miscs/TableSearch";


const headers = [
    new AppTableHeader("legalName", "Юр. наименование поставщика".t),
    new AppTableHeader("fmid", "Название в системе FM".t),
    new AppTableHeader("clientNames", "Клиент".t),
    new AppTableHeader("status", "Статус".t),
];
export default withRouter(Providers);

function Providers({location}) {
    const contextNavi = useContext(ContextNavi);
    const [loaded, setLoaded] = useState(false);
    const [companiesList, setCompanyOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const keys = headers.map(h => h.key);

    async function fetchData(query) {
        setLoading(true);
        try {
            const stringQuery = `?${qs.stringify(query)}`;
            return await getProviders(stringQuery);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    }

    async function updateRows() {
        const query = qs.parse(location.search, {ignoreQueryPrefix: true});
        const rows = await fetchData(query);
        setRows(rows);
    }

    useEffect(() => {
        updateRows();
    }, [location.search]);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("Поставщики".t, "/providers")]);
        (async () => {
            const options = await getCompaniesList();
            setCompanyOptions(options.map(i => ({
                key: i.fmid,
                text: i.name,
                value: i.fmid,
                description: i.fmid
            })));
        })();
    }, []);

    const styleCell = {
        overflow: 'hidden',
        position: 'relative'
    };

    return (
        <div className="bgn-white h-100">
            <Container className="h-100 app-table">
                <Shield loading={loading && !loaded} loadingOver={false}>
                    <div style={{minHeight: '53px'}}>
                        <AppTableToolbar>
                            <AppTableToolbar.Left>
                                <TableSearch
                                    isUrlSearch
                                />
                            </AppTableToolbar.Left>
                            <AppTableToolbar.Right>
                                <ProviderFormModal
                                    key="newProviderForm"
                                    title={"Добавление поставщика".t}
                                    isAdd
                                    size="small"
                                    companiesList={companiesList}
                                    close={updateRows}
                                >
                                    <AppToolbarButton icon="plus"><T>Добавить поставщика</T></AppToolbarButton>
                                </ProviderFormModal>
                            </AppTableToolbar.Right>
                        </AppTableToolbar>
                    </div>
                    <div className="table-wrapper">
                        <div className="table-scroll">
                            <PointGridHeader
                                isCheckbox={false}
                                headers={headers}
                                hasRows={rows.length}
                                selectedPoints={selectedPoints || []}
                                setSelectedPoints={setSelectedPoints}
                            />
                        </div>
                        <div className="table-scroll points-table">
                            <Table celled fixed singleLine className="table-data-inner" selectable>
                                <Table.Body>
                                    {rows.map(r => {
                                        return <Table.Row key={r.id}
                                                          style={{cursor: 'pointer'}}
                                                          active={selectedPoints.includes(r.id)}
                                        >
                                            {keys.map(key =>
                                                <ProviderFormModal
                                                    key={`updateProviderForm-${key}`}
                                                    title={"Редактирование поставщика".t}
                                                    size="small"
                                                    companiesList={companiesList}
                                                    close={updateRows}
                                                    id={r.id}
                                                >
                                                    <Table.Cell
                                                        style={styleCell}
                                                        key={key}
                                                        title={''}
                                                    >

                                                        <PointGridCell
                                                            item={r}
                                                            keyName={key}
                                                        />

                                                    </Table.Cell>
                                                </ProviderFormModal>
                                            )}
                                        </Table.Row>;
                                    })}
                                </Table.Body>
                            </Table>
                            <PointsScrollPreloader {...{location, rows, fetchData, setRows}} />
                        </div>
                    </div>
                </Shield>
            </Container>
        </div>
    );
}
