import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Dropdown, Form, Grid, Header, Icon, List, Modal, Popup, Table} from "semantic-ui-react";
import T from "../../../../components/Translate";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import {getPointOptionsFmidBySearch, getPointsBySearch, Od} from "../../../../api/points";
import {ContextUser} from "../../../../services/context";
import UserPermissions from "../../../../api/model/UserPermissions";
import {addAddressException, deleteAddressExceptions, getAddressExceptions} from "../../../../api/companies";

const CompanyTabAddressExceptions = ({data}) => {
    let [openModal, setOpenModal] = useState(false);
    let [fmidAddress, setFmidAddress] = useState(null);
    let [exceptions, setExceptions] = useState([]);
    let [value, setValue] = useState({});
    let [addressSearchIsFetching, setAddressSearchIsFetching] = useState(false);
    let [addresses, setAddresses] = useState([]);
    let [loading, setLoading] = useState(false);
    let searchRef = useRef(null);


    const contextUser = useContext(ContextUser);
    const hasPermissionFmidAddress = contextUser.current.permissions.includes(UserPermissions.FMID_ADDRESS);

    useEffect(() => {
        getExceptions();
    }, []);

    const getExceptions = async () => {
        const exceptions = await getAddressExceptions(data.fmid);
        setExceptions(exceptions);
    };

    const handleAdd = async () => {
        setLoading(true);
        try {
            await addAddressException({
                companyFMID: data.fmid,
                pointId: value.id
            });

            handleCloseModal();

            getExceptions();
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        await deleteAddressExceptions(id);

        await getExceptions();
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setValue({});
        setFmidAddress(null);
        setAddresses([]);
        setOpenModal(false);
        setValue({});
    };

    function searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length ? options.filter(opt =>
            opt.text && opt.text.toLowerCase().includes(q0) ||
            opt.description && opt.description.toLowerCase().includes(q0)
        ) : [];
    };

    async function handlePointSearchChange(e, {searchQuery}) {
        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;
        setAddressSearchIsFetching(hasSearch);

        if (hasSearch) {
            const newAddresses = await getPointsBySearch(searchQuery);
            if (newAddresses.length) {
                const addresses = newAddresses;
                setAddressSearchIsFetching(false);
                setAddresses(addresses);
            } else {
                setAddressSearchIsFetching(false);
            }
        }
    };

    async function handlePointChange(e, dd) {
        const address = addresses.find(adr => adr.id === dd.value);
        if (address === undefined) {
            return setValue({});
        }
        setValue(address);
    };

    const addressOptions = (addresses || [])
        .map(p => new Od(p.id, p.address.value, (p.pointName || p.companyName || '').trunc(50)));
    const searchTipsText = (
        <List>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle'/>Адрес введенный по частям, например <em>монтажная 7</em></span></List.Item>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle'/>Название клиента, например <em>ашан</em></span></List.Item>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle'/>Название адреса для быстрого поиска</span></List.Item>
            {false && hasPermissionFmidAddress &&
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle'/>Название адреса в системе FM</span></List.Item>}
        </List>
    );

    const searchTips = (
        <Popup
            flowing
            trigger={<Icon name='info circle' color="blue" style={{fontSize: "1.1em"}}/>}
            content={
                <>
                    <Header>Подсказка по поиску</Header>
                    <div>Для поиска <em>адреса FM</em> используйте один из критериев</div>
                    {searchTipsText}
                    <div>Возможно использовать часть названия клиента и часть адреса, например <em>ашан вавилова 3</em>
                    </div>
                </>
            }
        />
    );


    async function searchAddressOptions() {
        const res = await getPointOptionsFmidBySearch(fmidAddress);
        setAddresses(res);
        if (res && res[0] && res[0].id) {
            setValue(res[0]);
        } else setValue({});
    }

    function onKeyPressFmid(e) {
        if (e.key === "Enter") {
            searchRef && searchRef.current.focus();
        }
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header className="m-b-15" dividing>Список исключений</Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column align="right">
                                <Button primary onClick={handleOpenModal}>
                                    <Icon name="plus"/>
                                    <T>Добавить адрес в исключения</T>
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={7}>
                                    Название адреса в системе FM
                                </Table.HeaderCell>
                                <Table.HeaderCell width={7}>
                                    Адрес
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                exceptions.map(row => (
                                    <Table.Row key={row.id}>
                                        <Table.Cell>
                                            {row.fmid}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {row.address}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Icon
                                                name="close"
                                                link
                                                className="m-l-15"
                                                title="Удалить адрес из исключений"
                                                size="big"
                                                onClick={() => handleDelete(row.id)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table>
                    <Modal
                        open={openModal}
                    >
                        <Modal.Header>Добавить адрес в исключение</Modal.Header>
                        <Modal.Content>
                            <Form loading={loading}>
                                <Form.Field>
                                    <label><T>Название адреса в системе FM</T></label>
                                    {<Input
                                        placeholder={"Название адреса в системе FM".t}
                                        value={fmidAddress}
                                        onChange={(e) => setFmidAddress(e.target.value)}
                                        onKeyPress={onKeyPressFmid}
                                        onBlur={searchAddressOptions}
                                    />}
                                </Form.Field>
                                <Form.Field>
                                    <label><T>Найти в адресах FM</T> {searchTips}</label>
                                    <Dropdown
                                        clearable
                                        selection
                                        search={searchTextAndDescription}
                                        value={value.id || ''}
                                        noResultsMessage={'Результатов не найдено'.t}
                                        onSearchChange={handlePointSearchChange}
                                        onChange={handlePointChange}
                                        className="InputDropdown input-pointSearch"
                                        placeholder={`Введите адрес по частям | название клиента | название для быстрого поиска`.t}
                                        fluid
                                        closeOnChange
                                        options={addressOptions || []}
                                        loading={addressSearchIsFetching === true}
                                        selectOnBlur={false}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Клиент</label>
                                    <span>{value.companyName || <span className="tc-grey">нет данных</span>}</span>
                                </Form.Field>
                                <input ref={searchRef} style={{opacity: 0, height: 0, padding: 0, margin: 0}}/>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={handleCloseModal}>
                                Отмена
                            </Button>
                            <Button color="green" disabled={loading || !value.id} onClick={handleAdd}>
                                Добавить
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default CompanyTabAddressExceptions;
