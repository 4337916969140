import React, {useMemo, useState} from 'react';
import {Button, Form, Grid, Icon, Popup} from "semantic-ui-react";
import InputDropdown from "../inputs/InputDropdown";
import {
    fieldCompareOptions,
    getAutocompleteExcel,
    searchnamesFromExcel
} from "../../api/calculatingFieldsRules";
import O from '../../components/Option';
import Fields from "./fields";
import {nanoid} from "nanoid";
import FormImport from "../formImport/FormImport";
import downloadFile from "../../services/downloadFile";

const RulesConstructor = ({items, fields: fieldsFromRulesProps = [], disabled, onChange, companyId}) => {

    const onDelete = (index) => {
        const list = items.filter((item, itemIndex) => itemIndex !== index)
        onChange(list.length ? list : [{
            nanoId: nanoid(),
            name: '',
            values: [''],
            type: ''
        }]);
    };

    const onAdd = () => {
        onChange([...items, {nanoId: nanoid()}])
    };

    const handleChange = (value, name, index) => {
        const list = [...items];
        if (name === 'key') {
            if (value === 'ClientFMID' && companyId) {
                list[index] = {
                    nanoId: list[index].nanoId,
                    [name]: value,
                    type: 0,
                    values: [companyId]
                };
            }
            else list[index] = {
                nanoId: list[index].nanoId,
                [name]: value,
                type: '',
                values: []
            };
        } else {
            list[index] = {
                ...list[index],
                [name]: value,
            };
        }
        onChange(list);
    };


    const fieldsFromRules = useMemo(() => fieldsFromRulesProps.map(item => new O(item.key, item.field.name)), [fieldsFromRulesProps]);

    const options = (item) => (fieldsFromRulesProps.find(field => field.key === item.key) || {}).field || {};

    const downloadExcel = async (id) => {
        await getAutocompleteExcel(id).then(res => {
            window.open(res.url, '_blank');
        });
    };
    const downloadTemplate = async () => {
        let file = 'files/attach-searchnames.xlsx';
        window.open(file, '_blank');
    };

    const deleteSearchnames = (index) => {
        handleChange([], 'values', index);
    };

    const [openPopupContent, setOpenPopupContent] = useState(false);

    const onImport = (data, index) => {
        handleChange(data ? data : [], 'values', index);
    };

    const showBtns = (item) => {
        const keys = ['DestinationPointSearchname', 'OriginPointSearchname'];
        return item && keys.includes(item.key);
    };

    return (
        <>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>Условие по полю</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>Тип условия</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>Целевое значение</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <Button icon onClick={() => onAdd()}>
                                <Icon name="add"/>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Grid>
                {
                    items.map((item, index) => (
                        <Grid.Row key={item.nanoId}>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            options={fieldsFromRules}
                                            disabled={disabled}
                                            value={item.key}
                                            placeholder="Значение"
                                            onChange={(val) => handleChange(val, 'key', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            placeholder="выберите"
                                            value={item.type}
                                            disabled={disabled || (item.key === 'ClientFMID' && companyId)}
                                            options={item.key && fieldCompareOptions.filter(option => options(item).rules.includes(option.value))}
                                            onChange={(val) => handleChange(val, 'type', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        {showBtns(item) && <div className="input-buttons">
                                            <Popup
                                                trigger={<Icon disabled={!item.id}
                                                               onClick={() => downloadExcel(item.id)} color={'blue'}
                                                               name={'arrow down'}/>}
                                                content={'Выгрузить адреса в Excel'}
                                            />

                                            <Popup
                                                trigger={
                                                    <FormImport
                                                        header="Загрузить адреса из Excel"
                                                        saveBtnName="Загрузить и сохранить"
                                                        id={item.id}
                                                        importMethod={searchnamesFromExcel}
                                                        callbackFunction={(data) => onImport(data, index)}
                                                        payload={{
                                                            names: (item.values || []).join(';')
                                                        }}
                                                    >
                                                        <Icon onMouseOver={() => setOpenPopupContent(index)}
                                                              onMouseOut={() => setOpenPopupContent(false)}
                                                              color={'blue'} name={'arrow up'}/>
                                                    </FormImport>}
                                                open={openPopupContent === index}
                                                content={'Загрузить адреса из Excel'}
                                            />

                                            <Popup
                                                trigger={<Icon onClick={() => deleteSearchnames(index)}
                                                               name={'delete'}/>}
                                                content={'Удалить все адреса'}
                                            />
                                            <Popup
                                                trigger={<Icon onClick={downloadTemplate} color={'blue'}
                                                               name={'table'}/>}
                                                content={'Выгрузить шаблон Excel файла'}
                                            />
                                        </div>}
                                        <Fields
                                            fieldOptions={options(item)}
                                            itemKey={item.key}
                                            value={item.values}
                                            disabled={disabled || (item.key === 'ClientFMID' && companyId)}
                                            multiple
                                            isConstructor
                                            onChange={handleChange}
                                            indexRule={index}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Button icon onClick={() => onDelete(index)}>
                                    <Icon name="trash alternate"/>
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    ))
                }
            </Grid>
        </>
    )
};

export default RulesConstructor;
