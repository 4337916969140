import O from "../../components/Option";
// eslint-disable-next-line no-unused-vars
import PointAddress from "./PointAddress";
// eslint-disable-next-line no-unused-vars
import PointContact from "./PointContact";
// eslint-disable-next-line no-unused-vars
import Persistable from "./Persistable";
import {PointDays} from "./PointDays"; // eslint-disable-line

export const LoadCapacityOptions = [
    new O(900, '0,9 т'),
    new O(1500, "1,5 т"),
    new O(3000, "3 т"),
    new O(5000, "5 т"),
    new O(10000, "10 т"),
    new O(15000, '15 т'),
    new O(20000, "20 т"),
];

export const PointTypeEnum = {
    RECEPIENT: 0,
    CLIENT_WAREHOUSE: 1,
    FM: 2,
    SHOP: 3,
    DISTRIBUTION_CENTER: 4,
};

export const PointStatusEnum = {
    СREATED: 0,
    ON_APPROVAL: 1,
    CANCELLED: 2,
    NEEDS_CORRECTION: 9,
    REJECTED: 10,
    ACCEPTED: 11,
};

export const PointExportStatusEnum = {
    CREATED: 0,
    EXPORTED: 1,
    EXPORT_ERROR: 2
};

// вариант с получением через заявки
// получить все заявки со статусом подтв адреса
// получить все pointsIds

export const PointStatusOptions = [
    new O(PointStatusEnum.ON_APPROVAL, "Проверка"),
    new O(PointStatusEnum.NEEDS_CORRECTION, "Корректировка"),
    new O(PointStatusEnum.REJECTED, "Отклонен"),
    new O(PointStatusEnum.ACCEPTED, "Одобрен"),
];

export const PointExportStatusOptions = [
    new O(PointExportStatusEnum.CREATED, "Не экспортирован"),
    new O(PointExportStatusEnum.EXPORTED, "Экспортирован"),
    new O(PointExportStatusEnum.EXPORT_ERROR, "Ошибка экспорта")
];

export const PointRegistrationStatusOptions = [
    new O(PointExportStatusEnum.CREATED, "Не зарегистрирован"),
    new O(PointExportStatusEnum.EXPORTED, "Зарегистрирован"),
    new O(PointExportStatusEnum.EXPORT_ERROR, "Ошибка регистрации")
];

export const PointLoadingPositionEnum = {
    BACK: 0,
    SIDE: 1,
    FRONT: 2
};

export const LoadPositionOptions = [
    new O(PointLoadingPositionEnum.BACK, "Задняя"),
    new O(PointLoadingPositionEnum.SIDE, "Боковая"),
    new O(PointLoadingPositionEnum.FRONT, "Спереди"),
];

export const CompanyTypeOptions = [
    new O(PointTypeEnum.SHOP, "Магазин"),
    new O(PointTypeEnum.DISTRIBUTION_CENTER, "РЦ"),
    new O(PointTypeEnum.RECEPIENT, "Контрагент"),
    new O(PointTypeEnum.CLIENT_WAREHOUSE, "Склад клиента"),
    new O(PointTypeEnum.FM, "Кросс-док"),
];

export function getPointStatusColor(statusId) {
    switch (statusId) {
        case PointStatusEnum.ON_APPROVAL: return "orange";
        case PointStatusEnum.NEEDS_CORRECTION: return "orange";
        case PointStatusEnum.APPROVAL_ERROR: return "red";
        case PointStatusEnum.REJECTED: return "grey";
        case PointStatusEnum.ACCEPTED: return "teal";
        default: return null;
    }
}

export const PointBookmarkTypeEnum = {
    NONE: 0,
    LOADING: 1,
    UNLOADING: 2,
    SHARE: 3
};

export const PointBookmarkTypeName = {
    [PointBookmarkTypeEnum.NONE]: 'пусто',
    [PointBookmarkTypeEnum.LOADING]: 'на загрузке',
    [PointBookmarkTypeEnum.UNLOADING]: 'на выгрузке',
    [PointBookmarkTypeEnum.SHARE]: 'везде'
};

export const PointBookmarkTypeColor = {
    [PointBookmarkTypeEnum.NONE]: 'grey',
    [PointBookmarkTypeEnum.LOADING]: 'orange',
    [PointBookmarkTypeEnum.UNLOADING]: 'green',
    [PointBookmarkTypeEnum.SHARE]: 'blue'
};

export const PointBookmarkTypeOptions = Object.keys(PointBookmarkTypeName).map((key) => {
    return new O(Number(key), PointBookmarkTypeName[key]);
});


export function getPointStatusName(statusId) {

    const option = PointStatusOptions.find(o => o.key === statusId);

    return option ? option.text : statusId;
}

export function getCompanyTypeName(typeId) {
    const option = CompanyTypeOptions.find(o => o.key === typeId);

    return option ? option.text : typeId;
}

export default class Point extends Persistable {

    /** @param {Point} dto */
    constructor(dto = {}) {
        super(dto);

        /** @type {string} */
        this.id = dto.id;

        /** @type {string} */
        this.fmid = dto.fmid;   // searchName

        /** @enum {PointStatusEnum} */
        this.statusId = dto.statusId || PointStatusEnum.СREATED;

        /** @enum {PointTypeEnum} */
        this.pointType = dto.pointType;

        /** @type {string} */   // clientSearchName
        this.pointName = dto.pointName;

        /** @type {string} */
        this.companyName = dto.companyName;

        /** @type {string} */
        this.companyLegalForm = dto.companyLegalForm;


        /** @type {PointAddress} */
        this.address = dto.address instanceof PointAddress ? dto.address : new PointAddress(dto.address);

        /** @type {PointDays} */
        this.days = dto.days;

        /** @type {PointContact[]} */
        this.contacts = dto.contacts;


        /** @enum {number} */
        this.dockCapacity = dto.dockCapacity;

        /** @enum {PointLoadingPositionEnum} */
        this.dockPosition = dto.dockPosition;

        /** @type {string} */
        this.comment = dto.comment;

        /** @type {boolean} */
        this.isHub = dto.isHub;

        /** @type {PointDays[] | undefined}  */
        this.comments = dto.comments;

        this.exportStatus = dto.exportStatus;

        /** @type {string | undefined} */
        this.zone = dto.zone;

        this.createdByClient = dto.createdByClient;

        this.useCount = dto.useCount;

        this.activityId = dto.activityId;

        this.platformId = dto.platformId;

        this.activityName = dto.activityName;

        this.platformName = dto.platformName;

        this.mappingCode = dto.mappingCode;

        this.isLocked = dto.isLocked;

        this.pointType = dto.pointType;

        this.tariffZoneId = dto.tariffZoneId;
    }
}
