import React from 'react';
import { Message, Icon, Header } from 'semantic-ui-react';
import './EntryBrowserFailed.css';

export default function EntryBrowserFailed() {

    return (
        <div className="login-bgn">
            <div className="entry-outdated-message-container">
                <Message
                    className="entry-outdated-message"
                    floating
                    // negative
                    color="black"
                    icon={<Icon name="ban" color="red" />}
                    header={<Header as="h3" style={{ color: 'white', marginBottom: '7px' }}>Ваш браузер не поддерживется.</Header>}
                    content={<span>Для работы c сайтом FM Клиентский портал рекомендуется использовать<br />последнюю версию браузера <a href="https://www.google.ru/intl/ru/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></span>}
                />
            </div>
        </div>
    );
}