import {
    dateInputs,
    fieldTypes,
    FieldTypesEnum,
    getNameProcessingType,
    latinLettersValue,
    ProcessingTypesEnum,
    RuleStatusEnum,
    ServiceParamsOptions,
    timeInputs
} from "../../../api/model/ImportForms";
import moment from "moment/moment";

const errorText = (displayName, text) => `${displayName}: ${text}`;

const errorObject = (displayName, text) => ({
    type: RuleStatusEnum.WARNING,
    text: errorText(displayName, text)
});

const errorNoData = (displayName, name) => {
    return errorObject(displayName, `для правила '${name}' не введено значение`);
};
const errorNoSelectData = (displayName, name) => {
    return errorObject(displayName, `для правила '${name}' не выбрано значение`);
};
const errorNoSchedules = (displayName, name) => {
    return errorObject(displayName, `для правила '${name}' не заполнен график`);
};
const errorInValidData = (displayName, name) => {
    return errorObject(displayName, `для правила '${name}' введены некорректные данные`);
};
const errorRequiredFields = (displayName, name) => {
    return errorObject(displayName, `для правила '${name}' не заполнены обязательные поля`);
};
const errorNoRule = (displayName, isAdditional) => {
    return errorObject(displayName, `не выбрано ${isAdditional ? 'дополнительное ' : ''}правило`);
};


const constantCheckValidFormat = (val, type) => {
    switch (type) {
        case FieldTypesEnum.TIME: {
            return new moment(val, 'HH:mm', true).isValid();
        }
        default:
            return !!val;
    }
};

const typesWithValidEmpty = [ProcessingTypesEnum.AUTOMATIC, ProcessingTypesEnum.SCHEDULE];
const selectTypes = [ProcessingTypesEnum.REPLACE_BY_TEMPLATE];

const errorObjectByRule = ({val, ruleType, isServiceParameter, displayName, type, schedulesIsNotEmpty}) => {
    const name = getNameProcessingType(ruleType);
    let errorsObject;

    if (!isServiceParameter && !val && !typesWithValidEmpty.includes(ruleType)) {
        errorsObject = selectTypes.includes(ruleType)
            ? errorNoSelectData(displayName, name)
            : errorNoData(displayName, name);
    } else {
        switch (ruleType) {
            case ProcessingTypesEnum.SCHEDULE: {
                errorsObject = !schedulesIsNotEmpty ? errorNoSchedules(displayName, name) : false;
                break;
            }
            case ProcessingTypesEnum.CONSTANT: {
                const isValid = constantCheckValidFormat(val, type);
                errorsObject = !isValid ? errorInValidData(displayName, name) : false;
                break;
            }
            case ProcessingTypesEnum.SPLITTERS: {
                if (!val.length) {
                    errorsObject = errorNoData(displayName, name);
                }
                break;
            }
            case ProcessingTypesEnum.CELL: {
                if (!latinLettersValue(val)) {
                    errorsObject = errorInValidData(displayName, name);
                }
                break;
            }
            case ProcessingTypesEnum.REPLACE: {
                if (!val.length) {
                    errorsObject = errorNoData(displayName, name);
                } else if (!val.every(v => v.search)) {
                    errorsObject = errorObject(displayName, `для правила '${name}' не введены заменяемые данные`);
                }
                break;
            }
            case ProcessingTypesEnum.REPLACE_CUSTOM: {
                const inputsByType = (type) => {
                    switch (type) {
                        case FieldTypesEnum.TIME:
                            return timeInputs;
                        case FieldTypesEnum.DATE:
                        default:
                            return dateInputs;
                    }
                };

                if (!inputsByType(type).every(t => val[t.name] || val[t.name] === false)) {
                    errorsObject = errorRequiredFields(displayName, name);
                }
                break;
            }
            case ProcessingTypesEnum.TRIM: {
                if (!val.every(v => v.value)) {
                    errorsObject = errorRequiredFields(displayName, name);
                }
                break;
            }
            case ProcessingTypesEnum.POSITION: {
                if (!((val[0] || {}).value && (val[1] || {}).value)) {
                    errorsObject = errorRequiredFields(displayName, name);
                } else if (
                    !(Number(val[0].value) > 0 && Number(val[1].value) > 0)
                    || (Number(val[0].value) > Number(val[1].value))
                ) {
                    errorsObject = errorInValidData(displayName, name);
                }
                break;
            }
            case ProcessingTypesEnum.REPLACE_BY_TEMPLATE:
            default:
                break;
        }
    }

    return errorsObject;
};

export const checkInValid = (allFields, form, schedulesIsNotEmpty) => {
    const errorsObject = {};

    allFields.forEach(field => {
        const value = form[field.columnName];


        if (!field.isRequired && (!value || !value.ruleType)) {
            return null;
        } else if (
            !value
            || (field.isServiceParameter && ServiceParamsOptions[field.columnName].type !== fieldTypes.DROPDOWN
                && !value.ruleType)
        ) {
            errorsObject[field.columnName] = errorNoRule(field.displayName);
            return null;
        }

        if (field.isServiceParameter && ServiceParamsOptions[field.columnName].type === fieldTypes.NUMBER && Number(value.ruleType) < 1) {
            errorsObject[field.columnName] = errorObject(field.displayName, `некорректное значение`);
            return null;
        }

        if (!field.isServiceParameter) {
            const ruleType = value.ruleType;
            const val = value.value;

            const err = errorObjectByRule({
                field,
                val,
                ruleType,
                displayName: field.displayName,
                type: field.type,
                isServiceParameter: field.isServiceParameter,
                schedulesIsNotEmpty
            }) || false;

            errorsObject[field.columnName] = err;

            if (!err && value.rules && value.rules.length) {
                for (let i = 0; i < value.rules.length; i++) {
                    const valRule = value.rules[i];
                    if (!valRule || !valRule.ruleType) {
                        errorsObject[field.columnName] = errorNoRule(field.displayName, true);
                    } else {
                        const err = errorObjectByRule({
                            field,
                            val: valRule.value,
                            ruleType: valRule.ruleType,
                            displayName: field.displayName,
                            type: field.type,
                            schedulesIsNotEmpty
                        }) || false;

                        if (err) {
                            errorsObject[field.columnName] = err;
                            return;
                        }
                    }
                }
            }
        }
    });
    return errorsObject;
};