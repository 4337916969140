import React, {useEffect, useState} from 'react';
import {Dropdown, Form} from 'semantic-ui-react';
import {getCounties, getRegions} from "../../../../../api/dictionaries";

const PointFormAddressManual = ({ ve, address, mergeToAddress, mini }) => {

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);

    useEffect(()=>{
        getCounties().then(data=>setCountries(data.map(d => {return {key: d, value: d, text: d}})));
    }, []);

    useEffect(()=>{
        address.country && getRegions(`?country=${address.country}`).then(data=> {
            setRegions(data.map(d => {
                return {key: d.id, value: d.name, text: d.name};
            }));
        });
    }, [address.country]);

    return <div className={!mini ? "point-form-manual-wrap" : ''}>
        <Form.Group widths='equal'>

            <Form.Field required>
                <label>{'Страна'.t}</label>
                <Dropdown
                    id='dropdown_country'
                    options={countries}
                    error={ve.includes("country")}
                    placeholder={'Страна'.t}
                    value={address.country}
                    onChange={(e, {value}) => mergeToAddress({ country: value, region: null })}
                    className='InputDropdown'
                    name="country"
                    fluid
                    search
                    selection
                    noResultsMessage={'Результатов не найдено'.t}
                    selectOnBlur={false}
                />
            </Form.Field>

            <Form.Field required>
                <label>{'Регион'.t}</label>
                <Dropdown
                    id='dropdown_region'
                    options={regions}
                    error={ve.includes("region")}
                    placeholder={'Регион'.t}
                    value={address.region}
                    onChange={(e, {value}) => mergeToAddress({ region: value })}
                    className='InputDropdown'
                    name="region"
                    fluid
                    search
                    selection
                    noResultsMessage={'Результатов не найдено'.t}
                    selectOnBlur={false}
                />
            </Form.Field>

            <Form.Input
                id='input_area'
                label={'Район'.t}
                placeholder={'Район'.t}
                value={address.area}
                onChange={e => mergeToAddress({ area: e.target.value })}
            />
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Input
                id='input_settlement'
                required
                error={ve.includes("settlement")}
                label={'Населенный пункт (с типом)'.t}
                placeholder={'Населенный пункт'.t}
                value={address.settlement}
                onChange={e => mergeToAddress({ settlement: e.target.value })}
            />
            <Form.Input
                id='input_postalCode'
                required
                error={ve.includes("postalCode")}
                label={'Индекс'.t}
                placeholder={'Индекс'.t}
                value={address.postalCode}
                onChange={e => mergeToAddress({ postalCode: e.target.value })}
            />
            <Form.Input
                id='input_streetName'
                label={'Название улицы (с типом)'.t}
                placeholder={'Название улицы включая тип'.t}
                value={address.streetName}
                onChange={e => mergeToAddress({ streetName: e.target.value })}
            />
        </Form.Group>

        <Form.Group widths='equal' devided="">
            <Form.Input
                id='input_streetNo'
                label={'Номер дома (с типом)'.t}
                placeholder={'например д 1 или влд 3'.t}
                value={address.streetNo}
                onChange={e => mergeToAddress({ streetNo: e.target.value })}
            />
            <Form.Input
                id='input_building'
                label={'Номер строения (с типом)'.t}
                placeholder={'например стр 1 или корпус 8'.t}
                value={address.building}
                onChange={e => mergeToAddress({ building: e.target.value })}
            />
            <Form.Input
                id='input_office'
                label={'Офис'.t}
                placeholder={'Офис или квартира'.t}
                value={address.office}
                onChange={e => mergeToAddress({ office: e.target.value })}
            />
        </Form.Group>
    </div>;
};

export default PointFormAddressManual;

