import React, {useEffect, useState} from 'react';
import {fieldsTypeEnum} from "../../api/calculatingFieldsRules";
import InputDropdown from "../inputs/InputDropdown";
import {Input} from "semantic-ui-react";
import {restrictionFloat, restrictionInt} from "../../services/utils";
import InputDate from "../inputs/InputDate";
import O from '../Option';
import {normalizeTime, normalizeTimePeriod} from "../../services/normalizeTime";
import DateTime from "../inputs/DateTime";

const Fields = ({ value, onChange, indexRule, multiple, disabled, fieldOptions = {}, isConstructor, itemKey }) => {

    const {values: source = [], type, maxLength, maxFractionalLength, maxValue, minValue} = (fieldOptions || {});

        let [options, setOptions] = useState( value ? value.map(value => ({text: value, value})) : []);

        useEffect(() => {
            setOptions(value ? value.map(value => ({text: value, value})) : []);
        }, [itemKey, value]);

        const onAdd = (e, {value}) => {
            let newValues = trimValues([value]).filter(nV => !options.find(o => o.value === nV));
            setOptions(options => [...options, ...newValues.map(v => {
                return {text: v, value: v};
            })]);
        };

        const trimValues = (val) => {
            let newVal = [];
            val.forEach(v => {
                newVal.push(...v.split(';'));
            });
            return [...new Set(newVal.map(v=>v.trim()).filter(val => val!==''))];
        };

    switch (type) {
        case fieldsTypeEnum.String:

            if (isConstructor) {
                return <InputDropdown
                    placeholder="Значение"
                    options={options}
                    search
                    selection
                    fluid
                    multiple
                    handleAdd={onAdd}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                    onChange={val => onChange(trimValues(val), 'values', indexRule)}
                />;
            }
            return <Input
                placeholder="Значение"
                value={value && value[0] ? value[0] : ''}
                maxLength={maxLength}
                disabled={disabled}
                onChange={(e, {value: val}) => onChange([val], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Int:
            return <Input
                placeholder="Значение"
                value={value && value[0] ? value[0] : ''}
                maxLength={maxLength}
                disabled={disabled}
                onChange={(e, {value: val}) => onChange([restrictionInt(val, minValue, maxValue, value && value[0])], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Double:
            return <Input
                placeholder="Значение"
                maxLength={maxLength}
                value={value && value[0] ? value[0] : ''}
                disabled={disabled}
                onChange={(e, {value: val}) => onChange([restrictionFloat(val, maxFractionalLength, minValue, maxValue, value && value[0])], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Date:
            return <InputDate
                icon="calendar outline"
                placeholder={"Значение".t}
                position="bottom center"
                disabled={disabled}
                onChange={val => onChange([val], 'values', indexRule)}
                value={value && value[0]}
            />;
        case fieldsTypeEnum.Time:
            return <Input
                fluid
                placeholder={"Значение".t}
                position="bottom center"
                value={value && value[0]}
                disabled={disabled}
                onChange={e => onChange([normalizeTime(e.target.value)], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Enum:
            return <InputDropdown
                options={source.map(item => new O(item.key, item.name))}
                value={multiple ? value : (value && value[0])}
                multiple={multiple}
                placeholder="Значение"
                disabled={disabled}
                onChange={(val) => onChange(multiple ? val : [val], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Boolean:
            return <InputDropdown
                options={source.map(item => new O(item.key, item.name))}
                value={multiple ? value : (value && value[0])}
                multiple={multiple}
                placeholder="Значение"
                disabled={disabled}
                onChange={(val) => onChange(multiple ? val : [val], 'values', indexRule)}
            />;
        case fieldsTypeEnum.TimePeriod:
            return <Input
                fluid
                placeholder={"Значение".t}
                position="bottom center"
                value={value && value[0]}
                disabled={disabled}
                onChange={e => onChange([normalizeTimePeriod(e.target.value)], 'values', indexRule)}
            />;
        case fieldsTypeEnum.Decimal:
            return <Input
                       placeholder="Значение"
                       maxLength={maxLength}
                       value={value && value[0] ? value[0] : ''}
                       disabled={disabled}
                       onChange={(e, { value: val }) => onChange([restrictionFloat(val, maxFractionalLength, minValue, maxValue, value && value[0])], 'values', indexRule)}
                   />;
        case fieldsTypeEnum.DateTime:
            return <DateTime
                onChange={val => onChange([val], 'values', indexRule)}
                value={value && value[0]}
                isDisabled={disabled}
            />;
        default: return <Input
            placeholder="Значение"
            value={value && value[0] ? value[0] : ''}
            disabled={disabled}
            onChange={(e, {value: val}) => onChange([val], 'values', indexRule)}
        />;
    }
};

export default Fields;
