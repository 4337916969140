import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { userPasswordReset } from '../../../api/users';
import T from '../../../components/Translate';
import {toast} from "../../../services/toast";

export default class ResetPassword extends React.Component {

    state = {
        open: false,
        isLoading: false,
        isDone: false
    }

    handleOpen = () => {
        this.props.email ? this.setState({open: true})
            : toast.error('Для восстановления пароля сначала введите email', 'Введите email');
    }
    
    handleClose = () => this.setState({ open: false });

    resetPassword() {
        this.setState({ isLoading: true }, async () => {
            
            const result = await userPasswordReset(this.props.email);

            this.setState({ isLoading: false, isDone: true, result });

        });
    }

    render() {

        const { open, isLoading, isDone, result } = this.state;
        const resetPasswordText = 'Ваш текущий пароль будет сброшен и новый выслан на указанный в логине email: '.t + this.props.email || '';
        const resetPasswordResult = result ? result.errors : "Пароль успешно сброшен.";

        return (
            <Modal
                open={open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                closeOnEscape={!isLoading}
                closeOnDimmerClick={!isLoading}
                trigger={this.props.children}
            >
                <Modal.Header><T>Сброс пароля</T></Modal.Header>
                <Modal.Content>{isLoading ? "Сброс пароля...".t : isDone ? resetPasswordResult : resetPasswordText}</Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.handleClose.bind(this)}
                        disabled={isLoading}>{isDone ? <T>Закрыть</T> : <T>Отмена</T>}</Button>
                    {!isDone && 
						<Button
							negative
							icon="exclamation triangle"
							content={"Сбросить пароль".t}
							disabled={isLoading}
							loading={isLoading}
							onClick={this.resetPassword.bind(this)} 
							/>
					}
                </Modal.Actions>
            </Modal>
        );
    }
}