import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Modal} from "semantic-ui-react";
import {auctionExtend, auctionStart, auctionUpdate, auctionValidate, getWaybillById} from "../../../api/waybills";
import DateTime from "../../../components/inputs/DateTime";
import moment from "moment/moment";
import AuctionRulesModal from "../../auctionRules";
import {UserRolesEnum} from "../../../api/usersRoles";
import {ContextUser} from "../../../services/context";
import {auctionValidation} from "./WbEdit";
import {toast} from "../../../services/toast";

const AuctionStartModal = ({isExtension, children, refresh, save, dto: initDto, selectedItem, fetchData, unsaved}) => {
    const [dto, setDto] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [minEndDate, setMinEndDate] = useState(null);
    const [modalRules, setModalRules] = useState(false);
    const [modalRulesCallback, setModalRulesCallback] = useState(null);

    const {auction = {}} = dto;

    const setParam = (values) => {
        setDto(dto => ({
            ...dto,
            auction: {
                ...dto.auction,
                ...values
            }
        }));
    };

    const setDates = (auction) => {
        let start;
        if ((auction || {}).auctionStart) {
            start = (auction || {}).auctionStart;
        } else {
            let now = new Date();
            const minutesToAdd = 2;
            now.setMinutes(now.getMinutes() + minutesToAdd);
            start = moment(now).format('YYYY-MM-DDTHH:mm:ss');
        }
        const minEnd = (auction || {}).auctionEnd
            ? moment((auction || {}).auctionEnd)
            : moment(moment(start).toDate().setHours(moment(start).toDate().getHours() + 1));
        setMinEndDate(minEnd.toDate());
        const end = moment(minEnd).format('YYYY-MM-DDTHH:mm:ss');
        setParam({
            auctionStart: start,
            auctionEnd: end
        });
        setLoading(false);
    }

    const setInitData = (auction, dto) => {
        auctionValidate(auction.id).then(() => {
            setOpen(true);
            if (!selectedItem) {
                setDto(dto || initDto);
                setDates(auction);
            } else getWaybillById(selectedItem.waybillId).then(d => {
                setDto(d);
                setDates(auction);
            });

        }).catch(() => setLoading(false));
    };

    useEffect(() => {
        (!unsaved && loading && !selectedItem && !!(initDto || {}).auction) && setInitData(initDto.auction);
    }, [initDto]);

    const contextUser = useContext(ContextUser);

    const rulesCheck = () => {
        if (isExtension) {
            const auctionEnd = new Date(selectedItem ? selectedItem.auctionEnd : initDto.auction.auctionEnd);
            let timeDiff = auctionEnd.getTime() - new Date().getTime();
            let minDiff = timeDiff / 1000 / 60;
            if (minDiff <= 3 && minDiff >= 0) {
                toast.error('В текущий момент продление аукциона невозможно, дождитесь завершения торгов или свяжитесь со специалистом CS');
                return;
            }
        }

        const rulesView = !contextUser.current.acceptanceOfTheAuctionRules && contextUser.current.role.id === UserRolesEnum.CLIENT;
        if (!rulesView) {
            onOpen();
        } else {
            setModalRules(rulesView);
            setModalRulesCallback({f: onOpen});
        }
    };

    const onOpen = async () => {
        setLoading(true);
        if (selectedItem) setInitData(selectedItem);
        else if (unsaved || !(initDto.auction || {}).id) {
            await save(null, (r) => {
                r && setInitData(r.auction, r);
            });
            setLoading(false);
        } else setInitData(initDto.auction);
    };

    const onClose = () => {
        setOpen(false);
        setDto({});
        refresh && refresh();
    };

    const onSave = () => {
        const errors = auctionValidation(dto, isExtension);
        if (errors) {
            toast.error(errors.map(e => e.txt).join('\n'));
        } else {
            setLoading(true);
            auctionUpdate({...dto, isSave: true})
                .then(d => {
                    const f = isExtension ? auctionExtend : auctionStart;
                    f(d).then(() => {
                        fetchData && fetchData();
                        onClose();
                    }).finally(() => {
                        setLoading(false);
                    });
                })
                .catch(e => {
                    console.log(e);
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Modal
                trigger={!loading && children}
                size="small"
                open={open}
                onOpen={rulesCheck}
                onClose={onClose}
            >
                <Modal.Header>{isExtension ? 'Продлить аукцион' : 'Запустить аукцион'}</Modal.Header>
                <Modal.Content>
                    <b>{isExtension ? 'Продлить аукцион на указанные сроки?' : 'Запустить аукцион в указанные сроки?'}</b>
                    <br/>
                    <Form className="p-t-15">
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{'Дата и время начала аукциона'.t}</label>
                                        <DateTime
                                            value={auction.auctionStart}
                                            isDisabled
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{'Дата и время окончания аукциона'.t}</label>
                                        <DateTime
                                            onChange={auctionEnd => setParam({auctionEnd})}
                                            value={auction.auctionEnd}
                                            min={minEndDate}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose}>Отмена</Button>
                    <Button disabled={!auction.auctionStart || !auction.auctionEnd || loading} primary
                            loading={loading}
                            onClick={onSave}>Подтвердить</Button>
                </Modal.Actions>
            </Modal>
            <AuctionRulesModal open={modalRules} setOpen={setModalRules} callback={(modalRulesCallback || {}).f}/>
        </>
    );
};

export default AuctionStartModal;