import { LoadCapacityEnum } from "./model/Waybill";
import { PackTypeEnum } from "./model/Enums";

export function getWeightCapacityFromPalletes(palletsCount, restrictions) {
    for (let i = 0; i < restrictions.length; i++) {
        if (restrictions[i].maxPallets >= palletsCount) {
            return restrictions[i].maxCapacity;
        }
    }
    return LoadCapacityEnum.C20000;
}

export function getMinCapacity(wb, restrictions) {

    const loadUnitSetsAll = wb.pointsLoading.reduce((res, i) => res.concat(i.loadUnitSets || []), []);

    const currentWeightTotal = loadUnitSetsAll.reduce((res, i) => res + (i.loadTotalWeigthBruttoKg || 0), 0);

    const loadCapacityValues = (restrictions || []).map(r => r.maxCapacity).sort((a, b) => b - a);

    let minRequiredCapacityWeight = loadCapacityValues[0];

    loadCapacityValues.forEach(l => {
       if (currentWeightTotal <= l) minRequiredCapacityWeight = l;
    });

    let minRequiredCapacityPallets = LoadCapacityEnum.C900, minRequiredCapacity;

    if (restrictions && restrictions.length) {

        const loadUnitSetsWithPallets = loadUnitSetsAll.filter(lus => lus.loadUnitType === PackTypeEnum.EP);

        if (loadUnitSetsWithPallets.length) {

            const currentPalletesCount = loadUnitSetsWithPallets.reduce((res, i) => res + (i.loadUnitsCount || 0), 0);

            minRequiredCapacityPallets = getWeightCapacityFromPalletes(currentPalletesCount, restrictions);
        }
    }

    minRequiredCapacity = Math.max(minRequiredCapacityPallets, minRequiredCapacityWeight);

    return minRequiredCapacity;
}
