import React from 'react';
import {DictionariesEnum} from "../../api/model/Dictionaries";
import ImportDictionary from "./importDictionary";

const PointStandardFormDictionary = ({goToForm, height, formId, fmid, disabled}) => {
    const checkError = (dictionarySettings, row, rows) => {
        const errorText = (fieldName, fieldName1) => `Введенный ${fieldName} ${fieldName1 ? `и ${fieldName1} ` : ''}уже использован в справочнике для другого Searchname адреса. Требуется корректировка`;
        const pointFMIDField = dictionarySettings.headers[0];
        const codeField = dictionarySettings.headers[1];
        const consigneeField = dictionarySettings.headers[2];
        const consigneeAddressField = dictionarySettings.headers[3];

        // НЕ может быть указан одинаковый код адреса для разных Searchname адреса
        if (
            row[codeField.key] && rows.find(r => (
                    r.id !== row.id
                    && r[codeField.key] === row[codeField.key])
                && r[pointFMIDField.key] !== row[pointFMIDField.key]
            )
        ) {
            return errorText(codeField.text);
        }

        // НЕ может быть указан одинаковый адрес грузополучателя для разных Searchname адреса
        // НЕ могут быть указаны одинаковые адреса грузополучателя и ГП/ГО для разных Searchname адреса
        // (условия выше идентичны, т.к. пустые ГП равны)
        if (
            row[consigneeAddressField.key] && rows.find(r => (
                    r.id !== row.id
                    && r[consigneeAddressField.key] === row[consigneeAddressField.key])
                && (r[consigneeField.key] === row[consigneeField.key])
                && (r[pointFMIDField.key] !== row[pointFMIDField.key])
            )
        ) {
            return errorText(consigneeAddressField.text, row[consigneeField.key] && consigneeField.text);
        }

        return false;
    };

    const onErrorFlag = (flag, setDuplicateText) => {
        setDuplicateText(
            flag,
            'Данные адреса (код адреса, адрес грузополучателя и грузополучатель) уже использованы в справочнике для другого Searchname адреса.'
        );
    };

    const customDeletedText = (allRows, rowId) => {
        const searchname = (allRows.find(r => r.id === rowId) || {}).pointFMID;
        return 'Адрес удален'.replace(' ', searchname ? ` ${searchname} ` : ' ');
    };

    const checkRequired = (dictionarySettings, row) => {
        const pointFMIDField = dictionarySettings.headers[0];
        const codeField = dictionarySettings.headers[1];
        const consigneeAddressField = dictionarySettings.headers[3];
        if (row[pointFMIDField.key] && !row[codeField.key] && !row[consigneeAddressField.key]) {
            return 'Пожалуйста, укажите "Код адреса" или "Адрес грузополучателя"';
        }
        return false;
    };

    return (
        <ImportDictionary
            disabled={disabled}
            customDeletedText={customDeletedText}
            dictionary={DictionariesEnum.POINTS}
            height={height}
            goToForm={goToForm}
            formId={formId}
            fmid={fmid}
            customCheckError={checkError}
            errorFlag="isHasDuplicateValues"
            onErrorFlag={onErrorFlag}
            customCheckRequired={checkRequired}
        />
    );
};

export default PointStandardFormDictionary;