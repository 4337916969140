import React, {useEffect, useState} from 'react';
import {generateHeaders} from "./const";

const TextAreaForTable = ({table, data: initData, setValue, disabled, onTab}) => {
    const value = (initData) => (table[initData.rowIndex].cells || [])[initData.columnIndex] || {};
    const [data, setData] = useState(null);
    const [val, setVal] = useState("");
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (data && (val !== (value(data).value || ''))) {
            setValue(
                data.rowIndex,
                data.columnIndex,
                val,
            );
        }
        if (initData) {
            setVal(value(initData).value || '');
            setData(initData);
            setStyle({
                textarea: getSize(),
                pos: getSize(true)
            });
        }
    }, [initData]);

    const getSize = (pos) => {
        const el = document.getElementById(`input-${initData.keyId}`);
        const styles = pos ? {} : {
            backgroundColor: value(initData).color
        };

        if (el) {
            const sizeParam = el.getBoundingClientRect();
            return pos ? {
                    top: `${sizeParam.top - 22}px`,
                    left: `${sizeParam.left}px`,
                }
                : {
                    ...styles,
                    height: `${sizeParam.height}px`,
                    width: `${sizeParam.width}px`,
                    top: `${sizeParam.top}px`,
                    left: `${sizeParam.left}px`,
                };
        } else {
            return styles;
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Tab') {
            onTab(e);
        }
    };

    return (
        initData && <>
            <div
                className="big-table__position"
                style={style.pos}
            >
                {generateHeaders(initData.columnIndex + 1)}{initData.rowIndex + 1}
            </div>
            <textarea
                onKeyDown={onKeyDown}
                disabled={disabled}
                style={style.textarea}
                key={initData.keyId}
                autoFocus
                value={val}
                onChange={(e) => setVal(e.target.value)}
                className="big-table__cell big-table__textarea"
            />
        </>
    );
};

export default TextAreaForTable;