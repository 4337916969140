import React, {useState} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {isBoolValue} from "../../services/utils";
import {DictionariesSettings} from "../../api/model/Dictionaries";
import {EditField} from "./editField";
import {checkRequiredFields} from "../../services/dictionaries";
import {createDictionaryItem} from "../../api/dictionaries";

const CreateEditModal = ({dictionary, children, initData = {}}) => {
    const dictionarySettings = DictionariesSettings.find(h => h.key.toString() === dictionary) || {};

    const {
        headers = [],
        customText = {},
        urlName
    } = dictionarySettings;

    const {
        addModalTitle,
        saveTitle,
        addInfo
    } = customText;

    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({});

    const handleChange = (e, {value, name}) => {
        let newRow = {...form};
        newRow[name] = (value || isBoolValue(value)) ? value : null;
        setForm(newRow);
    };

    const onClose = () => {
        setOpen(false);
        setForm({});
    };

    const onOpen = () => {
        setOpen(true);
    };

    const onSave = () => {
        createDictionaryItem(urlName, {...initData, ...form}).then(() => onClose());
    };

    const disabledSave = () => {
        return !checkRequiredFields(headers, form);
    };

    return (
        <Modal
            closeIcon
            trigger={children}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            size='small'
        >
            <Modal.Header>{addModalTitle}</Modal.Header>
            <Modal.Content>
                <Message
                    icon='info'
                    info
                    content={addInfo}
                />
                <Form className='edit-modal__form'>
                    {
                        headers.map(header => <Form.Field
                            className='edit-modal__input'
                            key={header.key}
                            required={header.isRequired}
                        >
                            <label>{header.text}</label>
                            <EditField
                                col={header}
                                handleChange={handleChange}
                                row={form}
                                className='ui input'
                            />
                        </Form.Field>)
                    }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Отмена</Button>
                <Button
                    color='green'
                    onClick={onSave}
                    disabled={disabledSave()}
                >{saveTitle}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateEditModal;