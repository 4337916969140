import moment from 'moment';
import qs from 'query-string';
import { del, download, get, post, put, upload } from '../services/http';
import PointAddress from './model/PointAddress';
import Waybill, { WaybillAvisationStatus, WaybillStatusEnum } from './model/Waybill';
import { columnsByRole } from "./model/WaybillColumn";

export function getIsoDate(point, part = "from") {
    let result = null;
    if (point.arrivalDatePlan) {
        const timeStr = (point.arrivalTimeslotPlan && point.arrivalTimeslotPlan[part])
            ? point.arrivalTimeslotPlan[part]
            : "";
        const datetime = point.arrivalDatePlan + " " + timeStr;
        result = moment(datetime, "YYYY-MM-DD HH:mm").toISOString();
    }

    return result;
}

export async function getAll(search) {

    const waybills = await get(`waybills${search}`);

    const tableData = (waybills || []).map(w => {
        const hasPointsLoading = w.pointsLoading && w.pointsLoading.length;
        const hasPointsUnloading = w.pointsUnloading && w.pointsUnloading.length;

        const hasLoads = hasPointsLoading && w.pointsLoading.some(pl => pl.loadUnitSets && pl.loadUnitSets.length);
        const hasUnloads = hasPointsUnloading && w.pointsUnloading.some(pu => pu.loadUnitsToOrderMappings && pu.loadUnitsToOrderMappings.length);

        const crossDockMap = Array.from(new Set(w.pointsLoading.filter(pl => pl.crossDockWithActivity).map(item => item.pointId)));

        return {
            ...w,

            id: w.id,

            clientId: w.clientId,

            canCopy: w.canCopy,

            providerName: w.providerName,

            slotId: w.slotId,

            reservationId: w.reservationId,

            clientName: w.clientName,

            fmid: w.fmid,

            type: w.shippingType,

            refs: hasUnloads
                ? w.pointsUnloading.map(pu => pu.loadUnitsToOrderMappings.map(p => p.recipientOrderNo).toString()).toString()
                : null,

            torg12No: hasUnloads
                ? w.pointsUnloading.map(pu => pu.loadUnitsToOrderMappings.map(p => p.torg12No).toString()).toString()
                : null,

            consignee: hasPointsUnloading
                ? w.pointsUnloading.map(pt => pt.companyName).toString()
                : null,

            status: w.status,

            statusAnomaly: w.statusAnomaly,

            shipper: w.shipper,

            shipto: hasUnloads
                ? w.pointsUnloading.map(pu => new PointAddress(pu.address))
                : null,

            loadingDate: hasPointsLoading
                ? getIsoDate(w.pointsLoading[0])
                : null,

            deliveryDate: hasPointsUnloading
                ? getIsoDate(w.pointsUnloading[w.pointsUnloading.length - 1], "to")
                : null,

            // totalUnitsAggragate: hasLoads
            //     ? w.pointsLoading
            //         .reduce((val, pl) => val + pl.loadUnitSets
            //             .reduce((val2, lus) => val2 + Number.parseInt(lus.loadUnitsCount || 0), 0), 0)
            //     : null,

            totalUnits: hasLoads
                ? w.pointsLoading
                    .reduce((val, point) => val = [...val, ...point.loadUnitSets], [])
                    .reduce((val2, loadUnitSet) => {
                        val2[loadUnitSet.loadUnitType] = (val2[loadUnitSet.loadUnitType] || 0) + loadUnitSet.loadUnitsCount;
                        return val2;
                    }, {})
                : [],

            totalWeight: hasLoads
                ? w.pointsLoading
                    .reduce((val, pl) => val + pl.loadUnitSets
                        .reduce((val2, lus) => val2 + Number.parseInt(lus.loadTotalWeigthBruttoKg || 0), 0), 0)
                : null,

            cost: w.cargoCost,

            isAuction: w.isAuction,

            readiness: w.steps,

            pickupType: w.pickupType,

            pickupId: w.pickupId,

            isValid: w.isValid,

            isPooling: w.isPooling,

            hubLoading: w.hubLoading,

            crossDockWithActivity: w.pointsLoading.some(pl => pl.crossDockWithActivity),

            crossDock: crossDockMap.length === 1 ? crossDockMap[0] : null,

            hubWithOldAvization: w.pointsLoading.some(pl => pl.avisationByEmail),

            avisationStatus: w.avisationStatus,

            canHaveAvisation: w.canHaveAvisation,

            dateCreated: w.dateCreated,

            document: w.podIsAvailable,

            services: w.services,
            returnWaybillId: w.returnWaybillId,
            reservation: w.reservation,
            reservationDriver: w.reservationDriver,
            reservationDriverPhoneNumber: w.reservationDriverPhoneNumber,
            tsunamiSendingMessage: w.tsunamiSendingMessage,
            shippingTemperatureCondition: w.shippingTemperatureCondition,
            vehicleCapacity: w.vehicleCapacity,
            cargoType: w.cargoType,
            isArchived: w.isArchive,
            parentWaybillId: w.parentWaybillId,
        };
    });

    return tableData;
}

export async function getWaybillLogs(id, isAuction) {
    const logs = await get(`waybill/${id}/logs`);
    return isAuction ? logs.filter(l => l.code >= 80 && l.code < 90) : logs;
}

export async function getWaybillById(id, reverse = false) {
    const wb = await get(`waybill/id/${id}?reverse=${reverse}`);
    return wb.map(w => new Waybill(w));
}

export async function getWaybillsByIds(ids) {
    const result = await get(`waybill/ids`, ids);
    return result;
}

export const WAYBILL_COLUMNS_KEY = "waybill/columns";
export const AUCTION_COLUMNS_KEY = 'auctions/columns';


export function getGridColumns(userData, key, cols) {
    const { email, role } = userData;
    const colsSet = columnsByRole(cols, role);

    const setColumns = setup => setGridColumns(setup, email, key);
    const data = localStorage[key];
    if (data) {
        const userColumns = JSON.parse(data);
        const userKey = window.btoa(email);

        if (userColumns && userColumns[userKey]) {
            let newSortUserColumns = colsSet.map(item => {
                const localColSettings = userColumns[userKey].find(i => i.key === item.key) || {};
                return {
                    ...item,
                    order: item.system ? item.order : localColSettings.order,
                    hide: item.system ? item.hide : localColSettings.hide,
                };
            }).sort((a, b) => a.order - b.order);

            if (data !== JSON.stringify({ [userKey]: newSortUserColumns })) {
                setColumns(newSortUserColumns);
            }
            return newSortUserColumns;
        }
    }
    return colsSet;
}

export function setGridColumns(columns, email, key) {

    const userKey = window.btoa(email);

    const data = localStorage[key];

    const dataSaved = data ? JSON.parse(data) : {};

    const dataNew = { ...dataSaved, [userKey]: columns || [] };

    localStorage[key] = JSON.stringify(dataNew);

    return columns;
}

export async function updateWaybill(dto, fetchData) {
    const invalidIdsTo0 = (d) => {
        return {
            ...d,
            pointsLoading: d.pointsLoading.map(p => ({ ...p, id: p.id < 1 ? 0 : p.id, loadUnitSets: p.loadUnitSets.map(l => ({ ...l, id: l.id < 1 ? 0 : l.id })), loadUnitsToOrderMappings: p.loadUnitsToOrderMappings.map(l => ({ ...l, id: l.id < 1 ? 0 : l.id })) })),
            pointsUnloading: d.pointsUnloading.map(p => ({ ...p, id: p.id < 1 ? 0 : p.id, loadUnitSets: p.loadUnitSets.map(l => ({ ...l, id: l.id < 1 ? 0 : l.id })), loadUnitsToOrderMappings: p.loadUnitsToOrderMappings.map(l => ({ ...l, id: l.id < 1 ? 0 : l.id })) })),
        };
    };

    const result = await post(`waybill/${dto[0].id}/update`, dto.map(d => invalidIdsTo0(d)));
    fetchData && fetchData();
    return result;
}

export async function updateWaybillClient(dto, isResend = false) {
    const result = await post(`waybill/${dto.id}/update/torg12/${isResend}`, dto);
    return result;
}

export async function createWaybill(isAuction = false) {
    const result = await get(`waybill/create${isAuction ? "?isAuction=true" : ""}`);
    return result;
}

export async function waybillPointCreate(wbId, wbPoint) {
    const result = await post(`waybill/${wbId}/points/add`, wbPoint);
    return result;
}

export async function waybillPointUpdate(wbId, wbPoint) {
    const result = await post(`waybill/${wbId}/points/update`, wbPoint);
    return result;
}

/**
 * @param {number[]} selectedIds
 */
export async function waybillsAvizo(selectedIds) {
    await post(`waybills/avizo`, selectedIds);
}

export async function waybillAvisationRegistration(dto) {
    return await post('waybill/avisation/registration', dto);
}

export async function waybillAvisationSet(dto) {
    const response = await post('waybill/avisation/timeslots/set', dto);
}

export async function getAvisationTimeslot(params) {
    const paramsNew = qs.stringify(params);
    const response = await get(`waybill/avisation/timeslots?${paramsNew}`);
    const resNew = response.map(i => ({
        date: i.date,
        timeSlots: i.timeSlots.map(val => ({ ...val, date: i.date }))
    }));
    return resNew;
}

export const getAvisDates = async (ids) => {
    return await get(`waybill/avisation/registration/dates?${qs.stringify({ ids })}`);
};

/**
 * @param {Waybill[]} scope
 */
export async function executeWaybillsInParallel(scope = []) {

    const exscope = scope.map(executeWaybill);

    const results = await Promise.all(exscope);

    const messages = results.map(r => r.resultText);

    return { messages, hasError: results.some(r => r.hasError) };
}

export async function executeWaybills(scope = []) {

    const results = [];

    for (const waybill of scope) {

        try {
            let result = await executeWaybill(waybill);

            results.push(result);

        } catch (error) {

            results.push({ resultText: `Ошибка регистрации заявки: ${error}`, hasError: true });

        }
    }

    const messages = results.map(r => r.resultText);

    return { messages, hasError: results.some(r => r.hasError) };
}

export async function importWaybills(scope = []) {
    const response = await post(`waybill/import/send`, scope);

    return response;
}

/**
 * @param {Waybill} scope
 */
export async function executeWaybill(dto) {

    const res = {
        hasError: false,
        resultText: ""
    };

    const response = await get(`waybill/execute/${dto.id}?pickupType=${dto.pickupType || 0}`);

    if (response.error) {
        res.hasError = true;
        res.resultText = `Заявка ${dto.id}:\r\n${response.error}`;
    } else {
        switch (response.status) {
            case WaybillStatusEnum.SUBMITTED:
                dto.fmid = response.fmid;
                res.resultText = `Заявка ${dto.fmid || ''} перешла в статус Отправлена`;
                break;
            case WaybillStatusEnum.ON_APPROVAL:
                dto.fmid = response.fmid;
                res.resultText = `Заявка ${dto.fmid || ''} перешла в статус На проверке`;
                break;
            case WaybillStatusEnum.DRAFT:
                if (response.avisationStatus === WaybillAvisationStatus.REQUESTED) {
                    dto.fmid = response.fmid;
                    res.resultText = `Заявка ${dto.id} перешла в статус Требует Авизации`;
                } else {
                    res.hasError = true;
                    res.resultText = `Заявки ${dto.id} осталась в статусе Черновик`;
                }
                break;
            default:
                res.hasError = true;
                res.resultText = `Заявки ${dto.id}:\r\nUnknown status of ${response.status || ""}`;
                break;
        }

        dto.status = response.status;
    }
    return res;
}

export async function executeWaybillRemote(dto) {
    const result = await get(`waybill/register/remote/${dto.id}`);
    return result;
}

export async function delWaybill(id) {
    await get(`waybill/delete/${id}`);
}

export async function copyWaybill(id) {

    const result = await get(`waybill/copy/${id}`);
    return result.id;
}

export async function cancelWaybills(ids) {

    var result = await post('waybill/cancel', ids);

    return result;
}

export async function waybillImportExel(formData) {

    var result = await upload('waybill/import/excel', formData);

    return result;
}

export async function waybillExport(ids) {
    var result = await post("waybill/export/excel", ids);

    return result;
}

export async function waybillExportAll(filter) {
    return await get(`waybill/export/excel/filter${filter}`);
}

export async function waybillPointDateCal(wbId, wbPointId) {
    var result = await post(`waybill/${wbId}/point/${wbPointId}`);
    return result;
}

export async function waybillUpdateCapacity(wbId, capacity) {
    await post(`waybill/${wbId}/capacity/${capacity}`);
}

export async function waybillResend(wbId) {
    const result = await get(`waybill/${wbId}/resend`);
    return result
}

export async function getAwisationIds(wbId) {
    return await get(`avisation/reservation/${wbId}/requires`)
}

export async function getWaybillsInVisit(wbIds) {

    const query = wbIds.map(i => `Ids=${i}`).join('&');
    const waybills = await get(`waybills/avisation?${query}`);

    const tableData = (waybills || []).map(w => {
        const hasPointsLoading = w.pointsLoading && w.pointsLoading.length;
        const hasPointsUnloading = w.pointsUnloading && w.pointsUnloading.length;

        const hasLoads = hasPointsLoading && w.pointsLoading.some(pl => pl.loadUnitSets && pl.loadUnitSets.length);
        const hasUnloads = hasPointsUnloading && w.pointsUnloading.some(pu => pu.loadUnitsToOrderMappings && pu.loadUnitsToOrderMappings.length);

        return {
            id: w.id,

            clientId: w.clientId,

            canCopy: w.canCopy,

            providerName: w.providerName,

            slotId: w.slotId,

            reservationId: w.reservationId,

            clientName: w.clientName,

            fmid: w.fmid,

            type: w.shippingType,

            refs: hasUnloads
                ? w.pointsUnloading.map(pu => pu.loadUnitsToOrderMappings.map(p => p.recipientOrderNo).toString()).toString()
                : null,

            torg12No: hasUnloads
                ? w.pointsUnloading.map(pu => pu.loadUnitsToOrderMappings.map(p => p.torg12No).toString()).toString()
                : null,

            consignee: hasPointsUnloading
                ? w.pointsUnloading.map(pt => pt.companyName).toString()
                : null,

            status: w.status,

            statusAnomaly: w.statusAnomaly,

            shipper: w.shipper,

            shipto: hasUnloads
                ? w.pointsUnloading.map(pu => new PointAddress(pu.address))
                : null,

            loadingDate: hasPointsLoading
                ? getIsoDate(w.pointsLoading[0])
                : null,

            deliveryDate: hasPointsUnloading
                ? getIsoDate(w.pointsUnloading[w.pointsUnloading.length - 1], "to")
                : null,

            // totalUnitsAggragate: hasLoads
            //     ? w.pointsLoading
            //         .reduce((val, pl) => val + pl.loadUnitSets
            //             .reduce((val2, lus) => val2 + Number.parseInt(lus.loadUnitsCount || 0), 0), 0)
            //     : null,

            totalUnits: hasLoads
                ? w.pointsLoading
                    .reduce((val, point) => val = [...val, ...point.loadUnitSets], [])
                    .reduce((val2, loadUnitSet) => {
                        val2[loadUnitSet.loadUnitType] = (val2[loadUnitSet.loadUnitType] || 0) + loadUnitSet.loadUnitsCount;
                        return val2;
                    }, {})
                : [],

            totalWeight: hasLoads
                ? w.pointsLoading
                    .reduce((val, pl) => val + pl.loadUnitSets
                        .reduce((val2, lus) => val2 + Number.parseInt(lus.loadTotalWeigthBruttoKg || 0), 0), 0)
                : null,

            cost: w.cargoCost,

            readiness: w.steps,

            pickupType: w.pickupType,

            pickupId: w.pickupId,

            isValid: w.isValid,

            isPooling: w.isPooling,

            hubLoading: w.hubLoading,

            hubWithNewAvization: w.pointsLoading.some(pl => pl.crossDockWithActivity),

            avisationStatus: w.avisationStatus,

            canHaveAvisation: w.canHaveAvisation,

            dateCreated: w.dateCreated,

            document: w.podIsAvailable,

            isArchived: w.isArchive
        };
    });

    return tableData;
}

export async function waybillResendTorg12(wbId) {
    await get(`waybill/${wbId}/resend/torg12`);
}

export async function waybillDelete(wbIds) {
    await post('waybills/delete', wbIds);
}

export async function waybillsDeleteSoft(wbIds) {
    return await del('waybills/delete/soft', { waybills: wbIds });
}

export async function waybillDeleteSoftById(wbId) {
    await del(`waybill/${wbId}/delete/soft`);
}

export async function waybillValidationStatusUpdate(wbId, isValid) {
    await post(`waybills/${wbId}/isValid/${isValid}`);
    console.log(`waybill ${wbId} validation status updated to ${isValid}`);
}

export async function GetNewSscc(count) {
    const set = await get(`sscc/generate/${count}`);
    return set;
}

export function sortByArrival(a, b) {
    if (a.arrivalDatePlan && b.arrivalDatePlan) {
        let aDate = `${a.arrivalDatePlan} ${a.arrivalTimeslotPlan || ''}`;
        let bDate = `${b.arrivalDatePlan} ${b.arrivalTimeslotPlan || ''}`;

        return aDate > bDate
            ? 1
            : aDate === bDate
                ? 0
                : -1;
    } else {
        return a.arrivalDatePlan ? 1 : b.arrivalDatePlan ? -1 : 0;
    }
};

export const getHasToMirror = (wb) => {
    if (!wb) {
        return false;
    }
    const { pointsLoading, pointsUnloading } = wb;
    if (pointsLoading.length !== 1 || pointsUnloading.length !== 1) {
        return false;
    }
    const loadPoint = pointsLoading[0];
    const unloadPoint = pointsUnloading[0];
    const { loadUnitSets } = loadPoint;
    const { loadUnitsToOrderMappings } = unloadPoint;
    return loadUnitSets.length === 1 && loadUnitsToOrderMappings.length === 1;
};

export const getMaxIndex = (wb) => {
    const points = wb.pointsLoading;
    let res = 0;
    points.forEach(({ loadUnitSets }) => {
        loadUnitSets.forEach(({ loadUnitsCount }) => {
            res += loadUnitsCount;
        });
    });

    return res;
};

export const wbIsEdit = async (id) => {
    return await get(`waybills/${id}/update/available`);
};

export const wbUpdateCompany = async params => {
    return await post(`waybill/update/company`, params);
};

export const wbsServices = async wbIds => {
    const ids = wbIds.map(i => `waybillIds=${i}`).join('&');
    return await get(`waybills/unloading/services?${ids}`);
};


export const wbsCosts = async wbIds => {
    const ids = wbIds.map(i => `Ids=${i}`).join('&');
    return await get(`waybills/cost?${ids}`);
};

export const wbsServicesUpdate = async params => {
    return await put(`waybills/unloading/services`, params);
};

export const wbsCutoffs = async wbIds => {
    const ids = wbIds.map(i => `waybillIds=${i}`).join('&');
    return await get(`waybills/unloading/services/cutOff?${ids}`);
};

export const downloadZip = async params => {
    return await download(`waybills/docs/cargo?${new URLSearchParams(params).toString()}`);
};

export const sendEmail = async params => {
    return await post(`waybills/docs/send`, params);
};

export const wbsCostsSave = async params => {
    return await post(`waybills/cost/update`, params);
};

export const getCanUseCarType = async () => {
    return await get(`waybill/carTypes/canUse`);
};

export async function getAllAuctions(search) {
    return await get(`auctions${search}`);
}

export async function auctionsExport(search) {
    return await get(`auctions/export/excel${search || ''}`);
}

export async function auctionDelete(ids) {
    await post('auctions/removeDraft', { ids });
}

export async function auctionUpdate(dto, callback) {
    const res = await post(`auctions/${dto.id}/update`, dto);
    callback && callback();
    return res;
}

export async function auctionValidate(id) {
    return await post(`auctions/launchValidate/${id}`);
}

export async function auctionStart(id) {
    return await post(`auctions/start/${id}`);
}

export async function auctionExtend(id) {
    return await post(`auctions/extend/${id}`);
}

export async function auctionCancel(id) {
    return await post(`auctions/cancel/${id}`);
}

export async function auctionCompleteWithWinner(id) {
    return await post(`auctions/completeWithWinner/${id}`);
}

export async function createRoute(id) {
    return await get(`waybill/${id}/route/create`);
}
export async function changeRoutePoints(data) {
    return await post(`waybill/route/point/swap`, data);
}
export async function changeRoutesPosition(data) {
    return await post(`waybill/route/swap`, data);
}
export async function wbRestoreFromDB(ids) {
    return await post(`waybill/unarchive`, ids);
}