import React from 'react';
import InputDropdownSearch from "../../../../components/inputs/InputDropdownSearch";
import {getDictionaryItems} from "../../../../api/importForms";

const DropdownForDictionary = ({
                                   onChange,
                                   dictionaryType,
                                   value,
                                   name,
                                   companyId,
                                   disabled
                               }) => {
    const getData = async (search) => {
        const data = await getDictionaryItems(dictionaryType, search, value, companyId);
        return new Promise((resolve) => {
            resolve(data.map(v => {
                return {
                    key: v.value,
                    value: v.value,
                    text: v.name,
                    description: v.description,
                };
            }));
        });
    };

    return (
        <InputDropdownSearch
            disabled={disabled}
            loadInitially
            value={value}
            onChange={(value) => onChange(null, {name, value})}
            getOptionsBySearch={(search) => getData(search)}
            selectOnBlur={false}
            className=""
            minLetters={0.1}
        />
    );
};

export default DropdownForDictionary;