import React, {useContext, useEffect, useState} from 'react';
import ScrollTable from "../../layout/table/scrollTable";
import {blockCheck, copyForm, deleteForm, getFormsOfClient, saveForCreateForm} from "../../api/importForms";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi, ContextUser} from "../../services/context";
import AppTableHeader from "../../components/tables/AppTableHeader";
import AppToolbarButton from "../../components/buttons/AppToolbarButton";
import {Confirm, Icon} from "semantic-ui-react";
import {preventDefault} from "../../services/utils";
import {ImportFormStatusEnum} from "../../api/model/ImportForms";
import WbsImportForm from "../waybills/wbGrid/wbGridToolbar/WbsImportForm";
import UserPermissions from "../../api/model/UserPermissions";


const ActionImportForm = ({refresh, item, history, fmid, isOnlyView}) => {
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const isDone = item.status === ImportFormStatusEnum.DONE;

    const deleteRow = () => {
        deleteForm(item.id)
            .then(() => {
                setDeleteConfirm(false);
                refresh();
            });
    };

    const onDelete = () => {
        blockCheck(item.id).then(res => {
            if (!res) {
                setDeleteConfirm(true);
            }
        });
    };

    const edit = () => {
        blockCheck(item.id).then(res => {
            if (!res) {
                history.push(`/import/${fmid}/${item.id}/edit`);
            }
        });
    };

    const copy = () => {
        copyForm(item.id).then(res => {
            if (res) {
                history.push(`/import/${fmid}/${res}/edit`);
            }
        });
    };

    const open = () => {
        history.push(`/import/${fmid}/${item.id}`);
    };

    return <div
        className="import-page__actions"
        onClick={preventDefault}
    >
        {isDone && <Icon
            onClick={open}
            name="sync"
            link
            title="Текущие загрузки"
            size="large"
            color="orange"
        />}
        {isDone && <WbsImportForm
            history={history}
            formName={item.id}
            clientName={fmid}
        >
            <Icon
                className="m-l-15"
                name="arrow alternate circle down outline"
                link
                title="Загрузить"
                size="large"
                color="teal"
            />
        </WbsImportForm>}
        {!isOnlyView && <>
            <Icon
                name="pencil"
                link
                className={isDone ? "m-l-15" : ''}
                title="Редактировать"
                size="large"
                color="blue"
                onClick={edit}
            />
            <Icon
                name="copy outline"
                link
                className="m-l-15"
                title="Копировать"
                size="large"
                color="grey"
                onClick={copy}
            />
            <Icon
                name="trash alternate"
                link
                className="m-l-15"
                title="Удалить"
                size="large"
                color="red"
                onClick={onDelete}
            />
        </>}
        <Confirm
            open={deleteConfirm}
            dimmer="inverted"
            header={'Вы уверены, что хотите удалить данную форму?'}
            content={'Если да, то нажмите на кнопку “Ок”, если нет, то на кнопку “Отмена”'}
            confirmButton={'Ок'}
            cancelButton={'Отмена'}
            onCancel={() => setDeleteConfirm(false)}
            onConfirm={deleteRow}
        />
    </div>;
};

const ImportClientPage = ({location, history, match}) => {
    const contextUser = useContext(ContextUser);
    const permissions = contextUser.current.permissions;
    const isOnlyView = !permissions.includes(UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE);

    const fmid = match.params.fmid;
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({});

    const headers = [
        new AppTableHeader('name', 'Наименование формы'),
        new AppTableHeader('status', 'Cтатус формы'),
        new AppTableHeader('formFileTaskStatus', 'Статус обработки файла'),
        new AppTableHeader('actions', 'Действия'),
    ];

    const contextNavi = useContext(ContextNavi);

    useEffect(() => {
        contextNavi.setItems([
            new LinkInfo("Импорт заявок", "/import"),
            new LinkInfo(info.companyName || fmid, `/import/${fmid}`)
        ]);
    }, [info]);

    const save = () => {
        setLoading(true);
        saveForCreateForm(fmid)
            .then(res => {
                history.push(`/import/${fmid}/${res.id}/edit`);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const actions = (refresh, item) => {
        return <ActionImportForm
            fmid={fmid}
            item={item}
            refresh={refresh}
            history={history}
            isOnlyView={isOnlyView}
        />;
    };

    const onClickRow = (item) => {
        blockCheck(item.id).then(res => {
            if (!res) {
                history.push(`/import/${fmid}/${item.id}/edit`);
            }
        });
    };

    const getRows = async (s) => {
        const data = await getFormsOfClient(fmid, s);
        const {items, ...info} = data;
        setInfo(info);
        return items;
    };

    return (
        <ScrollTable
            tableName="importClientForms"
            onClickRow={onClickRow}
            location={location}
            headers={headers}
            getRowsFunc={getRows}
            actions={actions}
            rightHeaderData={
                !isOnlyView && <AppToolbarButton
                    icon="plus"
                    onClick={save}
                    disabled={loading}
                >
                    Добавить форму
                </AppToolbarButton>
            }
        />
    );
};

export default ImportClientPage;