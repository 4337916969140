import React from 'react';
import {DictionariesEnum} from "../../api/model/Dictionaries";
import {Checkbox} from "semantic-ui-react";
import {setActiveDouble} from "../../api/importForms";
import {isBoolValue} from "../../services/utils";
import ImportDictionary from "./importDictionary";

const SchedulesDictionary = ({goToForm, height, formId, disabled}) => {
    const customActions = (row, refresh) => {
        return isBoolValue(row.isDefault) && <span
            title='Активность графика-дубля'
            className="import-dictionary__custom-actions"
        >
            <Checkbox
                disabled={disabled}
                toggle
                checked={row.isDefault}
                onChange={() => {
                    setActiveDouble(row.id)
                        .then(refresh);
                }}
            />
        </span>;
    };

    const rowOnClick = (row, setDuplicateText) => {
        setDuplicateText(
            isBoolValue(row.isDefault),
            'График является дублем по полям “Searchname адреса разгрузки” и “День загрузки”. Требуется корректировка.'
        );
    };

    return (
        <ImportDictionary
            disabled={disabled}
            rowOnClick={rowOnClick}
            dictionary={DictionariesEnum.SCHEDULES}
            customActions={customActions}
            height={height}
            goToForm={goToForm}
            formId={formId}
        />
    );
};

export default SchedulesDictionary;