import React, {useContext} from "react";
import {
    Divider,
    Icon,
    Label,
    Table,
    TableBody,
    TableCell
} from "semantic-ui-react";
import LoadUnitsToOrderMapping from "../../../api/model/LoadUnitsToOrderMapping";
import AppButtonLink from "../../../components/buttons/AppButtonLink";
import T from "../../../components/Translate";
import {ContextWb} from "../../../services/context";
import WbPointCardTabsPositionsUnloadForm from "./WbPointCardTabsPositionsUnloadForm";
import {WaybillStatusEnum} from "../../../api/model/Waybill";

export default function PointCardTabsPositionsUnload({
                                                         pointId,
                                                         positions = [],
                                                         handleDataChange,
                                                         unitsLoadedTotal,
                                                         isClient,
                                                         disabled,
                                                         isPoolingCanEdit,
                                                         isPoolingEdit,
                                                         isPoolingCanEditCompletely,
                                                         data
                                                     }) {
    const contextWb = useContext(ContextWb);
    const wb = data || contextWb.wb;
    const points = wb.pointsUnloading;
    const isPoolingDraft = wb.status === WaybillStatusEnum.DRAFT && wb.isPooling;

    async function positionAdd(position) {
        position.pointToId = pointId;
        position.id = Math.random();
        const newPositions = [...positions];
        newPositions.push(new LoadUnitsToOrderMapping(position));
        await handleDataChange("loadUnitsToOrderMappings", newPositions);
    }

    async function positionRemove(id) {
        const newPositions = [...positions];
        const targetIndex = newPositions.findIndex(p => p.id === id);
        newPositions.splice(targetIndex, 1);
        handleDataChange("loadUnitsToOrderMappings", newPositions);
    }

    async function positionUpdate(position) {
        const newPositions = [...positions];
        const target = newPositions.find(p => p.id === position.id);
        Object.assign(target, position);
        handleDataChange("loadUnitsToOrderMappings", newPositions);
    }

    const positionsPointScope = positions;
    let positionsPointScopeIndex = 0;

    const spanStyleMini = {
        minWidth: "20px",
        marginRight: "10px",
        display: "inline-block"
    };

    const spanStyle = {
        marginLeft: "10px",
        marginRight: "4px",
        display: "inline-block"
    };

    return (
        <>
            <div style={{marginTop: "0", textAlign: "left", marginBottom: "4px"}}>
                <label style={{fontSize: "13px", fontWeight: "bold"}}>
                    <T>Грузовые единицы и Заказы</T>
                </label>
                <WbPointCardTabsPositionsUnloadForm
                    wb={wb}
                    points={points}
                    header="Добавить номера заказов"
                    isAdding
                    unitsLoadedTotal={unitsLoadedTotal}
                    pointId={pointId}
                    submit={positionAdd}
                    disabled={(isClient || disabled) && !isPoolingCanEditCompletely}
                    isPoolingCanEditCompletely={isPoolingCanEditCompletely}
                >
                      <span className="f-r">
                        {!isPoolingDraft && <AppButtonLink
                            style={{ fontSize: "12px" }}
                            disabled={(isClient || disabled) && !isPoolingCanEditCompletely}>
                            добавить
                        </AppButtonLink>}
                      </span>
                </WbPointCardTabsPositionsUnloadForm>
            </div>
            <Divider fitted/>
            <div className="f-c" style={{paddingTop: "0"}}>
                <Table basic="very" selectable className="table-unloads">
                    <TableBody>
                        {positionsPointScope.map((p, index) => (
                            <WbPointCardTabsPositionsUnloadForm
                                wb={wb}
                                key={index}
                                points={points}
                                pointId={pointId}
                                header="Редактировать грузовые единицы"
                                unitsLoadedTotal={unitsLoadedTotal}
                                value={p}
                                submit={positionUpdate}
                                isClient={isClient}
                                disabled={(isPoolingDraft || disabled) && !isPoolingCanEditCompletely}
                                isPoolingCanEdit={isPoolingCanEdit}
                                isPoolingEdit={isPoolingEdit}
                                isPoolingCanEditCompletely={isPoolingCanEditCompletely}
                            >
                                <Table.Row
                                    key={positionsPointScopeIndex++}
                                    className="mouse-pointer"
                                    id={`position-item-unload_${p.id}`}
                                >
                                    {/* style={{ width: '80px' }} */}
                                    <TableCell style={{width: "90px"}}>
                                        <Label color="pink" style={{minWidth: "75px"}} horizontal>
                                            c {p.loadUnitPositionStart || 1} по{" "}
                                            {p.loadUnitPositionEnd || p.loadUnitsCount}
                                        </Label>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            maxWidth: "200px",
                                            paddingLeft: "0",
                                            fontSize: "14px"
                                        }}
                                    >
                                        <div style={{overflow: "hidden"}} className="ellipsis">
                                            {p.shipperOrderNo && "WNO: " + p.shipperOrderNo + ";"}
                                            {p.recipientOrderNo && "CNO: " + p.recipientOrderNo}
                                            {p.weightKgBrutto && (
                                                <>
                                                    ,<span style={spanStyle}>{"Вес".t}:</span>
                                                    <span>{p.weightKgBrutto}</span>
                                                </>
                                            )}
                                            {p.volumeM3 && (
                                                <>
                                                    ,<span style={spanStyle}>{"Объём".t}:</span>
                                                    <span>{p.volumeM3}</span>
                                                </>
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            verticalAlign: "baseline",
                                            width: "50px",
                                            fontSize: "14px"
                                        }}
                                    >
                                        {(!isPoolingDraft && !isPoolingEdit) && <Icon
                                            id={`position-item-unload-del_${p.id}`}
                                            disabled={disabled && !isPoolingCanEditCompletely}
                                            name="trash"
                                            className="f-r"
                                            link
                                            onClick={positionRemove.bind(null, p.id)}
                                        />}
                                    </TableCell>
                                </Table.Row>
                            </WbPointCardTabsPositionsUnloadForm>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    );
}
