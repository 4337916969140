import React, {useContext, useMemo, useState} from 'react';
import {Dropdown, Icon} from 'semantic-ui-react';
import {WaybillStatusAvizationEnum, WaybillStatusEnum,} from '../../../api/model/Waybill';
//import { pointUpdate } from '../../../api/points';
import WbPointAddForm from './WbPointAddForm';
import {ContextUser} from "../../../services/context";
import UserPermissions from "../../../api/model/UserPermissions";

export default function WbPointCardShellMenu({
                                                 wb,
                                                 wbPoint,
                                                 profile,
                                                 pointRemove,
                                                 pointReplace,
                                                 isEdit,
                                                 isClient,
                                                 disabled
                                             }) {
    const [open, setOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const menuClose = () => setOpen(false);

    const menuOpen = () => setOpen(true);

    const resetId = i => {
        return {...i, id: 0};
    };

    function doPointReplace(point) {
        // copying stuff
        point.arrivalDatePlan = wbPoint.arrivalDatePlan;
        point.arrivalDateFact = wbPoint.arrivalDateFact;
        point.arrivalTimeslotPlan = wbPoint.arrivalTimeslotPlan;
        point.arrivalTimeslotFact = wbPoint.arrivalTimeslotFact;

        point.contact = wb.isCopy ? {} : wbPoint.contact;
        point.comment = wb.isCopy ? "" : wbPoint.comment;

        point.loadUnitSets = (wbPoint.loadUnitSets || []).map(resetId);
        point.loadUnitsToOrderMappings = (wbPoint.loadUnitsToOrderMappings || []).map(resetId);
        point.services = (wbPoint.services || []).map(resetId);

        pointReplace(point);
    }

    function doPointRemove() {
        menuClose();
        pointRemove();
    }

    // async function doPointUpdate(pt) {
    //     menuClose();
    //     await pointUpdate(pt);
    // };

    const onFormClose = () => {
        setIsFormOpen(false);
    };

    const contextUser = useContext(ContextUser);
    const canEdit = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);

    const actions = useMemo(() => {
        const actions = [];

        if (
            !disabled
            && !wb.isPooling //Запрещено редактирование адресов Pooling заявок на UI
            && ![
                WaybillStatusEnum.CANCELLED,
                WaybillStatusEnum.FM_CANCELLED,
                WaybillStatusEnum.FM_DELETED
            ].includes(wb.status) //На статусах "Отмена", "Отменена FM", "Удалена FM" - редактирование адресов недоступно
        ) {

            const isDraft = [WaybillStatusEnum.DRAFT].includes(wb.status); //Статус "Черновик" - редактирование в данном статусе доступно всем ролям

            const canEditByPermission = canEdit && [
                WaybillStatusEnum.ON_APPROVAL,
                WaybillStatusEnum.EXECUTING,
                WaybillStatusEnum.CARGO_DELIVERED,
                WaybillStatusEnum.DOCS_SENT
            ].includes(wb.status); //Статус "Проверка" и выше, кроме "Отмена", "Отменена FM", "Удалена FM" - редактирование заявки зависит от пермишена у Роли "Заявки-редактирование"

            const isOnlyOne = (wbPoint.type === 0 && wb.pointsLoading.length === 1) || (wbPoint.type === 1 && wb.pointsUnloading.length === 1); //точка является единственной загрузкой или выгрузкой

            const canChangeAddressForAvisFillDriverAndConfirmed = wbPoint.type === 1 && isEdit && [
                WaybillStatusAvizationEnum.FILL_DRIVER,
                WaybillStatusAvizationEnum.CONFIRMED,
                WaybillStatusAvizationEnum.AUTO_CONFIRMED,
                WaybillStatusAvizationEnum.SORTING,
            ].includes(wb.avisationStatus); //На статусах авизации “Внести водителя”, “Авизовано” ИЗМЕНЕНИЕ и удаление адреса запрещено в точках загрузки, но в пределах временных лимитов на обновление и по истечению временных лимитов, если включена возможность обновления со стороны CS, то ИЗМЕНИТЬ адрес выгрузки можно по кнопке “Заменить адрес”

            const canChangeAddressForAvisNoneAndRequires = isEdit && [
                WaybillStatusAvizationEnum.NONE,
                WaybillStatusAvizationEnum.REQUIRES
            ].includes(wb.avisationStatus); //Статус авизации "Не требует авизации", “Требует авизации” - доступна "Замена адреса" в пределах временных лимитов и в точках загрузки и точках выгрузки

            const deleteDisabledForAvis = [
                WaybillStatusAvizationEnum.FILL_DRIVER,
                WaybillStatusAvizationEnum.CONFIRMED,
                WaybillStatusAvizationEnum.AUTO_CONFIRMED,
                WaybillStatusAvizationEnum.SORTING,
            ].includes(wb.avisationStatus); //На статусах авизации “Внести водителя”, “Авизовано” изменение и УДАЛЕНИЕ адреса запрещено в точках загрузки

            if (
                isDraft
                || (canEditByPermission && (canChangeAddressForAvisNoneAndRequires || canChangeAddressForAvisFillDriverAndConfirmed))
            ) actions.push(
                <Dropdown.Item
                    key='1'
                    icon="reply"
                    text={'Заменить адрес'.t}
                    onClick={() => {
                        menuClose();
                        setIsFormOpen(true);
                    }}
                />,
            );

            if (
                isDraft
                || (canEditByPermission && !isOnlyOne && !deleteDisabledForAvis)
            ) actions.push(
                <Dropdown.Item
                    key='2'
                    onClick={doPointRemove}
                    icon="trash alternate outline"
                    text="Удалить точку"
                />,
            );
        }

        return actions;
    }, [wb, wbPoint, menuClose, doPointRemove]);

    const trigger = (
        <Icon
            name="setting"
            size="large"
            link
            className={'icon-pointer'}
            onClick={() => (open ? menuClose() : menuOpen())}
        />
    );

    return (
        <>
            <Dropdown
                trigger={trigger}
                disabled={isClient || !actions.length}
                pointing="top right"
                direction="right"
                onBlur={menuClose}
                closeOnBlur
                //onOpen={menuOpen}
                //onClose={menuClose}
                open={open}
                icon={null}
                className="point-card-shell-header-menu"
            >
                <Dropdown.Menu>{actions.map(item => item)}</Dropdown.Menu>
            </Dropdown>

            <WbPointAddForm
                isEdit
                wbId={wb.id}
                wbPointId={wbPoint.id}
                profile={profile}
                wbClientId={wb.clientId}
                pointsExistingIds={[]}
                submit={doPointReplace}
                hideNew={wb.status !== WaybillStatusEnum.DRAFT}
                value={wbPoint}
                open={isFormOpen}
                onClose={onFormClose}
            ></WbPointAddForm>
        </>
    );
}
