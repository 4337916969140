import React, {useEffect, useState} from 'react';
import BigTable from "./bigTable";
import './style.css';
import {Button, Popup} from "semantic-ui-react";
import {adjustCellSizes, generateHeaders, initTable, tableTabs} from "./const";
import ColorPicker from "../colorPicker/colorPicker";
import ErrorsBlock from "./errorsBlock";

const Tables = ({tables = [], defaultActiveIndex, height, width, buttons, saveValue, disabled: initDisabled, errors}) => {
    const [active, setActive] = useState(null);
    const [data, setData] = useState(null);
    const [selectedCells, setSelectedCells] = useState({});
    const [openErrors, setOpenErrors] = useState(false);

    const setVal = (val) => {
        setData(data => {
            const newData = {...data};
            newData[active] = val;
            return newData;
        });
    };

    const setSelectedCellsTable = (val) => {
        setSelectedCells(data => {
            const newData = {...data};
            newData[active] = val;
            return newData;
        });
    };

    const setDataTable = (d) => {
        setVal(typeof d === "function" ? d(data[active]) : d);
    };

    useEffect(() => {
        setData(() => {
            let data = {};
            tables.forEach(t => {
                data[t.key] = adjustCellSizes(t.tableData || initTable());
            });

            return data;
        });
        if (!active || !tables.find(t => t.key === active)) {
            const activeKey = defaultActiveIndex || 0;
            setActive(tables[activeKey] ? tables[activeKey].key : null);
        }
        setSelectedCells([]);
    }, [tables]);

    const setSizes = (rowIndex, columnIndex, val) => {
        if (rowIndex !== -1) {
            setDataTable(data => {
                const newData = [...data];
                newData[rowIndex] = {
                    ...newData[rowIndex],
                    height: val
                };
                return newData;
            });
        } else {
            setValue(0, columnIndex, val, 'width', false);
        }
    };

    const setColor = (color) => {
        let array = [];
        selectedCells[active].forEach(cell => {
            const coordinates = cell.split('-');
            const rowIndex = coordinates[0];
            const columnIndex = coordinates[1];

            array.push({
                rowIndex,
                columnIndex,
                excelColumnIndex: generateHeaders(Number(columnIndex) + 1),
                color
            });

            setValue(rowIndex, columnIndex, color, 'color', false);
        });

        saveValue(array);
    };

    const setValue = (rowIndex, columnIndex, val, name = 'value', save = true) => {
        setDataTable(data => {
            const newData = [...data];
            const col = (newData[rowIndex].cells || [])[columnIndex];
            const resCell = {
                ...col,
                [name]: val
            };
            if (name === 'value') {
                resCell.messageType = null;
                resCell.message = null;
            }
            (newData[rowIndex].cells || [])[columnIndex] = resCell;
            return newData;
        });

        if (saveValue && save) {
            const resCell = {
                rowIndex,
                columnIndex,
                excelColumnIndex: generateHeaders(Number(columnIndex) + 1),
                [name]: val
            };
            if (name === 'value') {
                resCell.messageType = null;
                resCell.message = null;
            }
            saveValue([resCell]);
        }
    };

    const addRow = () => {
        setDataTable(data => {
            return [
                ...data,
                {
                    cells: data[0].cells.map(c => ({
                        value: null
                    }))
                }
            ];
        });
    };

    const addCol = () => {
        setDataTable(data => {
            return data.map(row => ({
                ...row,
                cells: [...(row.cells || []), {
                    value: null
                }]
            }));
        });
    };

    const Picker = ({}) => {
        return <div>
            <div
                className="big-tables__color-cancel"
                onClick={() => setColor(null)}
            >
                Сбросить параметры
            </div>
            <ColorPicker
                onChange={(color) => setColor(color)}
            />
        </div>;
    };

    const PopupBtn = ({disabled: disabledInit}) => {
        const disabled = disabledInit || !((selectedCells[active] || []).length);

        return <Popup
            className="big-tables__color"
            position="bottom left"
            trigger={<Button
                size="mini"
                color="orange"
                icon="eye dropper"
                content={'Цвет заливки'}
                disabled={disabled}
            />}
            disabled={disabled}
            on="click"
            content={<Picker/>}
        />;
    };

    const disabled = initDisabled || (tables.find(t => t.key === active) || {}).disabled;

    useEffect(() => {
        setOpenErrors(false);
    }, [active, disabled]);

    useEffect(() => {
        !(errors && errors.length) && setOpenErrors(false);
    }, [errors]);

    return (
        <div
            style={{
                height: height ? `${height}px` : 'auto'
            }}
            className="big-tables"
        >
            <div className="big-tables__header">
                <div className="big-tables__tabs">
                    {
                        tables.map((p) => (
                            <div
                                key={p.key}
                                className={`big-tables__tab ${active === p.key ? 'big-tables__tab_active' : ""}`}
                                onClick={() => setActive(p.key)}
                            >
                                {p.name}
                            </div>
                        ))
                    }
                </div>

                <div className="big-tables__btns">
                    {active === tableTabs[1].key && <PopupBtn disabled={disabled}/>}
                    {buttons && buttons({data, setData, active, openErrors: () => setOpenErrors(open => !open)})}
                </div>
            </div>
            <div className='display-flex'>
                {
                    data && <BigTable
                        disabled={disabled}
                        key={active}
                        data={data[active]}
                        selectedCells={selectedCells[active] || []}
                        setSelectedCells={setSelectedCellsTable}
                        height={height - 35}
                        width={width - (openErrors ? 250 : 0)}
                        setValue={setValue}
                        addCol={addCol}
                        addRow={addRow}
                        setSizes={setSizes}
                    />
                }
                {
                    openErrors && <ErrorsBlock
                        height={height}
                        errors={errors}
                    />
                }
            </div>
        </div>
    );
};

export default Tables;