import React, {useContext, useRef, useState} from 'react';
import {Button, Dropdown, Form, Header, Icon, List, Modal, Popup} from 'semantic-ui-react';
import {getPointOptionsFmidBySearch, getPointsBySearch, Od} from '../../../api/points';
import AppButtonLink from '../../../components/buttons/AppButtonLink';
import T from '../../../components/Translate';
import {toast} from '../../../services/toast';
import ActionPointForm from './ActionPointForm';
import InputDropdownSearchMultiple from "../../../components/inputs/InputDropdownSearchMultiple";
import InputDropdownSearch from "../../../components/inputs/InputDropdownSearch";
import {getCompanyOptionsBySearchUser} from "../../../api/users";
import {ContextUser} from "../../../services/context";
import UserPermissions from "../../../api/model/UserPermissions";
import Input from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import InputSelectMultiple from "../../../components/inputs/InputSelectMultiple";
import {mappingCodeTrim} from "../../../services/utils";

export default function ActionPointSearch({
                                              onClose,
                                              selectedCompany,
                                              setSelectedCompany,
                                              fmidAddress,
                                              setFmidAddress,
                                              createItem,
                                              setSelectedCompanyNew,
                                              selectedCompanyNew,
                                              companyOptions,
                                              children,
                                              handleAddToList,
                                          }) {
    const [open, setOpen] = useState(false);

    const [value, setValue] = useState({});
    const [addressSearchIsFetching, setAddressSearchIsFetching] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [searchname, setSearchname] = useState(null);
    const [mappingCode, setMappingCode] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const iRef = useRef(null);

    const contextUser = useContext(ContextUser);
    const hasPermissionFmidAddress = contextUser.current.permissions.includes(UserPermissions.FMID_ADDRESS);
    const hasPermissionMappingCode = contextUser.current.permissions.includes(UserPermissions.MAPPING_CODE);
    function onSelect(point, company, searchname, mappingCode) {
        handleAddToList(point, company, searchname.trim(), mappingCode);
    };

    function onOpen() {
        if (!companyOptions.length) {
            toast.warning('Для создания заявки требуется привязать к пользователю клиентов');
            return;
        }
        setOpen(true);
    };

    function handleClose() {
        setValue({});
        setFmidAddress('');
        setSearchname('');
        setMappingCode('');
        setSelectedCompany(null);
        onClose && onClose();
        setIsSubmitting(false);
        setOpen(false);
    };

    function searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length ? options.filter(opt =>
            opt.text && opt.text.toLowerCase().includes(q0) ||
            opt.description && opt.description.toLowerCase().includes(q0)
        ) : [];
    };

    async function handlePointSearchChange(e, { searchQuery }) {
        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;
        setAddressSearchIsFetching(hasSearch);

        if (hasSearch) {
            const newAddresses = await getPointsBySearch(searchQuery, selectedCompany);
            if (newAddresses.length) {
                const addresses = newAddresses;
                setAddressSearchIsFetching(false);
                setAddresses(addresses);
            } else {
                setAddressSearchIsFetching(false);
            }
        }
    };

    async function handlePointChange(e, dd) {
        const address = addresses.find(adr => adr.id === dd.value);
        if (address === undefined) {
            return setValue({});
        }
        setValue(address);
    };

    async function submit() {
        onSelect(selectedCompany, value.id, searchname, mappingCode);
        setValue({});
        setMappingCode('');
        onClose && onClose();
        handleClose();
    };

    async function createItemAndClose() {
        await createItem(...arguments);
        setSelectedCompanyNew(null);
        handleClose();
    }

    const valueIsOk = !!(value.id && selectedCompany);

    const addressOptions = (addresses || [])
        .map(p => new Od(p.id, p.address.value, (p.pointName || p.companyName || '').trunc(50)));
    const searchTipsText = (
        <List>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />Адрес введенный по частям, например <em>монтажная 7</em></span></List.Item>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />Название клиента, например <em>ашан</em></span></List.Item>
            <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />Название адреса для быстрого поиска</span></List.Item>
            {false && hasPermissionFmidAddress &&  <List.Item><span><Icon style={{verticalAlign: 'middle'}} color={'black'} size='mini' name='circle' />Название адреса в системе FM</span></List.Item>}
        </List>
    );

    const searchTips = (
        <Popup
            flowing
            trigger={<Icon name='info circle' color="blue" style={{ fontSize: "1.1em" }} />}
            content={
                <>
                    <Header>Подсказка по поиску</Header>
                    <div>Для поиска <em>адреса FM</em> используйте один из критериев</div>
                    {searchTipsText}
                    <div>Возможно использовать часть названия клиента и часть адреса, например <em>ашан вавилова 3</em></div>
                </>
            }
        />
    );

    const newPointLink = (
        <ActionPointForm
            companyOptions={companyOptions}
            selectedCompany={selectedCompanyNew}
            setSelectedCompany={setSelectedCompanyNew}
            handleAddToList={handleAddToList}
            createItem={createItemAndClose}
        >
            <AppButtonLink
                style={{ textDecoration: 'underline', paddingTop: '1px' }}
                floated="right"
            >
                <T>Создать адрес FM</T>
            </AppButtonLink>
        </ActionPointForm>

    );

    async function searchAddressOptions(e) {
        setFmidAddress(e.target.value.trim());
        if (fmidAddress) {
            const res = await getPointOptionsFmidBySearch(fmidAddress.trim(), selectedCompany);
            setAddresses(res);
            if (res && res[0] && res[0].id) {
                setValue(res[0]);
            } else setValue({});
        }
    }

    function onKeyPressFmid (e) {
        if (e.key === "Enter") {
            iRef.current.inputRef.current.blur && iRef.current.inputRef.current.blur()
        }
    }


    return (
        <Modal
            trigger={children}
            closeOnDimmerClick={false}
            size="small"
            dimmer="inverted"
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={handleClose}
        >
            <Modal.Header><T>Добавить мой адрес</T></Modal.Header>
            <Modal.Content>
                <div className="m-b-10">
                    <Form
                        disabled={isSubmitting}
                    >
                        <Form.Field>
                            <label><T>Выбрать клиента</T></label>
                            {companyOptions.length > 1 ? (<InputDropdownSearchMultiple
                                    getOptionsBySearch={getCompanyOptionsBySearchUser}
                                placeholder={"Выбрать клиента".t}
                                    options={companyOptions}
                                    value={selectedCompany}
                                    onChange={(values, options) => {
                                        setSelectedCompany(values);
                                        setValue({});
                                        setFmidAddress('');
                                        setSearchname('');
                                        setMappingCode('');
                                        setAddresses([]);
                                    }}
                                />) :
                                (<InputDropdownSearch
                                    getOptionsBySearch={getCompanyOptionsBySearchUser}
                                    placeholder={"Выбрать клиента".t}
                                    options={companyOptions}
                                    value={selectedCompany}
                                    onChange={(values, options) => setSelectedCompany(values)}
                                />)}
                        </Form.Field>
                        <Form.Field>
                            <label><T>Название для быстрого поиска</T></label>
                            { <Input
                                placeholder={"Название для быстрого поиска".t}
                                value={searchname}
                                onChange={(e) => setSearchname(e.target.value.trimStart())}
                                onBlur={(e) => setSearchname(e.target.value.trim())}
                            />}
                        </Form.Field>
                        {
                           hasPermissionMappingCode &&
                           <Form.Field>
                               <label>Код для маппинга</label>
                               <InputSelectMultiple
                                   placeholder={"Код для маппинга".t}
                                   value={mappingCode}
                                   onChange={(e, {value}) => setMappingCode(mappingCodeTrim(value))}
                               />
                           </Form.Field>
                        }
                        {hasPermissionFmidAddress && (<Form.Field>
                            <label><T>Название адреса в системе FM</T></label>
                            { <Input
                                ref={iRef}
                            placeholder={"Название адреса в системе FM".t}
                            value={fmidAddress}
                            onChange={(e) => setFmidAddress(e.target.value.trimStart())}
                            onKeyPress={onKeyPressFmid}
                            onBlur={searchAddressOptions}
                        />}
                        </Form.Field>)}
                        <Form.Field>
                            <label><T>Найти в адресах FM</T> {searchTips} {newPointLink}</label>
                            <Dropdown
                                clearable

                                selection
                                search={searchTextAndDescription}
                                value={value.id || null}
                                noResultsMessage={'Результатов не найдено'.t}
                                onSearchChange={handlePointSearchChange}
                                onChange={handlePointChange}

                                className="InputDropdown input-pointSearch"
                                placeholder={`Введите адрес по частям | название клиента | название для быстрого поиска`.t}
                                fluid

                                closeOnChange

                                options={addressOptions || []}
                                loading={addressSearchIsFetching === true}
                                selectOnBlur={false}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Клиент</label>
                            <span>{value.companyName || <span className="tc-grey">нет данных</span>}</span>
                        </Form.Field>
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}><T>Отмена</T></Button>
                <Button
                    loading={isSubmitting}
                    positive
                    onClick={submit}
                    disabled={!valueIsOk}><T>Добавить</T></Button>
            </Modal.Actions>
        </Modal>
    );
};

