import React from 'react';
import {Header, Label} from "semantic-ui-react";
import {WaybillStatusEnum} from "../../../api/model/Waybill";

const RouteHeader = ({index, route}) => {
    return (
        <div className="wbPointCardShellHeader-container points-block__route_header">
            <div className="wbPointCardShellHeader-item-left">
                <Label size="big" circular>{index + 1}</Label>
            </div>
            <div className="wbPointCardShellHeader-item-center">
                <Header className="m-0">
                    Маршрут <a href={`/waybills/${route.id}/${route.status !== WaybillStatusEnum.DRAFT ? 'view/info' : 'edit'}`}>{route.id}</a>
                </Header>
            </div>
        </div>
    );
};

export default RouteHeader;