/** @typedef {number} WaybillPointServiceEnum **/

/** @enum {PointServiceEnum} */
export const PointServiceEnum = {
	LOADUNLOAD: 1,
	LOADERS: 2,

	CRATING: 5,
	PALLETSERVING: 6,
	PALLETSPLITTING: 7,

    WAITING: 10,
    TIMESLOT: 11,     //LTL Only - Доставка во временной слот
    AFTERHOURS: 12,   //LTL Onlt - Доставка в не рабочее время
    WEEKEND: 13,      //LTL Only Доставка в праздники и выходные дни

    ESCORT: 20,

    BOXRESP: 30,

    TEMPREC: 40,
    STAKES: 41,
    STRAPS: 42,
    PALLETS: 45,

    CARGO_PROTECTION: 50,
    TERM_PROTECTION: 51,

    PAID_ENTRY: 60
};

export const PointServiceValueTypeEnum = {
    NONE: 0,
    INFO: 1,
    INT: 2,
    NUM: 3,
    OPT: 9
};

export class PointServiceOption {

    /**
     * @param {PointServiceEnum} key
     * @param {String} text
     * @param {String} valueTypeText
    */
    constructor(scope, title, key,  valueType, valueTip) {
        this.scope = scope;
        this.title = title;
        this.key = key;
        this.valueType = valueType;
        this.valueTip = valueTip;
    }
}

const LTL = "LTL";
const FTL = "FTL";

export const PointServiceOptions = [
    new PointServiceOption(
        null,
        "Обрешетка",
        PointServiceEnum.CRATING,
        PointServiceValueTypeEnum.INT,
        "m3"
    ),
    new PointServiceOption(
        null,
        "Грузчики",
        PointServiceEnum.LOADERS,
        PointServiceValueTypeEnum.INT,
        "Количество"
    ),
    new PointServiceOption(
        null,
        "ПРР (силами водителя)",
        PointServiceEnum.LOADUNLOAD,
        PointServiceValueTypeEnum.INT,
        "Количество паллет"
    ),
    new PointServiceOption(
        FTL,
        "Выкатка паллет",
        PointServiceEnum.PALLETSERVING,
        PointServiceValueTypeEnum.INFO,
        "За ТС"
    ),
    new PointServiceOption(
        null,
        "Раздел паллет",
        PointServiceEnum.PALLETSPLITTING,
        PointServiceValueTypeEnum.INT,
        "паллет"
    ),
    new PointServiceOption(
        null,
        "Плановый простой",
        PointServiceEnum.WAITING,
        PointServiceValueTypeEnum.NUM,
        "Количество часов"
    ),
    new PointServiceOption(
        LTL,
        "Доставка во временной слот",
        PointServiceEnum.TIMESLOT,
        PointServiceValueTypeEnum.INFO,
        "диапазон 5 ч. max",
    ),
    new PointServiceOption(
        LTL,
        "Доставка в не рабочее время",
        PointServiceEnum.AFTERHOURS,
        PointServiceValueTypeEnum.INFO,
        "Доставка с 18-00 до 8-00"
    ),
    new PointServiceOption(
        LTL,
        "Доставка в праздники и выходные дни",
        PointServiceEnum.WEEKEND,
        PointServiceValueTypeEnum.NONE,
    ),

    new PointServiceOption(
        FTL,
        "Охрана/сопровождение груза",
        PointServiceEnum.ESCORT,
        PointServiceValueTypeEnum.OPT,
        ["Охранник в кабине", "Машина сопровождения"]
    ),

    new PointServiceOption(
        FTL,
        "Покоробочное экспедирование груза",
        PointServiceEnum.BOXRESP,
        PointServiceValueTypeEnum.NONE
    ),

    new PointServiceOption(
        FTL,
        "Термописцы",
        PointServiceEnum.TEMPREC,
        PointServiceValueTypeEnum.NONE
    ),
    new PointServiceOption(
        FTL,
        "Коники",
        PointServiceEnum.STAKES,
        PointServiceValueTypeEnum.INT,
        "Количество"
    ),
    new PointServiceOption(
        FTL,
        "Наличие ремней/распорок",
        PointServiceEnum.STRAPS,
        PointServiceValueTypeEnum.INT,
        "Количество"
    ),
    new PointServiceOption(
        LTL,
        "Пустые поддоны",
        PointServiceEnum.PALLETS,
        PointServiceValueTypeEnum.INT,
        "Количество"
    ),
    new PointServiceOption(
        null,
        "Защита грузов",
        PointServiceEnum.CARGO_PROTECTION,
        PointServiceValueTypeEnum.NONE
    ),
    new PointServiceOption(
        null,
        "Защита сроков",
        PointServiceEnum.TERM_PROTECTION,
        PointServiceValueTypeEnum.NONE
    ),
    new PointServiceOption(
        null,
        "Платный въезд",
        PointServiceEnum.PAID_ENTRY,
        PointServiceValueTypeEnum.NONE
    ),
];

export class PointService {

    /**
     * @param {PointServiceEnum} key
     * @param {String} value
    */
    constructor(key, value) {
        this.key = key;
        this.value = value;
    }
}

export const servicesConst = () => {
    let obj = {};
    PointServiceOptions.forEach(item => {
        obj = {
            ...obj,
            [item.key.toString()]: item.title
        }
    });
    return obj;
};
