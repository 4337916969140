import React, {useState} from "react";
import {isBoolValue} from "../../services/utils";
import {EditField} from "./editField";
import {Table} from "semantic-ui-react";

const EditRow = ({initialRow, columns, actions}) => {
    const [row, setRow] = useState({...initialRow});

    const handleChange = (e, {value, name}) => {
        let newRow = {...row};
        newRow[name] = (value || isBoolValue(value)) ? value : null;
        setRow(newRow);
    };

    return <Table.Row
        className={'notifications__new-row'}
    >
        {
            columns.map(col => (
                <Table.Cell key={`tc_e_${col.key}`} className="notifications__table-cell">
                    <EditField
                        row={row}
                        col={col}
                        handleChange={handleChange}
                    />
                </Table.Cell>
            ))
        }
        {actions(row, true)}
    </Table.Row>;
};

export default EditRow;