import React from 'react';
import {Button, Modal} from "semantic-ui-react";

const WbEditAvisTimeslot = ({onClose, editTimeslotData: data}) => {
    return (
        <Modal
            open={data && data.isUpdated}
            onClose={onClose}
        >
            <Modal.Header>Период таймслота изменен</Modal.Header>
            {data && data.newTimeslot && <Modal.Content>
                {`В брони №${data.newTimeslot.reservationId} изменен период таймслота (${data.newTimeslot.oldTimeslot.from}-${data.newTimeslot.oldTimeslot.to}) на новый период: `}
                <b>{data.newTimeslot.newTimeslot.from}-{data.newTimeslot.newTimeslot.to}</b>
            </Modal.Content>}
            <Modal.Actions>
                <Button color="blue" onClick={onClose}>Ok</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default WbEditAvisTimeslot;
