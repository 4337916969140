import React from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Header
} from 'semantic-ui-react';

export default function ErrorNotFound(props) {
    return (
        <>
            <Container text style={{ paddingTop: '7em' }}>
                <Header as='h1'>Страница не найдена :(</Header>
                <p>Возможно вы найдете информацию на <Link to="/">главной странице</Link></p>
            </Container>
        </>
    );
}