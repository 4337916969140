import UserPermissions from "./UserPermissions";

export default class PointColumn {
    /**
     * @param {string} key
     * @param {string} text
     * @param {number} width
     * @param {boolean} [hide]
     */
    constructor(key, text, hide = false, width = null, permission = null) {
        this.key = key;
        this.text = text;
        this.width = width;
        this.hide = hide;
        this.permission = permission
    }
}

export const pointColumnsSet = [
    new PointColumn("client", "Привязка".t, false),
    new PointColumn("pointStatus", "Статус".t, false),
    new PointColumn("address", "Адрес".t, false),
    new PointColumn("companyName", "Грузополучатель".t, false),
    new PointColumn("pointType", "Тип грузополучателя".t, false),
    new PointColumn("fmid", "Название адреса в системе FM".t, true, null, UserPermissions.FMID_ADDRESS),
    new PointColumn("mappingCode", "Код для маппинга".t, true, null, UserPermissions.MAPPING_CODE),
    new PointColumn("companySearchName", "Название для быстрого поиска".t, false, 3),
    new PointColumn("bookmarkType", "Избранный", false)
];
