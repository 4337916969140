import React from 'react';
import {withRouter} from 'react-router-dom';
import {Divider, Form, Grid, GridColumn, Header} from 'semantic-ui-react';
import {debounce} from "awesome-debounce-promise";
import { setDocsManagement } from "../../../../api/companies";

const saveData = debounce(co => {
    return setDocsManagement(co);
}, 500);

export default withRouter(CompanyTabDocs);

function CompanyTabDocs({data, setData}) {

    function updateCompany(companyChanged) {
        setData(companyChanged);
        saveData({
            fmid: companyChanged.fmid,
            recipeForParcel: companyChanged.recipeForParcel,
            palletBoxLabelFtlRail: companyChanged.palletBoxLabelFtlRail,
        });
    }

    return (
        <Grid>
            <Grid.Row>
                <GridColumn width={10}>
                    <Header className="m-b-10">Управление документами</Header>
                    <Divider className="m-t-0"/>
                    <Form size="small">
                        <Form.Checkbox
                            label={'Экспедиторская расписка для Parcel'.t}
                            checked={data.recipeForParcel}
                            onChange={(e, {checked}) => updateCompany({...data, recipeForParcel: checked})}
                        />
                    </Form>
                </GridColumn>
            </Grid.Row>
            <Grid.Row className='m-t-10'>
                <GridColumn width={10}>
                    <Header className="m-b-10">Паллетная/покоробочная этикетка</Header>
                    <Divider className="m-t-0"/>
                    <Form size="small">
                        <Form.Checkbox
                            label={'Паллетная/покоробочная этикетка для FTL, Rail'.t}
                            checked={data.palletBoxLabelFtlRail}
                            onChange={(e, {checked}) => updateCompany({...data, palletBoxLabelFtlRail: checked})}
                        />
                    </Form>
                </GridColumn>
            </Grid.Row>
        </Grid>
    );

}

