import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function NavbarPath(props) {
    const items = props.items;
    const itemsLength = items.length;
    const linkStyle = { color: '#4183c4' };

    return (
        <Breadcrumb style={{ fontSize: '16px' }}>
            {
                items.map((i, idx) => {
                    const isLink = (itemsLength !== 1) && (idx !== (itemsLength - 1));

                    return <React.Fragment key={idx}>
                        {(idx > 0) && <Breadcrumb.Divider icon='right chevron' />}
                        <Breadcrumb.Section
                            as={isLink && Link}
                            to={i.url || ''}
                            style={isLink ? linkStyle : {}}
                        >
                            {i.name}
                        </Breadcrumb.Section>
                    </React.Fragment>;
                })
            }
        </Breadcrumb>
    );
} 