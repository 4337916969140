import React from 'react';
import {Button, Message, Modal} from "semantic-ui-react";


const RgSendModal = ({open, onClose, onSend, confirmProgress}) => {
    return (
        <Modal
            open={open}
        >
            <Modal.Header>Отправка в FM</Modal.Header>
            <Modal.Content>
                <Message
                    icon="info"
                    info
                    content={
                        <>
                            <div>Вы заполнили статусы по всем заявкам реестра.</div>
                            <div>По готовности отправлять информацию в FM, нажмите Отправить в FM.</div>
                            <div>После отправки в FM редактирование статусов, комментариев и стоимости будет
                                недоступно.
                            </div>
                        </>
                    }
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color="green"
                    onClick={onSend}
                    loading={confirmProgress}
                    disabled={confirmProgress}
                >
                    Отправить в FM
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default RgSendModal;
