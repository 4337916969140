import React, {useContext, useEffect} from 'react';
import {ContextFooter, ContextNavi} from "../../services/context";
import {LinkInfo} from "../../layout/navbar/Navbar";
import ImportFormEdit from "./ImportFormEdit";
import ImportTaskHeader from "./ImportTaskHeader";

const ImportTaskPage = ({history, match}) => {
    const contextNavi = useContext(ContextNavi);
    const contextFooter = useContext(ContextFooter);

    const fmid = match.params.fmid;
    const id = match.params.id;
    const taskId = match.params.taskId;

    const setNavi = (form, task) => {
        contextNavi.setItems([
            new LinkInfo("Импорт заявок", "/import"),
            new LinkInfo(form.companyName || fmid, `/import/${fmid}`),
            new LinkInfo(form.name || "Новая форма", `/import/${fmid}/${id}`),
            new LinkInfo(task.taskFileName || taskId, `/import/${fmid}/${id}/tasks/${taskId}`),
        ]);
    };

    const header = (props) => {
        return <ImportTaskHeader {...props}/>;
    };

    useEffect(() => {
        return () => {
            contextFooter.setIndicator(null);
        };
    }, []);

    return (
        <ImportFormEdit
            history={history}
            fmid={fmid}
            id={id}
            setNavi={setNavi}
            taskId={taskId}
            onlyTableMode
            header={header}
        />
    );
};

export default ImportTaskPage;