import React from 'react';
import {Icon} from "semantic-ui-react";

const Indicator = ({errors, validText}) => {
    const keys = Object.keys(errors);
    const isValid = keys.every(key => !errors[key]);
    if (!errors) {
        return null;
    }

    const iconName = isValid ? 'checkmark' : 'exclamation triangle';
    const iconColor = isValid ? 'green' : 'orange';
    const text = isValid ? validText : '';

    const keyOfFirstError = keys.find(k => errors[k]);
    const error = keyOfFirstError ? errors[keyOfFirstError].text : '';

    return (
        <div className='indicator'>
            <Icon
                name={iconName}
                color={iconColor}
            />
            <span>
                {text} {error}
            </span>
        </div>
    );
};

export default Indicator;