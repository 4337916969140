import React from 'react';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';
import { pointApprove } from '../../../../api/points';
import T from '../../../../components/Translate';

export default function PointModalApprove({ 
    updateState,
    updateData,
    data,
    close,
    loading,
    onOk
}) {
    const submit = async () => {
            
        updateState({ loading: true }); 
    
        await pointApprove(data.id, { statusComment: data.statusComment });
                
        updateState({ open: false, loading: false });

        onOk && onOk();
    };

    return (
        <React.Fragment>
            <Modal.Header><T>Завершение корректировки</T></Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Field>
                        <label><T>Опциональный комментарий</T></label>
                        <TextArea
                            placeholder={'комментарий'.t}
                            autoHeight
                            rows={3}
                            value={data.statusComment || ''}
                            onChange={e => updateData({ statusComment: e.target.value })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={loading}
                    onClick={close}
                >
                    <T>Отмена</T>
                </Button>
                <Button
                    disabled={loading}
                    color="orange"
                    onClick={submit}
                >
                    <T>Отправить запрос</T>
                </Button>
            </Modal.Actions>
        </React.Fragment>
    );
}