import React from 'react';
import { Statistic } from 'semantic-ui-react';
import { PackTypeEnum } from '../../../api/model/Enums';
import T from '../../../components/Translate';

export default function LoadStatistics({ wb }) {

    let totalPallets = 0, totalBoxes = 0, totalWeight = 0, totalVolume = 0;
    let totalEmptyPallets = 0, totalOversizedPallets = 0, totalLowPallets = 0, totalHigPallets = 0, totalLongBox = 0;

    let totalPalletsPlan = 0, totalBoxesPlan = 0, totalEmptyPalletsPlan = 0, totalOversizedPalletsPlan = 0, totalLowPalletsPlan = 0, totalHigPalletsPlan = 0, totalLongBoxPlan = 0;

    const loadUnitSets = (wb.pointsLoading || []).reduce((val, p) => val = val.concat(p.loadUnitSets), []);

    if (loadUnitSets && loadUnitSets.length) {
        loadUnitSets.forEach(p => {
            if (p.loadUnitType === PackTypeEnum.EP) {
                totalPallets = totalPallets + (Number.parseInt(p.loadUnitsCount) || 0);
                totalPalletsPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.BX) {
                totalBoxes = totalBoxes + (Number.parseInt(p.loadUnitsCount) || 0);
                totalBoxesPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.OT) {
                totalOversizedPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                totalOversizedPalletsPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.PP) {
                totalEmptyPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                totalEmptyPalletsPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.LP) {
                totalLowPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                totalLowPalletsPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.HP) {
                totalHigPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                totalHigPalletsPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            if (p.loadUnitType === PackTypeEnum.LB) {
                totalLongBox += (Number.parseInt(p.loadUnitsCount) || 0);
                totalLongBoxPlan += (Number.parseInt(p.planLoadUnitsCount) || 0);
            }
            p.loadTotalWeigthBruttoKg && (totalWeight = totalWeight + Number.parseFloat(p.loadTotalWeigthBruttoKg));
            p.loadTotalVolumeM3 && (totalVolume = totalVolume + Number.parseFloat(p.loadTotalVolumeM3));
        });
    }

    const formatNumber = (number) => {
        if (number % 1 !== 0) {
            return number.toFixed(3).replace(/0+$/, '');
        }
        return number;
    };

    const value = (value, text) => {
      return value ? <Statistic horizontal>
              <Statistic.Value>{value}</Statistic.Value>
              <Statistic.Label>{text}</Statistic.Label>
          </Statistic> : null;
    };

    const values = (valueFact, valuePlan, text) => {
        //const valuePlanIsVisible = valuePlan && (valueFact !== valuePlan);
        //return (valueFact || valuePlan) ? <>
        //    { value(valueFact, `${text} ${valuePlanIsVisible ? 'факт' : ''}`)}
        //    {valuePlanIsVisible ? value(valuePlan, `${text} план`) : null}
        //</> : null;
        
        return <>
            {valueFact && valueFact > 0 ? value(valueFact, `${text} ${valuePlan ? 'факт':''}`):null}
            {valuePlan && valuePlan > 0 ? value(valuePlan, `${text} план`):null}
        </>;
    };

    return (
        <Statistic.Group className='load-statistics' size="mini" style={{ margin: 0 }}>
            {values(totalPallets, totalPalletsPlan, 'паллет')}
            {values(totalEmptyPallets, totalEmptyPalletsPlan, 'пустых поддонов')}
            {values(totalOversizedPallets, totalOversizedPalletsPlan, 'негабаритных паллет')}
            {values(totalLowPallets, totalLowPalletsPlan, 'низких паллет')}
            {values(totalHigPallets, totalHigPalletsPlan, 'высоких паллет')}
            {values(totalLongBox, totalLongBoxPlan, 'длинных коробов')}
            {values(totalBoxes, totalBoxesPlan, 'коробов')}
            <Statistic horizontal>
                <Statistic.Value>{totalWeight && formatNumber(totalWeight)}</Statistic.Value>
                <Statistic.Label><T>КГ БРУТТО</T></Statistic.Label>
            </Statistic>
            {totalVolume ? <Statistic horizontal>
                <Statistic.Value>{totalVolume && formatNumber(totalVolume)}</Statistic.Value>
                <Statistic.Label><T>ОБЪЕМ М3</T></Statistic.Label>
            </Statistic> : null}
        </Statistic.Group>
    );
}
