import React, {useEffect, useState} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import { companyCutoffDateLTLAdd } from '../../../../../api/companyCutoffs';
import { WeekDayOptions } from '../../../../../api/model/Enums';
import T from '../../../../../components/Translate';

export function CutoffsLTLDateAdd({children, onAdd, companyId}) {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isClickSave, setIsClickSave] = useState(false);

    useEffect(() => {
        if (isClickSave) {
            const errorsForm = getErrorsForm();
            setErrors(errorsForm);
        }
    }, [data]);

    function getErrorsForm() {
        let errorsNew = [];
        if (data && (data.weekdaySubmitting === undefined || data.weekdaySubmitting === null)) {
            errorsNew.push('weekdaySubmitting')
        }
        if (data && (data.weekdayLoading === undefined || data.weekdayLoading === null)) {
            errorsNew.push('weekdayLoading')
        }
        return errorsNew;
    }

    function updateData(prop) {
        setData({...data, ...prop});
    }

    function onClickClose() {
        setIsClickSave(false);
        setData({});
        setErrors([]);
        setOpen(false);
    }

    async function save() {
        setIsClickSave(true);
        const errorsForm = getErrorsForm();
        if (errorsForm.length > 0) {
            setErrors(errorsForm);
        } else {
            setLoading(true);
            try {
                const newRule = await companyCutoffDateLTLAdd(companyId, data);
                if (newRule)
                    onAdd(data);
            } finally {
                setLoading(false);
                onClickClose();
            }
        }
    }

    return (
        <Modal
            trigger={children}
            dimmer="inverted"
            size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={onClickClose}
        >
            <Modal.Header><T>Добавление ограничения по дню недели</T></Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Select
                        error={errors.includes('weekdaySubmitting')}
						required
                        label={'День недели отправки заявки'.t}
                        placeholder={'День недели отправки заявки'.t}
                        options={WeekDayOptions}
                        value={data.weekdaySubmitting}
                        onChange={(e, { value }) => updateData({ weekdaySubmitting: value })}
                    />
                    <Form.Select
                        error={errors.includes('weekdayLoading')}
						required
                        label={'День передачи груза ФМ'.t}
                        placeholder={'День передачи груза ФМ'.t}
                        options={WeekDayOptions}
                        value={data.weekdayLoading}
                        onChange={(e, { value }) => updateData({ weekdayLoading: value })}
                    />
                </Form>
                {errors.length > 0 && <Message warning>
                    <T>Заполните обязательные поля</T>
                </Message>}
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={onClickClose}><T>Отмена</T></Button>
                <Button disabled={loading} primary onClick={save}><T>Сохранить</T></Button>
            </Modal.Actions>
        </Modal>
    );
}
