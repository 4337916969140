import React, {useEffect, useState} from 'react';
import {
    copyRuleApi,
    createRuleApi,
    deleteRuleApi,
    getRulesApi,
    getRulesListApi,
    saveRuleApi
} from "../../../../api/calculatingFieldsRules";
import TableComponent from "../../../autocomplete/TableComponent";
import {Button, Grid, Icon, Menu} from "semantic-ui-react";
import AddModalComponent from "../../../autocomplete/AddModalComponent";
import T from "../../../../components/Translate";
import Search from "../../../autocomplete/search";
import {exportFile} from "../../../../services/export";

const CalculatingFieldsRules = ({match}) => {
    const [rules, setRules] = useState([]);


    useEffect(() => {
        getRules();
    }, []);


    const getRules = async () => {
        const result = await getRulesListApi(match.params.id);

        setRules(result)

    };

    const confirmDelete = async (id, companyId) => {
        return await deleteRuleApi(companyId, id);
    };

    const handleCopy = async (id, companyId) => {

        return await copyRuleApi(companyId, id);

    };

    const handleGetRuleById = async (id, companyId) => {
        return await getRulesApi(companyId, id);
    };

    const handleRuleEdit = async (id, params, companyId) => {
        return await saveRuleApi(companyId, id, params);
    };

    const handleRuleCreate = async (params, companyId) => {
        return await createRuleApi(companyId, params);
    };

    const [rulesWithSearch, setRulesWithSearch] = useState([]);

    useEffect(() => {
        !rulesWithSearch.length && setRulesWithSearch(rules);
    }, [rules]);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Search
                            setRulesWithSearch={setRulesWithSearch}
                            rules={rules}
                        />
                    </Grid.Column>
                    <Grid.Column
                        className='display-flex menu-buttons'
                        width={11}
                        align="right"
                    >
                        <Menu
                            className="waybills-toolbar shd-inset menu-button-style"
                            size="small"
                            borderless
                        >
                            <Menu.Menu>
                                <Menu.Item onClick={() => exportFile(match.params.id)}>
                                    <Icon color="blue" name="arrow up"/>
                                    <T>Экспорт конструктора</T>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        <AddModalComponent indexRule={rules.length + 1} companyId={match.params.id} fetchData={getRules}
                                           getRuleById={handleGetRuleById}
                                           createRule={handleRuleCreate}
                                           editRule={handleRuleEdit}
                        >
                            <Button primary>
                                <Icon name="plus"/>
                                <T>Добавить правило</T>
                            </Button>
                        </AddModalComponent>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className='calculating-fields-rules__table'>
                <TableComponent
                    companyId={match.params.id}
                    rules={rulesWithSearch}
                    getRules={getRules}
                    deleteRule={confirmDelete}
                    getRuleById={handleGetRuleById}
                    copyRule={handleCopy}
                    createRule={handleRuleCreate}
                    editRule={handleRuleEdit}
                />
            </div>
        </>
    );
};

export default CalculatingFieldsRules;
