import React, {useEffect, useState} from 'react';
import UserForm from './UserForm';
import { postUser } from '../../../api/users';
import { Modal, Button } from 'semantic-ui-react';
import T from '../../../components/Translate';

export default function UserFormNew({
	children,
	fetchData,
	companyOptions,
	roleOptions = []
}) {

    const centered = true;
    const size = "small";

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const isValid = validateEmail(data.email) && data.roleId && data.firstName;

	function validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	  }

    async function submit() {
        try {
            setLoading(true);
			await postUser(data);
			fetchData && fetchData();
        } catch(error) {
            // there can be validation errors
            return false;
        } finally {
            setLoading(false);
            onCloseModal();
		}
	}

	function update(prop) {
        const userUpdated = { ...data, ...prop };

        //setErrors([]);
        setData(userUpdated);
    }

    function onCloseModal() {
        setData({});
        setOpen(false)
    }

    useEffect(() => {
        setData(data => ({
            ...data,
            companyOptions
        }));
    }, [companyOptions]);

    return (
        <Modal
            trigger={children}
            centered={centered}
            dimmer="inverted"
            size={size}
            closeIcon
            open={open}
            onOpen={() => setOpen(true)}
            onClose={onCloseModal}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header><T>Новый пользователь</T></Modal.Header>
            <Modal.Content>
				<UserForm
					data={data}
					setData={setData}
					isEdit={false}
					update={update}
					companyOptions={data.companyOptions || []}
                    profileOptions={roleOptions}
				/>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onCloseModal}
                    disabled={loading}><T>Отмена</T></Button>
                <Button
                    positive
                    disabled={loading || !isValid}
                    onClick={submit} ><T>Сохранить</T></Button>
            </Modal.Actions>
		</Modal>
	);
}
