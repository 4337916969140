import React from 'react';
import {Icon} from "semantic-ui-react";
import {RuleStatusEnum, RuleStatusOptions} from "../../../api/model/ImportForms";
import RuleFields from "./ruleFields";


const RuleStatus = ({value}) => {
    const statusData = RuleStatusOptions.find(s => s.key === value);

    return statusData ? <Icon
        name={statusData.icon}
        color={statusData.color}
        size="large"
    /> : null;
};

const FormRule = ({item, disabled, form, setValue, errors, companyId, checkSchedules}) => {
    const value = form[item.columnName];
    return (
        <div className="form-rules__row">
            <div className="m-t-5">
                <RuleStatus value={
                    errors[item.columnName]
                        ? errors[item.columnName].type
                        : ((item.isRequired || value) && RuleStatusEnum.DONE)
                }/>
            </div>
            <div className="m-t-5">
                <b>{item.displayName} {item.isRequired && <span className='color-red'>*</span>}</b>
            </div>
            <RuleFields
                companyId={companyId}
                disabled={disabled}
                form={form}
                setValue={setValue}
                data={item}
                checkSchedules={checkSchedules}
            />
        </div>
    );
};

export default FormRule;