import moment from 'moment';
import React from 'react';
import { Table } from 'semantic-ui-react';
import { PointTimeslot } from '../../../api/model/PointTimeslot';
import { RecordData, TableRecord, TableRecordElement } from './record/Record';


const styleNoBorderYellow = {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderTop: '2px solid #fbbd08'
};

const dateFormat = "DD.MM.YYYY";

/**  */
export default function CardLoading({ point, getServiceName, submitPoint }) {

    const noData = "[нет данных]";
    const getValue = val => val ? ": " + val : "";

    const shipper = new RecordData("Грузоотправитель", point.companyName || noData);

    const adr = <span>{point.address || noData}</span>;

    const formatNumber = (number) => {
        if (number % 1 !== 0) {
            return number.toFixed(3).replace(/0+$/, '');
        }
        return number;
    };

    const buildContact = (contact) => {
        if (!contact) return null;
        const { name, phone } = contact;
        if (name && phone) return `${name}, ${phone}`;
        if (!name && !phone) return null;
        return `${(name || '')}${phone || ''}`;
    };

    const loadDate = new RecordData("Дата прибытия на адрес", moment(point.arrivalDatePlan).format(dateFormat) || noData);    // "Дата привоза на FM"
    const loadTime = new RecordData("Время прибытия на адрес", (point.arrivalTimeslotPlan && new PointTimeslot(point.arrivalTimeslotPlan).toString()) || noData);        // "Время привоза на FM"
    const services = new RecordData("Опции", point.services && point.services.length && point.services.map(s => `${getServiceName(s.key)}${getValue(s.value)}`).join('; '));
    const contact = new RecordData("Контакт", buildContact(point.contact));

    const loadUnitsCount = new RecordData("Количество грузовых единиц", point.loadUnitSets.reduce((result, us) => result + Number.parseInt(us.loadUnitsCount || 0), 0));
    const loadWeight = new RecordData("Вес отправки кг брутто", formatNumber(point.loadUnitSets.reduce((result, us) => result + Number.parseFloat(us.loadTotalWeigthBruttoKg || 0), 0)));
    const loadVolume = new RecordData("Объем, м3", formatNumber(point.loadUnitSets.reduce((result, us) => result + Number.parseFloat(us.loadTotalVolumeM3 || 0), 0)));
	const sscc = point.loadUnitSets.map(lus => lus.loadUnitsSSCCNos).join(',');
	const loadSSCC = new RecordData("SSCC (штрихкод грузовой единицы)", sscc);


    const tableLayout = (
        <Table basic fixed style={styleNoBorderYellow}>
            <Table.Body>
                <Table.Row style={{backgroundColor: '#eeeeee'}}>
                    <Table.Cell><TableRecord data={shipper} /></Table.Cell>
                    {point.comment
                        ? <>
                            <Table.Cell colSpan="2"><TableRecordElement label="Адрес загрузки">{adr}</TableRecordElement> </Table.Cell>
                            <Table.Cell><TableRecord data={new RecordData("Комментарий", point.comment)} /></Table.Cell>
                        </>
                        : <Table.Cell colSpan="3"><TableRecordElement label="Адрес загрузки">{adr}</TableRecordElement></Table.Cell>
                    }
                </Table.Row>
                <Table.Row>
                    <Table.Cell><TableRecord data={loadDate} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadTime} /></Table.Cell>
                    <Table.Cell><TableRecord data={services} /></Table.Cell>
                    <Table.Cell><TableRecord data={contact} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><TableRecord data={loadUnitsCount} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadWeight} /></Table.Cell>
                    <Table.Cell><TableRecord data={loadVolume} /></Table.Cell>
					<Table.Cell><TableRecord data={loadSSCC} /></Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );

    return tableLayout;
}
