import React from 'react';
import {Label} from "semantic-ui-react";
import {RegistrieStatusOptions} from "../../api/model/registries";

const RgStatus = ({status, className}) => {
    const o = RegistrieStatusOptions.find(o => o.key === status);

    if (o && o.text) {
        return <Label color={o.color || null} className={className}><b>{o.text}</b></Label>;
    }
    return null;
};

export default RgStatus;
