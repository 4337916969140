import React from 'react';
import {Grid} from "semantic-ui-react";
import {RecordData, TableRecord} from "../record/Record";
import moment from "moment";
import {PointTimeslot} from "../../../../api/model/PointTimeslot";
import {PointServiceOptions} from "../../../../api/model/PointService";
import {formatNumber} from "../../../../services/utils";
import {RequestTypeOptions} from "../../../../api/model/Enums";

const CCard = ({point, isLoading}) => {
    const noData = "[нет данных]";

    const getServiceName = (key) => {

        const serviceOption = PointServiceOptions.find(s => s.key === key);
        if (serviceOption)
            return serviceOption.title;
        else
            return key;
    };

    const getValue = val => val ? ": " + val : "";

    const address = new RecordData(point.address || noData, point.companyName || noData);
    const loadDate = new RecordData("Дата прибытия на адрес", moment(point.arrivalDatePlan).format("DD.MM.YYYY") || noData);
    const loadTime = new RecordData("Время прибытия на адрес", (point.arrivalTimeslotPlan && new PointTimeslot(point.arrivalTimeslotPlan).toString()) || noData);
    const contact = new RecordData("Контакт", (point.contact || {}).name);
    const comment = new RecordData("Комментарий", point.comment);
    const services = new RecordData("Опции", point.services && point.services.length && point.services.map(s => `${getServiceName(s.key)}${getValue(s.value)}`).join('; '));

    const UnitHeader = ({from, to}) => <Grid.Row className='point-card__unit-header'>
        <Grid.Column>
            Позиции: с {from} по {to}
        </Grid.Column>
    </Grid.Row>;

    const LoadUnitSet = ({l}) => {
        const index = point.loadUnitSets.findIndex(u => u.id === l.id);
        const from = index > 0 ? point.loadUnitSets[index - 1].loadUnitsCount + 1 : 1;

        const loadUnitsCount = new RecordData("Количество грузовых единиц", l.loadUnitsCount);
        const loadWeight = new RecordData("Вес отправки кг брутто", formatNumber(l.loadTotalWeigthBruttoKg));
        const loadVolume = new RecordData("Объем, м3", formatNumber(l.loadTotalVolumeM3));
        const loadSSCC = new RecordData("SSCC (штрихкод грузовой единицы)", l.loadUnitsSSCCNos);

        return <>
            <UnitHeader from={from} to={l.loadUnitsCount}/>
            <Grid.Row className="point-card__data" columns={2}>
                <Grid.Column>
                    <Grid.Row>
                        <TableRecord data={loadUnitsCount}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={loadVolume}/>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row>
                        <TableRecord data={loadWeight}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={loadSSCC}/>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </>;
    };

    const getOrderName = (orderType) => {
        let result = null;
        if (orderType) {
            const option = RequestTypeOptions.find(o => o.key === orderType);
            if (option)
                result = option.text;
        }
        return result;
    };

    const UnloadUnitSet = ({l}) => {
        const recipientOrderNo = new RecordData("Номер заказа грузополучателя", l.recipientOrderNo);
        const shipperOrderNo = new RecordData("Номер заказа грузоотправителя", l.shipperOrderNo);
        const torg12No = new RecordData("Номер ТОРГ-12", l.torg12No);
        const orderType = new RecordData("Тип заказа", getOrderName(l.orderType));
        const weightKgBrutto = new RecordData("Вес, кг", l.weightKgBrutto);
        const volumeM3 = new RecordData("Объем, м3", l.volumeM3);

        return <>
            <UnitHeader from={l.loadUnitPositionStart} to={l.loadUnitPositionEnd}/>
            <Grid.Row className="point-card__data" columns={2}>
                <Grid.Column>
                    <Grid.Row>
                        <TableRecord data={recipientOrderNo}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={torg12No}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={weightKgBrutto}/>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row>
                        <TableRecord data={shipperOrderNo}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={orderType}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={volumeM3}/>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </>;
    };

    return (
        <Grid divided="vertically" className={`point-card point-card_${isLoading ? 'yellow' : 'green'}`}>
            <Grid.Row className="point-card__address" columns={1}>
                <Grid.Column>
                    <TableRecord data={address}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="point-card__data" columns={2}>
                <Grid.Column>
                    <Grid.Row>
                        <TableRecord data={loadDate}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={contact}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={services}/>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row>
                        <TableRecord data={loadTime}/>
                    </Grid.Row>
                    <Grid.Row>
                        <TableRecord data={comment}/>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
            {
                isLoading && point.loadUnitSets.map(loadUnit => <LoadUnitSet l={loadUnit}/>)
            }
            {
                !isLoading && point.loadUnitsToOrderMappings.map(loadUnit => <UnloadUnitSet l={loadUnit}/>)
            }
        </Grid>
    );
};

export default CCard;