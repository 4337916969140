import React from 'react';
import {withRouter} from 'react-router-dom';
import {Grid, GridColumn, List, Menu, MenuItem} from 'semantic-ui-react';
import {CargoTypeOptions, PickupTypeOptions, ThermoModeOptions} from '../../../api/model/Enums';
import {PointServiceOptions} from '../../../api/model/PointService';
import {WaybillCarTypeOptions, WaybillStatusEnum, WaybillTypeEnum} from '../../../api/model/Waybill';
import CardLoading from './CardLoading';
import CardUnloading from './CardUnloading';
import LoadStatistics from './LoadStatistics';
import PointsVisual from './PointsVisual';
import {Record, RecordData} from './record/Record';
import {pointUpdate} from '../../../api/points';
import O from "../../../components/Option";
import AddressesAndCargo from "./AddressesAndCargo/AddressesAndCargo";

function getRecords(wb) {

    const getText = o => (o && o.text) || "нет данных";

    return [
        new RecordData('Номер заявки FM', wb.fmid),
        wb.__isRoute && new RecordData('Номер основной заявки FM', <a href={`/waybills/${wb._idMain}/view/info`}>{wb._fmIdMain}</a>),
        new RecordData('Клиент', wb.clientId),
        new RecordData('Поставщик', wb.providerName),
        new RecordData("Тип сервиса доставки", wb.shippingType),
        (wb.shippingType === WaybillTypeEnum.FTL && !wb._viewCarType) && new RecordData("Грузоподъемность", wb.shippingType === WaybillTypeEnum.FTL ? `${(wb.vehicleCapacity / 1000)} т` : "не применимо"),
        (wb.shippingType === WaybillTypeEnum.Rail || wb._viewCarType) && new RecordData("Тип ТС", WaybillCarTypeOptions.getText(wb._viewCarType ? wb.carTypeName : wb.carType)),
        (![WaybillTypeEnum.Rail, WaybillTypeEnum.RailLTL].includes(wb.shippingType) && !wb._viewCarType) && new RecordData("Температурный режим",  getText(ThermoModeOptions.find(o => o.key === wb.shippingTemperatureCondition))),
        (wb.shippingType === WaybillTypeEnum.FTL) && wb.shippingTemperatureCondition === 60 ?
            new RecordData('Температурный режим клиента', wb.temperatureCondition ? `${wb.temperatureCondition.from} ${wb.temperatureCondition.to}` : '') : null,
        new RecordData("Тип груза", getText(CargoTypeOptions.find(o => o.key === wb.cargoType))),
        new RecordData("Стоимость груза без НДС", wb.cargoCost),
        new RecordData("Способ доставки груза", getText(PickupTypeOptions.find(i => i.value === wb.pickupType))),
        wb.isPooling && new RecordData("Номер слота", wb.slotId),
        new RecordData('Номер перевозки', wb.tripNumber)
    ].filter(r => !!r);
}

function WbView({wb, fetchWb, routes}) {

    function getServiceName(key) {

        const serviceOption = PointServiceOptions.find(s => s.key === key);
        if (serviceOption)
            return serviceOption.title;
        else
            return key;
    }

    async function submitPoint(point) {
        await pointUpdate(point);
        await fetchWb();
    }

    const generalRecords = getRecords(wb);

    let topBlock = null;

    switch (wb.status) {
        case WaybillStatusEnum.EXECUTING:
        case WaybillStatusEnum.CARGO_DELIVERED:
        case WaybillStatusEnum.ON_APPROVAL:
        case WaybillStatusEnum.DOCS_SENT:
            topBlock = <PointsVisual data={wb.steps}/>;
            break;
        default:
            break;
    }

    const loadUnitsSetsAll = (wb.pointsLoading || []).reduce((val, point) => val = [...val, ...point.loadUnitSets], []);
    return (
        <>
            {topBlock}
            <Grid className="m-t-0 h-100" divided>
                <GridColumn width={4}>
                    <Menu borderless style={{minHeight: '49px'}}>
                        <Menu.Item><b>Общая информация</b></Menu.Item>
                    </Menu>
                    <div style={{padding: '7px'}}>
                        <List relaxed>
                            {generalRecords.map(r => <Record data={r} key={r.name}/>)}
                        </List>
                    </div>
                </GridColumn>
                <GridColumn width={12}>
                    <Menu borderless style={{marginRight: "7px"}}>
                        <Menu.Item><b>Адреса и грузы</b></Menu.Item>
                        <Menu.Menu position="right" style={{marginRight: '20px'}}>
                            <MenuItem style={{flex: 'unset'}}>
                                <LoadStatistics wb={wb}/>
                            </MenuItem>
                        </Menu.Menu>
                    </Menu>
                    {
                        !wb.__isRoute && routes && routes.length
                            ? <AddressesAndCargo
                                wb={wb}
                                routes={routes && routes.filter(r => !r.isDraftRoute)}
                            />
                            : <div style={{padding: '7px'}}>
                                {(wb.pointsLoading || []).map(point => <CardLoading
                                    key={point.id}
                                    point={point}
                                    getServiceName={getServiceName}
                                    submitPoint={submitPoint}
                                />)}
                                {(wb.pointsUnloading || []).map(point => <CardUnloading
                                    key={point.id}
                                    point={point}
                                    getServiceName={getServiceName}
                                    submitPoint={submitPoint}
                                    loadUnitSets={loadUnitsSetsAll}
                                    loadUnitsToOrderMappings={point.loadUnitsToOrderMappings}/>)}
                            </div>
                    }
                </GridColumn>
            </Grid>
        </>
    );
}

export default withRouter(WbView);

