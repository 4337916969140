import React, {useState} from 'react';
import FormRules from "./formRules/formRules";
import SchedulesDictionary from "./SchedulesDictionary";
import PointStandardFormDictionary from "./PointStandardFormDictionary";
import {importFormModesEnum} from "../../api/model/ImportForms";

const FormBlock = ({height, formId, checkSchedules, disabled, fmid, ...props}) => {
    const [mode, setMode] = useState(importFormModesEnum.FORM);

    switch (mode) {
        case importFormModesEnum.SCHEDULES:
            return <SchedulesDictionary
                disabled={disabled}
                height={height}
                goToForm={() => setMode(importFormModesEnum.FORM)}
                formId={formId}
            />;
        case importFormModesEnum.POINTS:
            return <PointStandardFormDictionary
                disabled={disabled}
                height={height}
                goToForm={() => setMode(importFormModesEnum.FORM)}
                formId={formId}
                fmid={fmid}
            />;
        case importFormModesEnum.FORM:
        default:
            return <FormRules
                {...props}
                disabled={disabled}
                checkSchedules={checkSchedules}
                height={height}
                setMode={setMode}
            />;
    }
};

export default FormBlock;