import React, {useState} from 'react';
import {Rnd} from "react-rnd";
import {enableResizingAllFalse, generateHeaders} from "./const";

const ResizeCell = ({isCol, index, data, setSelectedCells, setSizes, selectedCells}) => {
    const [isResizing, setIsResizing] = useState(false);

    const onResize = (e, direction, ref) => {
        setSizes(!isCol ? ref.offsetHeight : ref.offsetWidth);
    };

    const keys = (index, isCol) => {
        const keys = Object.keys(!isCol ? (data[0] || {}).cells : data);
        return keys.map((d, i) => !isCol ? `${index - 1}-${i}` : `${i}-${index - 1}`);
    };

    const onClick = () => {
        if (!isResizing) {
            let cells = [];

            if (index === 0) {
                data.forEach((d, i) => {
                    cells = [
                        ...cells,
                        ...keys(i + 1, false)
                    ];
                });
            } else {
                cells = keys(index, isCol);
            }

            setSelectedCells(cells);
        }
    };

    const enableResizing = !isCol
        ? {
            bottom: true
        }
        : {
            right: true
        };

    const getValue = () => {
        if (index === 0) {
            return null;
        } else {
            if (isCol) {
                return generateHeaders(index);
            } else {
                return index;
            }
        }
    };

    const value = getValue();

    const isSelected = (selectedCells || []).length && selectedCells.every(s => s.split('-')[!isCol ? 0 : 1] === (index - 1).toString());

    return (
        <Rnd
            className={`${isCol ? 'big-table__cell_first-row' : 'big-table__cell_first-col'} ${(isResizing || isSelected) ? 'big-table__cell_resizing' : ''}`}
            disableDragging
            enableResizing={{
                ...enableResizingAllFalse,
                ...enableResizing
            }}
            size={{
                height: '100%',
                width: '100%'
            }}
            onResizeStart={() => setIsResizing(true)}
            onResizeStop={(...props) => {
                onResize(...props);
                setIsResizing(false);
            }}
            onClick={onClick}
        >
            <div>{value}</div>
        </Rnd>
    );
};

export default ResizeCell;