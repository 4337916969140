import React from 'react';
import { Icon } from 'semantic-ui-react';

export default function RowDeleteAction({row, rows, setRows, removeAsync}) {

	function remove() {
		removeAsync(row);

		const index = rows.indexOf(row);
		const rowsUpdated = [...rows];
		rowsUpdated.splice(index, 1);
		setRows(rowsUpdated);
	}

	return (
		<Icon fitted link title={"удалить настройку".t} name="delete" onClick={remove} size="large" />
	);
};