import React, {useState, useEffect} from "react";
import {Button, Divider, Form, Header, Input, Message, Modal, TextArea} from "semantic-ui-react";
import InputDate from "../../components/inputs/InputDate";
import {createBanner, editBanner, getBanner} from "../../api/banners";

const CreateBannerModal = ({open, onClose, id, fetchData}) => {
    let [params, setParams] = useState({});
    let [loader, setLoader] = useState(false);

    useEffect(() => {
        id && handleGetBanner();
    }, [open]);

    const handleGetBanner = async() => {
        const params = await getBanner(id);
        setParams(params);
    };

    const handleClose = () => {
        setParams({});
        onClose();
    };

    const handleChange = (e, {name, value}) => {
        setParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };

    const handleCreate = async () => {
        setLoader(true);
        try {
            if (!id) {
                await createBanner(params);
            } else {
                await editBanner(params);
            }
            handleClose();
            fetchData();
        } finally {
            setLoader(false);
        }

    };

    return (
        <Modal open={open} closeOnDimmerClick={false} onClose={handleClose}>
            <Modal.Header>{`${id ? 'Редактирование' : 'Создание'} информационного баннера`}</Modal.Header>
            <Modal.Content>
                <Message
                    icon="info"
                    info
                    content={
                        <div>
                            Информационные баннеры будут видны поставщикам на главном экране в
                            указанный период.
                            <br/>
                            <br/>
                            Пользователь будет видеть информационный баннер с Заголовком и Текстом, а также кнопкой с указанным текстом пока не нажмет на нее.
                        </div>
                    }
                />
                <Form className="banner-form">
                    <Form.Field>
                        <div className="banner-form_counter">
                            <label>Заголовок</label>
                            <Input
                                value={params.title}
                                name="title"
                                placeholder="Укажите заголовок"
                                maxLength={100}
                                onChange={handleChange}
                            />
                            <span>{`${params.title ? params.title.length : 0}/100`}</span>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="banner-form_counter">
                            <label>Текст</label>
                            <TextArea
                                value={params.text}
                                name="text"
                                placeholder="Укажите текст информационного баннера"
                                maxLength={200}
                                onChange={handleChange}
                            />
                            <span>{`${params.text ? params.text.length : 0}/200`}</span>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="banner-form_counter">
                            <label>Текст кнопки</label>
                            <Input
                                value={params.buttonText}
                                name="buttonText"
                                maxLength={80}
                                placeholder="Укажите текст кнопки"
                                onChange={handleChange}
                            />
                            <span>{`${
                                params.buttonText ? params.buttonText.length : 0
                                }/80`}</span>
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div className="banner-form_url">
                            <label>URL перехода</label>
                            <Input
                                fluid
                                value={params.url}
                                placeholder="http://example.com"
                                name="url"
                                onChange={handleChange}/>
                        </div>
                    </Form.Field>
                    <Header className="m-b-10">Период показа</Header>
                    <Divider className="m-t-0"/>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Начиная с</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChange(null, {name: "periodFrom", value})
                                }
                                value={params.periodFrom}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Включительно до</label>
                            <InputDate
                                icon="calendar outline"
                                position="bottom center"
                                onChange={value =>
                                    handleChange(null, {name: "periodTo", value})
                                }
                                value={params.periodTo}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Отменить</Button>
                <Button primary loading={loader} disabled={loader} onClick={handleCreate}>{id ? 'Сохранить' : 'Создать'}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateBannerModal;
