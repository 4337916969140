import React, {useState} from 'react';
import {Icon, Input} from 'semantic-ui-react';
import qs from "query-string";
import { withRouter } from "react-router-dom";


const TableSearch = ({handleSearch, hasSearch: initHasSearch, isUrlSearch, location, history}) => {
    const q = qs.parse(location.search);
    const [searchValue, setSearchValue] = useState(isUrlSearch ? (q || {}).search : "");

    const hasSearch = !isUrlSearch ? initHasSearch : (q || {}).search;

    const updateQuery = (q) => {
        history.push('?' + qs.stringify(q));
    };

    const search = e => {
        if (e.key === 'Enter') {
            document.activeElement.blur();
            !isUrlSearch
                ? handleSearch(searchValue)
                : updateQuery({
                    ...(q || {}),
                    search: searchValue
                });
        }
    };

    function clearSearch() {
        setSearchValue("");
        !isUrlSearch
            ? handleSearch("", true)
            : updateQuery({
                ...(q || {}),
                search: undefined
            });
    }

    const placeholder = 'введите не менее 3 символов для поиска';


    return (
        <Input
            icon
            iconPosition="left"
            transparent
            className="f-l"
            style={{marginLeft: '9px', minWidth: '500px'}}
            placeholder={placeholder}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={search}
            value={searchValue || ''}
        >
            <input style={{fontSize: '14px'}}/>
            <Icon
                link={hasSearch}
                name={hasSearch ? "delete" : "search"}
                style={{top: '1px'}}
                onClick={hasSearch ? clearSearch : null}
            />
        </Input>
    );
}

export default withRouter(TableSearch);