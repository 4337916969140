import React from 'react';
import { FullscreenControl, Map, Placemark, YMaps } from 'react-yandex-maps';

export default function PointViewMap({point}) {

    let mapPoint = [55.751574, 37.573856];
    if (point.address && point.address.geoLat && point.address.geoLon) {
        const geoLat = Number.parseFloat(point.address.geoLat);
        if (geoLat) {
            const geoLon = Number.parseFloat(point.address.geoLon);
            if (geoLon) {
                mapPoint = [geoLat, geoLon];
            }
        }
    }

    const mapState = {
        center: mapPoint,
        zoom: 16,
    };

    const mapStyle = {
        width: '350px',
        height: "350px",
        paddingTop: "22px"
    };

    return (
        <YMaps>
            <Map style={mapStyle} defaultState={mapState} >
                <FullscreenControl />
                <Placemark geometry={mapState.center} />
            </Map>
        </YMaps>
    );
}