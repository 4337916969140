import React, { useState } from 'react';
import { Button, Comment, Form, Feed, Icon } from 'semantic-ui-react';
import { pointCommentCreate } from '../../../../api/points';
import T from '../../../../components/Translate';
import { PointStatusEnum } from '../../../../api/model/Point';
import HistoryItem from '../../../_shared/HistoryItem';

const CommentItem = ({data}) => (
    <Comment>
        <Comment.Avatar as={Icon} name="user" size="large" color="grey"  />
        <Comment.Content>
            <Comment.Author as='a'>{data.createdByEmail}</Comment.Author>
            <Comment.Metadata><div>{data.createdOn}</div></Comment.Metadata>
            <Comment.Text>{data.text}</Comment.Text>
        </Comment.Content>
    </Comment>
);

export default function PointViewTabComments({point, updatePoint, isClient}) {

    const [comment, setComment] = useState(""); 
	const [loading, setLoading] = useState(false);
	const editableStatuses = [
        PointStatusEnum.ACCEPTED,
        PointStatusEnum.NEEDS_CORRECTION
    ];

    const adminCanSend = !isClient && editableStatuses.includes(point.statusId);
    const clientCanSend = isClient && point.statusId === PointStatusEnum.NEEDS_CORRECTION;

    async function create() {

        setLoading(true);
       
        const commentNew = await pointCommentCreate(point.id, comment);
        const pointNew = { ...point };

        if (!pointNew.comments)
            pointNew.comments = [];

        pointNew.comments.push(commentNew);
        
        updatePoint(pointNew);
        setComment("");
        setLoading(false);
    }

    return (
        <div style={{paddingTop: "16px"}}>
            <Feed>
				{point.comments ? point.comments.map(i => <HistoryItem {...i} />) : null}
			</Feed>
            {(adminCanSend || clientCanSend) &&
                <Form reply>
                    <Form.TextArea
                        style={{maxWidth: '650px'}}
                        // className="te-100"
                        // label={"Комментарий к адресу для пользователя".t}
                        value={comment || ''}
                        onChange={e => setComment(e.target.value)}
                    />
                    <Button loading={loading} onClick={create} labelPosition='left' icon='edit' primary content={"Добавить".t} />
                </Form>
            }
        </div>

    );
}