import {dateInputs, FieldTypesEnum, ProcessingTypesEnum, timeInputs} from "../../../api/model/ImportForms";

export const getValueByTypeForSave = (type, value, fieldType) => {
    if (!value) {
        return [];
    }

    let array = [];

    const getString = (index, inputs) => {
        const v = value[inputs[index].name];
        return (v || v === false) ? `${v}` : '';
    };

    switch (type) {
        case ProcessingTypesEnum.REPLACE_CUSTOM:
            if (fieldType === FieldTypesEnum.DATE) {
                const toString = index => getString(index, dateInputs);
                array = [
                    toString(3),
                    toString(0),
                    toString(1),
                    toString(2),
                ];
            }
            if (fieldType === FieldTypesEnum.TIME) {
                const toString = index => getString(index, timeInputs);
                array = [
                    toString(2),
                    toString(0),
                    toString(1),
                ];
            }
            break;
        case ProcessingTypesEnum.REPLACE:
            (value || []).forEach(v => {
                array.push(v.search || '');
                array.push(v.replace || '');
            });
            break;
        case ProcessingTypesEnum.TRIM:
            (value || []).forEach(v => {
                array.push(v.trimType || '');
                array.push(v.value || '');
            });
            break;
        case ProcessingTypesEnum.SPLITTERS:
            array = value;
            break;
        case ProcessingTypesEnum.POSITION:
            array = (value || []).map(v => v.value);
            break;
        default:
            array.push(value);
            break;
    }

    return array;
};

const splitArrayIntoChunks = (arr, chunkSize) => {
    let result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        let chunk = arr.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
};

export const getValueByTypeForView = (type, value, fieldType) => {
    let resultValue;

    switch (type) {
        case ProcessingTypesEnum.REPLACE_CUSTOM:
            if (fieldType === FieldTypesEnum.DATE) {
                resultValue = {
                    [dateInputs[3].name]: value[0],
                    [dateInputs[0].name]: value[1],
                    [dateInputs[1].name]: value[2],
                    [dateInputs[2].name]: value[3],
                };
            }
            if (fieldType === FieldTypesEnum.TIME) {
                resultValue = {
                    [timeInputs[2].name]: value[0],
                    [timeInputs[0].name]: value[1],
                    [timeInputs[1].name]: value[2] === `${true}`,
                };
            }
            break;
        case ProcessingTypesEnum.TRIM:
            resultValue = splitArrayIntoChunks((value || []), 2).map(v => ({
                trimType: v[0] || '',
                value: v[1] || '',
            }));
            break;
        case ProcessingTypesEnum.REPLACE:
            resultValue = splitArrayIntoChunks((value || []), 2).map(v => ({
                search: v[0] || '',
                replace: v[1] || '',
            }));
            break;
        case ProcessingTypesEnum.SPLITTERS:
            resultValue = value;
            break;
        case ProcessingTypesEnum.POSITION:
            resultValue = [
                {value: value ? value[0] : null},
                {value: value ? value[1] : null}
            ];
            break;
        default:
            resultValue = value && value.length ? value[0] : null;
            break;
    }

    return resultValue;
};