import React from 'react';
import {Divider, Form, Grid, Input} from "semantic-ui-react";
import InputDropdown from "../../../components/inputs/InputDropdown";
import {AuctionTypeOptions} from "../../../api/model/Enums";
import {restrictionFloat} from "../../../services/utils";
import DateTime from "../../../components/inputs/DateTime";
import {AuctionStatusEnum} from "../../../api/model/Waybill";
import moment from "moment";

const AuctionBlock = ({dto = {}, updateData, errors, setError, disabled}) => {
    const {auction} = dto;

    const setParam = (values) => {
        Object.keys(values).forEach(v => (values[v] && errors.includes(v)) && setError(errors.filter(e => e !== v)));
        updateData({
            auction: {
                ...auction,
                ...values
            }
        });
    };

    const fieldsIsDisabled = ![AuctionStatusEnum.DRAFT, AuctionStatusEnum.CREATED, AuctionStatusEnum.CANCELLED].includes(auction.auctionStatus);

    return (
        <Form>
            <Grid>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Form.Field required error={errors.includes('auctionType')}>
                            <label>{'Тип аукциона'.t}</label>
                            <InputDropdown
                                options={AuctionTypeOptions}
                                placeholder={'Тип аукциона'.t}
                                value={auction.auctionType}
                                onChange={auctionType => setParam({auctionType})}
                                disabled={disabled || fieldsIsDisabled}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field error={errors.includes('auctionStart')}>
                            <label>{'Дата и время начала аукциона'.t}</label>
                            <DateTime
                                max={auction.auctionEnd ? moment(auction.auctionEnd).toDate() : null}
                                onChange={auctionStart => setParam({auctionStart})}
                                value={auction.auctionStart}
                                isDisabled={disabled || fieldsIsDisabled}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field error={errors.includes('auctionEnd')}>
                            <label>{'Дата и время окончания аукциона'.t}</label>
                            <DateTime
                                min={auction.auctionStart ? moment(auction.auctionStart).toDate() : null}
                                onChange={auctionEnd => setParam({auctionEnd})}
                                value={auction.auctionEnd}
                                isDisabled={disabled || fieldsIsDisabled}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field error={errors.includes('comment')}>
                            <label>{'Комментарий'.t}</label>
                            <Input
                                disabled={disabled}
                                placeholder={'Комментарий'.t}
                                onChange={(e, {value}) => setParam({comment: value})}
                                value={auction.comment || ''}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Form.Field required error={errors.includes('initialOffer')}>
                            <label>{'Начальная стоимость, без НДС'.t}</label>
                            <Input
                                placeholder="Начальная стоимость, без НДС"
                                value={auction.initialOffer}
                                onChange={(e, {value}) => setParam({initialOffer: restrictionFloat(value, 2, 0)})}
                                disabled={disabled || fieldsIsDisabled}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>{'Стоимость страхования грузов без НДС'.t}</label>
                            <Input
                                disabled
                                placeholder="Стоимость страхования грузов без НДС"
                                value={auction.cargoInsuranceWithoutVATCost || ''}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>{'Стоимость страхования сроков без НДС'.t}</label>
                            <Input
                                disabled
                                placeholder="Стоимость страхования сроков без НДС"
                                value={auction.termInsuranceWithoutVATCost || ''}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>{'Лучшее предложение, без НДС'.t}</label>
                            <Input
                                disabled
                                placeholder='Лучшее предложение, без НДС'
                                value={auction.bestBet || ''}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider className="m-t-15" />
        </Form>
    );
};

export default AuctionBlock;