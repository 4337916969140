import React from 'react';
import WbGridFilterListMulti from "../../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";
import {Oc} from "../../../api/model/Waybill";
import {Table} from "semantic-ui-react";

export default function CompaniesFilters({filters, setFilters, headers}) {

    function applyFilter(filter) {
        setFilters({...filters, ...filter});
    }

    function removeFilter(key) {
        let newFilters = {...filters};
        delete newFilters[key];
        setFilters({...newFilters});
    }

    const getFilter = (key) => {
        switch (key) {
            case 'statusId':
                return <WbGridFilterListMulti
                    filters={filters}
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new Oc(0, 'Активна', "blue"),
                        new Oc(1, 'Заблокирована', "grey")
                    ]}/>;
            default:
                return null;
        }
    };

    return (
        <Table.Row className='notifications-filter'>
            {headers.map(({key}) => (
                <Table.HeaderCell
                    key={key}
                    className={"table-select-cell notifications-filter-fixed__title app-table-filters"}
                >
                    {getFilter(key)}
                </Table.HeaderCell>
            ))}
        </Table.Row>
    );

}
