import React from 'react';
import {normalizeTime} from "../../services/normalizeTime";

export const InputTime = ({onChange, name, value, className}) => {
    return (
        <input
            className={className}
            name={name}
            value={value}
            onChange={(e) => {
                onChange(null, {
                    name,
                    value: normalizeTime(e.target.value)
                });
            }}
        />
    );
};