

export const pointAbbrsMap = [
    { from: 'Автономный округ.', to: 'AO' },
    { from: 'Автономный округ', to: 'AO' },
    { from: 'деревня', to: 'д.' },
    { from: 'железнодорожная станция', to: 'ж/д_ст' },
    { from: 'платформа', to: 'платф.' },
    { from: 'площадка', to: 'пл-ка' },
	{ from: 'разъезд ', to: 'рзд. ' },
	{ from: 'поселок ', to: 'п. ' },
	{ from: '^село', to: 'с.' },
	{ from: ' село', to: 'с.' },
    { from: 'тупик', to: 'туп.' },
    { from: 'участок', to: 'уч-к' },
    { from: 'хутор', to: 'х.' },
    { from: 'шоссе', to: 'ш.' },
    { from: 'секция', to: 'сек.' },
    { from: 'литер', to: 'лит.' },
    { from: 'поселение', to: 'пос-е' },
    { from: 'влд', to: 'влад.' },
    { from: '^к ', to: 'корп. ' },
    { from: ' к ', to: ' корп. ' }
];

export const replaceAbbrs = (pointAddress) => {
    const fieldsToReplace = [
        'country', 'region', 'area', 'settlement', 'streetName',
        'streetNo', 'building', 'office', 'value'
    ];

    const newAddress = { ...pointAddress };
    fieldsToReplace.forEach((field) => {
        let value = newAddress[field] || '';
        pointAbbrsMap.forEach(({ from, to }) => {
            const regexp = new RegExp(from, 'g');
            value = value.replace(regexp, to);
        });
        newAddress[field] = value;
    });
    return newAddress;
};

export default class PointAddress {
    
    /** @param {PointAddress} dto */
    constructor(dto = {}) {

        /** @type {number} */
        //this.id = dto.id;
        this.country = dto.country;

        /** @type {string} */
        this.region = dto.region;

        /** @type {string} */
        this.area = dto.area;

        /** @type {string} */
        this.settlement = dto.settlement;

        /** @type {string} */
        this.streetName = dto.streetName;

        /** @type {string} */
        this.streetNo = dto.streetNo;

        /** @type {string} */
        this.building = dto.building;

        /** @type {string} */
        this.office = dto.office;

        /** @type {string} */
        this.postalCode = dto.postalCode;

        /** @type {string} */
        this.kladr = dto.kladr;

        /** @type {string} */
        this.fias = dto.fias;

        this.fias_level = dto.fias_level;

        /** @type {string} */
        this.geoLon = dto.geoLon;

        /** @type {string} */
        this.geoLat = dto.geoLat;

        /** @type {string} */
        this.value = dto.value;

        this.timezone = dto.timezone;

        this.inn = dto.inn;
    }


    toString() {
		let result = '';
		const isSettlementRegion = this.region && this.settlement && this.settlement.includes(this.region);
		if (this.region && !isSettlementRegion) {
            this.region && (result += `${this.region}`);
        }
		this.area && (result += `, ${this.area}`);
        this.settlement && (result += result ? `, ${this.settlement}` : this.settlement);
        this.streetName && (result += `, ${this.streetName}`);
		this.streetNo && (result += `, ${this.streetNo}`);
		this.building && (result += `, ${this.building}`);

        return result.trim();
    }

    toStringWithRegion() {
        if (this.settlement && this.settlement.includes(this.region)) {
            return this.toString();
        }
        return `${this.region}, ${this.settlement || '[нас. пункт]'}, ${this.streetName || '[улица]'}, ${this.streetNo || 'дом'}`;
    }
}