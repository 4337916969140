import {WaybillTypeEnum} from "../api/model/Waybill";

export default (wb) => {
    let newPointsLoading = [];
    let newPointsUnloading = [];
    let indexGlobal = 0;

    if (wb.shippingType === WaybillTypeEnum.FTL) {
        let allPoints = wb.pointsLoading.map(i => ({
            ...i,
            date: i.arrivalTimeslotPlan && i.arrivalTimeslotPlan.from && i.arrivalDatePlan
                ? new Date(`${i.arrivalDatePlan.split('T')[0]}T${i.arrivalTimeslotPlan.from}`)
                : i.arrivalDatePlan ? new Date(i.arrivalDatePlan) : null,
        }))
            .concat(wb.pointsUnloading.map(i => ({
                ...i,
                date: i.arrivalTimeslotPlan && i.arrivalTimeslotPlan.from && i.arrivalDatePlan
                    ? new Date(`${i.arrivalDatePlan.split('T')[0]}T${i.arrivalTimeslotPlan.from}`)
                    : i.arrivalDatePlan ? new Date(i.arrivalDatePlan) : null,
            })));

        let sortPointFromDateTime = allPoints
            .filter(item => item.date)
            .sort((a, b) =>
                a.date.getTime() - b.date.getTime()) || [];

        let pointsNotDateTime = allPoints.filter(item => !item.date).sort((a, b) =>
            a.index - b.index);

        wb.pointsLoading.sort((a, b) =>
            a.index - b.index).forEach((item, index) => {
            const findIndexPointsLoading = sortPointFromDateTime.findIndex(i => i.id === item.id);
            if (findIndexPointsLoading >= 0) {
                newPointsLoading.push({
                    ...item,
                    positionNumber: findIndexPointsLoading + 1
                })
            } else {
                newPointsLoading.push({
                    ...item,
                    positionNumber: sortPointFromDateTime.length + pointsNotDateTime.findIndex(i => i.id === item.id) + 1
                })
            }
        });

        wb.pointsUnloading.sort((a, b) =>
            a.index - b.index).forEach(item => {
            const findIndexPointsUnloading = sortPointFromDateTime.findIndex(i => i.id === item.id);
            if (findIndexPointsUnloading >= 0) {
                newPointsUnloading.push({
                    ...item,
                    positionNumber: findIndexPointsUnloading + 1
                })
            } else {
                newPointsUnloading.push({
                    ...item,
                    positionNumber: sortPointFromDateTime.length + pointsNotDateTime.findIndex(i => i.id === item.id) + 1
                })
            }
        });
    } else {

        wb.pointsLoading.forEach(item => {
            newPointsLoading.push({
                ...item,
                positionNumber: ++indexGlobal
            })
        });

        wb.pointsUnloading.forEach(item => {
            newPointsUnloading.push({
                ...item,
                positionNumber: ++indexGlobal
            })
        });

    }

    return {
        ...wb,
        pointsLoading: newPointsLoading,
        pointsUnloading: newPointsUnloading
    };

}
