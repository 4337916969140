import React, {useEffect, useState} from 'react';
import qs from "query-string";
import {Container, Table} from "semantic-ui-react";
import Shield from "../page/shield/Shield";
import AppTableToolbar from "../../components/tables/AppTableToolbar";
import PointGridHeader from "../../pages/admin/points/pointsGrid/PointGridHeader";
import PointGridCell from "../../pages/admin/points/pointsGrid/PointGridCell";
import PointsScrollPreloader from "../../pages/_shared/pointsScrollPreloader/PointsScrollPreloader";

const ScrollTable = ({location, headers, getRowsFunc, leftHeaderData = '', rightHeaderData = '', onClickRow, tableName, actions}) => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const keys = headers.map(h => h.key);

    async function fetchData(query) {
        setLoading(true);
        try {
            return await getRowsFunc(query);
        } finally {
            setLoading(false);
        }
    }

    async function updateRows() {
        const query = qs.parse(location.search, {ignoreQueryPrefix: true});
        const rows = await fetchData(query);
        setRows(rows);
    };

    useEffect(() => {
        updateRows();
    }, [location.search]);

    const styleCell = {
        overflow: 'hidden',
        position: 'relative'
    };

    return (
        <div className="bgn-white h-100">
            <Container className="h-100 app-table">
                <Shield loading={loading} loadingOver={false}>
                    <div style={{minHeight: '53px'}}>
                        <AppTableToolbar>
                            <AppTableToolbar.Left>
                                {leftHeaderData}
                            </AppTableToolbar.Left>
                            <AppTableToolbar.Right>
                                {rightHeaderData}
                            </AppTableToolbar.Right>
                        </AppTableToolbar>
                    </div>
                    <div className="table-wrapper">

                        <div className="table-scroll">
                            <PointGridHeader
                                isCheckbox={false}
                                headers={headers}
                                hasRows={rows.length}
                                tableName={tableName}
                            />
                        </div>
                        <div className="table-scroll points-table">
                            <Table celled fixed singleLine className="table-data-inner" selectable>
                                <Table.Body>
                                    {rows.map((r, i) => {
                                        return <Table.Row
                                            onClick={() => onClickRow && onClickRow(r)}
                                            key={`${r.id}_${i}`}
                                            style={{cursor: 'pointer'}}
                                        >
                                            {keys.map(key =>
                                                <Table.Cell
                                                    style={styleCell}
                                                    key={key}
                                                    title={''}
                                                >

                                                    <PointGridCell
                                                        actions={(...v) => actions(updateRows, ...v)}
                                                        item={r}
                                                        keyName={key}
                                                        tableName={tableName}
                                                    />

                                                </Table.Cell>
                                            )}
                                        </Table.Row>;
                                    })}
                                </Table.Body>
                            </Table>
                            <PointsScrollPreloader {...{location, rows, fetchData, setRows}} />
                        </div>
                    </div>
                </Shield>
            </Container>
        </div>
    );
};

export default ScrollTable;