import React from "react";
import {Statistic} from "semantic-ui-react";
import "./WbEditPointsTotals.css";
import T from "../../../components/Translate";

export default function CargoTotals({
                                        totalEmptyPallets,
                                        totalOversizedPallets,
                                        totalPallets,
                                        totalBoxes,
                                        totalWeight,
                                        totalVolume,
                                        totalLowPallets,
                                        totalHighPallets,
                                        totalLongBox
                                    }) {
    const checkAlert = (val, max) =>
        val > max ? "cargo-totals-color-alert" : "cargo-totals-color-normal";

    const formatNumber = number => {
        if (number % 1 !== 0) {
            return number.toFixed(3).replace(/0+$/, "");
        }
        return number;
    };
    return (
        <Statistic.Group size="tiny" className="cargo-totals">
            {totalPallets ? (
                <Statistic horizontal className="m-t-5 m-b-0">
                    <Statistic.Value className={checkAlert(totalPallets, 33)}>
                        {totalPallets}
                    </Statistic.Value>
                    <Statistic.Label>
                        <T>паллет</T>
                    </Statistic.Label>
                </Statistic>
            ) : null}
            {totalEmptyPallets ? (
                <Statistic horizontal className="m-t-5 m-b-0">
                    <Statistic.Value className={checkAlert(totalEmptyPallets, 33)}>
                        {totalEmptyPallets}
                    </Statistic.Value>
                    <Statistic.Label>
                        <T>пустых поддонов</T>
                    </Statistic.Label>
                </Statistic>
            ) : null}
            {totalOversizedPallets ? (
                <Statistic horizontal className="m-t-5 m-b-0">
                    <Statistic.Value className={checkAlert(totalOversizedPallets, 33)}>
                        {totalOversizedPallets}
                    </Statistic.Value>
                    <Statistic.Label>
                        <T>негабаритных паллет</T>
                    </Statistic.Label>
                </Statistic>
            ) : null}
            {totalLowPallets ? (
                <Statistic horizontal>
                    <Statistic.Value className={checkAlert(totalLowPallets, 33)}>
                        {totalLowPallets}
                    </Statistic.Value>
                    <Statistic.Label>
                        <T>низких паллет</T>
                    </Statistic.Label>
                </Statistic>
            ) : null}
            {totalHighPallets ? (
                    <Statistic horizontal>
                        <Statistic.Value className={checkAlert(totalHighPallets, 33)}>
                            {totalHighPallets}
                        </Statistic.Value>
                        <Statistic.Label>
                            <T>высоких паллет</T>
                        </Statistic.Label>
                    </Statistic>
                ) : null}
            {totalLongBox ? (
                    <Statistic horizontal>
                        <Statistic.Value className="cargo-totals-color-normal">
                            {totalLongBox}
                        </Statistic.Value>
                        <Statistic.Label>
                            <T>длинных коробов</T>
                        </Statistic.Label>
                    </Statistic>
                ) : null}
            {totalBoxes ? (
                <Statistic horizontal className="m-t-5 m-b-0">
                    <Statistic.Value className="cargo-totals-color-normal">
                        {totalBoxes}
                    </Statistic.Value>
                    <Statistic.Label>
                        <T>коробов</T>
                    </Statistic.Label>
                </Statistic>
            ) : null}
            <Statistic horizontal className="m-t-5 m-b-0">
                <Statistic.Value className={checkAlert(totalWeight, 20000)}>
                    {totalWeight && formatNumber(totalWeight)}
                </Statistic.Value>
                <Statistic.Label>
                    <T>кг брутто</T>
                </Statistic.Label>
            </Statistic>
            {totalVolume ? (
                <Statistic horizontal className="m-t-5 m-b-0">
                    <Statistic.Value className="cargo-totals-color-normal">
                        {totalVolume && formatNumber(totalVolume)}
                    </Statistic.Value>
                    <Statistic.Label>
                        <T>объем м3</T>
                    </Statistic.Label>
                </Statistic>
            ) : null}
        </Statistic.Group>
    );
}
