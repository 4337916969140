import React, {useContext, useEffect, useState} from 'react';
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import {getFiles} from "../../api/importForms";
import ScrollTable from "../../layout/table/scrollTable";
import AppTableHeader from "../../components/tables/AppTableHeader";

const ImportFilesPage = ({location, history, match}) => {
    const fmid = match.params.fmid;
    const id = match.params.id;
    const contextNavi = useContext(ContextNavi);
    const [info, setInfo] = useState({});

    const headers = [
        new AppTableHeader('filename', 'Наименование файла загрузки заявок'),
        new AppTableHeader('taskStatus', 'Статус обработки файла'),
    ];

    useEffect(() => {
        contextNavi.setItems([
            new LinkInfo("Импорт заявок", "/import"),
            new LinkInfo(info.companyName || fmid, `/import/${fmid}`),
            new LinkInfo(info.formName || id, `/import/${fmid}/${id}`),
        ]);
    }, [info]);

    const onClickRow = (row) => {
        history.push(`/import/${fmid}/${id}/tasks/${row.id}`);
    };

    const getRows = async () => {
        const data = await getFiles(id);
        const { items, ...info } = data;
        setInfo(info);
        return items;
    };

    return (
        <ScrollTable
            onClickRow={onClickRow}
            location={location}
            headers={headers}
            getRowsFunc={getRows}
        />
    );
};

export default ImportFilesPage;