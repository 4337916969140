import React, {useContext, useEffect, useMemo} from 'react';
import './styles.css';
import {Button} from "semantic-ui-react";
import FormRule from "./FormRule";
import {
    headerRules,
    importFormModes,
    optionsByProcessingDescriptors,
    ProcessingTypesEnum
} from "../../../api/model/ImportForms";
import {ContextFooter} from "../../../services/context";
import Indicator from "./indicator";

const findMatchingRuleTypeValues = (data, ruleType) => {
    const groupedByValue = {};

    for (const key in data) {
        if (data[key].value && data[key].ruleType.toString() === ruleType.toString()) {
            const value = data[key].value;
            if (!groupedByValue[value]) {
                groupedByValue[value] = [];
            }
            groupedByValue[value].push(key);
        }
    }

    return Object.values(groupedByValue).filter(group => group.length > 1);
};

const searchFieldsWithPosition = (form, fieldsData, fieldsWithPosition) => {
    const findMatching = findMatchingRuleTypeValues(form, ProcessingTypesEnum.CELL);

    const newArray = [];
    let i = 0;

    findMatching.forEach((f) => {
        f.forEach(key => {
            const isFieldWithPosition = fieldsWithPosition.find(f => f.columnName === key);

            if (isFieldWithPosition) {
                newArray[i] = newArray[i] ? [...newArray[i], key] : [key];
            }
        });
        newArray[i] && i++;
    });

    return newArray.filter(a => a.length > 1);
};

const getFieldsWithPosition = (fieldsData) => {
    const arrayOfFields = Object.keys(fieldsData).map(key => fieldsData[key]);

    return arrayOfFields.filter(field => optionsByProcessingDescriptors(
        field.processingDescriptors,
        ProcessingTypesEnum.CELL,
    ).find(o => o.processingType === ProcessingTypesEnum.POSITION));
};

const autoAddPositionRules = (form, fieldsData, fieldsWithPosition, setValue) => {
    //автоматическое добавление/удаление правила Позиция
    if (form) {
        const array = searchFieldsWithPosition(form, fieldsData, fieldsWithPosition).flat();

        fieldsWithPosition.forEach(field => {
            const colName = field.columnName;
            const rulePositionIsOn = !!((form[colName] || {}).rules || [])
                .find(rule => rule.ruleType === ProcessingTypesEnum.POSITION);

            if (rulePositionIsOn && !array.includes(colName)) {
                setValue(
                    colName,
                    {
                        ...form[colName],
                        rules: form[colName].rules.filter(rule => rule.ruleType !== ProcessingTypesEnum.POSITION)
                    },
                    true
                );
            } else if (!rulePositionIsOn && array.includes(colName)) {
                setValue(
                    colName,
                    {
                        ...form[colName],
                        rules: [
                            {
                                ruleType: ProcessingTypesEnum.POSITION,
                                value: null
                            },
                            ...(form[colName].rules || [])
                        ]
                    },
                    true
                );
            }
        });
    }
};

const FormRules = ({form, height, disabled, setValue, companyId, fieldsData, errors, isValid, saveForm, loading, setMode, checkSchedules}) => {
    const contextFooter = useContext(ContextFooter);

    const fieldsWithPosition = useMemo(() => getFieldsWithPosition(fieldsData), [fieldsData]);

    useEffect(() => {
        contextFooter.setIndicator(<Indicator
            errors={errors}
            validText="Правила корректны и готовы к сохранению"
        />);
        return () => {
            contextFooter.setIndicator(null);
        };
    }, [errors]);

    const data = fieldsWithPosition.map(f => (form[f.columnName] || {}).ruleType === ProcessingTypesEnum.CELL && (form[f.columnName] || {}).value).filter(v => v).join(';');

    useEffect(() => {
        autoAddPositionRules(form, fieldsData, fieldsWithPosition, setValue);
    }, [data]);

    useEffect(() => {
        const needToCheckSchedules = Object.keys(form).find(f => form[f].ruleType === ProcessingTypesEnum.SCHEDULE);
        if (needToCheckSchedules) {
            checkSchedules();
        }
    }, []);

    return (
        <div
            style={{
                height: `${height}px`
            }}
            className="form-rules"
        >
            <div className="form-rules__header">
                {
                    headerRules.map(h => <div
                        key={h.name}
                    >
                        {h.name}
                    </div>)
                }
                <div>
                    {
                        importFormModes.map(mode => <Button
                            onClick={() => setMode(mode.code)}
                            size="mini"
                            color='blue'
                        >
                            {mode.name}
                        </Button>)
                    }
                    <Button
                        onClick={saveForm}
                        size="mini"
                        positive
                        disabled={disabled || !isValid || loading}
                        loading={loading}
                    >
                        Сохранить форму
                    </Button>
                </div>
            </div>
            <div
                className="form-rules__body"
                style={{
                    height: `${height - 40}px`
                }}
            >
                {
                    fieldsData.map((row, i) => <FormRule
                        key={`rule_${i}`}
                        companyId={companyId}
                        errors={errors}
                        setValue={setValue}
                        form={form}
                        item={row}
                        disabled={disabled}
                        checkSchedules={checkSchedules}
                    />)
                }
            </div>
        </div>
    );
};

export default FormRules;