import React from 'react';
import { Icon } from 'semantic-ui-react';
import T from '../Translate';
import './AppToolbarButton.css';

export default function AppToolbarButton({icon, floated, disabled, onClick, children, color}) {

    const classNames = `app-toolbar-button ${disabled ? "app-toolbar-button--disabled" : ''}`;

    return (
        <span
            className={classNames}
            floated={floated}
            onClick={onClick}
        >
            {icon && <Icon name={icon} disabled={disabled} color={color}/>}<T>{children || 'Button'}</T>
        </span>
    );

}