import React from 'react';
import {
    dataTypes,
    fieldTypes,
    optionsByProcessingDescriptors,
    ProcessingTypesEnum,
    ServiceParamsOptions
} from "../../../api/model/ImportForms";
import RuleInput from "./ruleInput";
import RuleValueBlock from "./ruleValueBlock";

const RuleField = ({companyId, data, form, setValue, type, selectedOptions, disabled, delBtn, clearable}) => {
    const ruleType = (form || {}).ruleType;
    const value = (form || {}).value;
    const ruleTypeIsPosition = ruleType === ProcessingTypesEnum.POSITION;

    const options = optionsByProcessingDescriptors(data.processingDescriptors, type, selectedOptions, !ruleTypeIsPosition && ProcessingTypesEnum.POSITION);

    const onChange = (e, {name, value}) => setValue(name || 'ruleType', value);

    return (
        <div className="rule-field">
            <div className="rule-field__rule">
                <RuleInput
                    clearable={clearable}
                    disabled={disabled || ruleTypeIsPosition}
                    inputType={data.isServiceParameter ? ServiceParamsOptions[data.columnName].type : fieldTypes.DROPDOWN}
                    value={ruleType}
                    options={data.isServiceParameter ? ServiceParamsOptions[data.columnName].options : options}
                    onChange={onChange}
                    isInt={data.dataType === dataTypes.INT}
                    canBeNegative={false}
                />
            </div>
            {!!(!data.isServiceParameter && ruleType) && <div className="rule-field__value">
                <RuleValueBlock
                    companyId={companyId}
                    type={data.type}
                    processingType={ruleType}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    delBtn={!ruleTypeIsPosition && delBtn}
                    dictionaryType={data.dictionaryType}
                    dataType={data.dataType}
                />
            </div>}
        </div>
    );
};

export default RuleField;