import React, {useEffect, useState} from 'react';
import {Dropdown, Form, Grid, GridColumn} from 'semantic-ui-react';
import { DataCell } from './PointViewTabAddrParams';
import PointAddress from '../../../../api/model/PointAddress';
import Point, { PointStatusEnum } from '../../../../api/model/Point';
import T from '../../../../components/Translate';
import {getCounties, getDictionaryItemsData, getRegions} from "../../../../api/dictionaries";
import MaskInput from "../../../../components/inputs/MaskInput";


export default function PointViewTabAddr({ point, updatePoint }) {

    const addr = point && point.address;

    async function updateAddress(prop) {

        const pointChanged = new Point(point);

        pointChanged.address = new PointAddress({ ...point.address, ...prop });
        pointChanged.address.value = pointChanged.address.toString();

        updatePoint(pointChanged);
    }

    async function update(prop) {
        const pointChanged = new Point({ ...point, ...prop });
        updatePoint(pointChanged);
    }

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [zones, setZones] = useState([]);

    useEffect(()=>{
        getDictionaryItemsData('zone', '?IsDeletedFilteringDisabled=true').then(data => {
            data && setZones(data.map(z => ({
                key: z.id,
                value: z.id,
                text: z.tariffZone
            })));
        });
        getCounties().then(data=>setCountries(data.map(d => {return {key: d, value: d, text: d}})));
    }, []);

    useEffect(()=>{
        addr.country && getRegions(`?country=${addr.country}`).then(data=> {
            setRegions(data.map(d => {
                return {key: d.id, value: d.name, text: d.name};
            }));
        });
    }, [addr.country]);

    const unloadingAreaName = 'zone';

    return (
        <Form>
            <Grid columns='equal' divided='vertically'>
                <Grid.Row className="p-b-15">
                    <GridColumn>
                        <Form.Input
                            label={'Адрес одной строкой'.t}
                            placeholder={'Адрес одной строкой'.t}
                            value={addr.value}
                            disabled={true}
                        />
                    </GridColumn>
                </Grid.Row>

                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Input
                            disabled={point.statusId !== PointStatusEnum.ON_APPROVAL}
                            required
                            label={'Название адреса в системе FM'.t}
                            placeholder={'Название адреса в системе FM'.t}
                            value={point.fmid}
                            onChange={e => update({ fmid: e.target.value.trimStart() })}
                            onBlur={e => update({ fmid: e.target.value.trim() })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <MaskInput
                            regex={/^[А-Яа-яA-Za-z0-9]{1,5}$/}
                            name={unloadingAreaName}
                            onChange={update}
                            label='Зона выгрузки'
                            value={point[unloadingAreaName]}
                            placeholder='Зона выгрузки'
                        />
                        <Form.Field>
                            <label>{'Тарифная зона'.t}</label>
                            <Dropdown
                                label={'Тарифная зона'.t}
                                placeholder={'Тарифная зона'.t}
                                value={point.tariffZoneId}
                                onChange={(e, {value}) => update({ tariffZoneId: value })}
                                options={zones}
                                className='InputDropdown'
                                name="tariffZoneId"
                                fluid
                                search
                                selection
                                noResultsMessage={'Результатов не найдено'.t}
                                disabled
                                selectOnBlur={false}
                            />
                        </Form.Field>
                    </GridColumn>
                </Grid.Row>

                <Grid.Row className="p-t-0">

                    <GridColumn>
                        <Form.Field required>
                            <label>{'Страна'.t}</label>
                            <Dropdown
                                label={'Страна'.t}
                                placeholder={'Страна'.t}
                                value={addr.country}
                                onChange={(e, {value}) => updateAddress({ country: value, region: null })}
                                options={countries}
                                className='InputDropdown'
                                name="country"
                                fluid
                                search
                                selection
                                noResultsMessage={'Результатов не найдено'.t}
                                selectOnBlur={false}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Field required>
                            <label>{'Регион'.t}</label>
                            <Dropdown
                                label={'Регион'.t}
                                placeholder={'Регион'.t}
                                value={addr.region}
                                onChange={(e, {value}) => updateAddress({ region: value })}
                                options={!!regions.find(v=>v.text === addr.region) ? regions : [...regions, {key: addr.region, text: addr.region, value: addr.region}]}
                                className='InputDropdown'
                                name="region"
                                fluid
                                search
                                selection
                                noResultsMessage={'Результатов не найдено'.t}
                                selectOnBlur={false}
                            />
                        </Form.Field>
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Район'.t}
                            placeholder={'Район'.t}
                            value={addr.area}
                            onChange={e => updateAddress({ area: e.target.value })}
                        />
                    </GridColumn>
                    {/* <GridColumn>&nbsp;</GridColumn> */}
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Input
                            required
                            label={'Населенный пункт (с типом)'.t}
                            placeholder={'Населенный пункт'.t}
                            value={addr.settlement}
                            onChange={e => updateAddress({ settlement: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            required
                            label={'Индекс'.t}
                            placeholder={'Индекс'.t}
                            value={addr.postalCode}
                            onChange={e => updateAddress({ postalCode: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Название улицы (с типом)'.t}
                            placeholder={'Название улицы включая тип'.t}
                            value={addr.streetName}
                            onChange={e => updateAddress({ streetName: e.target.value })}
                        />
                    </GridColumn>
                    
                </Grid.Row>
                <Grid.Row className="p-t-0">
                    <GridColumn>
                        <Form.Input
                            label={'Номер дома (с типом)'.t}
                            placeholder={'например д 1 или влд 3)'.t}
                            value={addr.streetNo}
                            onChange={e => updateAddress({ streetNo: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Номер строения (с типом)'.t}
                            placeholder={'например стр 1 или корпус 8'.t}
                            value={addr.building}
                            onChange={e => updateAddress({ building: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Офис'.t}
                            placeholder={'Офис или квартира'.t}
                            value={addr.office}
                            onChange={e => updateAddress({ office: e.target.value })}
                        />
                    </GridColumn>
                </Grid.Row>

                <Grid.Row columns={3} className="p-t-0">
                    <GridColumn>
                        <Form.Input
                            label={'Geo Lat'.t}
                            placeholder={'Географическая широта'.t}
                            value={addr.geoLat}
                            onChange={e => updateAddress({ geoLat: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Geo Lon'.t}
                            placeholder={'Географическая долгота'.t}
                            value={addr.geoLon}
                            onChange={e => updateAddress({ geoLon: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <DataCell
                            title={"Ccылка на dadata".t}
                            text={addr.fias && <a target="_blank" rel="noopener noreferrer" href={`https://dadata.ru/find/address/${addr.fias}/`}><T>Ccылка на dadata</T></a>}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            required
                            label={'Часовой пояс'.t}
                            placeholder={'Часовой пояс'.t}
                            value={addr.timezone}
                            onChange={e => updateAddress({ timezone: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'ИНН'.t}
                            placeholder={'ИНН'.t}
                            value={addr.inn}
                            maxLength="17"
                            onChange={e => updateAddress({ inn: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn/>
                </Grid.Row>
                <Grid.Row>
                    <GridColumn>
                        <Form.Input
                            label={'Кладр'.t}
                            placeholder={'Кладр'.t}
                            value={addr.kladr}
                            onChange={e => updateAddress({ kladr: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Фиас'.t}
                            placeholder={'Фиас'.t}
                            value={addr.fias}
                            onChange={e => updateAddress({ fias: e.target.value })}
                        />
                    </GridColumn>
                    <GridColumn>
                        <Form.Input
                            label={'Фиас уровень'.t}
                            placeholder={'Фиас уровень'.t}
                            value={addr.fias_level}
                            onChange={e => updateAddress({ fias_level: e.target.value })}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
}