const findStringInObject = (obj, searchString, excludeFields = []) => {
    const results = [];
    if (searchString) {
        for (let key in obj) {
            if (excludeFields.includes(key)) {
                continue;
            }
            if (typeof obj[key] === 'object') {
                const result = findStringInObject(obj[key], searchString, excludeFields);
                result && results.push(...result.map(item => [key, ...item]));
            } else if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(searchString.toLowerCase())) {
                results.push([key]);
            }
        }
    }
    return results.length > 0 ? results : null;
};

export const sortByFieldPresence = (arr, field) => {
    return arr.sort((v1, v2) => (v1.hasOwnProperty(field) && !v2.hasOwnProperty(field))
        ? -1
        : ((!v1.hasOwnProperty(field) && v2.hasOwnProperty(field))
                ? 1
                : 0
        ));
};

export const searchTextFromCells = (arrayOfObjs, text, excludeFields) => {
    const obj = [...(arrayOfObjs || [])];

    (arrayOfObjs || []).forEach((v, i) => {
        const path = findStringInObject(v, text, excludeFields);
        if (path) {
            obj[i] = {
                ...v,
                searchResult: path
            };
        }

    });
    return obj;
};