import React, {useContext, useState} from 'react';
import {Button, Checkbox, Modal} from 'semantic-ui-react';
import rules from './rules';
import {acceptAuctionRules, getUserCurrent} from "../../api/users";
import {ContextUser} from "../../services/context";

const AuctionRulesModal = ({open, setOpen, callback}) => {
    const [isChecked, setIsChecked] = useState(false);
    const contextUser = useContext(ContextUser);
    const clickCancel = () => {
        setOpen(false);
    };

    const setUserContext = async () => {
        const user = await getUserCurrent();
        contextUser.current = {
            ...contextUser.current,
            acceptanceOfTheAuctionRules: user.acceptanceOfTheAuctionRules
        };
        callback && callback();
    };

    const clickAccept = async () => {
        await acceptAuctionRules()
            .then(() => {
                setOpen(false);
                setUserContext();
            })
            .catch(e => console.log(e));
    };

    return (
        <Modal open={open} size="large" dimmer="blurring">
            <Modal.Header>
                Положение об электронных аукционах
            </Modal.Header>
            <Modal.Content style={{maxHeight: '60vh', overflow: 'auto'}}>
                {
                    rules()
                }
                <Checkbox
                    className='auction-rules__checkbox'
                    label={<label><b>Принять правила Положения об электронных аукционах</b></label>}
                    onChange={(e, {checked}) => setIsChecked(checked)}
                    checked={isChecked}
                />
            </Modal.Content>
            <Modal.Actions className="confirm-dialog-actions">
                <div>
                    <Button onClick={clickCancel}>
                        Отмена
                    </Button>
                    <Button color="blue" disabled={!isChecked} onClick={clickAccept}>
                        Принять
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default AuctionRulesModal;