import React from 'react';
import {Route, Switch} from "react-router";
import ErrorNotFound from "../ErrorNotFound";
import './style.css';
import DictionariesList from "./dictionariesPage";

const Dictionaries = () => {
    return (
        <Switch>
            <Route exact path="/dictionaries" component={DictionariesList} />
            <Route component={ErrorNotFound} />
        </Switch>
    )
};

export default Dictionaries;
