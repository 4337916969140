import React, {useContext, useEffect, useState} from 'react';
import {Label, Menu} from 'semantic-ui-react';
import PointCardTabMain from './WbPointCardTabsMain';
import WbPointCardTabsPositionsLoad from './WbPointCardTabsPositionsLoad';
import WbPointCardTabsPositionsUnload from './WbPointCardTabsPositionsUnload';
import PointCardTabServices from './WbPointCardTabsServices';
import {WaybillTypeEnum} from '../../../api/model/Waybill';
import {getCompaniesIsMixAllowed} from "../../../api/companies";
import {ContextUser, ContextWb} from "../../../services/context";
import {UserRolesEnum} from "../../../api/usersRoles";


const tabMainName = "Основная информация";
const tabOptionsName = "Сервисы";
const tabPositions = "Грузовые единицы";

export default function PointCardTabs({
                                          value = {},
                                          handleTabChange, activeTab, wbId, wbType,
                                          profile, updatePoint, updateWb, positions, unitsLoadedTotal,
                                          oppositeDate, needCalc, pointsAll, isClient, wbClientId, isPoolingEdit, disabled,
                                          isRoute, fieldConfigs, wb
                                      }) {
    const [isMixCargoUnitsConfig, setIsMixCargoUnitsConfig] = useState(false);
    useEffect(() => {
        getIsMixAllowed();
    }, [wbClientId])

    async function getIsMixAllowed() {
        const isMix = await getCompaniesIsMixAllowed(wbClientId);
        setIsMixCargoUnitsConfig(isMix);
    }

    const handleTabClick = (e, tab) => {
        handleTabChange(tab.id);
    };

    const handleDataChange = async (key, val, loadingsChanged) => {

        const waybillPoint = {...value};

        waybillPoint[key] = val;

        await updatePoint(waybillPoint, loadingsChanged);
    };

    const countUsedPositions = (positions) => {
        let usedIndexes = [];

        positions.forEach(p => {
            let posStart = p.loadUnitPositionStart || 0;
            let posEnd = p.loadUnitPositionEnd || posStart;

            if (posStart) {
                for (let index = posStart; index <= posEnd; index++) {
                    if (!usedIndexes.includes(index)) {
                        usedIndexes.push(index);
                    }
                }
            }
        });

        // we have 5 pos
        // next we have refs to 1 to 2 and 4 to 4

        return usedIndexes.length;
    };

    const isLoading = profile === "pointsLoading";

    let pointUnitSets = [], pointUnitsCount = 0, loadIndicatorColor = "red";

    const waybillPoint = value;

    if (isLoading) {
        pointUnitSets = waybillPoint.loadUnitSets || [];
        pointUnitsCount = pointUnitSets.reduce((val, pus) => val + (pus.loadUnitsCount || 0), 0);
        loadIndicatorColor = pointUnitsCount > 0 ? "green" : "red";
    } else {
        pointUnitSets = waybillPoint.loadUnitsToOrderMappings || [];
        pointUnitsCount = pointUnitSets.length && countUsedPositions(pointUnitSets);
        loadIndicatorColor = countUsedPositions(positions) === unitsLoadedTotal
            ? "green"
            : "red";
    }

    const isRail = wbType === WaybillTypeEnum.Rail || wbType === WaybillTypeEnum.RailLTL;
    const tabsCount = isRail ? 2 : 3;

    const contextUser = useContext(ContextUser);
    const contextWb = useContext(ContextWb);
    const roleUser = ((contextUser.current || {}).role || {}).id;
    const isPooling = contextWb.wb.isPooling;

    const isPoolingCanEdit = isPooling && [
        UserRolesEnum.COORDINATOR_CS,
        UserRolesEnum.SPECIALIST_CS,
        UserRolesEnum.ADMIN
    ].includes(roleUser);

    const isPoolingCanEditCompletely = isPooling && [
        UserRolesEnum.SPECIALIST_CS,
    ].includes(roleUser);

    return (
        <>
            <Menu pointing widths={tabsCount} size="small" className="point-card-tabs">
                <Menu.Item
                    id={tabMainName}
                    disabled={isClient}
                    active={activeTab === tabMainName}
                    onClick={handleTabClick}
                >
                    <span className="required-icon">{tabMainName}</span>
                </Menu.Item>
                <Menu.Item
                    id={tabPositions}
                    disabled={profile === 'pointsLoading' && (isClient && !isPoolingCanEdit)}
                    active={activeTab === tabPositions}
                    onClick={handleTabClick}>
                    <span className="required-icon">{tabPositions}</span>
                    {pointUnitsCount > 0 && <Label floating color={loadIndicatorColor}>{pointUnitsCount}</Label>}
                </Menu.Item>
                {!isRail && <Menu.Item
                    id={tabOptionsName}
                    disabled={isClient}
                    active={activeTab === tabOptionsName}
                    onClick={handleTabClick}>
                    {tabOptionsName}
                    {waybillPoint.services && waybillPoint.services.length
                        ? <Label floating color="yellow">{waybillPoint.services.length}</Label>
                        : null
                    }
                </Menu.Item>}
            </Menu>
            <div>
                {activeTab === tabMainName
                    ? <PointCardTabMain
                        wbId={wbId}
                        wbType={wbType}
                        pointsAll={pointsAll}
                        data={waybillPoint}
                        handleDataChange={handleDataChange}
                        isLoading={isLoading}
                        oppositeDate={oppositeDate}
                        needCalc={needCalc}
                        isClient={isClient}
                        disabled={disabled}
                        isRoute={isRoute}
                    />
                    : activeTab === tabOptionsName
                        ? <PointCardTabServices
                            id={waybillPoint.id}
                            isRoute={isRoute}
                            disabled={disabled}
                            isLoading={isLoading}
                            wbType={wbType}
                            services={waybillPoint.services}
                            update={handleDataChange.bind(null, "services")}/>
                        : isLoading
                            ? <WbPointCardTabsPositionsLoad
                                isRoute={isRoute}
                                disabled={disabled}
                                id={waybillPoint.id}
                                isMixAllowed={isMixCargoUnitsConfig}
                                pointsAll={pointsAll}
                                pointId={waybillPoint.pointId}
                                positions={pointUnitSets}
                                isClient={isClient}
                                handleDataChange={(key, val) => handleDataChange(key, val, true)}
                                updateWb={updateWb}
                                isPoolingEdit={isPoolingEdit}
                                isPoolingCanEdit={isPoolingCanEdit}
                                isPoolingCanEditCompletely={isPoolingCanEditCompletely}
                                fieldConfigs={fieldConfigs}
                                data={wb}
                            />
                            : <WbPointCardTabsPositionsUnload
                                disabled={disabled}
                                isClient={isClient}
                                unitsLoadedTotal={unitsLoadedTotal}
                                pointId={waybillPoint.pointId}
                                positions={pointUnitSets}
                                handleDataChange={handleDataChange}
                                updateWb={updateWb}
                                isPoolingEdit={isPoolingEdit}
                                isPoolingCanEdit={isPoolingCanEdit}
                                data={wb}
                                isPoolingCanEditCompletely={isPoolingCanEditCompletely}
                            />
                }
            </div>
        </>
    );
};
