import React from 'react';
import {Header, Label, Segment, SegmentGroup} from 'semantic-ui-react';
import './WbPointCardShell.css';
import WbPointCardShellMenu from './WbPointCardShellMenu';
import WbPointCardTabs from './WbPointCardTabs';

export default function PointCardShell({
                                           positions,
                                           wbPoint,
                                           profile,
                                           updateWb,
                                           remove,
                                           update,
                                           pointReplace,
                                           activeTab,
                                           unitsLoadedTotal,
                                           handleTabChange,
                                           wbType,
                                           wbId,
                                           oppositeDate,
                                           needCalc,
                                           pointsAll,
                                           isClient,
                                           wbClientId,
                                           isEdit,
                                           disabled,
                                           isPoolingEdit,
                                           isRoute,
                                           fieldConfigs,
                                           route,
                                       }) {
    const addr0 = (
        <span
            className="ellipsis"
            title={wbPoint.address || "Адрес"}
            style={{
                color: '#737373',
            }}
        >
            {wbPoint.address || "Адрес"}
        </span>
    );

    return (
        <SegmentGroup
            className="m-b-15 m-t-0"
            id={`wb-point_${profile}_${wbPoint.id}`}
        >
            <Segment clearing={true} style={{paddingTop: '7px', paddingBottom: '7px', minHeight: '54px'}}>
                <div className="wbPointCardShellHeader-container">
                    {!isRoute && <div className="wbPointCardShellHeader-item-left">
                        <Label size="big" circular>{wbPoint.positionNumber}</Label>
                    </div>}
                    <div className="wbPointCardShellHeader-item-center">
                        <Header className="m-0">
                            {addr0}
                            <Header.Subheader className="m-t-3">{wbPoint.companyName}</Header.Subheader>
                        </Header>
                    </div>
                    <div className="wbPointCardShellHeader-item-right">
                        <div className="t-r" style={{marginTop: '9px'}}>
                            <WbPointCardShellMenu
                                wb={route}
                                isClient={isClient}
                                wbPoint={wbPoint}
                                profile={profile}
                                pointRemove={remove}
                                pointReplace={pointReplace}
                                isEdit={isEdit}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            </Segment>
            <Segment placeholder size="small" className="point-card-form">
                <WbPointCardTabs
                    disabled={disabled}
                    isClient={isClient}
                    isPoolingEdit={isPoolingEdit}
                    wbClientId={wbClientId}
                    profile={profile}
                    pointsAll={pointsAll}
                    value={wbPoint}
                    oppositeDate={oppositeDate}
                    wbId={wbId}
                    wbType={wbType}
                    positions={positions}
                    updatePoint={update}
                    updateWb={updateWb}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    unitsLoadedTotal={unitsLoadedTotal}
                    needCalc={needCalc}
                    isRoute={isRoute}
                    fieldConfigs={fieldConfigs}
                    wb={route}
                />
            </Segment>
        </SegmentGroup>
    );
}
