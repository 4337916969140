import React from 'react';
import Spinner from './Spinner';
import Error from './Error';

export default class Page extends React.Component {

    state = {
        loading: this.fetchData ? true : false,
        hasLoaded: false, // concequent loadings will be overlayed
        errors: null
    }

    componentDidMount() {

        console.log(this.constructor.name + ' has mounted');
        

        if (this.state.loading) {

            this.fetchData().then(() => {

                this.setState({
                    loading: false,
                    hasLoaded: true,
                    errors: null,
                });
    
            }, (err) => {
                this.setState({
                    loading: false,
                    hasLoaded: true,
                    error: err.message || err || 'error'
                });
            });
            
        } 
    }

    render() {

        const { loading, hasLoaded, error } = this.state;
        const isOnline = navigator.onLine;

        if (loading) {

            if (hasLoaded) {
                return (
                    <React.Fragment>
                        {this.renderData()}
                        <Spinner show />
                    </React.Fragment>
                ); 
            } else {
                return <Spinner show />;
            }

        } else {
            if (error) {
                return <Error
                    error={isOnline ? error : ' '}
                    header={isOnline ? '' : 'Связь с сервером потеряна, проверьте соединение'.t}
                />;
            } else {
                return this.renderData();
            }
        }
    }
}