import React from 'react';

const text =
<div>
    <p align='right'>
        <b>
            УТВЕРЖДЕНО приказом № 32б/21/ОД от 01.06.2021 <br/>
            Директор по операциям <br/>
            Бормин А.В.
        </b>
    </p>
    <br/>
    <p align='center'>
        <b>
            ПОЛОЖЕНИЕ ОБ ЭЛЕКТРОННЫХ АУКЦИОНАХ<br/>
            по закупкам транспортных услуг<br/>
            АО «ФМ Ложистик Восток»
        </b>
        <br/><br/>
        Московская область, г. Долгопрудный
        <br/><br/>
        «01» июня 2021г.
        <br/><br/>
    </p>
    <p align='center'>
        Настоящее Положение «Об Электронных аукционах по закупкам транспортных услуг и
        порядке их проведения» (далее – Положение) Акционерного Общества «ФМ Ложистик
        Восток» (далее – Заказчик) является локальным нормативным документом,
        регламентирующим Закупочную деятельность Заказчика, связанную с транспортно-экспедиционной деятельностью в том числе для целей коммерческого использования.
        <br/><br/>
        Настоящее Положение разработано в соответствии с:<br/>
        1) Гражданским кодексом Российской Федерации;<br/>
        2) другими федеральными законами и иными нормативными правовыми актами
        Российской
        Федерации.
        <br/><br/>
        Электронные Аукционы, проводимые в рамках настоящего Положения, не являются
        торгами (конкурсом, аукционом) в соответствии со статьями 447-449 или публичным
        конкурсом в соответствии со статьями 1057-1061 Гражданского кодекса Российской
        Федерации. Настоящее Положение распространяется на закупки товаров, работ, услуг
        (далее – Продукция) для нужд Заказчика, связанных с транспортно-экспедиционной
        деятельностью, в том числе для целей коммерческого использования.
        <br/><br/>
        Цели и принципы деятельности Заказчика при проведении Электронного аукциона в
        рамках настоящего Положения.<br/>
        Основными целями Закупочной деятельности Заказчика являются:<br/>
        1) своевременное и полное обеспечение потребностей в услугах, продукции, товарах
        требуемого качества с необходимыми условиями ее приобретения (цена, качество,
        надежность и др.);<br/>
        2) повышение эффективности использования денежных средств, направляемых на
        закупки;<br/>
        3) создание положительной деловой репутации Заказчика;<br/>
        4) предотвращение возможных злоупотреблений в сфере закупок со стороны Заказчика
        <br/><br/>
        Основными принципами Заказчика при проведении Электронного аукциона в рамках
        настоящего Положения являются:<br/>
        1) информационная открытость;<br/>
        2) равноправие, справедливость, отсутствие дискриминации и необоснованных
        ограничений конкуренции по отношению к Участникам аукциона;<br/>
        3) целевое и экономически эффективное расходование денежных средств на
        приобретение Продукции (с учетом при необходимости стоимости жизненного цикла
        закупаемой Продукции) и реализация мер, направленных на сокращение издержек
        Заказчика;
        <br/><br/><br/>
        <b>1. ЭЛЕКТРОННЫЙ АУКЦИОН</b> – это конкурентный способ закупки, при котором победитель
        определяется следующими способами:<br/>
        1.1. <b>в автоматическом порядке</b> победителем признается лицо, которое соответствует
        требованиям к Участникам Электронного Аукциона, установленным настоящим
        Положением, и которое предложило наиболее низкую цену лота путем снижения
        начальной (максимальной) цены лота<br/>
        1.2. <b>в порядке по выбору Заказчика</b> победителем по решению Аукционной Комиссии
        признается лицо, которое соответствует требованиям к Участникам Электронного
        Аукциона, установленным настоящим Положением, и наиболее соответствует характеру
        оказания услуг по размещенной Заказчиком закупке
        <br/><br/>
        Информация о закупке сообщается Заказчиком Участникам Электронного Аукциона по
        выбору Заказчика путем направления на адрес электронной почты, указанный
        Участниками Электронного Аукциона при регистрации в Единой Информационной Системе
        извещения о проведении такого Аукциона и информации о лоте.
        В целях настоящего Положения под Единой Информационной Системой (ЕИС) понимается
        сайт в информационно-телекоммуникационной сети «Интернет», размещенный по адресу
        https://carrier.fmlogistic.ru/ (далее также – электронная площадка).<br/>
        В извещении о проведении Электронного Аукциона по определенному лоту указываются
        следующие данные:<br/>
        1) Номер Аукциона<br/>
        2) Адреса загрузок и разгрузок<br/>
        3) Период оказания услуг<br/>
        4) Тип транспорта<br/>
        Заказчик вправе внести изменение в Извещение о проведении электронного Аукциона.
        Заказчик вправе принять решение о продлении срока подачи заявок на участие в
        Электронном Аукционе в любое время независимо от обстоятельств.<br/>
        Электронный Аукцион проводится на электронной площадке в указанный в извещении, о
        его проведении день. Время начала проведения такого Аукциона устанавливается
        оператором электронной площадки в соответствии со временем часовой зоны, в которой
        расположен Заказчик.<br/>
        Электронный Аукцион проводится путем изменения начальной цены лота, указанной в
        извещении о проведении такого Аукциона.<br/>
        Величина изменения начальной цены договора (далее - "шаг Аукциона") устанавливается
        оператором электронной площадки отдельно для каждого лота.<br/>
        При проведении электронного Аукциона его Участники подают предложения о цене лота,
        предусматривающие изменение текущего предложения о цене на величину в пределах
        "шага Аукциона", при этом Участникам Электронного Аукциона не отображаются текущие
        ставки по соответствующему лоту, поданные иными Участниками Электронного Аукциона
        за исключением текущей лучшей ставки аукциона.<br/>
        При проведении электронного Аукциона его Участники подают предложения о цене лота в
        соответствии с правилами и функционалом работы ЕИС, за исключением следующего
        случая<br/>
        - Участник такого Аукциона не вправе подать предложение о цене лота, равное нулю.
        Время приема предложений Участников такого Аукциона о цене лота, устанавливается
        функционалом работы ЕИС.<br/>
        В случае если Участником электронного Аукциона предложена цена лота, равная цене,
        предложенной другим Участником такого Аукциона, лучшим признается предложение о
        цене договора, поступившее раньше.<br/>
        В случае, если по окончании Электронного Аукциона подана только одна Заявка на участие
        в нем, Участник такого Аукциона, подавший единственную Заявку на участие в нем и
        поданная им Заявка признаются победителем соответствующего лота.<br/>
        <br/>
        <b>2. ТРЕБОВАНИЯ К УЧАСТНИКАМ ЭЛЕКТРОННОГО АУКЦИОНА</b><br/>
        2.1. Требования, предъявляемые к Участникам:<br/>
        2.1.1. Участники Электронного аукциона, проводимого в рамках настоящего Положения
        должны соответствовать следующим обязательным требованиям:<br/>
        1) иметь государственную регистрацию в качестве юридического лица (для участников
        процедуры закупки – юридических лиц), государственную регистрацию физического лица
        в качестве индивидуального предпринимателя (для участников закупки – индивидуальных
        предпринимателей), отсутствие ограничения или лишения правоспособности и / или
        дееспособности (для участников процедуры закупки – физических лиц);<br/>
        2) В отношении Участника не должно проводиться процедур ликвидации (для
        юридического лица) и должно отсутствовать решение арбитражного суда о признании
        Участника банкротом и об открытии конкурсного производства (для юридического лица и
        индивидуального предпринимателя;<br/>
        3) Деятельность Участника не должна быть приостановлена в порядке, предусмотренном
        Кодексом Российской Федерации об административных правонарушениях, на день подачи
        заявки на участие в закупочной процедуре;<br/>
        4) отсутствие у Участника недоимки по налогам, сборам, задолженности по иным
        обязательным платежам в бюджеты бюджетной системы Российской Федерации (за
        исключением сумм, на которые предоставлены отсрочка, рассрочка, инвестиционный
        налоговый кредит в соответствии с законодательством о налогах и сборах, которые
        реструктурированы в соответствии с законодательством, по которым имеется вступившее
        в законную силу решение суда о признании обязанности заявителя по уплате этих сумм
        исполненной, или которые признаны безнадежными к взысканию в соответствии с
        законодательством о налогах и сборах) за прошедший календарный год, размер которой
        превышает процент, указанный в каждой закупочной документации, балансовой
        стоимости активов Участника по данным бухгалтерской отчетности за последний
        завершенный отчетный период;<br/>
        5) отсутствие сведений об Участнике в реестре недобросовестных поставщиков
        (подрядчиков, исполнителей), предусмотренном Законом № 223-ФЗ и / или в реестре
        недобросовестных поставщиков, предусмотренном Законом № 44-ФЗ.<br/>
        6) наличие материально-технических ресурсов, необходимых для исполнения
        обязательств по договору;<br/>
        8) наличие кадровых ресурсов, необходимых для исполнения обязательств по договору;<br/>
        9) наличие финансовых ресурсов, необходимых для исполнения обязательств по
        договору;<br/>
        10) наличие действующего Полиса страхования ответственности Экспедитора /
        Перевозчика с лимитом ответственности 3 000 000,00 (три миллиона рублей) и покрытием
        рисков: ДТП, пожар, противоправных действий третьих лиц, нарушения температурного
        режима (при перевозках соответствующих грузов).<br/>
        <br/>
        <b>3. ПОРЯДОК ПРОВЕДЕНИЯ ЭЛЕКТОННОГО АУКЦИОНА:</b><br/>
        <b>3.1. Аукционная Комиссия</b><br/>
        3.1.1. Для определения победителя Электронного аукциона <b>в порядке по выбору
        Заказчика</b> создается Аукционная Комиссия (далее – Комиссия) – постоянно действующий
        коллегиальный орган, принимающий решения по ходу Электронного аукциона, в том числе
        допуск и отклонение участников, принятие решения об отказе от Электронного аукциона,
        признание Участника уклонившимся. Комиссия включает в себя 3 (трех) сотрудников
        Заказчика, находящихся на рабочем дежурстве.<br/>
        3.1.2. Критериями определения победителя Электронного аукциона в порядке по выбору
        Заказчика являются:<br/>
        ● KPI (статистика качества выполненных перевозок Участником Электронного Аукциона);<br/>
        ● Текущая ставка Участника Электронного Аукциона по соответствующему лоту;<br/>
        3.1.3. Для определения победителя Электронного Аукциона <b>в автоматическом порядке </b>
        решения Аукционной Комиссии не требуется.<br/>
        <br/>
        <b>3.2. Права и обязанности Заказчика</b><br/>
        3.2.1. Заказчик вправе продлить срок Электронного аукциона в любое время до истечения
        объявленного срока.<br/>
        3.2.2. Заказчик вправе отменить Электронный аукцион в любое время. Уведомление
        (решение) об отказе/отмене размещается в ЕИС в день принятия решения.<br/>
        3.2.3. Заказчик не берет на себя обязательств по уведомлению Участников о дополнениях,
        изменениях, разъяснениях в документации, а также по уведомлению Участников об итогах
        запроса и не несет ответственности в случаях, когда участник не осведомлен о
        разъяснениях, внесенных изменениях, дополнениях, итогах запроса при условии их
        надлежащего размещения на сайтах.<br/>
        3.2.4. Нарушение Участником установленных в настоящем Положении требований
        является основанием для отказа в допуске к участию в Электронном аукционе.<br/>
        3.2.5. Заказчик вправе привлекать лицо и/или специализированную организацию для
        осуществления функций Организатора Электронного аукциона от имени Заказчика на
        основании договора. Нормы настоящего Положения, касающиеся Заказчика, также
        распространяются и на Организатора Электронного аукциона с учетом объема переданных
        ему по договору функций.<br/>
        <br/>
        <b>3.3. Права и обязанности Участника Электронного аукциона</b><br/>
        3.3.1. Участник Электронного аукциона вправе подать только одну Заявку на участие в
        Электронном аукционе.<br/>
        3.3.2. Участник Электронного аукциона имеет право обращаться к Заказчику.<br/>
        3.3.3. Участники Электронного аукциона имеют право выступать в отношениях, связанных
        с проведением аукциона, как непосредственно, так и через своих представителей. Полномочия представителей Участников подтверждаются доверенностью, выданной и
        оформленной в соответствии с гражданским законодательством.<br/>
        3.3.5. В случае, если Участник Электронного аукциона признан Победителем лота, он
        обязан подписать, в том числе в электронной форме в ЕИС, и принять к исполнению
        соответствующую Заявку Заказчика на перевозку груза.<br/>
        3.3.6. Участник Электронного Аукциона не вправе подавать Заказчику запросы о текущих
        ставках других Участников Электронного Аукциона по соответствующему лоту.<br/>
        3.3.7. Остальные права, обязанности и требования не указанные в данном положении
        регламентируются текущим договором на оказание транспортно-экспедиционных услуг и
        приложениями к нему между участником электронного аукциона и заказчика.<br/>
        <br/>
    </p>
</div>

export default function rules () {
    return (text)
}