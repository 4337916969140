import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {Divider, Form, Grid, Header} from 'semantic-ui-react';
import {WaybillTypeOptions} from '../../../../api/model/Waybill';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import O from '../../../../components/Option';
import T from '../../../../components/Translate';
import CutoffsFTLDate from './companyTabCutoffs/CutoffsFTLDate';
import CutoffsFTLTime from './companyTabCutoffs/CutoffsFTLTime';
import CutoffsLTLDate from './companyTabCutoffs/CutoffsLTLDate';
import CutoffsLTLTime from './companyTabCutoffs/CutoffsLTLTime';
import CutoffsRailDate from './companyTabCutoffs/CutoffsRailDate';
import CutoffsRailTime from './companyTabCutoffs/CutoffsRailTime';
import CutoffsRailLTLDate from "./companyTabCutoffs/CutoffsRailLTLDate";
import CutoffsRailLTLTime from "./companyTabCutoffs/CutoffsRailLTLTime";

const LTL = "LTL";
const DATE = "date", TIME = "time";
const pathBase = "/admin/companies/:id/:tab";

const blockTypeOptions = [
    new O(DATE, "День недели"),
    new O(TIME, "Время отправки")
];

export default withRouter(({match, history}) => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Switch>
                        <Route
                            path={`${match.url}/:type/:part`} exact
                            render={props => <CompanyTabCutoffsRulesContext pathBase={match.url}
                                                                            pathBasePtn={pathBase} {...props} />}
                        />
                        <Route path={`${pathBase}`} exact>
                            <Redirect to={`${match.url}/LTL/date`}/>
                        </Route>
                    </Switch>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
});

function CompanyTabCutoffsRulesContext({match, history, pathBase, pathBasePtn}) {

    const waybillType = (match.params.type || LTL); //.toUpperCase();
    const blockType = match.params.part || DATE;

    function updateWaybillType(waybillTypeNew) {
        history.push(`${pathBase}/${waybillTypeNew}/${blockType}`);
    }

    function updateBlockType(blockTypeNew) {
        history.push(`${pathBase}/${waybillType}/${blockTypeNew}`);
    }

    return (
        <>
            <Header className="m-b-10">Cписок блокировок регистрации заявки</Header>
            <Divider className="m-t-0"/>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label><T>Тип блокировки</T></label>
                        <InputDropdown
                            options={blockTypeOptions}
                            value={blockType}
                            onChange={updateBlockType}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label><T>Тип сервиса доставки</T></label>
                        <InputDropdown
                            options={WaybillTypeOptions}
                            value={waybillType}
                            onChange={updateWaybillType}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <Divider/>
            <Switch>
                <Route path={`${pathBasePtn}/LTL/date`} exact component={CutoffsLTLDate}/>
                <Route path={`${pathBasePtn}/LTL/time`} exact component={CutoffsLTLTime}/>
                <Route path={`${pathBasePtn}/FTL/date`} exact component={CutoffsFTLDate}/>
                <Route path={`${pathBasePtn}/FTL/time`} exact component={CutoffsFTLTime}/>
                <Route path={`${pathBasePtn}/Rail/date`} exact component={CutoffsRailDate}/>
                <Route path={`${pathBasePtn}/Rail/time`} exact component={CutoffsRailTime}/>
                <Route path={`${pathBasePtn}/RailLTL/date`} exact component={CutoffsRailLTLDate}/>
                <Route path={`${pathBasePtn}/RailLTL/time`} exact component={CutoffsRailLTLTime}/>
            </Switch>
        </>
    );
}



