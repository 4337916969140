import React, {useState, useEffect} from 'react';
import T from "../../../../components/Translate";
import {Button, Dimmer, Form, Icon, Input, Loader, Modal} from "semantic-ui-react";
import {getProviderId, providerCreate, providerUpdate} from "../../../../api/providers";
import ProviderFrom from "./ProviderForm";

export default function ProviderFormModal({key, children, title, companiesList, close, id, isAdd}) {

    const centered = false;
    const size = "large";
    const [open, setOpen] = useState(false);
    const [isClickSave, setIsClickSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState({
        fmid: '',
        legalName: '',
        clientIds: null
    });

    useEffect(() => {
        if (isClickSave) {
            const ve = getValidationErrors();
            setErrors(ve);
        }
    }, [data]);

    function getValidationErrors() {
        const res = [];
        const {fmid, legalName} = data;
        if (!fmid) {
            res.push('fmid');
        }
        if (!legalName) {
            res.push('legalName');
        }
        return res;
    };

    async function submit() {
        setIsClickSave(true);
        const ve = getValidationErrors();
        setErrors(ve);
        if (ve.length) {
            return;
        }
        let dataNew = data
        try {
            setLoading(true);
            if (id) {
                dataNew = {...data, id}
                await providerUpdate(dataNew);
            } else {
                await providerCreate(dataNew);
            }
            onClose();
            return close();
        } catch (error) {
            onClose();
            return false;
        }
    }

    function onClose() {
        setLoading(false);
        setData({
            fmid: '',
            legalName: '',
            clientId: null
        });
        setIsClickSave(false);
        setErrors([]);
        setOpen(false);
    }

    async function onOpen() {
        setLoading(true);
        if (id) {
            const item = await getProviderId(id);
           setData(item)
        }
        setOpen(true);
        setLoading(false);

    }
    return (
        <Modal
            trigger={children}
            centered={centered}
            dimmer="inverted"
            size={size}
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Dimmer active={loading} inverted>
                <Loader></Loader>
            </Dimmer>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <ProviderFrom
                    isAdd={isAdd}
                    setData={setData}
                    companiesList={companiesList}
                    errors={errors}
                    data={data}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onClose}
                    disabled={loading}><T>Отмена</T></Button>
                <Button
                    positive
                    disabled={loading}
                    onClick={submit} ><T>Сохранить</T></Button>
            </Modal.Actions>
        </Modal>
    )
};
