import React from 'react';
import { companyPointRemove } from '../../../api/companies';
import ModalConfirm from '../../../components/modals/ModalConfirm';

export default function ActionPointRemove({selectedRows, updateRows, children}) {

    async function handleRemoveFromList() {
        const promises = selectedRows
            .map(async ({companyId, pointId}) => await companyPointRemove(companyId, pointId));
        await Promise.all(promises);
        await updateRows();
    };

    return (
        <ModalConfirm 
            title="Удаление адреса" 
            text="Удалить адрес?" 
            onConfirm={handleRemoveFromList}
        >
                {children}
        </ModalConfirm>
    );
}