import debounce from 'awesome-debounce-promise';
import React, {useState, useEffect} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {getCompanyOptionsBySearch} from '../../api/companies';
import {toast} from '../../services/toast';
import O from '../Option';
import './InputDropdown.css';
import {deepEqual} from "../../services/utils";


const debounced = debounce((q, handler) => handler(q), 750);

function InputDropdownSearch({
                                 minLetters = 0,
                                 value: valueInitial,
                                 options: optionsInitial,
                                 getOptionsBySearch: getOptionsBySearchOuter,
                                 onChange,
                                 clearable = true,
                                 selectOnBlur = false,
                                 simple,
                                 allowAdditions,
                                 wrapSelection,
                                 placeholder,
                                 loadInitially = false,
                                 className = "InputDropdown",
                                 disabled,
                                 filters,
                             }) {
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState(optionsInitial || []);
    const [optionsNew, setOptionsNew] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setValue(valueInitial || '');
    }, [valueInitial]);

    useEffect(() => {
        optionsInitial && setOptions(optionsInitial);
    }, [optionsInitial]);

    function getOptionsBySearch(searchQuery) {
        if (loadInitially || (searchQuery.length >= minLetters)) {
            setIsFetching(true);
            (getOptionsBySearchOuter || getCompanyOptionsBySearch)(searchQuery, filters || null)
                .then(options => {
                    setIsFetching(false);
                    setOptionsNew(options || []);
                })
                .catch(err => {
                    setIsFetching(false);
                    toast.error("getOptionsBySearch error:\r\n" + err.message || JSON.stringify(err || null));
                });
        }
    };

    function handleSearchQueryChange(searchQuery) {
        setSearchQuery(searchQuery);
        debounced(searchQuery, getOptionsBySearch);
    }

    useEffect(() => {
        (loadInitially || filters) && getOptionsBySearch('');
    }, [loadInitially, filters]);

    function handleValueChange(e, {value}) {
        searchQuery && setSearchQuery('');
        setValue(value);
        const selectedOption = options.find(o => o.key === value);
        const text = (selectedOption && selectedOption.text) || value;
        onChange(value, text);
    };

    function handleAdd(e, {value}) {
        const newOption = new O(value, value);
        setOptions([newOption, ...options]);
    }

    useEffect(() => {
        if (minLetters) {
            setOptions(optionsNew);
        } else if (optionsNew && optionsNew.length) {
            const optionsExistingValues = options.map(o => o.value);
            const optionsNewFiltered = optionsExistingValues.length
                ? optionsNew.filter(o => !optionsExistingValues.includes(o.value))
                : optionsNew;
            setOptions([...options, ...optionsNewFiltered]);
        }
    }, [optionsNew]);

    const onBlurInput = () => {
        searchQuery && setSearchQuery('');
    };

    const searchOptions = () => {
        if ((!loadInitially && minLetters) || (minLetters && minLetters < 1)) {
            return () => {
                return options.filter(o => o.value !== value);
            };
        } else return true;
    };

    return (
        <Dropdown
            clearable={clearable}
            allowAdditions={allowAdditions}
            simple={simple}
            search={searchOptions()}
            searchQuery={searchQuery}
            value={value}
            selectOnBlur={selectOnBlur}
            noResultsMessage={'Результатов не найдено'.t}
            onSearchChange={(e, {searchQuery}) => handleSearchQueryChange(searchQuery)}
            onAddItem={handleAdd}
            onChange={handleValueChange}
            onBlur={onBlurInput}
            className={className}
            placeholder={placeholder}
            fluid

            closeOnChange
            selection
            options={options}
            wrapSelection={wrapSelection}

            disabled={isFetching || disabled}
            loading={isFetching}
        />
    );

}

export default React.memo(InputDropdownSearch, (prevProps, nextProps) => prevProps.value === nextProps.value && deepEqual(prevProps.filters, nextProps.filters) && prevProps.options === nextProps.options && prevProps.disabled === nextProps.disabled);

