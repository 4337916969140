export function getValuePassZero(val) {
    return val === 0 ? 0 : val || '';
}

export function getFloatOrString(val) {
    // x.0x enter cases (0 collapsing)
    if (isNaN(val) || val === 'NaN') {
        return '';
    }
    const result = Number.parseFloat(val);
    if (!(/0{2,},?/.test(val)) && result.toString() !== val) {
        return val;
    }
    return result;
}

export const formatNumber = (number) => {
    if (isNaN(number)) {
        return null
    }
    if (number % 1 !== 0) {
        return number.toFixed(3).replace(/0+$/, '');
    }
    return number;
};

export const restrictionFloat = (val, len = 2, minVal, maxVal, prevVal) => {
    if (!val) return null;

    let value = val.match(/[\d,.]/g) ? val.match(/[\d,.]/g).join('') : val;

    value = value.replace(/[^\d,.]/g, '').replace(',', '.');

    if (value.includes('.')) {
        const [v1, v2] = value.split('.');

        value = `${v1}.${v2.substr(0, len)}`;
    }

    if (minVal && maxVal && (parseFloat(value) < minVal || parseFloat(value) > maxVal)) value = prevVal || '';

    return value
};

export const restrictionInt = (val, minVal, maxVal, prevVal = '') => {
    if (!val) return null;

    let value = val.match(/[+\-\d]/g) ? val.match(/[+\-\d]/g).join('') : val;

    value = value.replace(/[^+\-\d]/, '');


    if (minVal && maxVal && (parseInt(value) < minVal || parseInt(value) > maxVal)) value = prevVal || '';

    return value;
};

export const restrictionOnlyNumber = (val) => {
    if (!val) return null;

    return val.replace(/[^\d]/g, '');
};

export function unique(arr) {
    let result = [];
    let resId = [];

    for (let item of arr) {
        if (!resId.includes(item.key)) {
            resId.push(item.key);
            result.push(item);
        }
    }

    return result;
}

export function sortData(x, y) {
    if (x.toUpperCase() < y.toUpperCase()) return -1;
    if (x.toUpperCase() > y.toUpperCase()) return 1;
    return 0;
}

export const isEmpty = (value) => Array.isArray(value) ? value.length === 0 : ['', null, undefined].includes(value);

export const onBeforeUnloadOn = () => {
    window.onbeforeunload = function () {
        return true;
    };
};

export const onBeforeUnloadOff = () => {
    window.onbeforeunload = null;
};

export function arraysHaveCommonValues(arr1, arr2) {
    return arr1.some(value => arr2.includes(value));
}

export function intersect(arrays) {
    const nonEmpty = arrays.filter(array => array.length);
    if (nonEmpty.length === 0) {
        return [];
    }
    const [first, ...rest] = nonEmpty;
    return first.filter(value => {
        for (let array of rest) {
            if (!array.includes(value)) {
                return false;
            }
        }
        return true;
    });
}

export const styleTh = (text) => {
    let styles = {};
    if (text.length >= 20 || text.split(' ').length > 2) {
        styles.minWidth = `${(text.length*10)/2}px`;
    }
    return styles;
};

export const trimStrings = (object, fields, skipNested = true) => {
    let obj = {...object};
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            if (!fields || fields.includes(key)) {
                obj[key] = obj[key].trim();
            }
        } else if (!skipNested && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            obj[key] = trimStrings(obj[key], fields);
        }
    }
    return obj;
};

export const mappingCodeTrim = (data) => {
    return data.map(v => v.split(';').map(v => v.trim())).flat().filter(v => v);
};

export const isNumber = (v) => typeof v === 'number';

export const replaceObjectById = (array, id, newObject) => {
    let newArray = [...(array || [])];
    if (array) {
        if (isNumber(id)) {
            newArray = newArray.map(obj => {
                if (obj.id === id) {
                    return newObject;
                } else {
                    return obj;
                }
            }).filter(v => v);
        }
        else {
            newArray = [...newArray, newObject];
        }
    }
    return newArray;
};

export const swapObjectsById = (array, id1, id2) => {
    const index1 = array.findIndex(obj => obj.id === id1);
    const index2 = array.findIndex(obj => obj.id === id2);

    if (index1 === -1 || index2 === -1) {
        return array;
    }

    const newArray = [...array];
    [newArray[index1], newArray[index2]] = [newArray[index2], newArray[index1]];

    return newArray;
};

export const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

export const copyObject = (data) => JSON.parse(JSON.stringify(data));

export const isBoolValue = (value) => value === true || value === false;

export const valueToString = (val) => ((val || val === 0) ? val : '').toString();

export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
        return true;
    }
    if (typeof obj1 !== typeof obj2) {
        return false;
    }
    if (typeof obj1 === 'object' && obj1 !== null && obj2 !== null) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    } else {
        return false;
    }
};
