import React from 'react';
import { Label, Container } from 'semantic-ui-react';
import {getUsers, postUser, getUserBySearch, exportExcel} from '../../../api/users';
import AppTableButtonLink from '../../../components/buttons/AppTableButtonLink';
import AppTable from '../../../components/tables/AppTable';
import AppTableHeader from '../../../components/tables/AppTableHeader';
import AppTableToolbar from '../../../components/tables/AppTableToolbar';
import { LinkInfo } from '../../../layout/navbar/Navbar';
import Page from '../../../layout/page/Page';
import { ContextNavi } from '../../../services/context';
import O from '../../../components/Option';
import TableSearch from "../../../components/miscs/TableSearch";
import UserFormNew from './UserFormNew';
import AppToolbarButton from '../../../components/buttons/AppToolbarButton';
import UserFilters from "./UserFilters";
import {getRoles} from "../../../api/usersRoles";


const headers = [
    //new AppTableHeader("id", "ID"),
    new AppTableHeader("filters", " "),
    new AppTableHeader("name", "ФИО"),
    new AppTableHeader("roleName", "Роль"),
    new AppTableHeader("email", "Email"),
    new AppTableHeader("isBlocked", "Статус"),
    new AppTableHeader("companies", "Привязка к клиентам"),
];

export default class UsersIndex extends Page {
    static contextType = ContextNavi;

    state = {
        ...this.state,
        filters: {}
    }

    setFilters = (filters) => this.setState({filters: filters});
    setSearchValue = (searchValue, isDrop) => {
        if (searchValue.length < 3 && !isDrop) {
            alert("Введите не менее 3 символов для поиска");
        }
        else this.setState({searchValue});
    };

    componentWillMount() {
        if (this.context.setItems) {
            this.context.setItems([
                new LinkInfo("Пользователи", "/users"),
            ]);
        }
        this.getAllRoles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filters !== this.state.filters) {
            this.handleSearch();
        }
        if (prevState.searchValue !== this.state.searchValue) {
            if (Object.keys(this.state.filters).length !== 0) this.setFilters({});
            else this.handleSearch();
        }
    };

    fetchData() {
        return Promise.all([getUsers()]).then(results => {
            this.setState(state => {
                return {
                    rows: results[0],
                    options: {
                        ...state.options,
                        users: results[0],
                    }
                };
            });
        });
    }

    async getAllRoles() {
        await getRoles().then(results => {
            this.setState(state => {
                return {
                    options: {
                        ...state.options,
                        profileOptions: results.map(p => new O(p.id, p.name)),
                    }
                };
            });
        });
    }

    fetchFiltersOptions(rows) {
        this.setState(prev => {
            return {
                options: {
                    ...prev.options,
                    users: rows,
                }
            };
        });
    }

    async addItem(user) {
        await postUser(user);
        return this.fetchData();
    }

    async editItem(user) {
        await postUser(user);
        return this.fetchData();
    }

    delItem(row) {
        alert('deleting item x');
    }


    renderItem(item, key) {
        const val = item[key];

        const style = {
            minWidth: '70px',
            display: 'inline-block',
            textAlign: 'center'
        };

        const lblStyle = {
            marginBottom: "5px",
            marginLeft: "2px",
            marginRight: "2px",
        };

        switch (key) {
			case "companies":

				return val
					? val.map(e => <Label style={lblStyle} key={e}>{e}</Label>)
					: null;

            case 'roleName':

				return val
                    ? <Label key={val} color={item.roleColor} style={style}>{val}</Label>
                    : null;

            case 'isBlocked':

				let text = val === true ? "заблокирован" : "активен";
				let color = val === true ? "red" : "teal";
                return <Label key={val} color={color} style={style}>{text.t}</Label>;

            default:
                return val;
        }
    }

    getFilters = () => {
        let newFilters = {};

        for (let key in this.state.filters) {
            if (this.state.filters[key] && this.state.filters[key] !== '') newFilters[key] = this.state.filters[key].split(',');
        }

        return newFilters;
    }

    async handleSearch() {
        const newFilters = this.getFilters();

        if (Object.keys(newFilters).length === 0 && (!this.state.searchValue || this.state.searchValue === ''))
            this.fetchData();
        else {
            let searchValue = this.state.searchValue || '';
            let rows;
            rows = await getUserBySearch(searchValue, newFilters);
            this.fetchFiltersOptions(rows);
            this.setState({rows});
        }
    }


    async excel() {
        const searchValue = this.state.searchValue || '';
        const newFilters = this.getFilters();
        await exportExcel(searchValue, newFilters).then(res => {
            if (!res.error) {
                window.open(res.url, '_blank');
            }
        });
    }

    renderData() {

        const editRowAction = (data) => {
            return <AppTableButtonLink key="edit"
                url={`/admin/users/${data.id}`}
                title={"редактировать пользователя".t}
                icon="edit" />;
        };

        const { history } = this.props;

        return (
            <div className="bgn-white h-100">
                <Container className="h-100 app-table">
                    <div className="flex-col h-100">
                        <div style={{minHeight: '53px'}}>
                            <AppTableToolbar>
                                <AppTableToolbar.Left>
                                    <TableSearch
                                        handleSearch={this.setSearchValue.bind(this)}
                                        hasSearch={!!this.state.searchValue && this.state.searchValue !== ''}
                                    />
                                </AppTableToolbar.Left>
                                <AppTableToolbar.Right>
                                    <span className='m-r-15'>
                                        <AppToolbarButton
                                            icon="arrow up"
                                            onClick={this.excel.bind(this)}
                                        >
                                            Экспорт пользователей в Excel
                                        </AppToolbarButton>
                                    </span>
									<UserFormNew
										companyOptions={this.state.options.companyOptions}
										roleOptions={this.state.options.profileOptions}
										fetchData={this.handleSearch.bind(this)}
									>
										<AppToolbarButton icon="plus">Добавить пользователя</AppToolbarButton>
									</UserFormNew>
                                    {/* <ModalForm
                                        title={"Новый пользователь".t}
                                        size="small"
                                        submit={this.addItem.bind(this)}
                                        form={UserForm}
                                        options={this.state.options}
                                    >
                                        <AppToolbarButton icon="plus">Добавить пользователя</AppToolbarButton>
                                    </ModalForm> */}
                                </AppTableToolbar.Right>
                            </AppTableToolbar>
                        </div>
                        <div className="flex-grow scrollable-v">
                            <AppTable
                                filters={
                                    <UserFilters
                                        isSearch={this.state.searchValue && this.state.searchValue !== ''}
                                        filters={this.state.filters}
                                        setFilters={this.setFilters}
                                        headers={headers}
                                        users={this.state.options.users}
                                    />
                                }
                                headers={headers}
                                rows={this.state.rows}
                                onRowClick={(data) => history.push(`/admin/users/${data.id}`)}
                                renderItem={this.renderItem.bind(this)}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
