export default class O {
    constructor(id, text, description) {
        this.key = id;
        this.value = id;
        this.text = text;
        this.description = description
    }
}

export class OptionWithIcon extends O {
    constructor(id, text, description, icon, color) {
        super(id, text, description);
        this.icon = icon;
        this.color = color;
    }
}