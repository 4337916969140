import React from 'react';
import {Grid} from "semantic-ui-react";
import CCard from "./CCard";
import WaybillStatusOrder from "../../common/WaybillStatusOrder";
import {getWaybillStatusTexts} from "../../../../api/waybillsUtils";
import RouteHeader from "../RouteHeader";

const AddressesAndCargoRoute = ({index, route}) => {
    const wbStatus = getWaybillStatusTexts(route);

    return (
        <>
            <Grid.Row columns={2} className='address-cargo__route-header'>
                <Grid.Column>
                    <RouteHeader route={route} index={index}/>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <WaybillStatusOrder statusOrder={wbStatus.statusOrder} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    {
                        route.pointsLoading.map(point => <CCard key={point.id} point={point} isLoading/>)
                    }
                </Grid.Column>
                <Grid.Column>
                    {
                        route.pointsUnloading.map(point => <CCard key={point.id} point={point}/>)
                    }
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

export default AddressesAndCargoRoute;