import React from 'react';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';
import { pointReject } from '../../../../api/points';
import T from '../../../../components/Translate';

export default function PointModalReject({
    updateState,
    updateData,
    data,
    dataValidationErrors,
    close,
    loading,
    onOk
}) {

    const getDataValidationErrors = () => {

        const dve = { ...dataValidationErrors };

        dve.statusComment = (!data.statusComment || data.statusComment.length < 10)
            ? "Введите не менее 10 символов"
            : null;

        return dve;
    };

    const submit = async () => {

        const dve = getDataValidationErrors();

        const dataIsValid = Object.entries(dve).every(e => !e[1]);

        if (dataIsValid) {

            updateState({ dataValidationErrors: dve, loading: true });

            await pointReject(data.id, { statusComment: data.statusComment });

            updateState({ open: false, loading: false });

            onOk && onOk();

        } else {
            updateState({ dataValidationErrors: dve });
        }
    };

    return (
        <React.Fragment>
            <Modal.Header><T>Отказ в регистрации адреса</T></Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Field required error={!!dataValidationErrors.statusComment}>
                        <label><T>Введите комментарий по отказу (не менее 10 символов)</T></label>
                        <TextArea
                            placeholder={'комментарий'.t}
                            autoHeight
                            rows={3}
                            value={data.statusComment || ''}
                            onChange={e => updateData({ statusComment: e.target.value })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={loading}
                    onClick={close}
                >
                    <T>Отмена</T>
                </Button>
                <Button
                    disabled={loading}
                    negative
                    onClick={submit}
                >
                    <T>Отклонить адрес</T>
                </Button>
            </Modal.Actions>
        </React.Fragment>
    );
}
