import React, {useEffect, useState} from 'react';
import InputClearable from "../../components/inputs/InputClearable";
import {searchTextFromCells, sortByFieldPresence} from "../../services/search";
import {fieldCompareOptions} from "../../api/calculatingFieldsRules";
import {toast} from "../../services/toast";

const Search = ({className, rules, setRulesWithSearch}) => {
    const [search, setSearch] = useState('');
    const onSearch = (e, {value}) => {
        setSearch(value);
        const newRows = searchTextFromCells((rules || []).map(r => ({
            ...r,
            rules: (r.rules || []).map(rule => ({
                ...rule,
                typeName: fieldCompareOptions.find(option => option.value === rule.type).text
            }))
        })), value, ['id']);

        const notFound = newRows.every(r => !r.searchResult);

        if (notFound && (search || '').length < (value || '').length) {
            toast.warning('Ничего не найдено');
        }
        setRulesWithSearch(sortByFieldPresence(newRows, 'searchResult'));
    };

    useEffect(() => {
        onSearch(null, {value: search});
    }, [rules]);

    return (
        <InputClearable
            className={className}
            value={search}
            onChange={onSearch}
            icon="search"
            placeholder='Поиск...'
        />
    );
};

export default Search;