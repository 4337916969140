import React, {useEffect, useState} from 'react';
import WbGridFilterListMulti from "../../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";
import {Oc} from "../../../api/model/Waybill";
import {Popup, Table} from "semantic-ui-react";
import {sortData} from "../../../services/utils";
import O from "../../../components/Option";
import iconClearFilters from "../../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png";
import {getCompaniesFilter, getEmailsFilter, getRolesFilter, getUserNamesFilter} from '../../../api/usersRoles';

export default function UserFilters({filters, setFilters, headers, users, isSearch}) {

    function applyFilter(filter) {
        setFilters({...filters, ...filter});
    }

    function removeFilter(key) {
        let newFilters = {...filters};
        delete newFilters[key];
        setFilters({...newFilters});
    }

    const [roles, setRoles] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [emails, setEmails] = useState([]);
    const [userNames, setUserNames] = useState([]);

    useEffect(() => {
        async function getFiltersData () {
            await getRolesFilter().then(data => setRoles(data));
            await getCompaniesFilter().then(data => setCompanies(data));
            await getEmailsFilter().then(data => setEmails(data));
            await getUserNamesFilter().then(data => setUserNames(data));
        }
        getFiltersData();
    }, []);

    const filtersIsNull = () => {
        if (isSearch) return false;
        for (let i in filters) {
            if (filters[i] !== '') return false;
        }
        return true;
    };

    const getFilter = (key) => {
        switch (key) {
            case 'isBlocked':
                return <WbGridFilterListMulti
                    filters={filters}
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new Oc(false, 'активен', "green"),
                        new Oc(true, 'заблокирован', "red")
                    ]}/>;
            case 'roleName':
                return <WbGridFilterListMulti
                    hideOnScroll={false}
                    filters={filters}
                    field={'roles'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={roles.map(r=>new Oc(r.id, r.name, r.color))}
                    //dependentOnOthersOptions={[...new Set(users.map(u=>u.roleId))]}
                    //emptyFilters={filtersIsNull()}
                />;
            case 'email':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={'emails'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={emails.map(r=>new O(r, r))}
                    dependentOnOthersOptions={[...new Set(users.map(u=>u.email))]}
                    emptyFilters={filtersIsNull()}
                />;
            case 'name':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={'names'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    dependentOnOthersOptions={[...new Set(users.map(u=>u.name))]}
                    emptyFilters={filtersIsNull()}
                    options={userNames.map(c=>new O(c.userName, c.userName))}
                />;
            case 'companies':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={companies.map(c=>new O(c.name, c.name))}
                    dependentOnOthersOptions={[...new Set(users.map(u=>u.companies).flat())]}
                    emptyFilters={filtersIsNull()}
                    position={'bottom right'}
                />;
            case 'filters':
                return <Popup content={"Очистить все фильтры".t} trigger={clearFilterButton} />;
            default:
                return null;
        }
    };

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={()=>setFilters({})}
            style={{ paddingTop: '5px', marginLeft: '10px', marginRight: '6px', display: 'inline-block', width: '17px' }}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{ height: '13px' }} />
        </span>
    );

    return (
        <Table.Row className='notifications-filter'>
            {headers.map(({key}) => (
                <Table.HeaderCell
                    key={key}
                    className={"table-select-cell notifications-filter-fixed__title app-table-filters"}
                >
                    {getFilter(key)}
                </Table.HeaderCell>
            ))}
        </Table.Row>
    );

}
