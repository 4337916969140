import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import T from '../Translate';

ModalConfirm.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default function ModalConfirm({ title, text, onConfirm, children }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleConfirm() {
        setLoading(true);
        try {
            onConfirm && await onConfirm();
        } finally {
            setLoading(false);
            setOpen(false);
        }
    }

    function handleCancel() {
        setLoading(false);
        setOpen(false);
    }

    return (
        <Modal
            size="tiny"
            trigger={children}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={handleCancel}
            closeIcon
            dimmer="inverted"
        >
            <Modal.Header><T>{title}</T></Modal.Header>
            <Modal.Content>
                <T>{text}</T>
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={handleCancel}><T>Отмена</T></Button>
                <Button loading={loading} onClick={handleConfirm} primary><T>Выполнить</T></Button>
            </Modal.Actions>
        </Modal>
    );
}

