import { getToken, clearUserContext } from './auth';
import { toast } from './toast';



function getOptions(isUpload) {

	const options = {
		headers: {
			Accept: 'application/json'
		}
    };

    if (isUpload) {
        //options.headers['Content-Type'] = false;
        //options.headers['Content-Type'] = 'multipart/form-data';
        //options.headers['Content-Disposition'] = 'form-data; name="upload"';
    } else {
        options.headers['Content-Type'] = 'application/json';
    }

	const token = getToken();

	if (token) {
		options.headers['Authorization'] = 'Bearer ' + token;
	}

	return options;
}

export class ApiError {

    constructor(title, resJson, severity = "error") {

        this.name = title || "Ошибка";
        this.severity = severity;

        if (resJson) {

            switch (typeof resJson) {
                case "object":
                    if (Array.isArray(resJson)){
                        this.arrayErrors = resJson;
                    }
                    else if (resJson.error) {         // what case?

                        this.message = resJson.error;

                    } else if (resJson.errors) {  // std case

                        switch (typeof resJson.errors) {
                            case "object":
                                const isValidationError = resJson.title === "One or more validation errors occurred.";

                                this.name = isValidationError ? this.name : (resJson.title || this.name);

                                let messages = [];

                                Object.entries(resJson.errors).forEach(p => {

                                    //messages.push(p[0] || "general"); // subtitle (it is not quite readable for users)

									const subErrors = p[1];
                                    if (subErrors && subErrors.length) {
                                        subErrors.forEach(se => messages.push("- " + se));
                                    }
                                });

								this.message = messages;
								this.severity = "warning";
                                break;
                            case "string":

                                this.message = resJson.errors;
                                break;

                            default:
                                this.message = "no description";
                                break;
                        }
                    }

                    break;
                case "string":
                    this.message = resJson;
                    break;

                default:
                    this.message = "no description";
                    break;
            }
        }
    }
}

function applyTasks(resJson) {

    if (resJson.tasks && resJson.tasks.length) {

        resJson.tasks.forEach(t => {

            switch (t.key) {
                case "msg":
                    toast[t.type](t.message);
                    break;
                case "notification":
                    throw new Error('Функционал находится в разработке.');
                    //break;
                default:
                    throw new Error("invalid task type");
            }
        });
    }
}

async function getResJson(res) {
    let resJson = null;

    // const contentLength = res.headers.get("content-length");

    // if (contentLength && contentLength !== "0") {
    //     resJson = await res.json();
    // }

    if (!res.bodyUsed) {
        // validation errors do not return cl
        try {
            resJson = await res.json();
        } catch (e) {
            // fall thru
        }
    }
    return resJson;
}

async function getBlob(res) {
    return res.blob();
}

async function send(method, url, data, isUpload, isDownload) {

	const options = getOptions(isUpload);

    options.method = method;

    if (data) {
        options.body = isUpload
            ? data
            : JSON.stringify(data);
    }

    try {

        const res = await fetch('api/' + url, options);

        if (res.ok) {

            if (res.status !== 204) {

                if (isDownload) {
                    const blob = await getBlob(res);
                    return  {
                        headers: res.headers.get('content-disposition'),
                        data: blob
                    };
                }

                const resJson = await getResJson(res);

                if (resJson) {
                    applyTasks(resJson);
                    return resJson.data;

                } else {
                    //downloads will have no json
                    console.log(`responce json is null for ${res.url}`);
                   /* throw new Error("responce json is null");*/
                }


            }

        } else {

            if (res.type === "error") {
                throw new ApiError("Ошибка соединения.");
            }

            const resJson = await getResJson(res);

            switch (res.status) {
                case 401:

                    clearUserContext();
                    window.location.assign("login");

                    break;

                case 404:

                    throw new ApiError("Ошибка приложения", {
                        error: `Адрес API не найден: ${url}`
                    });

                default:

                    if (res.status >= 500) {
                        const title = "Ошибка приложения"; // res.statusText
                        // 500 will pack json description of error
                        throw new ApiError(title, resJson);
                    }

                    if (res.status >= 400) {   // model validation, or bad request , add 413, 408
                        const resIsObject = typeof resJson === "object";
                        if (resIsObject) {
                            const message = typeof resJson.errors === "object"
                                ? Object.keys(resJson.errors).map(m => resJson.errors[m]).join(';\n')
                                : resJson.errors;
                            throw new ApiError(resJson.title, message, "error");
                        } else {
                            let title = "Операция не возможна"; //res.statusText;
                            throw new ApiError(title, resJson, "warning");
                        }

                    }

                    break;
            }


        }

    } catch (err) {
        let title = (err && err.name) || "Ошибка";
        let message = typeof err === "object" ? err.message : err;

        if (isUpload && message === "Failed to fetch") {
            if (data instanceof FormData && data.has("files")) {
                title = "Ошибка пересылки файлов";
                message = "Убедитесь, что все пересылаемые файлы закрыты и попробуйте еще раз.";
            }
        }

        if (!navigator.onLine) {
            title = 'Связь с сервером потеряна, проверьте соединение'.t;
            message = ' ';
        }

        const severity = err.severity || 'error';

        if (err.arrayErrors) {
            let title = "Операция не возможна";
            err.arrayErrors.forEach(e => toast[severity](e, title));
        } else toast[severity](message, title);

        throw err;
    }
};

export const get = (url) => send('get', url);

export const post = (url, data) => send('post', url, data);

export const put = (url, data) => send('put', url, data);

export const upload = (url, data, isUpload = true, isDownload) => send('post', url, data, isUpload, isDownload);

export const del = (url, data) => send('delete', url, data);

export const download = (url) => send('get', url, null,false, true);

export function delay(milliseconds, result, error) {

    return new Promise((resolve, reject) => {

        setTimeout(() => {

            let isOk = Math.random() >= 0.003 && !error;

            if (isOk)
            {
                result = typeof result === "function" ? result() : result;

                resolve(result);
            }
            else
                reject(error);

        }, milliseconds);
    });
}

export function packFiles(files, form) {

    if (!files || !files.length) {
        alert('не выбрано ни одного файла');
        return;
    }

    form = form || new FormData();

    for (var i = 0; i < files.length; i++) {
        form.append("upload", files[i]);
    }

    return form;
}

export function processFile(dto) {
    dto.id && window.open("/api/file/" + dto.id, '_blank', '');
}
