import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getCompanyOptionsBySearch } from '../../api/companies';
import { toast } from '../../services/toast';
import './InputDropdown.css';

//const getOptionsBySearchDebounced = debounce(getOptionsBySearch, 850);

export default function InputDropdownSearchMultiple({ onChange, value, options: optionsInitial = [], placeholder, disabled, getOptionsBySearch}) {

    const [options, setOptions] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');

    const [isFetching, setIsFetching] = useState(false);

    const optionsAll = [...optionsInitial, ...options];

    const optionsUnique = removeDuplicates(optionsAll, "value");

    function removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    async function handleSearchChange(e, { searchQuery }) {
        setSearchQuery(searchQuery);

        if (searchQuery && searchQuery.length > 2) {
            setIsFetching(true);
            try {
                const optionsNew = await getOptionsBySearch(searchQuery);
                const optionsSelected = options.filter(o => (value || []).includes(o.value));

                setOptions([...optionsNew, ...optionsSelected]);

            } catch (err) {

                toast.error(err.message, err.name);
            } finally {

                setIsFetching(false);
            }
        }
    };

    function handleChange(e, item) {

        onChange(item.value);

        setSearchQuery('');

    };

    function renderLabel(label) {
        return {
            color: 'blue',
            content: `${label.text}`
        };
    };

    // useEffect(() => {

    //     if (!searchQuery || searchQuery.length < 3) {
    //         setOptions([]);
    //     } else {
    //         getOptionsBySearchDebounced(searchQuery);
    //     }

    // }, [searchQuery]);

    return (
        <Dropdown

            search
            searchQuery={searchQuery}
            value={value || []}

            onSearchChange={handleSearchChange}
            onChange={handleChange}
            noResultsMessage={'Результатов не найдено'.t}
            className="InputDropdown"
            placeholder={placeholder}
            fluid
            multiple

            closeOnChange
            selection
            options={optionsUnique}
            renderLabel={renderLabel}

            disabled={isFetching || disabled}
            loading={isFetching}
        />
    );

}

