import React, {useContext, useEffect, useState} from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import {Popup} from 'semantic-ui-react';
import {ContextLocale} from '../../../../services/context';

export default function FilterInputDateTimePopup({value, position, updateParent, children, time, updateTime}) {
    const contextLocale = useContext(ContextLocale);

    const [selectedDay, setSelectedDay] = useState(value ? parseDate(value, 'DD.MM.YYYY') : undefined);

    useEffect(() => {
        setSelectedDay(value ? parseDate(value, 'DD.MM.YYYY') : undefined);
    }, [value]);

    function handleDayChange(day, {selected}) {
        setSelectedDay(day);
        updateParent(formatDate(day, "L", contextLocale.locale));
    }

    const picker = (
        <>
            <DayPicker
                onDayClick={handleDayChange}
                month={selectedDay}
                selectedDays={selectedDay}
                firstDayOfWeek={1}
                localeUtils={MomentLocaleUtils}
                locale={contextLocale.locale}/>
                <div className="filter-timeslot">
                    <label>Время:</label>
                    <input type="time" value={time.from} disabled={!selectedDay} onChange={e => updateTime(e.target.value, 'from')}/>
                    -
                    <input type="time" value={time.to} disabled={!selectedDay || !time.from} onChange={e => updateTime(e.target.value, 'to')}/>
                </div>
        </>
    );
    return (
        <Popup
            trigger={children}
            content={picker}
            on='click'
            position={position || 'top center'}
        />
    );

}
