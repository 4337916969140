import React, {useEffect, useState} from 'react';
import {Button, Grid, Icon, Table} from 'semantic-ui-react';
import T from '../../../../../components/Translate';
import {CutoffsFTLTimeAdd} from './CutoffsFTLTimeAdd';
import RowDeleteAction from './_shared/RowDeleteAction';
import {WaybillCutoffShipmentRegionsEnumOptions} from '../../../../../api/model/Enums';
import {companyCutoffTimeFTLDelete, getCompanyCutoffs} from '../../../../../api/companyCutoffs';


export default function CutoffsFTLTime({match}) {
    const [rows, setRows] = useState([]);

    function onAdd(row) {
        setRows([...rows, row]);
    }

    async function removeAsync(row) {
        await companyCutoffTimeFTLDelete(match.params.id, row);
    }

    function Row({row}) {
        return (
            <Table.Row>
                <Table.Cell>{row.cutoffHour}:{row.cutoffMinutes.toString().padStart(2, '0')}</Table.Cell>
                <Table.Cell>{WaybillCutoffShipmentRegionsEnumOptions.getText(row.region)}</Table.Cell>
                <Table.Cell textAlign="center"><RowDeleteAction {...{row, rows, setRows, removeAsync}} /></Table.Cell>
            </Table.Row>
        );
    }

    useEffect(() => {
        async function getCompanyCutoffsLocal() {
            const res = await getCompanyCutoffs(match.params.id, "ftl/time");
            setRows(res || []);
        }

        getCompanyCutoffsLocal();
    }, [match.params.id]);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column align="right">
                        <CutoffsFTLTimeAdd onAdd={onAdd} companyId={match.params.id}>
                            <Button primary>
                                <Icon name="plus"/>
                                <T>Добавить правило</T>
                            </Button>
                        </CutoffsFTLTimeAdd>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={5}><T>Время отсечки</T></Table.HeaderCell>
                        <Table.HeaderCell width={5}><T>Регион</T></Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign="center" />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((r, i) => <Row key={i} row={r}/>)}
                </Table.Body>
            </Table>
        </>
    );
}
