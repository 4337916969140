import {isEmpty, valueToString} from "./utils";

const isInvalid = (value, col) => {
    const isEmptyValue = isEmpty(value);
    let isInvalidValue = false;
    if (col.fieldType === 'number') {
        if ((!isEmpty(col.min) && value < col.min) || (!isEmpty(col.max) && value > col.max)) isInvalidValue = true;
    }
    return isInvalidValue || isEmptyValue;
};

export const checkRequiredFields = (headers, row) => {
    let allRequiredAreFilled = true;
    headers.forEach(col => {
        if (
            (col.isRequired && isEmpty(row[col.key]))
            || (col.requiredThisOr && isEmpty(row[col.key]) && col.requiredThisOr.every(key => isInvalid(row[key], col)))
        ) allRequiredAreFilled = false;
    });

    return allRequiredAreFilled;
};

export const isFullCopy = (allRows, headers, row) => {
    return allRows.find(
        r => (r.id !== row.id) && headers.map(h => h.key)
            .every(key => valueToString(row[key]) === valueToString(r[key]))
    );
};