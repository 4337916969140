import React, {useState} from 'react';
import {Button, Confirm, Grid, GridColumn, Header, Icon, Label} from "semantic-ui-react";
import WbPointCards from "./WbPointCards";
import {WaybillStatusEnum} from "../../../api/model/Waybill";
import {isNumber} from "../../../services/utils";
import getIndexWbPoints from "../../../services/getIndexWbPoints";

export const LoadUnloadTables = ({
                                     dto,
                                     isRoute,
                                     isLastRoute,
                                     index,
                                     updateData: changeData,
                                     isEdit,
                                     auctionHeight,
                                     routes,
                                     changePoints,
                                     changeRoutes,
                                     isClient = false,
                                     isPoolingEdit = false,
                                     disabled,
                                     firstUnloadingDate,
                                     firstLoadingDate,
                                     activeTab,
                                     handleTabChange,
                                     deleteRoute,
                                     fieldConfigs,
                                     isDraft,
                                 }) => {
    const isFirstRoute = index === 0;

    const loadUnitsToOrderMappings = (dto.pointsUnloading || []).reduce((val, p) => val = val.concat(p.loadUnitsToOrderMappings), []);

    const loadUnitSets = (dto.pointsLoading || []).reduce((val, p) => val.concat(p.loadUnitSets), []);

    const unitsLoadedTotal = (loadUnitSets || []).reduce((val, p) => val + (p.loadUnitsCount || 0), 0);

    const pointsAll = dto.pointsLoading.map(item => ({
        ...item,
        isLoading: true
    })).concat(dto.pointsUnloading.map(item => ({
        ...item,
        isLoading: false
    })));

    const routeHeader = isRoute && <div className="wbPointCardShellHeader-container points-block__route_header">
        <div className="wbPointCardShellHeader-item-left">
            <Label size="big" circular>{index + 1}</Label>
        </div>
        <div className="wbPointCardShellHeader-item-center">
            <Header className="m-0">
                Маршрут {dto.id}
            </Header>
        </div>
    </div>;

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const onDelete = (id) => {
        if (isDraft || routes.length > 2) {
            deleteRoute(id);
        } else setOpenConfirmDelete(id);
    };

    const routeDeleteBtn = isRoute &&
        <div className="wbPointCardShellHeader-container points-block__route_header">
            <Icon
                disabled={disabled || isClient}
                className="icon-pointer points-block__delete"
                name="trash alternate"
                size="large"
                onClick={() => onDelete(dto.id)}
            />
            <Confirm
                open={!!openConfirmDelete}
                onConfirm={() => deleteRoute(openConfirmDelete, true)}
                onCancel={() => setOpenConfirmDelete(false)}
                cancelButton='Нет'
                confirmButton='Да'
                header='Вы уверены, что хотите удалить маршрут?'
                content='При удалении одного из двух последних маршрутов в О-С-С заявке, текущая заявка будет удалена и будет автоматически создана новая заявка с оставшимся маршрутом.'
            />
        </div>;

    const emptyPoints = !dto.pointsUnloading.length && !dto.pointsLoading.length;

    const changePointPosition = (toNext, fromId, toId) => {
        changePoints({
            waybillIdFrom: dto.id,
            waybillIdTo: toNext ? routes[index + 1].id : dto.id,
            waybillPointIdFrom: fromId,
            waybillPointIdTo: toId
        });
    };
    const changeRoutesPosition = () => {
        changeRoutes({
            waybillIdFrom: dto.id,
            waybillIdTo: routes[index + 1].id,
        });
    };

    const updateData = (...props) => {
        changeData(...props, isRoute && dto.id);
    };

    return <Grid.Row key={dto.id}
                     className={(dto.pointsUnloading.length || dto.pointsLoading.length) ? 'point_min-h-400' : ''}
                     columns={2} divided>
        <GridColumn>
            <WbPointCards
                disabled={disabled}
                isEdit={isEdit}
                auctionHeight={auctionHeight}
                wbId={dto.id}
                wbType={dto.shippingType}
                wbClientId={dto.clientId}
                pointsAll={pointsAll}
                points={dto.pointsLoading}
                oppositeDate={firstUnloadingDate}
                updatePoints={(pointsLoading, loadingsChanged) => updateData({pointsLoading}, {loadingsChanged})}
                updateWb={updateData}

                profile="pointsLoading"

                title={isFirstRoute && "Загрузка"}
                addTitle="Добавить адрес загрузки"

                positions={loadUnitSets}
                unitsLoadedTotal={unitsLoadedTotal}

                activeTab={activeTab}
                handleTabChange={handleTabChange}

                isClient={isClient}
                isPoolingEdit={isPoolingEdit}
                isRoute={isRoute}
                header={routeHeader}
                fieldConfigs={fieldConfigs}
                route={dto}
            />
            {
                !!(isRoute && !isLastRoute && (isNumber((dto.pointsLoading[0] || {}).id) || isNumber((routes[index + 1].pointsLoading[0] || {}).id))) &&
                <Icon
                    disabled={disabled || isClient}
                    className="points-block__change-point-btn points-block__change-point_rotated"
                    size="large"
                    color="blue"
                    name="exchange"
                    rotated="clockwise"
                    onClick={() => changePointPosition(true, (dto.pointsLoading[0] || {}).id, (routes[index + 1].pointsLoading[0] || {}).id)}
                />
            }
        </GridColumn>
        {
            isRoute && !emptyPoints && <Icon
                disabled={disabled || isClient}
                className="points-block__change-point-btn"
                size="large"
                color="blue"
                name="exchange"
                onClick={() => changePointPosition(false, (dto.pointsLoading[0] || {}).id, (dto.pointsUnloading[0] || {}).id)}
            />
        }
        {
            (isRoute && !isLastRoute) && <Button
                disabled={disabled || isClient}
                className="points-block__change-point-btn points-block__change-routes-btn"
                size="small"
                color="blue"
                onClick={changeRoutesPosition}
            >
                Поменять маршруты
            </Button>
        }
        <GridColumn>
            <WbPointCards
                disabled={disabled}
                auctionHeight={auctionHeight}
                isEdit={isEdit}
                wbId={dto.id}
                wbType={dto.shippingType}
                wbClientId={dto.clientId}
                pointsAll={pointsAll}
                points={dto.pointsUnloading}
                oppositeDate={firstLoadingDate}
                updatePoints={pointsUnloading => updateData({pointsUnloading}, {unloadingsChanged: true}, isRoute && dto.id)}
                updateWb={updateData}
                profile="pointsUnloading"

                title={isFirstRoute && "Выгрузка"}
                addTitle="Добавить адрес выгрузки"

                positions={loadUnitsToOrderMappings}
                unitsLoadedTotal={unitsLoadedTotal}

                activeTab={activeTab}
                handleTabChange={handleTabChange}

                isClient={isClient}
                isPoolingEdit={isPoolingEdit}
                isRoute={isRoute}
                header={routeDeleteBtn}
                route={dto}
            />
            {
                !!(isRoute && !isLastRoute && (isNumber((dto.pointsUnloading[0] || {}).id) || isNumber((routes[index + 1].pointsUnloading[0] || {}).id))) &&
                <Icon
                    disabled={disabled || isClient}
                    className="points-block__change-point-btn points-block__change-point_rotated"
                    size="large"
                    color="blue"
                    name="exchange"
                    rotated="clockwise"
                    onClick={() => changePointPosition(true, (dto.pointsUnloading[0] || {}).id, (routes[index + 1].pointsUnloading[0] || {}).id)}
                />
            }
        </GridColumn>
    </Grid.Row>;
};

const WbPointsBlock = ({
                           wb,
                           routes,
                           auctionHeight,
                           canAddRoutes,
                           addRoute,
                           activeTab,
                           handleTabChange,
                           isEdit,
                           deleteRoute,
                           changePoints,
                           changeRoutes,
                           updateData,
                           disabled,
                           isClient,
                           isPoolingEdit,
                           firstUnloadingDate,
                           firstLoadingDate,
                           fieldConfigs
                       }) => {

    const wbs = (routes || [wb]);

    const title = (label, btn) => (
        <div
            className="points-block__header"
        >
            <h3>{label}</h3>
            {btn && <Button
                disabled={disabled || isClient}
                className="f-r"
                color="blue"
                size="small"
                onClick={addRoute}
            >
                Добавить маршрут
            </Button>}
        </div>
    );

    return (
        <div className="points-block">
            <Grid
                columns={2}
                className="points-block__points"
                style={{
                    maxHeight: `calc(100vh - 241px - ${auctionHeight}px)`,
                }}
            >
                <Grid.Row className="point-card-header__first" columns={2}>
                    <Grid.Column>
                        {title('Загрузка')}
                    </Grid.Column>
                    <Grid.Column>
                        {title('Выгрузка', canAddRoutes)}
                    </Grid.Column>
                </Grid.Row>
                {
                    wbs.map((route, index) => (
                        <LoadUnloadTables
                            key={index}
                            dto={getIndexWbPoints(route)}
                            isRoute={!!routes.length}
                            index={index}
                            auctionHeight={auctionHeight}
                            routes={routes}
                            changeRoutes={changeRoutes}
                            isLastRoute={(index + 1) === wbs.length}

                            updateData={updateData}
                            disabled={disabled}
                            isClient={isClient}
                            isPoolingEdit={isPoolingEdit}
                            firstUnloadingDate={firstUnloadingDate}
                            firstLoadingDate={firstLoadingDate}
                            activeTab={activeTab}
                            handleTabChange={handleTabChange}
                            isEdit={isEdit}
                            changePoints={changePoints}
                            deleteRoute={deleteRoute}
                            fieldConfigs={fieldConfigs}

                            isDraft={wb.status === WaybillStatusEnum.DRAFT}
                        />
                    ))
                }
            </Grid>
        </div>

    );
};

export default WbPointsBlock;