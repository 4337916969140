import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import {cutoffGet, cutoffSave, cutoffUpdateGet, cutoffUpdateSave} from "../../../../api/companies";
import {Checkbox, Divider, Form, Grid, Header} from "semantic-ui-react";
import DateTime from "../../../../components/inputs/DateTime";
import {withRouter} from "react-router-dom";

const CompanyTabCutoff = ({match, isUpdate}) => {
    const [cutoffsOff, setCutoffsOff] = useState(false);
    const [cutoffsOffTimeoutOn, setCutoffsOffTimeoutOn] = useState(false);
    const [cutoffsOffTimeout, setCutoffsOffTimeoutDateTime] = useState(null);
    const timeoutDisabled = !(cutoffsOff && cutoffsOffTimeoutOn);

    const getData = isUpdate ? cutoffUpdateGet : cutoffGet;
    const saveData = isUpdate ? cutoffUpdateSave : cutoffSave;

    const cutoffsDisabledName = isUpdate ? 'cutoffsUpdateDisabled' : 'cutoffsDisabled';
    const cutoffsDateName = isUpdate ? 'cutoffsUpdateDisabledDate' : 'cutoffsDisabledUntil';

    const bodyData = (newData) => {
        const data = {
            [cutoffsDisabledName]: cutoffsOff,
            [cutoffsDateName]: cutoffsOffTimeout,
            ...newData
        };

        data[cutoffsDateName] = data[cutoffsDateName] ? moment(data[cutoffsDateName]).format("YYYY-MM-DDTHH:mm:ss") : null;
        return data;
    };

    const setCutoffsOffTimeout = (dateTime) => {
        setCutoffsOffTimeoutDateTime(dateTime ? moment(dateTime).format('YYYY-MM-DDTHH:mm:ss') : null);
    };

    const setInit = () => {
        setCutoffsOff(false);
        setCutoffsOffTimeoutOn(false);
        setCutoffsOffTimeoutDateTime(null);
    };

    const saveDateTime = (dateTime) => saveData(match.params.id, bodyData({[cutoffsDateName]: dateTime}));
    const saveCutoffsOff = (data) => saveData(match.params.id, bodyData({[cutoffsDisabledName]: data}));

    const saveNull = () => saveData(match.params.id, bodyData({
        [cutoffsDisabledName]: false,
        [cutoffsDateName]: null,
    }));

    async function toggleCutoffs() {
        const prev = cutoffsOff;
        try {
            if (prev === true && cutoffsOffTimeoutOn) {
                // offing -> need to off timeout
                setInit();
                await saveNull();
            } else {
                await saveCutoffsOff(!prev);
                setCutoffsOff(!prev);
            }
        } catch (error) {
            setCutoffsOff(prev);
        }
    }

    async function toggleCutoffsOffTimeout() {
        const prev = cutoffsOffTimeoutOn;
        setCutoffsOffTimeoutOn(!prev);
        try {
            if (prev === true) {
                // swithing off
                await saveDateTime(null);
                setCutoffsOffTimeout(null);
            } else {
                const dateNow = moment().add(1, 'hour').toDate();
                await saveDateTime(dateNow);
                setCutoffsOffTimeout(dateNow);
            }
        } catch (error) {
            setCutoffsOffTimeoutOn(prev);
            throw error;
        }
    }

    const sendData = async (dateTime) => {
        setCutoffsOffTimeout(dateTime);
        await saveDateTime(dateTime);
    };

    async function fetchCutoffs() {
        const companyId = match.params.id;
        const data = await getData(companyId);
        const timeoutDate = data[cutoffsDateName];
        const hasValue = !!timeoutDate;
        if (hasValue) {
            if (moment().isAfter(moment(timeoutDate, "YYYY-MM-DDTHH:mm:ss"))) {
                setCutoffsOff(false);
                await saveNull();
                setCutoffsOffTimeout(null);
                setCutoffsOffTimeoutOn(false);
            } else {
                setCutoffsOffTimeout(timeoutDate);
                const cutoffsOffRes = data[cutoffsDisabledName];
                setCutoffsOff(cutoffsOffRes);
                setCutoffsOffTimeoutOn(hasValue);
            }
        } else {
            const cutoffsOffRes = data[cutoffsDisabledName];
            setCutoffsOff(cutoffsOffRes);
        }
    }

    useEffect(() => {
        setInit();
        fetchCutoffs();
    }, [isUpdate, match]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header className="m-b-10">Продление {isUpdate ? 'обновления' : 'регистрации'} заявки</Header>
                    <Divider className="m-t-0"/>
                    <Form size="small">
                        <Form.Checkbox
                            label={`Продление ${isUpdate ? 'обновления' : 'включено'}`}
                            checked={cutoffsOff}
                            onChange={toggleCutoffs}
                        />
                        <Form.Group inline widths="equal">
                            <Checkbox
                                label={`Продление ${isUpdate ? 'обновления' : 'включено'} до`}
                                checked={cutoffsOffTimeoutOn}
                                onChange={toggleCutoffsOffTimeout}
                                disabled={!cutoffsOff}
                            />
                            <Form.Field className='input-after-checkbox' disabled={timeoutDisabled}>
                                <label>Дата и время</label>
                                <DateTime
                                    onChange={sendData}
                                    value={cutoffsOffTimeout}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default withRouter(CompanyTabCutoff);