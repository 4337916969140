import React from 'react';
import {Icon, Input} from "semantic-ui-react";
import {fieldTypes} from "../../../../api/model/ImportForms";
import ObjectRows from "./objectRows";


const ReplaceRow = ({i, v, changeValue, ...props}) => {
    const clearBtn = (name, value) => <Icon
        className={`cursor-pointer ${value && 'pointer-events'}`}
        name={value && "close"}
        onClick={() => value && changeValue(i, {name, value: null})}
    />;

    const input = (name) => {
        const value = (v || {})[name];

        return <Input
            {...props}
            icon={clearBtn(name, value)}
            name={name}
            fluid
            type={fieldTypes.STRING}
            value={value || ''}
            onChange={(e, p) => changeValue(i, p)}
        />;
    };

    return <div className="replace__row">
        {input('search')}
        <Icon
            color="blue"
            name="arrow right"
        />
        {input('replace')}
    </div>;
};

const Replace = ({value, onChange, delBtn, icon, disabled, inputType, name}) => {
    const valueText = (value || []).map(v => (v.search || v.replace) ? `${v.search || ''} --> ${v.replace || 'не указано'}` : '').join('; ');

    return <ObjectRows
        value={value}
        onChange={onChange}
        delBtn={delBtn}
        icon={icon}
        disabled={disabled}
        inputType={inputType}
        name={name}
        valueText={valueText}
        className='replace'
        component={ReplaceRow}
    />;
};

export default Replace;