import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {companyCutoffTimeRailLTLAdd} from '../../../../../api/companyCutoffs';
import { WaybillCutoffShipmentRegionsEnumOptions } from '../../../../../api/model/Enums';
import T from '../../../../../components/Translate';
import { EnumMinutesOptions } from './_shared/EnumMinutes';
import { EnumHoursOptions } from './_shared/EnumHours';

CutoffsRailLTLTimeAdd.propTypes = {
	onAdd: PropTypes.func.isRequired,
	companyId: PropTypes.string.isRequired
};
export function CutoffsRailLTLTimeAdd({ children, onAdd, companyId }) {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isClickSave, setIsClickSave] = useState(false);

    useEffect(() => {
        if (isClickSave) {
            const errorsForm = getErrorsForm();
            setErrors(errorsForm);
        }
    }, [data]);

    function getErrorsForm() {
        let errorsNew = [];
        if (data && !data.cutoffHour && data.cutoffHour !== 0) {
            errorsNew.push('cutoffHour')
        }
        if (data && !data.cutoffMinutes && data.cutoffMinutes !== 0) {
            errorsNew.push('cutoffMinutes')
        }
        if (data && !data.region && data.region !== 0) {
            errorsNew.push('region')
        }
        return errorsNew;
    }

    function updateData(prop) {
        setData({ ...data, ...prop });
    }

    function onClickClose() {
        setIsClickSave(false);
        setData({});
        setErrors([]);
        setOpen(false);
    }

    async function save() {
        setIsClickSave(true);
        const errorsForm = getErrorsForm();
        if (errorsForm.length > 0) {
            setErrors(errorsForm);
        } else {
            setLoading(true);
            try {
                const newRule = await companyCutoffTimeRailLTLAdd(companyId, data);
                if (newRule)
                    onAdd(data);
            } finally {
                setLoading(false);
                onClickClose();
            }
        }
    }

    return (
        <Modal
            trigger={children}
            dimmer="inverted"
            size="small"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={onClickClose}
        >
            <Modal.Header><T>Добавление ограничения по времени отправки заявки</T></Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Group widths="equal">
                    	<Form.Select
                            error={errors.includes('cutoffHour')}
							required
	                        label={'Час отсечки'.t}
							placeholder={'Час отсечки'.t}
							options={EnumHoursOptions}
	                        value={data.cutoffHour}
	                        onChange={(e, { value }) => updateData({ cutoffHour: value })}
	                    />
						<Form.Select
                            error={errors.includes('cutoffMinutes')}
							required
							label={'Минуты отсечки'.t}
							placeholder={'Минуты отсечки'.t}
							options={EnumMinutesOptions}
							value={data.cutoffMinutes}
							onChange={(e, { value }) => updateData({ cutoffMinutes: value })}
						/>
                    </Form.Group>
                    <Form.Select
                        error={errors.includes('region')}
						required
                        label={'Регион'.t}
                        placeholder={'Регион'.t}
                        options={WaybillCutoffShipmentRegionsEnumOptions}
                        value={data.region}
                        onChange={(e, { value }) => updateData({ region: value })}
                    />
                </Form>
                {errors.length > 0 && <Message warning>
                    <T>Заполните обязательные поля</T>
                </Message>}
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={onClickClose}><T>Отмена</T></Button>
                <Button disabled={loading} primary onClick={save}><T>Сохранить</T></Button>
            </Modal.Actions>
        </Modal>
    );
}
