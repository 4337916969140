import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Divider, Dropdown, Form, Header, Input, Modal} from 'semantic-ui-react';
import {
    CargoTypeOptions,
    PickupTypeEnum,
    PickupTypeOptions,
    ThermoModeOptions,
    WaybillFieldEnum
} from '../../../api/model/Enums';
import {LoadCapacityOptions} from '../../../api/model/Point';
import UserPermissions from '../../../api/model/UserPermissions';
import {
    BodyCapacityOptions,
    LoadCapacityEnum,
    WaybillAvisationStatus,
    WaybillStatusEnum,
    WaybillTypeEnum,
    WaybillTypeOptions
} from '../../../api/model/Waybill';
import {getMinCapacity} from '../../../api/tonnage';
import InputDropdown from '../../../components/inputs/InputDropdown';
import T from '../../../components/Translate';
import {ContextUser} from '../../../services/context';
import {showField, showValue,} from '../../admin/companies/companyTabs/companyTabFieldConfig/companyTabUtils';
import {ProviderStatusEnum} from '../../../api/model/Provider';
import {getProvidersCommonCompanies} from "../../../api/companies";
import {getCompanyOptionsBySearchUser} from "../../../api/users";
import {restrictionInt, unique} from "../../../services/utils";
import WbPointAddForm from "./WbPointAddForm";
import {toast} from "../../../services/toast";
import {getCarTypesForField} from "../../../api/dictionaries";
import {getCanUseCarType} from "../../../api/waybills";
import {BodyTypeEnum} from "../../../api/model/Dictionaries";

const stl1 = {
    backgroundImage: 'linear-gradient(to right, rgba(0, 255, 102, 0.12), white)',
};

/** @param {Waybill} dto */
export default function WbEditMain({
                                       dto = {},
                                       withRoutes,
                                       setLoading,
                                       updateData,
                                       cargoRestrictions,
                                       fieldConfigs,
                                       isClient = false,
                                       cargoCostIsRequired,
                                       isAuction,
                                       disabled,
                                   }) {
    const isFTL = dto.shippingType === WaybillTypeEnum.FTL;
    const isLTL = dto.shippingType === WaybillTypeEnum.LTL;
    const isRail = dto.shippingType === WaybillTypeEnum.Rail;
    const isRailLTL = dto.shippingType === WaybillTypeEnum.RailLTL;
    const isPlanReturn = dto.shippingType === WaybillTypeEnum.PlanReturn;
    const contextUser = useContext(ContextUser);
    const user = contextUser.current;
    const canEdit = user.permissions.includes(UserPermissions.WAYBILL_EDIT);
    const isDraft = dto.status === WaybillStatusEnum.DRAFT;
    const isWbHubMoscow =
        dto.pointsLoading && dto.pointsLoading.some(pl => pl.crossDockWithActivity || pl.avisationByEmail);
    const canChangePickupType = canEdit && !isDraft && isWbHubMoscow;

    const [providersList, setProvidersList] = useState([]);
    const [clientIdOld, setClientIdOld] = useState('');
    const [shippingTypePre, setShippingTypePre] = useState('');
    const [dtoOld, setDtoOld] = useState(dto);
    const [temperatureCondition, setTemperatureCondition] = useState(dto.temperatureCondition);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isChangePickupType, setIsChangePickupType] = useState(false);

    const [companies, setCompanies] = useState([]);

    const isPoolingDraft = dto.status === WaybillStatusEnum.DRAFT && dto.isPooling;

    const mounted = useRef();

    useEffect(() => {
        setTemperatureCondition(dto.temperatureCondition);
    }, [dto.temperatureCondition]);

    function getThermoOptions() {
        let thermoOptions = ThermoModeOptions;

        if (isRailLTL) {
            return thermoOptions.filter(o => o.key === 10 || o.key === 40).map(item => (item.key === 10 ? {
                ...item,
                text: 'Без режима',
            } : item))
        } else if (isFTL || isPlanReturn) {
            if (dto.vehicleCapacity && dto.vehicleCapacity !== LoadCapacityEnum.C20000) {
                return thermoOptions.filter(o => o.key !== 30 && o.key !== 50);
            } else return thermoOptions
        } else {
            return thermoOptions.filter(o => o.key !== 60);
        }
    }

    function getWaybillTypeOptions() {
        return dto.shippingType === WaybillTypeEnum.Return ? WaybillTypeOptions : WaybillTypeOptions.filter(item => item.key !== WaybillTypeEnum.Return);
    }

    async function getProvidersInOptions() {
        const res = await getProvidersCommonCompanies(dto.clientId);
        setProvidersList(
            res
                .filter(i => i.status === ProviderStatusEnum.ACTIVE /*|| i.id === dto.providerId*/)
                .map(i => ({
                    key: i.id,
                    text: i.name,
                    value: i.id,
                })),
        );
    }

    useEffect(() => {
        setCompanies(dto.__optionsCompany);
    }, [dto.__optionsCompany]);

    useEffect(() => {
        (async function () {
            try {
                if (dto.clientId) {
                    await getProvidersInOptions();
                }
            } catch (e) {
                /* updateData({providerId: null});
                 await updateData({providerName: null});*/
                /*setProvidersList([]);*/
            }
        })();
    }, [dto.clientId]);

    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            let needToUpdateData = false;
            let dataToClear = {};
            if (dto.vehicleCapacity) {
                dataToClear.vehicleCapacity = null;
                needToUpdateData = true;
            }
            if (dto.bodyCapacity) {
                dataToClear.bodyCapacity = null;
                needToUpdateData = true;
            }
            if (needToUpdateData && dto.shippingType) {
                updateData(dataToClear);
            }
        }

    }, [dto.shippingType]);

    useEffect(() => {
        setClientIdOld(dto.clientId);
        setShippingTypePre(dto.shippingType);
    }, []);

    let timerId;

    useEffect(() => {
        if (
            (dto.providerId &&
                !!providersList.length &&
                !providersList.filter(i => i.key === dto.providerId).length) ||
            (clientIdOld && clientIdOld !== dto.clientId && !providersList.length)
        ) {
            if (dto.providerId === null) {
                timerId = setTimeout(() => setClientIdOld(dto.clientId), 500);
                return;
            }
            (async function () {
                try {
                    clearTimeout(timerId);
                    await updateData({providerId: null});
                    timerId = setTimeout(() => setClientIdOld(dto.clientId), 500);
                } catch (e) {
                    /* updateData({providerId: null});
                     await updateData({providerName: null});*/
                    /*setProvidersList([]);*/
                }
            })();
        }
    }, [dto, providersList]);

    function getCapacityOptions() {
        if ((isFTL || isPlanReturn) && (dto.shippingTemperatureCondition === 30 || dto.shippingTemperatureCondition === 50)) {
            return LoadCapacityOptions.filter(o => o.key === LoadCapacityEnum.C20000);
        } else {
            return LoadCapacityOptions;
        }
    }

    function onChangeClientId(clientId) {
        (async function () {
            try {
                await updateData({clientId});
                await setProvidersList([]);
            } catch (e) {
            }
        })();
    }

    function searchTextAndDescription(options, query) {
        const q = query.toLowerCase();
        const qterms = q.split(' ');
        const q0 = qterms[0];
        return options.length
            ? options.filter(
                opt =>
                    (opt.text && opt.text.toLowerCase().includes(q0)) ||
                    (opt.description && opt.description.toLowerCase().includes(q0)),
            )
            : [];
    }

    async function handleClientSearchChange(e, {searchQuery}) {

        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;

        if (hasSearch || searchQuery.length === 0) {
            let CompanyOptions = await getCompanyOptionsBySearchUser(searchQuery, isAuction);
            //const existingIds = this.state.addresses.map(a => a.id);
            CompanyOptions = unique(dto.__optionsCompany.concat(CompanyOptions));

            if (CompanyOptions.length) {
                setCompanies(CompanyOptions);
            }
        }
    }

    async function handleClientClick() {
    }

    const updateCost = (value) => {
        if (value.includes('.')) {
            const [v1, v2] = value.split('.');

            return `${v1}.${v2.substr(0, 2)}`
        }

        return value
    };

    const handleChangeTemperatureCondition = (e, {value, name}) => {
        setTemperatureCondition(prev => ({
            ...prev,
            [name]: restrictionInt(value),
        }));
    };

    const minCapacity = getMinCapacity(dto, cargoRestrictions);
    const capacityOptions = getCapacityOptions().filter(o => o.value >= minCapacity);


    const resetId = i => {
        return {...i, id: 0};
    };

    const wbPoint = useMemo(() => {
        return dto.pointsLoading.find(pl => pl.crossDockWithActivity || pl.avisationByEmail) || {}
    }, [dto]);

    function doPointReplace(point) {
        // copying stuff
        point.arrivalDatePlan = wbPoint.arrivalDatePlan;
        point.arrivalDateFact = wbPoint.arrivalDateFact;
        point.arrivalTimeslotPlan = wbPoint.arrivalTimeslotPlan;
        point.arrivalTimeslotFact = wbPoint.arrivalTimeslotFact;
        point.contact = wbPoint.contact;
        point.comment = wbPoint.comment;

        point.loadUnitSets = (wbPoint.loadUnitSets || []).map(resetId);
        point.loadUnitsToOrderMappings = (wbPoint.loadUnitsToOrderMappings || []).map(resetId);
        point.services = (wbPoint.services || []).map(resetId);

        pointReplace(point, !(point.crossDockWithActivity || point.avisationByEmail) ? {pickupType: PickupTypeEnum.NOT_SPECIFIED} : null);
    }

    function pointReplace(newPoint, scope) {
        const index = dto.pointsLoading.findIndex(pl => pl.crossDockWithActivity || pl.avisationByEmail);

        const updateScope = getPointRemoveUpdateScope(index);

        updateScope["pointsLoading"] = [newPoint, ...updateScope["pointsLoading"]];

        if (newPoint.hubLoading) {

            updateScope.hubLoading = true;
        }

        updateData({...updateScope, ...scope});
    }

    function getPointRemoveUpdateScope(index) {

        const pointsCloned = [...dto.pointsLoading];

        const deletable = pointsCloned.splice(index, 1)[0];

        const updateScope = {pointsLoading: pointsCloned};


        const hubLoading = deletable.isHub;

        if (hubLoading) {

            updateScope.hubLoading = false;

            //updateScope.needPickup = false;
        }

        const validPositions = dto.pointsLoading.filter(p => p.pointFromId !== deletable.id);

        if (validPositions.length !== dto.pointsLoading.length) {

            updateScope.loadUnitSets = [...validPositions];
        }


        return updateScope;
    }

    const [carTypesOptions, setCarTypesOptions] = useState([]);
    const [carTypeFieldIsView, setCarTypeFieldIsView] = useState(false);

    const getCarTypes = async () => {
        const carTypes = await getCarTypesForField({
            clientId: dto.clientId,
            shippingType: dto.shippingType,
            isAuction
        });
        if (!carTypes.isError) setCarTypesOptions(carTypes.map(cT => ({
            key: cT.id,
            value: cT.fmKey,
            text: cT.name,
            values: {
                vehicleCapacity: cT.carTonnage,
                shippingTemperatureCondition: cT.tempCondition,
                bodyCapacity: [90, 110].includes(cT.bodyCapacity) ? cT.bodyCapacity : null
            },
            data: cT
        })));
    };

    useEffect(() => {
        carTypeFieldIsView && getCarTypes();
    }, [carTypeFieldIsView, dto.clientId, dto.shippingType]);

    useEffect(() => {
        setLoading && setLoading(true);
        getCanUseCarType()
            .then(d => {
                setCarTypeFieldIsView(d);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, []);


    useEffect(() => {
        updateData({_carTypeFieldIsView: carTypeFieldIsView}, {ignoreUnsaved: true});
    }, [carTypeFieldIsView]);

    const carTypeData = carTypesOptions.find(v => v.value === dto.carTypeFMKey);
    const viewTemperatureFields = !isRailLTL && dto.carTypeFMKey && carTypeData?.data?.bodyType === BodyTypeEnum.REF;

    useEffect(() => {
        if (dto.carTypeFMKey) {
            if (carTypeData) updateData(carTypeData.values);
            if (viewTemperatureFields) updateData({
                temperatureCondition: {
                    from: carTypeData.data?.minimalTemperature || '',
                    to: carTypeData.data?.maximalTemperature || ''
                }
            });
        }
    }, [dto.carTypeFMKey]);

    return (
        <>
            <Header style={{marginTop: '-1px'}}>
                <T>Общая информация</T>
            </Header>
            <Form>
                <Form.Field required>
                    <label>{'Клиент'.t}</label>
                    <Dropdown
                        id='dropdown_clientId'
                        disabled={dto.__canEdit || isPoolingDraft || disabled}
                        noResultsMessage={'Результатов не найдено'.t}
                        selection
                        search={searchTextAndDescription}
                        value={dto.clientId || ''}
                        onSearchChange={handleClientSearchChange}
                        onChange={(e, {value}) => onChangeClientId(value)}
                        onClick={handleClientClick}
                        className="InputDropdown"
                        placeholder={'Клиент'.t}
                        fluid
                        closeOnChange
                        options={companies || []}
                        selectOnBlur={false}
                    />
                </Form.Field>
                <Divider hidden/>
                {showField(WaybillFieldEnum.Provider, dto, fieldConfigs) && <Form.Field>
                    <label>{'Поставщик'.t}</label>
                    <InputDropdown
                        id={'providerId'}
                        clearable
                        options={providersList}
                        placeholder={'Поставщик'.t}
                        value={dto.providerId}
                        onChange={providerId => updateData({providerId: providerId === '' ? null : providerId})}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>}
                <Form.Field required>
                    <label>{'Тип сервиса доставки'.t}</label>
                    <InputDropdown
                        id={'shippingType'}
                        style={stl1}
                        options={getWaybillTypeOptions().filter(({value}) =>
                            showValue(WaybillFieldEnum.ShippingType, value, dto, fieldConfigs)
                        )}
                        placeholder={'Тип сервиса доставки'.t}
                        value={dto.shippingType}
                        onChange={shippingType => updateData({shippingType})}
                        disabled={isAuction || isClient || dto.shippingType === WaybillTypeEnum.Return || isPoolingDraft || disabled || (withRoutes && (dto.status !== WaybillStatusEnum.DRAFT))}
                    />
                </Form.Field>
                <Divider hidden/>
                {((isFTL && carTypeFieldIsView) || isRail || isRailLTL) && <Form.Field required>
                    <label>{'Тип ТС'.t}</label>
                    <InputDropdown
                        id={'carTypeFMKey'}
                        options={carTypesOptions}
                        placeholder={'Тип ТС'.t}
                        value={dto.carTypeFMKey}
                        onChange={carTypeFMKey => updateData({carTypeFMKey})}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>}
                {(isFTL && !carTypeFieldIsView) && showField(WaybillFieldEnum.VehicleCapacity, dto, fieldConfigs) &&
                <Form.Field required>
                    <label>{'Грузоподъемность ТС'.t}</label>
                    <InputDropdown
                        id={'vehicleCapacity'}
                        options={capacityOptions.filter(({value}) =>
                            showValue(WaybillFieldEnum.VehicleCapacity, value, dto, fieldConfigs)
                        )}
                        clearable
                        placeholder={'Грузоподъемность ТС'.t}
                        value={dto.vehicleCapacity}
                        onChange={vehicleCapacity => updateData({vehicleCapacity})}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>
                }
                {(isFTL && !carTypeFieldIsView) && showField(WaybillFieldEnum.BodyCapacity, dto, fieldConfigs) &&
                <Form.Field>
                    <label>{'Объем кузова'.t}</label>
                    <InputDropdown
                        id={'bodyCapacity'}
                        options={BodyCapacityOptions.filter(({value}) =>
                            showValue(WaybillFieldEnum.BodyCapacity, value, dto, fieldConfigs)
                        )}
                        clearable
                        placeholder={'Объем кузова'.t}
                        value={dto.bodyCapacity}
                        onChange={bodyCapacity => updateData({bodyCapacity})}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>
                }
                {((!isRail && !isRailLTL && !isFTL) || (isFTL && !carTypeFieldIsView)) && showField(WaybillFieldEnum.ShippingTempCondition, dto, fieldConfigs) && <Form.Field required>
                    <label>{'Температурный режим'.t}</label>
                    <InputDropdown
                        id={'shippingTemperatureCondition'}
                        options={getThermoOptions().filter(({value}) =>
                            showValue(WaybillFieldEnum.ShippingTempCondition, value, dto, fieldConfigs)
                        )}
                        clearable
                        placeholder={'Температурный режим'.t}
                        value={dto.shippingTemperatureCondition}
                        onChange={shippingTemperatureCondition => {
                            shippingTemperatureCondition && toast.warning('Перевозка осуществляется при температурном режиме, исключительно в соответствии с диапазоном, указанном в поле "температурный режим". Температурные режимы, указанные Заказчиком в иных полях, в том числе в поле "комментарии", в том числе и входящие в заданные / указанные рамки в поле "температурный режим", не будут приниматься во внимание при осуществлении перевозки груза');
                            updateData({shippingTemperatureCondition});
                        }}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>}
                {
                    (viewTemperatureFields || Boolean((isFTL || isPlanReturn) && dto.shippingTemperatureCondition === 60)) &&
                    <Form.Field>
                        <label><T>Температурный режим клиента</T></label>
                        <Form.Group widths={2}>
                            <Form.Field required disabled={isPoolingDraft || disabled}>
                                <label>C</label>
                                <Input
                                    id={'input_temperatureCondition-from'}
                                    disabled={viewTemperatureFields && carTypeData.data?.minimalTemperature}
                                    value={temperatureCondition ? temperatureCondition.from : ''}
                                    type="text" pattern="[0-9]*"
                                    name="from"
                                    onChange={handleChangeTemperatureCondition}
                                    onBlur={() => updateData({temperatureCondition})}
                                />
                            </Form.Field>
                            <Form.Field required disabled={isPoolingDraft || disabled}>
                                <label>По</label>
                                <Input
                                    id={'input_temperatureCondition-to'}
                                    disabled={viewTemperatureFields && carTypeData.data?.maximalTemperature}
                                    value={temperatureCondition ? temperatureCondition.to : ''}
                                    name="to"
                                    type="text" pattern="[0-9]*"
                                    onChange={handleChangeTemperatureCondition}
                                    onBlur={() => updateData({temperatureCondition})}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form.Field>
                }
                <Divider hidden/>
                {showField(WaybillFieldEnum.CargoType, dto, fieldConfigs) && <Form.Field required>
                    <label>{'Тип груза'.t}</label>
                    <InputDropdown
                        id={'cargoType'}
                        options={CargoTypeOptions.filter(({value}) =>
                            showValue(WaybillFieldEnum.CargoType, value, dto, fieldConfigs)
                        )}
                        placeholder={'Тип товара'.t}
                        value={dto.cargoType}
                        onChange={cargoType => updateData({cargoType})}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>}
                <Form.Field required={cargoCostIsRequired}>
                    <label>{'Стоимость груза без НДС'.t}</label>
                    <Form.Input
                        id={'input_cargoCost'}
                        placeholder={'Стоимость груза без НДС'.t}
                        value={dto.cargoCost || ''}
                        onChange={e => updateData({cargoCost: updateCost(e.target.value.replace(/[^\d,.]/g, '').replace(',', '.'))})}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>

                {canChangePickupType && (
                    <Form.Field disabled={isClient || isPoolingDraft}>
                        <label>
                            <T>Способ доставки груза</T>
                        </label>
                        <InputDropdown
                            id={'pickupType'}
                            disabled={
                                !(
                                    (dto.avisationStatus === WaybillAvisationStatus.REQUESTED
                                        || dto.avisationStatus === WaybillAvisationStatus.NONE) &&
                                    dto.status !== WaybillStatusEnum.CANCELLED &&
                                    dto.status !== WaybillStatusEnum.FM_CANCELLED
                                ) || disabled
                            }
                            options={PickupTypeOptions}
                            placeholder={'Способ доставки груза'.t}
                            value={dto.pickupType}
                            onChange={pickupType => {
                                if (dto.pickupType && dto.pickupType !== pickupType) {
                                    setIsChangePickupType(true);
                                }
                                updateData({pickupType})
                            }}
                        />
                    </Form.Field>
                )}
            </Form>
            <WbPointAddForm
                profile="pointsLoading"
                isEdit
                wbId={dto.id}
                wbClientId={dto.clientId}
                pointsExistingIds={[]}
                wbPointId={wbPoint.id}
                submit={doPointReplace}
                hideNew={dto.status !== WaybillStatusEnum.DRAFT}
                value={wbPoint}
                open={isFormOpen}
                onClose={() => setIsFormOpen(false)}
            />
            <Modal
                closeOnDimmerClick={false}
                size="small"
                dimmer="inverted"
                closeIcon
                open={isChangePickupType}
                onClose={() => setIsChangePickupType(false)}>
                <Modal.Header>
                    Изменение способа доставки...
                </Modal.Header>
                <Modal.Content>
                    <div>Вы изменили способ доставки.</div>
                    <div>Изменить адрес загрузки для точки: <b>{wbPoint.address}?</b></div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setIsChangePickupType(false)}>
                        Отмена
                    </Button>
                    <Button primary onClick={() => {
                        setIsFormOpen(true);
                        setIsChangePickupType(false);
                    }}>
                        Изменить адрес
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}
