import React from 'react';
import {Icon, Popup} from "semantic-ui-react";
import {preventDefault} from "../../services/utils";
import {messageTypes} from "./const";

const Cell = ({keyId, columnIndex, rowIndex, style, data, selectedCells, setSelectedCells, setOpen, borders}) => {
    const id = `input-${keyId}`;

    const value = (data[rowIndex].cells || [])[columnIndex] || {};

    const onClick = () => {
        setSelectedCells([keyId]);
        setOpen({
            keyId,
            columnIndex,
            rowIndex
        });
    };

    const errorIsFunction = typeof value.error === 'function';

    const iconError = (onClick, isError) => <Icon
        color={isError ? "red" : "yellow"}
        className="big-table__cell__error-popup"
        name={isError ? "warning circle" : "warning sign"}
        onClick={onClick}
    />;

    const clickError = (e) => {
        preventDefault(e);
        value.error(keyId, columnIndex, rowIndex, style);
    };

    const getBorder = () => {
        let borderClasses = [];
        const pushClass = (name) => {
            if ((borders[name] || []).includes(keyId)) borderClasses.push(`big-table__cell_border-${name}`);
        };

        Object.keys(borders).forEach(b => pushClass(b));
        return borderClasses.join(' ');
    };

    const message = (text) => <div className='big-table__message'>{text}</div>

    const infoPopup = <Popup
        size="mini"
        style={{
            borderRadius: 0,
            padding: '5px 10px',
        }}
        position="top center"
        content={message(value.message)}
        trigger={<Icon
            color='blue'
            className="big-table__cell__error-popup big-table__cell__info"
            name='question circle outline'
        />}
    />;

    const isError = value.messageType === messageTypes.ERROR;
    const isWarning = value.messageType === messageTypes.WARNING;
    const isInfo = value.messageType === messageTypes.INFO;

    return <div
        id={id}
        onClick={onClick}
        className={`big-table__cell ${selectedCells.includes(keyId) ? 'big-table__cell_selected' : ''} ${isWarning && 'big-table__cell_warning'} ${isError && 'big-table__cell_error'} ${getBorder()}`}
        key={keyId}
        style={{
            height: style.height,
            width: style.width,
            backgroundColor: value.color,
            textAlign: value.info ? 'center' : undefined
        }}
    >
        {
            (isError || isWarning) && (errorIsFunction
                ? iconError(clickError, isError)
                : <Popup
                    size="mini"
                    style={{
                        borderRadius: 0,
                        padding: '5px 10px',
                    }}
                    position="top center"
                    content={message(value.message)}
                    trigger={iconError(preventDefault, isError)}
                />)
        }
        {value.value}
        {
            isInfo && infoPopup
        }
    </div>;
};

export default Cell;