import React from 'react';
import {nanoid} from "nanoid";
import {Button, Form, Grid, Icon} from "semantic-ui-react";
import InputDropdown from "../inputs/InputDropdown";
import {WeekDayOptions} from "../../api/model/Enums";

const SchedulesConstructor = ({items, onChange, points}) => {
    const onDelete = (index) => {
        const list = items.filter((item, itemIndex) => itemIndex !== index)
        onChange(list.length ? list : [{
            nanoId: nanoid(),
            name: '',
            values: [''],
            type: ''
        }]);
    };

    const onAdd = () => {
        onChange([...items, {nanoId: nanoid()}])
    };

    const handleChange = (value, name, index) => {
        const list = [...items];
        list[index] = {
            ...list[index],
            [name]: value,
        };
        onChange(list);
    };


    return (
        <>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>Кросс-док</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>День доставки</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required>
                                <label>День отправки</label>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <Button icon onClick={() => onAdd()}>
                                <Icon name="add"/>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Grid>
                {
                    items.map((item, index) => (
                        <Grid.Row key={item.nanoId}>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            options={points}
                                            value={item.pointId}
                                            disabled={index === 0}
                                            placeholder="выберите"
                                            onChange={(val) => handleChange(val, 'pointId', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            placeholder="выберите"
                                            value={item.deliveryDay}
                                            options={WeekDayOptions}
                                            onChange={(val) => handleChange(val, 'deliveryDay', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Form>
                                    <Form.Field>
                                        <InputDropdown
                                            placeholder="выберите"
                                            value={item.sendingDay}
                                            options={WeekDayOptions}
                                            onChange={(val) => handleChange(val, 'sendingDay', index)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <Button icon onClick={() => onDelete(index)}>
                                    <Icon name="trash alternate"/>
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    ))}
            </Grid>
        </>
    );
};

export default SchedulesConstructor;
