import React from 'react';
import RuleField from "./ruleField";
import {Icon} from "semantic-ui-react";
import {
    defaultSplitters,
    disabledParams,
    optionsByProcessingDescriptors,
    ProcessingTypesEnum
} from "../../../api/model/ImportForms";
import {isBoolValue} from "../../../services/utils";

const rulesWithSplittersRule = [
    'CNO',
    'TORG',
    'WNO',
    'SSCC'
];

const RuleFields = ({data, form, setValue, disabled, companyId, checkSchedules}) => {
    const valuesData = form[data.columnName];

    const setVal = (name, value) => {
        const values = {};
        if (name === 'ruleType') {
            values.rules = null;
            values.value = null;

            if (!isBoolValue(value) && !value) {
                setValue(data.columnName, null);
                return;
            }

            if (value === ProcessingTypesEnum.CELL && rulesWithSplittersRule.includes(data.columnName)) { //автоматический выбор правила 'Тип разделителя' при выборе 'Номера столбца'
                const options = optionsByProcessingDescriptors(data.processingDescriptors, value);
                const withSplitters = !!options.find(o => o.processingType === ProcessingTypesEnum.SPLITTERS);

                if (withSplitters) {
                    values.rules = [
                        {
                            ruleType: ProcessingTypesEnum.SPLITTERS,
                            value: defaultSplitters
                        }
                    ];
                }
            }

        }

        setValue(data.columnName, {
            ...(form[data.columnName] || {}),
            ...values,
            [name]: value
        });

        if (value === ProcessingTypesEnum.SCHEDULE) {
            checkSchedules && checkSchedules();
        }
    };

    const setRuleValue = (value, name = 'ruleType', index) => {
        let newValue = (form[data.columnName] || {}).rules || [];

        const newValueObject = {
            ...(newValue[index] || {}),
            [name]: value
        };

        if (name === 'ruleType') {
            newValueObject.value = null;
        }

        newValue[index !== undefined ? index : newValue.length] = newValueObject;

        setVal('rules', newValue);
    };

    const delRule = (index) => {
        setVal('rules', ((form[data.columnName] || {}).rules || []).filter((v, i) => i !== index));
    };

    const addRule = () => {
        setRuleValue();
    };

    const options = valuesData && optionsByProcessingDescriptors(data.processingDescriptors, valuesData.ruleType);
    const canAddRule = !!(options || []).length && !data.isServiceParameter && !disabled;

    const delBtn = (i) => <Icon
        color='grey'
        className="cursor-pointer del-btn"
        name='trash alternate'
        onClick={() => delRule(i)}
    />;

    return (
        <div>
            <RuleField
                clearable={!data.isRequired}
                disabled={disabledParams.includes(data.columnName) || disabled}
                form={form[data.columnName]}
                setValue={setVal}
                data={data}
                companyId={companyId}
            />
            {
                valuesData && (valuesData.rules || []).map((rule, i) => <RuleField
                        disabled={disabled}
                        key={i}
                        form={rule}
                        setValue={(name, value) => setRuleValue(value, name, i)}
                        data={data}
                        type={valuesData.ruleType}
                        selectedOptions={valuesData.rules.map((r, index) => index !== i && r.ruleType)}
                        delBtn={delBtn(i)}
                        companyId={companyId}
                    />
                )
            }
            {
                canAddRule && <div
                    className="add-btn"
                    onClick={addRule}
                >
                    <Icon name="plus" color="grey"/> Добавить правило
                </div>
            }
        </div>
    );
};

export default RuleFields;