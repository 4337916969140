import React from 'react';
import {withRouter} from 'react-router-dom';
import {Grid, GridColumn} from 'semantic-ui-react';
import {PackTypeEnum} from '../../../api/model/Enums';
import "./WbEditPoints.css";
import CargoTotals from './WbEditPointsTotals';
import {WaybillTypesWithRoutes} from "../../../api/model/Waybill";
import WbPointsBlock from "./WbPointsBlock";

const tabMainName = "Основная информация";

class WbEditPoints extends React.Component {

    state = {
        activeTab: tabMainName
    };

    handleTabChange(tab) {
        this.setState({activeTab: tab || tabMainName});
    }

    calcTotals(loadUnitSets) {
        let totalPallets = 0, totalBoxes = 0, totalWeight = 0, totalVolume = 0;
        let totalOversizedPallets = 0,
            totalEmptyPallets = 0,
            totalLowPallets = 0,
            totalHighPallets = 0,
            totalLongBox = 0;

        if (loadUnitSets && loadUnitSets.length) {

            loadUnitSets.forEach(p => {
                if (p.loadUnitType === PackTypeEnum.EP) {
                    totalPallets = totalPallets + (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.BX) {
                    totalBoxes = totalBoxes + (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.OT) {
                    totalOversizedPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.PP) {
                    totalEmptyPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.LP) {
                    totalLowPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.HP) {
                    totalHighPallets += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                if (p.loadUnitType === PackTypeEnum.LB) {
                    totalLongBox += (Number.parseInt(p.loadUnitsCount) || 0);
                }
                p.loadTotalWeigthBruttoKg && (totalWeight = totalWeight + Number.parseFloat(p.loadTotalWeigthBruttoKg));
                p.loadTotalVolumeM3 && (totalVolume = totalVolume + Number.parseFloat(p.loadTotalVolumeM3));
            });
        }

        return {
            totalPallets,
            totalBoxes,
            totalWeight,
            totalVolume,
            totalOversizedPallets,
            totalHighPallets,
            totalLongBox,
            totalEmptyPallets,
            totalLowPallets
        };
    }

    getArrivalDate = (points) => points && points[points.length - 1] && points[points.length - 1].arrivalDatePlan;

    getAllPointsLoading = () => {
        const {dto, routes} = this.props;
        return (routes && routes.length) ? routes.map(r => (r.pointsLoading || [])).flat() : (dto.pointsLoading || []);
    }
    render() {

        const {dto, updateData, isEdit, auctionHeight, routes, addRoute, changePoints, changeRoutes, deleteRoute, fieldConfigs} = this.props;

        const loadUnitSets = this.getAllPointsLoading().reduce((val, p) => val.concat(p.loadUnitSets), []);

        const calc = this.calcTotals(loadUnitSets);

        const header = (
            <div className="totalsStyleTop">
                <CargoTotals
                    totalPallets={calc.totalPallets}
                    totalBoxes={calc.totalBoxes}
                    totalWeight={calc.totalWeight}
                    totalVolume={calc.totalVolume}
                    totalOversizedPallets={calc.totalOversizedPallets}
                    totalEmptyPallets={calc.totalEmptyPallets}
                    totalLowPallets={calc.totalLowPallets}
                    totalHighPallets={calc.totalHighPallets}
                    totalLongBox={calc.totalLongBox}
                />
            </div>
        );

        const firstLoadingDate = this.getArrivalDate(dto.pointsLoading);
        const firstUnloadingDate = this.getArrivalDate(dto.pointsUnloading);

        const {isClient = false, isPoolingEdit = false, disabled} = this.props;


        const canAddRoutes = !this.props.isAuction && WaybillTypesWithRoutes.includes(dto.shippingType)
            && (!!routes.length || (!routes.length && !!dto.pointsUnloading.length && !!dto.pointsLoading.length));

        return (
            <Grid divided>
                <GridColumn width={16} className="p-t-5">
                    <Grid.Row>
                        {header}
                    </Grid.Row>
                    <WbPointsBlock
                        wb={dto}
                        routes={routes.length && routes}
                        auctionHeight={auctionHeight}
                        canAddRoutes={canAddRoutes}
                        addRoute={addRoute}

                        updateData={updateData}
                        disabled={disabled}
                        isClient={isClient}
                        isPoolingEdit={isPoolingEdit}
                        firstUnloadingDate={firstUnloadingDate}
                        firstLoadingDate={firstLoadingDate}
                        activeTab={this.state.activeTab}
                        handleTabChange={this.handleTabChange.bind(this)}
                        isEdit={isEdit}
                        changePoints={changePoints}
                        changeRoutes={changeRoutes}
                        deleteRoute={deleteRoute}
                        fieldConfigs={fieldConfigs}
                    />
                </GridColumn>
            </Grid>
        );
    }
}

export default withRouter(WbEditPoints);
