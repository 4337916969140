import React from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { pointReplace } from '../../../../api/points';
import T from '../../../../components/Translate';

export default function PointModalReplace({ updateState, updateData, data, dataValidationErrors, close, loading, onOk }) {

    const getDataValidationErrors = () => {

        const dve = { ...dataValidationErrors };

        dve.targetFmId = (!data.targetFmId || data.targetFmId.length < 4)
            ? "Введите не менее 4 символов"
            : null;

        return dve;
    };

    const submit = async () => {

        const dve = getDataValidationErrors();

        const dataIsValid = Object.entries(dve).every(e => !e[1]);

        if (dataIsValid) {

            updateState({ dataValidationErrors: dve, loading: true });

			try {

                await pointReplace(data.id, { targetFmId: data.targetFmId, clientFmId: data.createdByClient });

				onOk();

			} catch (e) {

			}

            updateState({ open: false, loading: false });


        } else {
            updateState({ dataValidationErrors: dve });
        }
    };

    return (
        <React.Fragment>
            <Modal.Header><T>Замена адреса на существующий</T></Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Field required error={!!dataValidationErrors.targetFmId}>
                        <label><T>Введите search name (не менее 4 символов)</T></label>
                        <Input
                            placeholder={'search name'.t}
                            value={data.targetFmId || ''}
                            onChange={e => updateData({ targetFmId: e.target.value.toUpperCase() })}
                        />
                    </Form.Field>
                    {/* <Form.Checkbox
                        label={'Заменить и архивировать текущий адрес'.t}
                        checked={!!data.archive}
                        onChange={(e, item) => updateData({ archive: item.checked })}
                    /> */}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={loading}
                    onClick={close}
                >
                    <T>Отмена</T>
                </Button>
                <Button
                    disabled={loading}
                    positive
                    onClick={submit}
                >
                    <T>Заменить адрес</T>
                </Button>
            </Modal.Actions>
        </React.Fragment>
    );
}
