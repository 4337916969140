import React from 'react';
import RuleWithOpenBlock from "./ruleWithOpenBlock";
import {Icon} from "semantic-ui-react";


const ObjectRowsBlock = ({withoutItems, RowComponent, value, changeValue, disabled, del, add, maxCount}) => {
    const val = (value || []).length ? value : [{}];
    const canAdd = (!disabled && (!maxCount || (maxCount > (value || []).length)));

    return withoutItems
        ? <RowComponent
            allValues={value}
            changeValue={changeValue}
            disabled={disabled}
        />
        : <div>
            {
                val.map((v, i) => <div
                    className={`object-rows__block ${(!canAdd && i === val.length - 1) ? 'p-b-10' : ''}`}>
                    <RowComponent
                        allValues={value}
                        key={i}
                        v={v}
                        i={i}
                        changeValue={changeValue}
                        disabled={disabled}
                    />
                    {
                        (del && !disabled) && <Icon
                            color="grey"
                            className="cursor-pointer del-btn"
                            name="trash alternate"
                            onClick={() => del(i)}
                        />
                    }
                </div>)
            }
            {canAdd && <div
                className="add-btn p-t-10 p-b-10"
                onClick={add}
            >
                <Icon name="plus" color="grey"/> Добавить условие
            </div>}
        </div>;
};

const ObjectRows = ({
                        maxCount,
                        name,
                        value,
                        onChange,
                        component,
                        valueText,
                        delBtn,
                        icon,
                        disabled,
                        inputType,
                        className,
                        withoutItems,
                        infoBlock
                    }) => {
    const add = () => {
        onChange(null, {name, value: [...(value || [{}]), {}]});
    };

    const del = (i) => {
        onChange(null, {name, value: (value || []).filter((v, index) => index !== i)});
    };

    const changeValue = (i, {name: valueName, value: val}) => {
        const newValue = [...(value || [])];
        newValue[i] = {
            ...(newValue[i] || {}),
            [valueName]: val
        };

        onChange(null, {
            name,
            value: newValue
        });
    };

    const propsForComponent = {
        value,
        add,
        disabled,
        changeValue,
        del: (value || []).length > 1 && del,
        maxCount,
        RowComponent: component,
        withoutItems
    };

    return (
        <RuleWithOpenBlock
            value={valueText}
            title={valueText}
            delBtn={delBtn}
            icon={icon}
            disabled={disabled}
            readOnly
            inputType={inputType}
            className={className}
            infoBlock={infoBlock}
        >
            <ObjectRowsBlock
                {...propsForComponent}
            />
        </RuleWithOpenBlock>
    );
};

export default ObjectRows;