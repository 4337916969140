import Waybill, { WaybillTypeEnum } from "./model/Waybill";
import WaybillPoint from "./model/WaybillPoint";
import { getPointById } from './points.js';
import LoadUnitSet from './model/LoadUnitSet.js';
import LoadUnitsToOrderMapping from './model/LoadUnitsToOrderMapping.js';
import { getWaybillsByIds, createWaybill } from "./waybills";
import { post } from "../services/http";

class LoadUnitInfo {
    constructor(loadUnitType, loadUnitOrders, loadUnitWeightKgBrutto, loadUnitVolumeM3, loadUnitSSCC, globalIndex) {
        this.loadUnitType = loadUnitType;

        this.loadUnitOrders = loadUnitOrders || [];
        this.loadUnitWeightKgBrutto = loadUnitWeightKgBrutto;
        this.loadUnitVolumeM3 = loadUnitVolumeM3;
        this.loadUnitSSCC = loadUnitSSCC;
        this.globalIndex = globalIndex;
    }
}

const nearest = (arr) => val => arr.reduce((p, n) => Math.abs(p) > Math.abs(n - val) ? n - val : p, Infinity) + val;

export async function createPickup2(pointLoadingId, ltlIds) {
    return await post("waybills/pickup", {
        pointLoadingId,
        ltlIds
    });
}

function arraySplitByKey(arr, splittingKey) { // key is like loadUnitType
    
    return arr.reduce((resultArray, arrItem) => {

        let splittingKeyValue = arrItem[splittingKey];
        
        let targetArray = resultArray.find(resultArrayEl => resultArrayEl.key === splittingKeyValue);

        if (targetArray) {
            targetArray.push(arrItem);
        } else {
            targetArray = [arrItem];
            targetArray.key = splittingKeyValue;
            resultArray.push(targetArray);
        }

        return resultArray;

    }, []);
} 

function setMainInfo(ftl, ltls = []) {
    const ltl0 = ltls[0];

    ftl.clientId = ltl0.clientId;
    ftl.provisionerId = ltl0.provisionerId;

    ftl.shippingTemperatureCondition = ltl0.shippingTemperatureCondition;
    ftl.cargoType = ltl0.cargoType;

    ftl.cargoCost = ltls.reduce((val, ltl) => val + Number.parseInt(ltl.cargoCost || 0), 0);
    ftl.loadEmptyPalletsCount = ltls.reduce((val, ltl) => val + Number.parseInt(ltl.loadEmptyPalletsCount || 0), 0);
}

function getLoadCapacity(ltls) {
    const weightReducer = (acc, loadUnitSet) => acc + Number.parseInt(loadUnitSet.loadTotalWeigthBruttoKg || 0);
    const loadCapacityReducer = (acc, ltl) => acc + ltl.loadUnitSets.reduce(weightReducer, 0);
    const totalLoadKg = ltls.reduce(loadCapacityReducer, 0);
    return nearest([1500, 3000, 5000, 10000, 20000])(totalLoadKg);
}

function getLoadUnits(ltls = []) {
    const result = [];
    let globalIndex = 1;

    ltls.forEach(ltl => {
        let ltl_loadUnit_index = 0;

        // мало получить спаны - на спаны есть карты в пределах одного ltl т.е нужно раскрыть все спаны на индексы и привести на эти индексы карты заяказов
        (ltl.loadUnitSets || []).forEach(loadUnitTypeSpan => {
            // set 1 - 5 palletes   (index from 1 to 5)
            // set 2 - 5 boxes      (index from 6 to 10)
            for (let index = 0; index < loadUnitTypeSpan.loadUnitsCount; index++) {
                ltl_loadUnit_index = ltl_loadUnit_index + 1;

                // find each intersecting order
                const intersectingOrders = ltl.loadUnitsToOrderMappings
                    .filter(o => ltl_loadUnit_index >= o.loadUnitPositionStart && ltl_loadUnit_index <= o.loadUnitPositionEnd); // eslint-disable-line

                result.push(new LoadUnitInfo(
                    loadUnitTypeSpan.loadUnitType,
                    intersectingOrders,
                    loadUnitTypeSpan.loadTotalWeigthBruttoKg && loadUnitTypeSpan.loadTotalWeigthBruttoKg / loadUnitTypeSpan.loadUnitsCount,
                    loadUnitTypeSpan.loadTotalVolumeM3 && loadUnitTypeSpan.loadTotalVolumeM3 / loadUnitTypeSpan.loadUnitsCount,
                    loadUnitTypeSpan.loadUnitsSSCCNos && loadUnitTypeSpan.loadUnitsSSCCNos[index]
                ));
            }
        });
    });

    result
        .sort((a, b) => a.loadUnitType.localeCompare(b.loadUnitType))
        .forEach(li => li.globalIndex = globalIndex++);
    
    return result;
}

function getLoadUnitSpans(pointFromId, unitsInfo = []) {
    const result = [];

    let unitSetsByType = arraySplitByKey(unitsInfo, "loadUnitType");
    
    unitSetsByType.forEach(array => {
        result.push(new LoadUnitSet({
            pointFromId: pointFromId,
            loadUnitType: array.key,
            loadUnitsCount: array.length,
            loadUnitsSSCCNos: array.map(lu => lu.loadUnitSSCC),
            loadTotalWeigthBruttoKg: array.reduce((result, el) => result + el.loadUnitWeightKgBrutto, 0), 
            loadTotalVolumeM3: array.reduce((result, el) => result + el.loadUnitVolumeM3, 0)
        }));
    });

    return result;
}

class loadUnitOrderSet {
    
    constructor(dto, globalIndexes) {
        this.recipientOrderNo = dto.recipientOrderNo;
        this.torg12No = dto.torg12No;
        this.shipperOrderNo = dto.shipperOrderNo;
        
        // include load unit index reference
        this.globalIndexes = globalIndexes; 

    }
}

function getLoadUnitOrderSpans(pointToId, loadUnits = []) {

    const orders = [];

    loadUnits.forEach(loadUnit => {

        (loadUnit.loadUnitOrders || []).forEach(loadUnitOrder => { 

            let existing = orders.find(order =>
                order.recipientOrderNo === loadUnitOrder.recipientOrderNo &&
                order.shipperOrderNo === loadUnitOrder.shipperOrderNo &&
                order.torg12No === loadUnitOrder.torg12No
            );

            if (existing) {
                existing.globalIndexes.push(loadUnit.globalIndex);
            } else {
                orders.push(new loadUnitOrderSet(loadUnitOrder, [loadUnit.globalIndex]));
            }

        });
    });

    // arrange orders globalIndexes into spans
    orders.forEach(order => {

        let seq = order.globalIndexes.sort((a, b) => a - b);
        
        let spans = [], spanIndex = 0;

        for (var i = 0; i < seq.length; i++) {

            if (i === 0) {
                spans[spanIndex] = [seq[i]];
            }
            else {
                if (seq[i] === seq[i - 1] + 1) {

                    spans[spanIndex].push(seq[i]);
                } else {
    
                    spanIndex++;
                    spans[spanIndex] = [seq[i]];
                }
            }
            
        }

        order.spans = spans;
    });

    // split orders by inner spans
    const spannedOrders = [];

    orders.forEach(order => {
        order.spans.forEach(span => {
            order.span = span;
            spannedOrders.push(new LoadUnitsToOrderMapping({
                pointToId: pointToId,
                loadUnitPositionStart: span[0],
                loadUnitPositionEnd: span[span.length - 1],
                shipperOrderNo: order.shipperOrderNo,
                recipientOrderNo: order.recipientOrderNo,
                torg12No: order.torg12No,
                orderType: order.orderType
            }));
        });
    });


    return spannedOrders;
}

export async function createPickup(ids) {
    // валидация
    // общая информация всех LTL должна быть идентична
    // точка загрузки должна быть идентична

    // 1 создать новый ftl
    const ftl = new Waybill({ type: WaybillTypeEnum.FTL, isPickup: true });

    // 2 сопировать общую информацию из LTL[1]
    
    const ltls = await getWaybillsByIds(ids);

    setMainInfo(ftl, ltls);

    // 4 создаем точку пикапа
    // we can check user company and find all user points and select one 
    const newPointFrom = new WaybillPoint();
    newPointFrom.id = newPointFrom.getId2();
    ftl.pointsLoading = [newPointFrom];

    // const combinedLoadUnitSets = ltls.reduce((result, ltl) => [...result, ...ltl.loadUnitSets], []);
    // combinedLoadUnitSets.forEach(p => {
    //     p.pointFromId = newPointFrom.id;
    // });

    //ftl.loadUnitSets = combinedLoadUnitSets;



    // 3 создать точку доставки в FM
    const oldPointFrom = ltls[0].pointsLoading[0];
    const newPointTo = new WaybillPoint(oldPointFrom);
    newPointTo.id = newPointTo.getId2();
    newPointTo.fmid = oldPointFrom.fmid;

    const company = await getPointById(oldPointFrom.id);
    newPointTo.companyName = company.name;

    ftl.pointsUnloading = [newPointTo];

    

    // 3.1 скопировать все позиции из каждой из LTL и привязать их к точке выгрузки
    // const combinedLoadMappings = ltls.reduce((res, ltl) => [...res, ...ltl.loadUnitsToOrderMappings], []);
    // combinedLoadMappings.forEach(lm => {
    //     lm.pointToId = newPointTo.id;

    // });
    //ftl.loadUnitsToOrderMappings = combinedLoadMappings;

    const unitsInfo = getLoadUnits(ltls);
    ftl.loadUnitSets = getLoadUnitSpans(newPointFrom.id, unitsInfo);
    ftl.loadUnitsToOrderMappings = getLoadUnitOrderSpans(newPointTo.id, unitsInfo);

    // посчитать нужную грузоподъемность
    ftl.dockCapacity = getLoadCapacity(ltls);


    await createWaybill();

    // apply groupping flag
    ltls.forEach(ltl => ltl.pickupId = ftl.id);

    return ftl;
}