import React, { useContext } from 'react';
import {
    Icon,
    Dropdown
} from 'semantic-ui-react';
import './index.css';
import { ContextLocale } from '../../../services/context';

const options = [
    { key: 'Russian', text: 'Русский', value: 'ru' },
    { key: 'English', text: 'English', value: 'en' }
];

export default function LanguageMenu() {
    const contextLocale = useContext(ContextLocale);

    const handleItemClick = (e, { value }) => {
        contextLocale.setLocale(value);
    };
        
    return (
        <div className="language-menu-wrapper">
            <Icon name='globe' inline="true" /> <Dropdown
                onChange={handleItemClick}
                inline
                options={options}
                defaultValue={contextLocale.locale}
            />
        </div>
    );

}

