import React from 'react';
import { Container, Message } from 'semantic-ui-react';

export default function Error(props) {
    const msg = props.error || 'Неизвестная ошибка'.t;
    const header = props.header || 'Произошла ошибка. Обратитесь к администратору сайта'.t;

    return (
        <Container className="page-offset">
            <Message
                negative
                icon='bug'
                header={header}
                content={msg}
            />
        </Container>
    );
}