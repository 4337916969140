import React from 'react';
import {Button, Dropdown, Form, Header, Icon, List, Menu, MenuHeader, Modal, Popup} from 'semantic-ui-react';
import {getCompanyPoints} from '../../../api/companies';
import {WaybillPointTypeEnum} from '../../../api/model/Enums';
import {getCompanyTypeName, PointBookmarkTypeEnum} from '../../../api/model/Point';
import PointAddress from '../../../api/model/PointAddress';
import WaybillPoint from '../../../api/model/WaybillPoint';
import {getPointById, getPointsBySearch, getPointsRecent, Oc, pointCreate} from '../../../api/points';
import {waybillPointCreate, waybillPointUpdate} from '../../../api/waybills';
import T from '../../../components/Translate';
import {ContextApp} from '../../../services/context';
import WbPointAddFormBookmarks from './WbPointAddFormBookmarks';
import {toast} from "../../../services/toast";
import WbPointAddCreateAddress from "./WbPointAddCreateAddress";


const profiles = {
    pointsLoading: {
        title: "Загрузка",
        addTitle: "Добавление адреса загрузки",
        companyTitle: "Грузоотправитель",
        calcDateTitle: "Рассчитать дату доставки",
        servicesOnPoint: "Сервисы на загрузке"
    },
    pointsUnloading: {
        title: "Выгрузка",
        addTitle: "Добавление адреса выгрузки",
        companyTitle: "Грузополучатель",
        calcDateTitle: "Рассчитать дату привоза на ФМ",
        servicesOnPoint: "Сервисы на доставке"
    }
};
const profilesEdit = {
    pointsLoading: {
        title: "Загрузка",
        addTitle: "Замена адреса загрузки",
        companyTitle: "Грузоотправитель",
        calcDateTitle: "Рассчитать дату доставки",
        servicesOnPoint: "Сервисы на загрузке"
    },
    pointsUnloading: {
        title: "Выгрузка",
        addTitle: "Замена адреса выгрузки",
        companyTitle: "Грузополучатель",
        calcDateTitle: "Рассчитать дату привоза на ФМ",
        servicesOnPoint: "Сервисы на доставке"
    }
};

const tab1Name = 'Поиск';
const tab2Name = 'Избранные адреса';
const tab3Name = 'Создание адреса';

export default class WbPointAddForm extends React.Component {

    static contextType = ContextApp;

    state = {
        value: this.props.value, // id, fmid, companyName // :WaybillPoint

        addressSearchIsFetching: false,
        addresses: [],

        isSubmitting: false,

        loading: false,
        open: this.props.open,
        activeTab: tab1Name,
        bookmarks: [],
        bookmarksLoading: false,
        tab3trigger: false,
        tab3disabledSave: true,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open !== prevState.open) {
            this.setState({open: this.props.open}, () => {
            });
            if (this.props.open) {
                this.handleOpen();
            }
        }
    }

    loadBookmarks = async () => {
        if (this.state.bookmarksLoading) {
            return;
        }
        this.setState({bookmarksLoading: true});
        const shared = await getCompanyPoints(`?bookmarkType=${PointBookmarkTypeEnum.SHARE}&companyId=${this.props.wbClientId}`);
        let specific;
        if (this.props.profile === 'pointsLoading') {
            specific = await getCompanyPoints(`?bookmarkType=${PointBookmarkTypeEnum.LOADING}&companyId=${this.props.wbClientId}`);
        } else {
            specific = await getCompanyPoints(`?bookmarkType=${PointBookmarkTypeEnum.UNLOADING}&companyId=${this.props.wbClientId}`);
        }
        this.setState({
            bookmarks: [...specific, ...shared],
            bookmarksLoading: false
        });
    };

    async handleOpen() {
        this.setState({
            loading: true,
            companies: [],
            addresses: [],
            value: this.props.value,
        }, async () => {
            const addresses = await getPointsRecent(this.props.wbClientId, this.props.value && this.props.value.pointId);
            this.setState({addresses, loading: false});
            this.loadBookmarks();
        });
    }

    handleClose() {
        const {onClose} = this.props;
        onClose && onClose();
        this.setState({
            isSubmitting: false,
            value: {},
        });
    }

    async handlePointSearchChange(e, {searchQuery}) {
        searchQuery = searchQuery && searchQuery.trim();
        const hasSearch = searchQuery && searchQuery.length > 2;

        this.setState({
            addressSearchIsFetching: hasSearch
        });

        if (hasSearch) {

            const newAddresses = await getPointsBySearch(searchQuery, this.props.wbClientId);

            //const existingIds = this.state.addresses.map(a => a.id);

            if (newAddresses.length) {

                //const addresses = [...this.state.addresses, ...(newAddresses.filter(adr => !existingIds.includes(adr.id)))];
                const addresses = newAddresses;

                this.setState({
                    addresses,
                    addressSearchIsFetching: false
                });

            } else {
                this.setState({
                    addressSearchIsFetching: false
                });
            }
        }
    }

    buildPointData = (newState, address) => {
        newState.value = new WaybillPoint();
        newState.value.isLocked = address.isLocked;
        newState.value.pointId = address.id;
        newState.value.pointFMID = address.fmid; // is used to
        newState.value.address = address.address.value || address.address.toString();
        newState.value.type = this.props.profile === "pointsLoading" ? WaybillPointTypeEnum.LOADING : WaybillPointTypeEnum.UNLOADING;
        newState.value.contact = (address.contacts && address.contacts.length && address.contacts[0]) || {};

        newState.value.companyName = address.companyName;
        if (address.companyLegalForm) {
            newState.value.companyName = `${address.companyLegalForm} ${newState.value.companyName}`;
        }
        newState.value.isHub = address.isHub;
    };

    async handlePointChange(e, dd) {

        const newState = {...this.state};

        if (dd.value) {
            const address = newState.addresses.find(adr => adr.id === dd.value);
            this.buildPointData(newState, address);
        } else {
            // deleted value
            newState.value = new WaybillPoint();
            newState.value.isHub = false;
        }

        this.setState(newState);
    }

    handleBookmarkPointChange = async (e, dd, f) => {

        const newState = {...this.state};

        if (dd.value) {
            const address = await getPointById(dd.value);

            const newAddresses = [...this.state.addresses, address];
            this.setState({
                value: {
                    id: address.id
                },
                address: newAddresses
            });
            this.buildPointData(newState, address);
        } else {
            // deleted value
            newState.value = new WaybillPoint();
            newState.value.isHub = false;
        }

        this.setState(newState, f);
    };

    addNewPoint = async (newPoint, alreadyCreated = false) => {

        const updateScope = {};

        // creating waibillPoint!
        if (!alreadyCreated) {
            newPoint.createdByClient = this.props.wbClientId;
        }
        let savedPoint;
        if (alreadyCreated) {
            savedPoint = newPoint;
        } else {
            savedPoint = await pointCreate(newPoint);
        }

        if (savedPoint) {
            newPoint.id = savedPoint.id;
            newPoint.address = new PointAddress(savedPoint.address);

            updateScope.addresses = [newPoint];

            updateScope.value = new WaybillPoint();
            updateScope.value.pointId = newPoint.id;
            updateScope.value.point = savedPoint;
            updateScope.value.address = newPoint.address.value || newPoint.address.toString();
            updateScope.value.companyName = newPoint.companyName;
            if (newPoint.companyLegalForm) {
                updateScope.value.companyName = `${newPoint.companyLegalForm} ${updateScope.value.companyName}`;
            }
            updateScope.value.contact = (newPoint.contacts && newPoint.contacts.length && newPoint.contacts[0]) || {};
            // the problem here is that we missing on the address!

            this.setState(updateScope);
        }
    };

    async submit() {
        if (this.state.activeTab === tab3Name) {
            this.setState(state => ({tab3trigger: !state.tab3trigger}));
            return null;
        }
        let wbPoint = {...this.state.value};
        const {onClose, isAuction} = this.props;

        this.setState({isSubmitting: true});

        wbPoint.isLocked && toast.warning('Данный адрес заблокирован'.t);
        try {
            if (!wbPoint.id && !isAuction) {
                // when creating point for ftl -> we do not have any wbId -> need to submit wbPoint instead
                if (this.props.wbId) {
                    if (!this.props.isEdit) {
                        wbPoint = await waybillPointCreate(this.props.wbId, wbPoint);
                    } else {
                        wbPoint = await waybillPointUpdate(this.props.wbId, {...wbPoint, id: this.props.wbPointId});
                    }
                }
            }

            if (wbPoint.pointFMID) {
                await this.props.submit(wbPoint);
            } else {
                if (!wbPoint.point) {
                    // for unregistered points we need to load point for editing on card
                    // todo -> move loading into form inself
                    wbPoint.point = await getPointById(wbPoint.pointId);
                }
                await this.props.submit(wbPoint);
            }
        } catch (e) {
            console.log(e)
        }

        this.setState({
            value: {},
            isSubmitting: false
        });

        onClose && onClose();
    }

    handleItemClick = (e, {id}) => {
        this.setState({
            activeTab: id
        });
    }

    addressOptionItem = (p) => {
        return <div className='address-item' id={p.id}>
            <div className='address-item address-item__label'>
                <div className=''>
                    {p.address.toString()}
                </div>
                <div className='description'>
                    {(`${p.companyLegalForm || ''} ${p.companyName || ''}`).trunc(50)}
                </div>
            </div>

            <div className='description last'>
                {getCompanyTypeName(p.pointType)}
            </div>
        </div>;
    }

    render() {
        const {
            value,
            addresses, addressSearchIsFetching,
            isSubmitting,
            activeTab
        } = this.state;

        const valueIsOk = activeTab === tab3Name ? !this.state.tab3disabledSave : !!(value.pointId && value.companyName);

        const profile = this.props.isEdit ? profilesEdit[this.props.profile] : profiles[this.props.profile];

        const addressOptions = (addresses || [])
            .map(p => new Oc(p.id, p.address.toString(), this.addressOptionItem(p)));

        const searchTipsText = (
            <List bulleted>
                <List.Item>Адрес введенный по частям, например <em>монтажная 7</em></List.Item>
                <List.Item>Название клиента, например <em>ашан</em></List.Item>
                <List.Item>Название адреса для быстрого поиска</List.Item>
            </List>
        );

        const searchTips = (
            <Popup
                flowing
                trigger={<Icon name='info circle' color="blue" style={{fontSize: "1.1em"}}/>}
                content={
                    <>
                        <Header>Подсказка по поиску</Header>
                        <div>Для поиска <em>адреса FM</em> используйте один из критериев</div>
                        {searchTipsText}
                        <div>Возможно использовать часть названия клиента и часть адреса, например <em>ашан вавилова
                            3</em></div>
                    </>
                }
            />
        );

        const tab1 = (
            <>
                <Form.Field disabled={isSubmitting}>
                    <label><T>Найти адрес FM</T> {searchTips}</label>
                    <Dropdown
                        id={`dropdown_${this.props.profile}-pointId`}
                        clearable
                        noResultsMessage={'Результатов не найдено'.t}
                        selection
                        search={() => addressOptions || []}
                        value={value.pointId || ''}

                        onSearchChange={this.handlePointSearchChange.bind(this)}
                        onChange={this.handlePointChange.bind(this)}

                        className="InputDropdown"
                        placeholder={"Введите адрес по частям | название клиента | название для быстрого поиска".t}
                        fluid

                        closeOnChange

                        options={addressOptions || []}
                        loading={addressSearchIsFetching === true}
                        selectOnBlur={false}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Грузоотправитель</label>
                    <span>{value.companyName || <span className="tc-grey">нет данных</span>}</span>
                </Form.Field>
            </>
        );

        const tab2 = (
            <>
                <WbPointAddFormBookmarks
                    list={this.state.bookmarks}
                    onClick={(point) => {
                        this.handleBookmarkPointChange(null, {value: point.pointId});
                    }}
                />
            </>
        );

        const tab3 = (
            <>
                <WbPointAddCreateAddress
                    setPointAddress={(v) => {
                        this.handleBookmarkPointChange(null, {value: v}, () => {
                            this.setState({activeTab: tab1Name}, this.submit.bind(this))
                        });
                    }}
                    saveTrigger={this.state.tab3trigger}
                    setDisabledSaveBtn={(d) => this.setState({tab3disabledSave: d})}
                />
            </>
        );

        const tabByName = (name) => {
          switch (name) {
              case tab1Name: return tab1;
              case tab2Name: return tab2;
              case tab3Name: return tab3;
              default: return tab1;
          }
        };

        const menuItem = (name) => {
            return <Menu.Item key={name} id={name} active={activeTab === name} onClick={this.handleItemClick}>
                <MenuHeader><T>{name}</T></MenuHeader>
            </Menu.Item>;
        };

        const tabNames = [tab1Name, tab2Name, tab3Name];

        const tabs = (
            <>
                <Menu fluid widths={3} tabular>
                    {tabNames.map(n => menuItem(n))}
                </Menu>

                {tabByName(activeTab)}
            </>
        );

        return (
            <Modal
                trigger={this.props.children}
                closeOnDimmerClick={false}
                dimmer="inverted"
                closeIcon
                open={this.props.open}
                onOpen={this.handleOpen.bind(this)}
                onClose={this.handleClose.bind(this)}

            >
                <Modal.Header><T>{this.props.title || profile.addTitle || "[Заголовок]"}</T></Modal.Header>
                <Modal.Content>
                    <div className="m-b-10" style={{height: activeTab !== tab3Name ? 250 : 'auto'}}>
                        <Form
                            loading={this.state.loading}
                            disabled={this.state.isSubmitting}
                        >
                            {tabs}
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        id={`btn_${this.props.profile}-cancel`}
                        onClick={this.handleClose.bind(this)}
                    >
                        <T>Отмена</T>
                    </Button>
                    <Button
                        id={`btn_${this.props.profile}-ok`}
                        loading={this.state.isSubmitting}
                        positive
                        onClick={this.submit.bind(this)}
                        disabled={!valueIsOk}
                    >
                        {this.props.isEdit ? <T>Заменить</T> : <T>Добавить</T>}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
};
