import React from 'react';
import {Route, Switch} from "react-router";
import ErrorNotFound from "../ErrorNotFound";
import './style.css';
import NotificationsList from "./list";

const Notifications = () => {
    return (
        <Switch>
            <Route exact path="/notifications" component={NotificationsList} />
            <Route component={ErrorNotFound} />
        </Switch>
    )
};

export default Notifications;
