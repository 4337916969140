import React, {useEffect, useRef, useState} from "react";
import {Rnd} from "react-rnd";
import './styles.css';
import {enableResizingAllFalse} from "../bigTable/const";

const ResizeDoublePage = ({page1, page2}) => {
    const doublePageRef = useRef();
    const [height, setHeight] = useState(0);

    const getHeightPage = () => doublePageRef.current && doublePageRef.current.getBoundingClientRect().height;

    const setPageSize = () => {
        setHeight(getHeightPage() / 2);
    };

    useEffect(() => {
        (page1 && !height) && setPageSize();
    }, [doublePageRef.current]);

    useEffect(() => {
        window.removeEventListener('resize', setPageSize);
        window.addEventListener('resize', setPageSize);
        return () => {
            window.removeEventListener('resize', setPageSize);
        };
    }, [height]);

    const onResize = (e, direction, ref) => {
        const maxSize = getHeightPage() - 40;
        setHeight(maxSize > ref.offsetHeight ? ref.offsetHeight : maxSize);
    };

    const page = (p) => typeof p === 'function' ? p(height, height2) : p;

    const height2 = (getHeightPage() - height);

    return <div ref={doublePageRef} className="resize-double-page">
        <Rnd
            className="resize-double-page__page"
            disableDragging
            enableResizing={{
                ...enableResizingAllFalse,
                bottom: true
            }}
            size={{height}}
            position={{x: 0, y: 0}}
            onResize={onResize}
        >
            {page(page1)}
        </Rnd>
        <Rnd
            className="resize-double-page__page"
            disableDragging
            enableResizing={enableResizingAllFalse}
            position={{x: 0, y: height}}
            size={{height: height2}}
        >
            {page(page2)}
        </Rnd>
    </div>;
};

export default ResizeDoublePage;