import qs from 'query-string';
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { PointStatusEnum } from '../../../../api/model/Point';
import { pointCreateAccepted } from '../../../../api/points';
import T from '../../../../components/Translate';
import PointsFormExportExcel from '../../../_shared/pointsFormExportExcel/PointsFormExportExcel';
import PointForm from '../pointForm/PointForm';
import PointsFormImport from '../pointsFormImport/PointsFormImport';

export default function PointsToolbar({
    location,
    updateRows,
    companyOptions,
    selectedCompany,
    setSelectedCompany,
    canExport,
    selectedPoints
}) {
    async function createItem(item, options = {}) {
         
        let { companyName } = item;

        if (companyName && companyName.length) {
            companyName = companyName.trim();
            if (!'"“”\'«'.includes(companyName[0])) {
                companyName = '"' + companyName;
            }
            if (!'"“”\'»'.includes(companyName[companyName.length - 1])) {
                companyName = companyName + '"';
            }
        }

        item.companyName = companyName;
        const point = await pointCreateAccepted(item, options.exportOnSave);
        if (point) {
            await updateRows();
        }
    };

    return (
        <Menu className="waybills-toolbar shd-inset" style={{ marginBottom: '0' }} size='small' borderless>
            <Menu.Menu position='right'>
                <PointForm
                    key="newPointForm"
                    searchName
                    title={"Новый адрес".t}
                    size="small"
                    exportOnSaveCheck
                    data={{ statusId: PointStatusEnum.ACCEPTED }}
                    submit={createItem}
                    needFindSimilar
                    companyOptions={companyOptions}
                >
                    <Menu.Item>
                        <Icon name="plus" />
                        <T>Новый адрес</T>
                    </Menu.Item>
                </PointForm>
                
                <PointsFormImport
                    isClient={false}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    companyOptions={companyOptions}
                    refresh={updateRows}
                >
                    <Menu.Item>
                        <Icon name="arrow down" />
                        <T>Импорт адресов</T>
                    </Menu.Item>
                </PointsFormImport>
                {canExport && <PointsFormExportExcel isClient={false} selectedPoints={selectedPoints}>
                    <Menu.Item>
                        <Icon name="arrow up" />
                        <T>Экспорт адресов в Excel</T>
                    </Menu.Item>
                </PointsFormExportExcel>}
            </Menu.Menu>
        </Menu>
    );
}