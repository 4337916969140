import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Menu, Segment } from 'semantic-ui-react';
import O from '../../../../components/Option';
import T from '../../../../components/Translate';
import { PointTimeslot } from '../../../../api/model/PointTimeslot';
import { PointDays } from '../../../../api/model/PointDays';

const WeekDisplayMap = {
    mon: 'пн'.t,
    tue: 'вт'.t,
    wed: 'ср'.t,
    thu: 'чт'.t,
    fri: 'пт'.t,
    sat: 'cб'.t,
    sun: 'вс'.t
};

const styleInputHours = {
    maxWidth: '80px'
};
const styleLabelHours = {
    fontSize: '14px'

};

const styleDayOff = {
    color: 'red'
};
const styleDayOn = {
    color: 'inherit'
};

export default class PointFormDays extends React.Component {
    
    static propTypes = {
        days: PropTypes.shape(PointDays),
        onChange: PropTypes.func.isRequired
    }

    state = {
        activeDayKey: 'mon'
    }

    daysOptions = Object.keys(WeekDisplayMap)
        .map(k => new O(k, WeekDisplayMap[k]));

    hoursTypeOptions = [
        new O(0, "закрыто".t),
        new O(1, "24 часа".t),
        new O(2, "часы работы".t),
    ];

    handleTabClick(key, e, item) {
        this.setState({ activeDayKey: key });
    }

    updateDayType(activeDayKey, e, item) {
        
        if (item.checked) {
            const daysCloned = { ...(this.props.days || new PointDays()) };

            // eslint-disable-next-line default-case
            switch (item.value) {
                case 0:
                    daysCloned[activeDayKey] = null;
                    break;
                case 1:
                    daysCloned[activeDayKey] = new PointTimeslot("00:00");
                    break;
                case 2:
                    daysCloned[activeDayKey] = new PointTimeslot("09:00-23:00");
                    break;
            }

            this.props.onChange(daysCloned); // this would fire one global onChange!
        }
    }

    updateHours(activeDayKey, hoursKey, e) {
        
        const daysCloned = { ...(this.props.days || new PointDays()) };
        const day = daysCloned[activeDayKey] || (daysCloned[activeDayKey] = new PointTimeslot());
        day[hoursKey] = e.target.value || null;

        this.props.onChange(daysCloned); // this would fire one global onChange!
    }

    render() {

        const { days = new PointDays() } = this.props;

        const { activeDayKey } = this.state;

        const activeDay = days && days[activeDayKey];

        const activeDayStatusKey = activeDay
            ? activeDay.to
                ? 2
                : 1
            : 0;
        
        const hoursDisabled = activeDayStatusKey < 2;

        const containerStyle = {
            marginLeft: "1px",
            marginRight: "1px"
        };

        const isDisabled = this.props.disabled;

        return (
            <div style={containerStyle}>
                <Form.Field label={"Часы работы".t} className="m-0" />
                <Menu attached="top" widths={7} className="m-0">
                    {this.daysOptions
                        .map(o =>
                            <Menu.Item
                                style={days && days[o.key] ? styleDayOn : styleDayOff }
                                key={o.key}
                                name={o.text.t}
                                active={activeDayKey === o.key}
                                onClick={this.handleTabClick.bind(this, o.key)}
                            />
                        )
                    }
                </Menu>
                <Segment attached="bottom">
                    <Form.Group inline className="m-b-0" >
                        {
                            this.hoursTypeOptions.map(hours =>
                                <Form.Radio
                                    disabled={isDisabled}
                                    key={hours.key}
                                    label={hours.text.t}
                                    value={hours.key}
                                    checked={activeDayStatusKey === hours.key}
                                    onChange={this.updateDayType.bind(this, activeDayKey)}
                                />)
                        }
                        <Form.Field inline>
                            <label style={styleLabelHours}>&nbsp;<T>с</T></label>
                            <Input
                                disabled={hoursDisabled || isDisabled}
                                placeholder={'с'.t}
                                style={styleInputHours}
                                value={(activeDay && activeDay.from) || ''}
                                onChange={this.updateHours.bind(this, activeDayKey, "from")}
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <label style={styleLabelHours}><T>по</T></label>
                            <Input
                                disabled={hoursDisabled || isDisabled}
                                placeholder={'по'.t}
                                style={styleInputHours}
                                value={(activeDay && activeDay.to) || ''}
                                onChange={this.updateHours.bind(this, activeDayKey, "to")}
                            />
                        </Form.Field>
                    </Form.Group>
                </Segment>
            </div>
        );
    }
}