import React from 'react';
import './ProgressVisual.css';
import {WaybillStepStatusEnum} from '../../../../api/model/WaybillStep';
import moment from "moment";
import {Popup} from "semantic-ui-react";


export default function ProgressVisual({data = [], step, centerLine}) {

    const maxLetters = 25;

    const getLen = (text) => {
        return (text && Math.min(maxLetters, text.length) * 6) + 5 || 10;
    };

    const getDateTimeMoment = datetime => moment(datetime, 'DD.MM.YYYY HH:mm');
    const getDate = (datetime) => getDateTimeMoment(datetime).format('DD.MM.YYYY');
    const getTime = (datetime) => getDateTimeMoment(datetime).format('HH:mm');

    if (data.length) {

        let counter = 0;
        const firstOffset = 85;
        const labelOffset = 20;
        const carYOffset = 70;
        const postalCodeYOffset = 18;

        return (
            <svg
                width={(data.length - 1) * step + 170}
                height="110"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <g id="cigreen">
                        <circle stroke="green" fill="green" r="10"/>
                    </g>
                    <g id="cigrey">
                        <circle stroke="grey" fill="grey" r="10"/>
                    </g>
                </defs>


                {data.map((i, idx) => (
                    <g key={idx}>

                        {i.lineStatus != null && <React.Fragment>
                            <line
                                y1={centerLine}
                                x1={(step * counter) + firstOffset}
                                y2={centerLine}
                                x2={(step * (counter + 1)) + firstOffset}
                                strokeWidth="1"
                                stroke={i.lineStatus > WaybillStepStatusEnum.PENDING
                                    ? "green"
                                    : "grey"}
                            />
                            <foreignObject
                                y={carYOffset}
                                x={(step * (counter) + firstOffset)}
                                width={step}
                                height="40">
                                <div className="car-outer">
                                    <div className="car-inner">
                                        <div className="car-text">{i.lineCar}</div>
                                        <div className="car-text">{i.lineDriverFirstName}</div>
                                        <div className="car-text">{i.lineDriverLastName}</div>
                                    </div>
                                </div>
                            </foreignObject>
                        </React.Fragment>}

                        <text
                            className="point-font"
                            y={labelOffset}
                            x={(step * counter) + firstOffset}
                            dx={-(getLen(i.pointPostalCode) / 2)}
                            textAnchor="start"
                        >
                            {i.pointPostalCode}
                        </text>
                        <text
                            className="point-font"
                            y={labelOffset + postalCodeYOffset}
                            x={(step * counter) + firstOffset}
                            dx={-(getLen(i.pointName.trunc(maxLetters, true)) / 2)}
                            textAnchor="start"
                        >
                            {i.pointName.trunc(maxLetters, true)}
                        </text>
                        {
                            (idx === 0) &&
                            <foreignObject
                                y={carYOffset}
                                x={10}
                                width="65"
                                height="40">
                                {i.loadingDateTimeArrival ? <div className="point-date">
                                    <div>{getDate(i.loadingDateTimeArrival)}</div>
                                    <div>{getTime(i.loadingDateTimeArrival)}</div>
                                </div> : null}
                            </foreignObject>
                        }
                        {
                            (idx === (data.length - 1)) &&
                            <foreignObject
                                y={carYOffset}
                                x={(step * counter) + firstOffset + 15}
                                width="65"
                                height="40">
                                {i.unloadingDateTimeDeparture ? <div className="point-date">
                                    <div>{getDate(i.unloadingDateTimeDeparture)}</div>
                                    <div>{getTime(i.unloadingDateTimeDeparture)}</div>
                                </div> : null}
                            </foreignObject>
                        }
                        <Popup
                            hoverable
                            trigger={<use
                                y={centerLine}
                                x={(step * counter++) + firstOffset}
                                xlinkHref={i.pointStatus > WaybillStepStatusEnum.PENDING ? "#cigreen" : "#cigrey"}/>}
                            content={i.pointFullAddress}
                            size='tiny'
                            position={idx === (data.length - 1) ? 'top right' : (idx === 0 ? 'top left' : 'top center')}
                            offset={(idx === (data.length - 1) || idx === 0) && [idx === 0 ? -6 : 6, 0]}
                        />
                    </g>
                ))}
            </svg>
        );

    } else {
        return <span>нет данных по маршруту</span>;
    }


}
