import React, {useContext, useState} from "react";
import {
    AUCTION_COLUMNS_KEY,
    getGridColumns,
    setGridColumns,
    WAYBILL_COLUMNS_KEY
} from "../api/waybills";
import {locales} from "./locale";
import {auctionColumnsSet, waybillColumnsSet} from "../api/model/WaybillColumn";

export const ContextApp = React.createContext({});

export const ContextLocale = React.createContext();
export function ContextLocaleProvider({children}) {
    const [locale, setLocale] = useState(localStorage.getItem('locale') || locales.ru);

    function setAndSaveLocale(localeNew) {
        localStorage.setItem('locale', localeNew);
        window.locale = localeNew; // use in translations
        setLocale(localeNew);
    }

    return (
        <ContextLocale.Provider value={{
            locale,
            setLocale: setAndSaveLocale
        }}>
            {children}
        </ContextLocale.Provider>
    );
}

export const ContextUser = React.createContext({
    current: null,
    setUser: () => { throw new Error("setUser: uninitialised contextWb"); },
    setCompanies: () => { throw new Error("setCompanies: uninitialised contextWb"); },
    setIsCompaniesAll: () => {throw new Error("setIsCompaniesAll: uninitialised contextWb"); }
});

export const ContextNavi = React.createContext({
    setItems: () => { }
});

export const ContextWb = React.createContext({
    wb: null,
    update: () => { throw new Error("update: uninitialised contextWb"); },
    save: () => { throw new Error("save: uninitialised contextWb"); },
    wbErrors: []
});



export const ContextFieldConfigs = React.createContext({
    configs: [],
    update: () => { throw new Error("update: uninitialised contextWb"); },
    save: () => { throw new Error("save: uninitialised contextWb"); },
});


export const ContextWaybill = React.createContext();
export function ContextWaybillProvider({children, loadingOver}) {
    const [wb, setWb] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    async function fetchWb() {
        alert('Функционал находится в разработке.');
    }
    // if there is no context - we should be showing ErrorNotFound
    return (
        <ContextGridSettings.Provider value={{ wb, setWb, fetchWb }}>
            {children}
        </ContextGridSettings.Provider>
    );
}

export const ContextGridSettings = React.createContext();
export function ContextGridSettingsProvider({children}) {
    const userContext = useContext(ContextUser);
    const userCurrent = userContext.current;
    const [columns, setColumns] = useState(getGridColumns(userCurrent, WAYBILL_COLUMNS_KEY, waybillColumnsSet));
    const [auctionsColumns, setAuctionsColumns] = useState(getGridColumns(userCurrent, AUCTION_COLUMNS_KEY, auctionColumnsSet));


    function setAndSaveColumns(setup) {
        setColumns(setGridColumns(setup, (userCurrent || {}).email, WAYBILL_COLUMNS_KEY)); // save
    }

    function setAndSaveAuctionColumns(setup) {
        setAuctionsColumns(setGridColumns(setup, (userCurrent || {}).email, AUCTION_COLUMNS_KEY)); // save
    }

    return (
        <ContextGridSettings.Provider value={{
            columns,
            setColumns: setAndSaveColumns,
            auctionsColumns,
            setAuctionsColumns: setAndSaveAuctionColumns,
        }}>
            {children}
        </ContextGridSettings.Provider>
    );
}

export const ContextFooter = React.createContext();
export function ContextFooterProvider({children}) {
    const [indicator, setIndicator] = useState(null);

    return (
        <ContextFooter.Provider value={{
            indicator,
            setIndicator
        }}>
            {children}
        </ContextFooter.Provider>
    );
}
