import React, {useState} from 'react';
import {Button, Message, Modal} from "semantic-ui-react";
import ImportFormSelect from "../../components/modals/components/ImportFormSelect";
import {copyFromTo} from "../../api/dictionaries";

const CopyModal = ({open, onClose, onSave, id, dictionarySettings, fmid}) => {
    const [form, setForm] = useState({});

    const {
        customText
    } = dictionarySettings;

    const onChange = (name, value) => {
        setForm(form => ({
            ...form,
            [name]: value
        }));
    };

    const handleClose = () => {
        setForm({});
        onClose && onClose();
    };

    const copy = () => {
        copyFromTo(dictionarySettings.urlName, form.formName, id)
            .then(() => {
                onSave && onSave();
                handleClose();
            });
    };

    return (
        <Modal
            dimmer="inverted"
            size="small"
            closeIcon
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>
                {customText.copyBtnName}
            </Modal.Header>
            <Modal.Content>
                <Message
                    content={customText.copyInfo}
                    icon="info"
                    info
                />
                <ImportFormSelect
                    fmid={fmid}
                    form={form}
                    onChange={onChange}
                    ignoreFormId={id}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => handleClose()}
                >
                    Отмена
                </Button>
                <Button
                    onClick={copy}
                    positive
                    disabled={(!fmid && !form.clientName) || !form.formName}
                >
                    {customText.copyBtnName}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CopyModal;