import React, {useEffect, useState} from 'react';
import {Divider, Form, Grid, GridColumn, Header, Message} from "semantic-ui-react";
import {getDocsContact, setDocsContact} from "../../../../api/companies";

const CompanyTabEmailSendingDocuments = ({data}) => {

    const [dto, setDto] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    const emailsByType = (type) => {
        return (dto.find(d => d.type === type) || {}).mails;
    };

    useEffect(() => {
        const getData = async () => {
            await getDocsContact(data.fmid).then(d => {
                setDto(d.data);
            });
        };
        getData().catch(e => console.error(e));
    }, []);

    function update(type, mails) {
        const element = {type, mails};
        let newDto = [...dto];
        const index = newDto.findIndex(d => d.type === type);
        if (index !== -1) newDto[index] = element;
        else newDto.push(element);
        setDto(newDto);
        setIsChanged(true);
    }

    const saveData = async () => {
        setIsChanged(false);
        isChanged && await setDocsContact(data.fmid, dto);
    };

    return (
        <Grid>
            <Grid.Row>
                <GridColumn width={10}>
                    <Header className="m-b-10">Управление контактами уведомления "Отправка документов в письме"</Header>
                    <Divider className="m-t-0"/>
                    <Message
                        info
                        icon="info"
                        content={<div>
                            <div>
                                {'Пожалуйста, укажите адресатов в формате <имя_пользователя@почтовый_домен>.'}
                            </div>
                            <div>
                                В качестве разделителя между адресатами требуется использовать запятую.
                            </div>
                        </div>}
                    />
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={'Адресат для получения паллетной/покоробочной этикетки'.t}
                                placeholder={'Пожалуйста, укажите адресатов уведомления'.t}
                                value={emailsByType(0) || ''}
                                onChange={e => update(0, e.target.value)}
                                onBlur={saveData}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={'Адресат для получения экспедиторской расписки'.t}
                                placeholder={'Пожалуйста, укажите адресатов уведомления'.t}
                                value={emailsByType(1) || ''}
                                onChange={e => update(1, e.target.value)}
                                onBlur={saveData}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={'Адресат для получения экспедиторской расписки и паллетной/покоробочной этикетки'.t}
                                placeholder={'Пожалуйста, укажите адресатов уведомления'.t}
                                value={emailsByType(2) || ''}
                                onChange={e => update(2, e.target.value)}
                                onBlur={saveData}
                            />
                        </Form.Group>
                    </Form>
                </GridColumn>
            </Grid.Row>
        </Grid>
    );
};

export default CompanyTabEmailSendingDocuments;

