import React from 'react';
import { Table } from 'semantic-ui-react';
import AppTableRow from './AppTableRow';
import './AppTable.css';
import T from '../Translate';

export default function AppTable(props) {

    const { headers, rows, rowActions, selection, selectedIds, handleRowSelection, onRowClick, filters } = props;

    let rowIndex = 0;

    return (
        <Table basic="very" color="blue" className="m-t-0 app-table" selectable={!!onRowClick}>
            <Table.Header>
                <Table.Row className="app-table-header-row">
                    {selection && <Table.HeaderCell>&nbsp;</Table.HeaderCell>}
                    {headers.map(h => (
                        <Table.HeaderCell key={h.key} width={h.width ? h.width : undefined}>
                            <T>{h.text}</T>
                        </Table.HeaderCell>
                    ))}
                    {rowActions && rowActions.length ? <Table.HeaderCell key="actions" width={1}>&nbsp;</Table.HeaderCell> : null}
                </Table.Row>
            </Table.Header>
            {
                filters && filters
            }
            <Table.Body>
                {rows.map(row => <AppTableRow
                    key={rowIndex++}
                    headers={headers}
                    actions={rowActions}
                    onRowClick={onRowClick}
                    row={row}
                    renderItem={props.renderItem}
                    titleEdit={props.titleEdit}
                    selection={selection}
                    selectedIds={selectedIds}
                    handleRowSelection={handleRowSelection}
                />)}
            </Table.Body>
        </Table>
    );
}
