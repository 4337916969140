import React from 'react';
import InputMask from 'react-input-mask';
import {Form, Input} from "semantic-ui-react";

const PhoneNumber = ({label, name, value, onChange, placeholder, isDisabled = false, error}) => {

    return (
        <div>
            <InputMask
                mask="+79999999999"
                maskChar={null}
                value={value || ''}
                disabled={isDisabled}
                onChange={e => onChange(e, {value: e.target.value, name})}
            >
                {inputProps => (
                    <Form.Field error={error}>
                        <label>{label}</label>
                        <Input
                            {...inputProps}
                            placeholder={placeholder}
                            disabled={isDisabled}
                            name={name}
                        />
                    </Form.Field>
                )}
            </InputMask>
        </div>
    );
};

export default PhoneNumber;