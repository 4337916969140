import React, {useContext, useState} from 'react';
import ImportFormEdit from "./ImportFormEdit";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi, ContextUser} from "../../services/context";
import {toast} from "../../services/toast";
import {usePageIsActive} from "../../services/pageTimerHook";
import ImportFormHeader from "./ImportFormHeader";
import UserPermissions from "../../api/model/UserPermissions";

const ImportFormPage = ({match, history}) => {
    const contextUser = useContext(ContextUser);
    const permissions = contextUser.current.permissions;
    const isOnlyView = !permissions.includes(UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE);
    const contextNavi = useContext(ContextNavi);
    const fmid = match.params.fmid;
    const id = match.params.id;
    const [formName, setFormName] = useState('');

    const setNavi = (form) => {
        contextNavi.setItems([
            new LinkInfo("Импорт заявок", "/import"),
            new LinkInfo(form.companyName || fmid, `/import/${fmid}`),
            new LinkInfo(form.name || "Новая форма", `/import/${fmid}/${id}`),
        ]);

        setFormName(form.name);
    };

    const inactive = () => {
        history.push(`/import/${fmid}`);
        toast.warning(`Данные страницы устарели. Для работы с формой ${formName}, пожалуйста, откройте ее еще раз на странице форм клиента`);
    };

    usePageIsActive(inactive);

    const header = (props) => {
        return <ImportFormHeader
            {...props}
            isOnlyView={isOnlyView}
        />;
    };

    return (
        <ImportFormEdit
            history={history}
            fmid={fmid}
            id={id}
            setNavi={setNavi}
            header={header}
            isOnlyView={isOnlyView}
        />
    );
};

export default ImportFormPage;