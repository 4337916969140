import React, {Component, useContext} from 'react';
import { withRouter } from 'react-router-dom';
import {Icon, Menu, Tab} from 'semantic-ui-react';
import {ContextNavi, ContextUser} from '../../services/context';
import './Navbar.css';
import LanguageSelector from './NavbarLanguageSelector';
import NavbarPath from './NavbarPath';
import UserContext from './NavbarUserContext';

export class LinkInfo {
    constructor(name, url, accessKey, icon) {
        /** @type {string} */
        this.name = name;

        this.url = url;
        this.accessKey = accessKey;
        this.icon = icon;
    }
}

export class LinkTab extends LinkInfo {
    constructor(name, url, accessKey, icon) {
        super(name, url, accessKey, icon);
        this.isTab = true;
    }
}

const Tabs = ({tabs, location, history}) => {
    return <Menu className='navbar-tab'>
        {
            tabs.map(tab => <Menu.Item
                key={tab.url}
                active={location.replaceAll('/', '') === tab.url.replaceAll('/', '')}
                onClick={() => history.push(tab.url)}
            >
                {tab.name}
            </Menu.Item>)
        }
    </Menu>;
};

class Navbar extends Component {

    state = {
        sidebarVisible: false,
        items: [new LinkInfo("", "/")],
        setItems: (items) => this.setItems(items),
        links:[]
    };

    setItems = (items) => {
        this.setState({ items });
        const isTabs = !!items.find(i => i.isTab);
        if (items && items.length)
            document.title = "FMCP " + (isTabs ? items.find(i => i.url.replaceAll('/', '') === this.props.location.pathname.replaceAll('/', '')).name : items[items.length - 1].name);
    };

    handleItemClick = (e, { name }) => {
        this.props.history.push(name);
    }

    handleSidebarClick = () => {
        this.setState({ sidebarVisible: !this.state.sidebarVisible });
    }

    handleRouteClick = (link) => {
        this.setState({ sidebarVisible: false });
        this.props.history.push(link.url);
    };

    componentWillMount() {
        if (this.context) {
            this.context.setItems = this.state.setItems.bind(this);
        }
    }

    componentWillUnmount() {
        if (this.context) {
            this.context.setItems = null;
        } 
    }

    render() {
        const { items } = this.state;
        const isTabs = !!items.find(i => i.isTab);

        return (
            <>
                <Menu size='small' borderless className="app-header">
                    {
                        isTabs
                            ? <Tabs tabs={items} location={this.props.location.pathname} history={this.props.history}/>
                            : <Menu.Item style={{ paddingLeft: '20px' }}>
                                <NavbarPath items={items}/>
                            </Menu.Item>
                    }
                    <Menu.Menu position="right">
                        <Menu.Item key="/user/profile" name="/user/profile">
                            <UserContext />
                        </Menu.Item>
                        <Menu.Item as="a" href="https://lk.fmlogistic.ru/lp" target="_blank" title="Справка">
                            <Icon name="help"/>
                        </Menu.Item>
                        <LanguageSelector />
                        <Menu.Item
                            name="/login"
                            key="/login"
                            title="Выход"
                            onClick={this.handleItemClick}> <Icon name='sign-out' fitted />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

            </>
        );
    }
}

export default withRouter(Navbar);

Navbar.contextType = ContextNavi;

