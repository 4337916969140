import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Message } from 'semantic-ui-react';
import T from '../../../components/Translate';
import CompanyForm from './CompanyForm';
import { companyCreate } from '../../../api/companies';

export default withRouter(CompanyFormNew);

function CompanyFormNew({children, history, fetchData}) {

    const centered = false;
    const size = "large";

    const requiredFields = ['name', 'fmid', 'idSAP', 'inn'];

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isValidForm, setIsValidForm] = useState(true);
    const [data, setData] = useState({});

 /*   useEffect(() => {
        if (errors.length === 0 ) {
            setIsValidForm(true)
        }
    }, errors);*/

    function updateDate (value) {
        if (!isValidForm) {
            const errors = requiredFields.filter(i => !Object.keys(value).includes(i)) || [];
            setErrors(errors);
        }
        setData(value);
    };

    async function submit() {
        try {

            if (!data || Object.keys(data).filter(i => requiredFields.includes(i)).length < requiredFields.length) {
                setIsValidForm(false);
                const errors = requiredFields.filter(i => !Object.keys(data).includes(i));
                setErrors(errors);
            } else {
                setLoading(true);
                await companyCreate(data);
                setLoading(false);
                setOpen(false);
                history.push(`/admin/companies/${data.fmid}/props`);
            }

        } catch(error) {
            // there can be validation errors
            setLoading(false);
            setOpen(false);
            return false;
        }
    }

    return (
        <Modal
            trigger={children}
            centered={centered}
            dimmer="inverted"
            size={size}
            closeIcon
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header><T>Добавить клиента</T></Modal.Header>
            <Modal.Content>
                <CompanyForm errors={errors} data={data} setData={updateDate} isNew={true} />
                {errors.length > 0 &&<Message warning>
                    <T>Заполните обязательные поля</T>
                </Message>}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setData({});
                        setOpen(false);}}
                    disabled={loading}><T>Отмена</T></Button>
                <Button
                    positive
                    disabled={loading || errors.length > 0 && !isValidForm}
                    onClick={submit} ><T>Сохранить</T></Button>
            </Modal.Actions>
        </Modal>
    );
}
