import React from 'react';
import {Form, Input} from "semantic-ui-react";
import HeaderMessage from "./HeaderMessage";

const ImportFormHeader = ({disabled, form, formFileName, fileIsBlocked, setValue, isOnlyView}) => {
    return (
        <Form className="import-form-edit__name-block">
            <Form.Field required inline>
                <label>
                    Наименование формы
                </label>
                <Input
                    disabled={disabled}
                    inline
                    required
                    value={form.name || ''}
                    name="name"
                    onChange={setValue}
                />
            </Form.Field>

            {(disabled && !isOnlyView) && <HeaderMessage
                text={`Форма доступна только для просмотра${fileIsBlocked && formFileName ? `, так как происходит обработка системой файла ${formFileName}` : ''}`}
            />}
        </Form>
    );
};

export default ImportFormHeader;