import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, Divider, Grid, Icon, Menu, Message} from 'semantic-ui-react';
import {getWaybillDocsInitial, getWaybillDocsClosing} from '../../../api/waybillDocs';
import GalleryItem from '../../../components/gallery/GalleryItem';
import T from '../../../components/Translate';
import RouteHeader from "../wbView/RouteHeader";


function DocsCards({hasDocsChecking, checkMessage, docs, id}) {
    const styleWrapper = {padding: '7px'};
    const styleHidden = {
        visibility: 'hidden',
        opacity: '0',
        marginTop: '-85px',
        transition: 'visibility 0s 1s, opacity 1s linear, margin-top 1s linear'
    };

    return (
        <div style={styleWrapper}>
            <div style={hasDocsChecking ? null : styleHidden}>
                <Message warning icon>
                    <Icon name="circle notched" loading/>
                    <Message.Content>
                        <Message.Header>Проверка...</Message.Header>
                        <p>{checkMessage}</p>
                    </Message.Content>
                </Message>
            </div>
            {!hasDocsChecking && docs.length > 0 &&
                <Card.Group className="m-t-5">{docs.map(doc => <GalleryItem {...doc} key={doc.header}
                                                                            id={id}/>)}</Card.Group>
            }
            {!hasDocsChecking && docs.length === 0 &&
                <Message info icon="info" header="Документация недоступна"
                         content="Попробуйте проверить позже или обратитесь в поддержку"/>
            }
        </div>
    );
}


const Docs = ({wb, isRoute}) => {
    const [docsInitial, setDocsInitial] = useState([]);
    const [hasDocsInitialChecking, setHasDocsInitialChecking] = useState(true);
    const [docsClosing, setDocsClosing] = useState([]);
    const [hasDocsClosingChecking, setHasDocsClosingChecking] = useState(true);

    useEffect(() => {
        async function fetchDocsInitial() {
            try {
                const dto = await getWaybillDocsInitial(wb.id);
                setDocsInitial(dto || []);
            } finally {
                setHasDocsInitialChecking(false);
            }

        }

        async function fetchDocsClosing() {
            try {
                const dto = await getWaybillDocsClosing(wb.id);
                setDocsClosing(dto || []);
            } finally {
                setHasDocsClosingChecking(false);
            }

        }

        Promise.all([fetchDocsInitial(), fetchDocsClosing()]);
    }, [wb.id]);

    return (
        <Grid columns={isRoute ? 2 : 1}>
            <Grid.Column>
                <Menu borderless>
                    <Menu.Item><b><T>Первичная документация</T></b></Menu.Item>
                </Menu>
                <DocsCards
                    hasDocsChecking={hasDocsInitialChecking}
                    checkMessage="проверка наличия первичной документации..."
                    docs={docsInitial}
                    id={wb.id}
                />
                <Divider hidden/>
            </Grid.Column>
            <Grid.Column>
                <Menu borderless>
                    <Menu.Item><b><T>Подтверждающая документация</T></b></Menu.Item>
                </Menu>
                <DocsCards
                    hasDocsChecking={hasDocsClosingChecking}
                    checkMessage="проверка наличия закрывающей документации..."
                    docs={docsClosing}
                    id={wb.id}
                />
            </Grid.Column>
        </Grid>);
};

function WbDocs({wb, routes}) {
    return (!wb.__isRoute && routes && routes.length)
        ? <Grid className='wb-docs' divided='vertically'>
            {
                routes.map((route, index) =>
                    <Grid.Row>
                        <Grid.Column>
                            <Grid.Row className="address-cargo__route-header">
                                <Grid.Column>
                                    <RouteHeader route={route} index={index}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Docs wb={route} isRoute/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                )
            }
        </Grid>
        : <Docs wb={wb}/>;
}

export default withRouter(WbDocs);
