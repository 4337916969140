import React from 'react';
import {Icon} from "semantic-ui-react";

const AddCell = ({isRow, isFirst, add, disabled}) => {
    const isAddRow = isRow && isFirst;
    const isAddCol = !isRow && isFirst;
    const isAdd = isAddCol || isAddRow;

    const params = (!disabled && isAdd)
        ? {
            onClick: add
        }
        : {};

    return (
        <div
            {...params}
            className={`big-table__add-cell ${isRow ? 'big-table__add-cell_row' : 'big-table__add-cell_col'} ${isAdd && 'big-table__add-cell_btn'}`}
        >
            {
                (isAdd && !disabled) && <Icon name="plus"/>
            }
        </div>
    );
};

export default AddCell;