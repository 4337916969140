import React, {useState, useEffect} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import {EnumHoursOptions} from "./companyTabCutoffs/_shared/EnumHours";
import {EnumMinutesOptions} from "./companyTabCutoffs/_shared/EnumMinutes";
import {CargoTypeOptions, CompanyTypeOptions, CutoffDayOptions} from "../../../../api/model/Enums";
import T from "../../../../components/Translate";
import {
    companyCutoffTimeLTLAdd,
    companyCutoffUpdateRuleAdd,
    companyCutoffUpdateRuleEdit,
    getCutoffUpdateRule
} from "../../../../api/companyCutoffs";

const CutoffUpdateRuleAdd = ({children, fetchData, type, companyId, id}) => {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isClickSave, setIsClickSave] = useState(false);

    const getRule = async () => {
            const result = await getCutoffUpdateRule(companyId, id);
            setData({
                cutoffDay: result.cutoffDay,
                pointType: result.pointType,
                cargoType: result.cargoType,
                cutoffHour: result.time && result.time.split.length ? EnumHoursOptions.find(item => item.text === result.time.split(':')[0]).value : null,
                cutoffMinutes: result.time && result.time.split.length ? EnumMinutesOptions.find(item => item.text === result.time.split(':')[1]).value : null,
            })
    };

    useEffect(() => {
        if (isClickSave) {
            const errorsForm = getErrorsForm();
            setErrors(errorsForm);
        }
    }, [data]);

    function getErrorsForm() {
        let errorsNew = [];
        if (data && !data.cutoffHour && data.cutoffHour !== 0) {
            errorsNew.push('cutoffHour')
        }
        if (data && !data.cutoffMinutes && data.cutoffMinutes !== 0) {
            errorsNew.push('cutoffMinutes')
        }
        if (data && !data.cargoType && data.cargoType !== 0) {
            errorsNew.push('cargoType')
        }
        if (data && !data.pointType && data.pointType !== 0) {
            errorsNew.push('pointType')
        }
        if (data && !data.cargoType && data.cutoffDay !== 0) {
            errorsNew.push('cutoffDay')
        }
        return errorsNew;
    }

    function updateData(prop) {
        setData({ ...data, ...prop });
    }

    const handleOpen = () => {
        console.log('EnumHoursOptions',EnumHoursOptions);
        id && getRule();
        setOpen(true)
    };

    const handleClose = () => {
        setIsClickSave(false);
        setData({});
        setErrors([]);
        setOpen(false);
    };

    const mapData = () => {
        return {
            shippingType: type,
            cutoffDay: data.cutoffDay,
            pointType: data.pointType,
            cargoType: data.cargoType,
            time: `${data.cutoffHour}:${data.cutoffMinutes}`
        }
    };

    const handleSave = async () => {
        setIsClickSave(true);
        const errorsForm = getErrorsForm();
        if (errorsForm.length > 0) {
            setErrors(errorsForm);
        } else {
            setLoading(true);
            try {
                if (id) {
                    await companyCutoffUpdateRuleEdit(companyId, mapData(), id);
                    await fetchData();
                } else {
                    await companyCutoffUpdateRuleAdd(companyId, mapData());
                    fetchData();
                }
            } finally {
                setLoading(false);
                handleClose();
            }
        }
    };

    return (
        <Modal
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                {id ? 'Редактирование ограничения по времени обновления заявки' : 'Добавление ограничения по времени обновления заявки'}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            error={errors.includes('cutoffDay')}
                            required
                            label={'Дата отсечки'.t}
                            placeholder={'Дата отсечки'.t}
                            options={CutoffDayOptions}
                            value={data.cutoffDay}
                            onChange={(e, { value }) => updateData({ cutoffDay: value })}
                        />
                        <Form.Select
                            error={errors.includes('cargoType')}
                            required
                            label={'Тип груза'.t}
                            placeholder={'Тип груза'.t}
                            options={CargoTypeOptions}
                            value={data.cargoType}
                            onChange={(e, { value }) => updateData({ cargoType: value })}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select
                            error={errors.includes('cutoffHour')}
                            required
                            label={'Час отсечки'.t}
                            placeholder={'Час отсечки'.t}
                            options={EnumHoursOptions}
                            value={data.cutoffHour}
                            onChange={(e, { value }) => updateData({ cutoffHour: value })}
                        />
                        <Form.Select
                            error={errors.includes('cutoffMinutes')}
                            required
                            label={'Минуты отсечки'.t}
                            placeholder={'Минуты отсечки'.t}
                            options={EnumMinutesOptions}
                            value={data.cutoffMinutes}
                            onChange={(e, { value }) => updateData({ cutoffMinutes: value })}
                        />
                    </Form.Group>
                    <Form.Select
                        error={errors.includes('pointType')}
                        required
                        label={'Тип клиента'.t}
                        placeholder={'Тип клиента'.t}
                        options={CompanyTypeOptions}
                        value={data.pointType}
                        onChange={(e, { value }) => updateData({ pointType: value })}
                    />
                </Form>
                {errors.length > 0 && <Message warning>
                    <T>Заполните обязательные поля</T>
                </Message>}
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={handleClose}><T>Отмена</T></Button>
                <Button disabled={loading} primary onClick={handleSave}><T>{id ? 'Сохранить' : 'Добавить'}</T></Button>
            </Modal.Actions>
        </Modal>
    )
};

export default CutoffUpdateRuleAdd;
