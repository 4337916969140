import React from 'react';
import { Grid, GridColumn, GridRow, Header } from 'semantic-ui-react';
import WaybillStatusOrder from '../common/WaybillStatusOrder';
import WaybillStatusShipping from '../common/WaybillStatusShipping';
import WbViewHeaderActions from './WbViewHeaderActions';
import WaybillStatusAvisation from "../common/WaybillSatusAvisation";

export default function WbViewHeader({ header, statusOrder, statusShipping, statusAnomaly, isAvisation, statusAvisation, statusAuction, isAuction }) {

    const styleRow = {
        minHeight: '95px',
    };

    return (
        <Grid verticalAlign='middle'>
            <GridRow style={styleRow} columns={3}>
                <GridColumn>
                    <div className="display-flex">
                        {
                            isAvisation
                            ? <WaybillStatusAvisation statusAvisation={statusAvisation}/>
                                : (isAuction ?
                                    <WaybillStatusOrder statusOrder={statusAuction} />
                                    : <>
                                        <WaybillStatusOrder statusOrder={statusOrder} />
                                        <WaybillStatusShipping statusShipping={statusShipping} className="m-l-10" />
                                        <WaybillStatusOrder statusOrder={statusAnomaly} className="m-l-10" />
                                    </>)
                        }

                    </div>
                </GridColumn>
                <GridColumn textAlign="center">
                    <Header>
                        <Header.Content>
                            {header}
                        </Header.Content>
                    </Header>
                </GridColumn>
                <GridColumn textAlign="right">
                    {!isAuction && <WbViewHeaderActions isAvisation={isAvisation}/>}
                </GridColumn>
            </GridRow>
        </Grid>
    );
}
