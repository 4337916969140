import React, {useCallback, useEffect, useState} from 'react';
import {Divider, Form, Grid, Header, Input} from "semantic-ui-react";
import {
    getApprovalRegistryRules,
    setApprovalRegistryRules
} from "../../../../api/companies";
import {debounce} from "awesome-debounce-promise";

const RegistryApprovalRules = ({match}) => {
    const [days, setDays] = useState(null);

    useEffect(() => {
        const approvalRegistry = async () => {
            return await getApprovalRegistryRules(match.params.id);
        };
        approvalRegistry().then(r => {
            setDays(r.days);
        });
    }, []);

    const setData = useCallback(debounce(async (params) => {
        await setApprovalRegistryRules(match.params.id, params);
    }, 500), [match]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header className="m-b-10">Правила одобрения реестра</Header>
                    <Divider className="m-t-0"/>
                    <Form size="small">
                        <Form.Field>
                            <label>Количество дней на согласование после отправки реестра (в рабочих днях)</label>
                            <Input
                                style={{maxWidth: '50%'}}
                                disabled={false}
                                type="number"
                                min={1}
                                value={days || 0}
                                onChange={(e, {value}) => {
                                    if (!value || value > 0) {
                                        setDays(value);
                                        setData({
                                            days: value
                                        });
                                    }
                                }}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default RegistryApprovalRules;