import React, {useState} from 'react';
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {Button, Confirm, Form, Table} from "semantic-ui-react";
import {wbsCosts, wbsCostsSave} from "../../../../api/waybills";
import {toast} from "../../../../services/toast";

const WbGridEditCostModal = ({children, refresh, selectedRows}) => {
    const [form, setForm] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);


    const handleOpen = async () => {
        setLoading(true);
        await wbsCosts(selectedRows.map(r => r.id)).then(d => {
            setLoading(false);
            setOpen(true);
            setForm(d.costs || []);
        }).catch(() => setLoading(false));
    };

    const handleClose = () => {
        setOpen(false);
        setConfirmOpen(false);
        refresh();
    };

    const onClose = () => {
        isChanged ? setConfirmOpen(true) : handleClose();
    };

    const handleSend = async () => {
        if (form.find(f => !f.cargoCost || f.cargoCost <= 0)) {
            const rows = (form.filter(f => !f.cargoCost || f.cargoCost <= 0) || []).map(c => c.fmid);
            toast.error(`Невозможно сохранить изменение по заявк${rows.length !== 1 ? 'ам': 'e'} ${rows.join(', ')}, т.к. стоимость груза не указана`);
        }
        else {
            setLoading(true);
            await wbsCostsSave({
                costs: form.map(f => ({
                    id: f.id,
                    cargoCost: f.cargoCost
                }))
            })
                .then(() => {
                    setLoading(false);
                    handleClose();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    const getCost = (id) => {
        const row = form.find(f => f.id === id);
        return row && row.cargoCost;
    };

    const setCost = (id, value) => {
        if (value >= 0) {
            setIsChanged(true);
            let newForm = [...form];
            const index = newForm.findIndex(f => f.id === id);
            if (index !== -1) newForm[index].cargoCost = value;
            setForm(newForm);
        }
    };

    return (
        <Modal
            open={open}
            trigger={children}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                Редактировать стоимость груза
            </Modal.Header>
            <Modal.Content>
                <b>Укажите новое значение стоимости груза у заявок</b>
                <br/>
                <Form className="p-t-15">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Номер заявки</Table.HeaderCell>
                                <Table.HeaderCell>Клиент</Table.HeaderCell>
                                <Table.HeaderCell>Общая стоимость груза без НДС</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                selectedRows.map(row => (
                                    <Table.Row key={row.id}>
                                        <Table.Cell>{row.fmid}</Table.Cell>
                                        <Table.Cell>{row.clientName}</Table.Cell>
                                        <Table.Cell className='p-0'>
                                            <input
                                                type='number'
                                                className='notifications__input'
                                                value={getCost(row.id) || ''}
                                                onChange={e => setCost(row.id, e.target.value)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Отмена</Button>
                <Button primary loading={loading} disabled={loading || !isChanged} onClick={handleSend}>Сохранить</Button>
            </Modal.Actions>
            <Confirm
                open={confirmOpen}
                cancelButton={'Нет'}
                confirmButton={'Да'}
                content="Вы уверены, что не хотите сохранить изменения?"
                onCancel={handleSend}
                onConfirm={handleClose}
            />
        </Modal>
    );
};

export default WbGridEditCostModal;