import React, {useContext, useEffect, useState} from 'react';
import {Grid, GridColumn, GridRow, Icon, Segment, Step, Tab} from 'semantic-ui-react';
import {Route, Switch} from 'react-router-dom';
import {getCompanyById} from '../../../api/companies';
import T from '../../../components/Translate';
import {LinkInfo} from '../../../layout/navbar/Navbar';
import Shield from '../../../layout/page/shield/Shield';
import {ContextNavi, ContextUser} from '../../../services/context';
import {StepData} from '../../waybills/wbView/StepInfo';
import CompanyTabHead from './companyTabs/CompanyTabHead';
import CompanyTabOApi from './companyTabs/CompanyTabOApi';
import CompanyTabProps from './companyTabs/CompanyTabProps';
import CompanyTabsCargoRestrictions from './companyTabs/CompanyTabCargoRestrictions';
import CompanyTabFieldConfig from './companyTabs/companyTabFieldConfig/CompanyTabFieldConfig';
import CompanyTabCuttoffRules from './companyTabs/CompanyTabCutoffRules';
import ErrorNotFound from '../../ErrorNotFound';
import CompanyTabAddressExceptions from "./companyTabs/CompanyTabAddressExceptions";
import CompanyTabCutoffUpdateRules from "./companyTabs/CompanyTabCutoffUpdateRules";
import CalculatingFieldsRules from "./companyTabs/CalculatingFieldsRules";
import UserPermissions from "../../../api/model/UserPermissions";
import CompanyTabDocs from "./companyTabs/CompanyTabDocs";
import CompanyTabEmailSendingDocuments from "./companyTabs/CompanyTabEmailSendingDocuments";
import RenewalOfRegistryApproval from "./companyTabs/RenewalOfRegistryApproval";
import CompanyTabHistory from "./companyTabs/CompanyTabHistory";
import {historyKeys} from "../../../api/model/Company";
import RegistryApprovalRules from "./companyTabs/RegistryApprovalRules";
import CompanyTabCutoff from "./companyTabs/CompanyTabCutoff";


export default function CompanyTabs({ match, history }) {
    const contextNavi = useContext(ContextNavi);
    const context = useContext(ContextUser);

    const user = context && context.current;

    const [data, setData] = useState();

    const ro = new ResizeObserver((entries, observer) => {
        if (entries && entries[0])
            entries[0].target.childNodes[1].childNodes[0].style.height = `calc(100vh - ${entries[0].target.childNodes[0].offsetHeight + 240}px)`;
    });

    let timerId = null;

    useEffect(() => {
        timerId = setTimeout(() => {
            const el = document.querySelector('.cutoff-tabs');
            if (el) {
                ro.observe(el);
                clearTimeout(timerId);
            }
        }, 500);
    }, [match]);

    const timeLimitsStep = new StepData(null, ["cutoffBlock", "cutoffRules", "cutoffUpdateBlock", "cutoffUpdateRules", "renewalOfRegistryApproval"], "Временные лимиты", "clock");

    const onlyTimeLimits = !user.permissions.includes(UserPermissions.COMPANIES) && user.permissions.includes(UserPermissions.TIMELIMITS);

    const steps = onlyTimeLimits ? [timeLimitsStep] : [
        new StepData(null, "props", "Свойства", "info"),
        new StepData(null, "oapi", "Open API", "feed"),
        timeLimitsStep,
        new StepData(null, "restrictions", "Огранич. вместимости", "truck"),
        new StepData(null, "field-config", "Настройка полей", "cogs"),
        new StepData(null, "address-exceptions", "Исключения по адресам", "exclamation")
    ];
    if (user.permissions.includes(UserPermissions.AUTOCOMPLETE)) {
        steps.push(new StepData(null, "calculating-fields-rules", "Правила авторасчета полей", "calculator"));
    }

    if (!onlyTimeLimits) {
        steps.push(new StepData(null, "docs-management", "Управление документами", "file alternate outline"));
        steps.push(new StepData(null, ["emailSendingDocuments"], "Управление контактами", "at"));
    }

    const tabKeyActive = match.params.tab;
    const styleNoBorderYellow = {
        paddingTop: '15px',
        borderTop: '2px solid #fbbd08',
    };

    async function fetchData() {
        const companyId = match.params.id;
        const company = await getCompanyById(companyId);
        setData(company);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            contextNavi.setItems([
                new LinkInfo("Клиенты", "/admin/companies"),
                new LinkInfo(data.name, `/admin/companies/${data.id}`),
            ]);
        }
    }, [data]);

    const cutoffPanesArray = [
        { key: 'cutoffBlock', icon: 'registered outline', content: 'Продление регистрации', tab: <CompanyTabCutoff/>},
        { key: 'cutoffRules', icon: 'registered outline', content: 'Правила регистрации', tab: <CompanyTabCuttoffRules data={data} setData={setData} />},
        { key: 'cutoffUpdateBlock', icon: 'edit outline', content: 'Продление обновления', tab: <CompanyTabCutoff isUpdate/>},
        { key: 'cutoffUpdateRules', icon: 'edit outline', content: 'Правила обновления', tab: <CompanyTabCutoffUpdateRules data={data} setData={setData} />},
        { key: 'renewalOfRegistryApproval', icon: 'check', content: 'Продление одобрения реестра', tab: <RenewalOfRegistryApproval match={match} />},
        { key: 'registryApprovalRules', icon: 'check', content: 'Правила одобрения реестра', tab: <RegistryApprovalRules match={match} />}
    ];

    const contactsPanesArray = [
        { key: 'emailSendingDocuments', icon: 'at', content: 'Отправка документов в письме', tab: <CompanyTabEmailSendingDocuments data={data} setData={setData} />},
    ];

    const historyArray = steps.map(step => {
        const index = historyKeys[Array.isArray(step.index) ? step.index[0] : step.index];
        return {
            index,
            key: 'history' + index,
            icon: step.icon,
            content: step.text,
            tab: <CompanyTabHistory id={match.params.id} name={index}/>
        };
    }).filter(s => s.index);

    historyArray.length && steps.push(new StepData(null, historyArray.map(h => h.key), "История", "history"));

    const stepBlock = (block, panesArray) => {
        const panes = panesArray.map(pane => ({
            menuItem: { key: pane.key, icon: pane.icon, content: pane.content },
            render: () => <Tab.Pane>{pane.tab}</Tab.Pane>,
        }));

        return (
            <Tab
                className='cutoff-tabs'
                menu={{ secondary: true }}
                panes={panes}
                activeIndex={panesArray.findIndex(p => p.key === block)}
                onTabChange={(e, dt) => history.push(`/admin/companies/${data.fmid}/${panesArray[dt.activeIndex].key}`)}
            />
        );
    };

    return (
        <Shield loading={!data} loadingOver={data && data.id}>
            <Grid columns={2} className="p-t-0 m-t-0 h-100">
                <GridRow stretched className="p-t-0 p-b-0 m-t-1-negative">
                    <Grid.Column className="p-b-0 p-r-0 m-r-1-negative" style={{ width: "16%" }}>
                        <Step.Group fluid vertical size='tiny' className="m-b-0 steps-grey">
                            <div className="step-wrapper">
                                {steps.map((s) => (
                                    <Step
                                        key={s.index}
                                        active={Array.isArray(s.index) ? s.index.includes(tabKeyActive) : tabKeyActive === s.index}
                                        link
                                        onClick={() => history.push(`/admin/companies/${data.fmid}/${Array.isArray(s.index) ? s.index[0] : s.index}`)}
                                    >
                                        <Icon name={s.icon} />
                                        <Step.Content>
                                            <Step.Title><T>{s.text}</T></Step.Title>
                                        </Step.Content>
                                    </Step>
                                ))}
                            </div>
                        </Step.Group>
                    </Grid.Column>
                    <Grid.Column className="editor-rightcol p-l-0 p-b-0" style={{width: "84%"}}>
                        <Segment style={{ marginRight: '25px', paddingLeft: '25px' }}>
                            <CompanyTabHead
                                data={data}
                                fetchData={fetchData}
                            />
                            <div style={{
                                overflowY: 'hidden',
                                overflowX: 'hidden',
                            }}>
                                <div style={styleNoBorderYellow} >
                                    <div className="scrollable-v m-t-10" style={{ height: 'calc(100vh - 200px)' }}>
                                        <Grid>
                                            <Grid.Row>
                                                <GridColumn width={16}>
                                                    <Switch>
                                                        <Route path='/admin/companies/:id/props' exact>
                                                            <CompanyTabProps data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/oapi' exact>
                                                            <CompanyTabOApi data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffBlock' exact>
                                                            {stepBlock('cutoffBlock', cutoffPanesArray)}
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffRules'>
                                                            {stepBlock('cutoffRules', cutoffPanesArray)}
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffUpdateBlock'>
                                                            {stepBlock('cutoffUpdateBlock', cutoffPanesArray)}
                                                        </Route>
                                                        <Route path='/admin/companies/:id/cutoffUpdateRules'>
                                                            {stepBlock('cutoffUpdateRules', cutoffPanesArray)}
                                                        </Route>
                                                        <Route path='/admin/companies/:id/renewalOfRegistryApproval'>
                                                            {stepBlock('renewalOfRegistryApproval', cutoffPanesArray)}
                                                        </Route>
                                                        <Route path='/admin/companies/:id/registryApprovalRules'>
                                                            {stepBlock('registryApprovalRules', cutoffPanesArray)}
                                                        </Route>
                                                        <Route path='/admin/companies/:id/restrictions' exact>
                                                            <CompanyTabsCargoRestrictions data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/field-config' exact>
                                                            <CompanyTabFieldConfig data={data} setData={setData} match={match} history={history} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/address-exceptions' exact>
                                                            <CompanyTabAddressExceptions data={data} setData={setData} match={match} history={history} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/calculating-fields-rules' exact>
                                                            <CalculatingFieldsRules data={data} setData={setData} match={match} history={history} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/docs-management' exact>
                                                            <CompanyTabDocs data={data} setData={setData} />
                                                        </Route>
                                                        <Route path='/admin/companies/:id/emailSendingDocuments'>
                                                            {stepBlock('emailSendingDocuments', contactsPanesArray)}
                                                        </Route>
                                                        {
                                                            (historyArray || []).map(h => (
                                                                <Route key={h.key} path={`/admin/companies/:id/${h.key}`}>
                                                                    {stepBlock(h.key, historyArray)}
                                                                </Route>
                                                            ))
                                                        }
                                                        <Route component={ErrorNotFound} />
                                                    </Switch>
                                                </GridColumn>
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </Segment>
                    </Grid.Column>
                </GridRow>
            </Grid>
        </Shield >

    );
}
