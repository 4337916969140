import O from '../components/Option';
import {get, post} from '../services/http';
import UserPermissions from './model/UserPermissions';


export const UserRolesEnum = {
    ADMIN: 1,
    CLIENT: 2,
    COORDINATOR_CS: 3,
    SPECIALIST_CS: 4,
    ADMIN_CS: 5,
    COMMERCE: 6,
    CLIENT_UPGRADE: 8
};

export async function getRoles() {
    const result = await get("roles");
    return result;
}


export async function getRolesFilter() {
    const result = await get("roles/filterParameters");
    return result;
}

export async function getCompaniesFilter() {
    const result = await get("companies/filterParameters");
    return result;
}

export async function getEmailsFilter() {
    const result = await get("emails/filterParameters");
    return result;
}

export async function getUserNamesFilter() {
    const result = await get("users/filterParameters");
    return result;
}

export async function getRoleById(id) {
    const result = await get(`roles/id/${id}`);
    return result;
}

/** @param {number} key  */
export function getPermissionName(key) {
    switch (key) {
        case UserPermissions.WAYBILLS: return "Заявки";
        case UserPermissions.COMPANY_POINTS: return "Справочник клиентов";
        case UserPermissions.WAYBILL_EDIT: return "Заявки - редактирование";
        case UserPermissions.WAYBILL_EDIT_COMPANY: return "Редактирование клиента";
		case UserPermissions.COMPANIES: return "Клиенты";
		case UserPermissions.PROVIDERS: return "Поставщики";
        case UserPermissions.POINTS: return "Справочник адреса";
        case UserPermissions.USERS: return "Пользователи";
        case UserPermissions.USERPROFILES: return "Профили";
        case UserPermissions.WAYBILL_SOFT_DELETE: return "Заявки - удаление";
        case UserPermissions.GETTING_NOTIFICATIONS: return "Получение уведомлений о проблемах";
        case UserPermissions.FMID_ADDRESS: return "Название адреса в системе FM";
        case UserPermissions.REGISTRIES: return "Реестры";
        case UserPermissions.COMPLETION_REGISTRIES: return "Согласование реестра";
        case UserPermissions.BANNERS: return "Управление баннерами";
        case UserPermissions.BANNER_NOTIFICATIONS: return "Получение уведомлений по баннерам";
        case UserPermissions.REGISTRIES_NOTIFICATIONS: return "Получение уведомлений по реестрам";
        case UserPermissions.REGISTRIES_DELETE: return "Удаление реестров";
        case UserPermissions.AUTOCOMPLETE: return "Правила авторасчета полей";
        case UserPermissions.STANDARD_AUTOCOMPLETE: return "Стандартные правила авторасчета полей";
        case UserPermissions.SCHEDULES: return "Графики";
        case UserPermissions.SCHEDULES_EDIT: return "Графики - редактирование";
        case UserPermissions.SCHEDULES_SETTINGS_VIEAW: return "Настройка графиков - просмотр";
        case UserPermissions.RETURN_WAYBILLS_EDIT: return "Редактирование Return заявки";
        case UserPermissions.DISALLOW_AVISATION: return "Отключение авизации";
        case UserPermissions.MAPPING_CODE: return "Код для маппинга";
        case UserPermissions.REGISTRIES_EDIT_PERIOD: return "Редактирование периода реестра";
        case UserPermissions.MANGING_NOTIFICATIONS: return "Управление уведомлениями";
        case UserPermissions.DICTIONARIES_EDIT: return "Справочники";
        case UserPermissions.TIMELIMITS: return "Временные лимиты";
        case UserPermissions.AUCTIONS: return "Аукционы";
        case UserPermissions.AUCTIONS_EDIT: return "Аукционы - редактирование";
        case UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE: return "Управление формами импорта и правилами";
        case UserPermissions.IMPORT_FORMS_AND_RULES_VIEW: return "Просмотр форм импорта и правил";
        default: return key;
    }
}

export function getPermissionOptions() {
    const permissionOptions = Object.keys(UserPermissions)
        .map(k => new O(UserPermissions[k], getPermissionName(UserPermissions[k])));

    return permissionOptions;
}

export async function postRole(dto) {

	return dto.id
		? await post(`roles/${dto.id}/update`, dto)
		: await post(`roles/create`, dto);
}

export async function delRole(id) {

	throw new Error("not implemented");
}

