import React, {useState} from 'react';
import {Button, Icon, Message, Modal, Table} from "semantic-ui-react";
import moment from 'moment';
import AddModalComponent from "./AddModalComponent";
import T from "../../components/Translate";
import {fieldCompareOptions, fieldsTypeEnum} from "../../api/calculatingFieldsRules";
import {parseDateTime} from "../../components/inputs/DateTime";

const TableComponent = ({companyId, rules, getRules, deleteRule, copyRule, getRuleById, createRule, editRule, isHeaderFixed}) => {

    let [isNew, setIsNew] = useState(false);

    let [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState({
        open: false
    });

    let [deleteProgress, setDeleteProgress] = useState(false);


    const confirmDelete = async () => {
        setDeleteProgress(true);
        try {
            await deleteRule(isDeleteConfirmOpen.id, companyId);
            getRules();
            setIsDeleteConfirmOpen({open: false})
        } finally {
            setDeleteProgress(false);
        }
    };

    const handleDelete = (id) => {
        setIsDeleteConfirmOpen({
            open: true,
            id
        });
    };

    const handleCopy = async (id) => {

        const result = await copyRule(id, companyId);

        getRules();

        setIsNew(result.id);

        setTimeout(() => setIsNew(false), 2000);
    };

    const highlight = (row, index) => {
        const res1IsIndexRows = row.searchResult && row.searchResult.filter(r => /^\d+$/.test(r[1]));
        const res1IsIndexRow = index !== undefined && res1IsIndexRows && res1IsIndexRows.find(r => r[1] === index.toString());
        return ((res1IsIndexRows && res1IsIndexRows.length && index !== undefined) ? res1IsIndexRow : row.searchResult) ? 'registry_cell_orange' : '';
    };

    return (
        <>
            <Table celled structured singleLine>
                <Table.Header className={isHeaderFixed && "table-header-fixed"}>
                    <Table.Row>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}><T>Заполняемое поле</T></Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}><T>Условие по полю</T></Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}><T>Тип условия</T></Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}><T>Целевое значение</T></Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={3}><T>Значение</T></Table.HeaderCell>
                        <Table.HeaderCell className={isHeaderFixed && "table-select-cell table-header-fixed__title"}
                                          width={1} textAlign="center"><T>Действия</T></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        rules.map((row, indexRow) => {
                            const valueIsDate = row.targetField.type === fieldsTypeEnum.Date;
                            const valueIsDateTime = row.targetField.type === fieldsTypeEnum.DateTime;
                            const v = valueIsDate ? moment(row.targetField.value).format('DD.MM.YYYY') : (valueIsDateTime ? moment(parseDateTime(row.targetField.value)).format("YYYY-MM-DD HH:mm") : row.targetField.value);

                            const rulesValuesIsDate = row.rules[0].fieldType === fieldsTypeEnum.Date;
                            const rulesValuesIsDateTime = row.rules[0].fieldType === fieldsTypeEnum.DateTime;
                            const rv = rulesValuesIsDate ? moment(row.rules[0].values.join(', ')).format('DD.MM.YYYY') : (rulesValuesIsDateTime ? moment(parseDateTime(row.rules[0].values.join(', '))).format("YYYY-MM-DD HH:mm") : row.rules[0].values.join(', '))
                            return (
                                <React.Fragment key={row.id}>
                                    {
                                        row.rules[0] &&
                                        <Table.Row className={`${isNew === row.id ? 'registry_cell_highlight' : ''}`}>
                                            <Table.Cell
                                                className={highlight(row)}
                                                rowSpan={row.rules.length}
                                            >
                                                {row.targetField.name}
                                            </Table.Cell>
                                            <Table.Cell
                                                className={highlight(row, 0)}
                                            >
                                                {row.rules[0].name}
                                            </Table.Cell>
                                            <Table.Cell
                                                className={highlight(row, 0)}
                                                textAlign="center">{fieldCompareOptions.find(option => option.value === row.rules[0].type).text}</Table.Cell>
                                            <Table.Cell
                                                className={highlight(row, 0)}
                                                textAlign="center"
                                            >
                                                <div style={{
                                                    width: '100px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}><span title={rv}>{rv}</span>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell
                                                className={highlight(row)}
                                                textAlign="center"
                                                rowSpan={row.rules.length}
                                            >
                                                {v}</Table.Cell>
                                            <Table.Cell
                                                className={highlight(row)}
                                                rowSpan={row.rules.length}
                                            >
                                                <div className="table-rules-action">
                                                    <AddModalComponent indexRule={row.index} id={row.id}
                                                                       getRuleById={getRuleById}
                                                                       createRule={createRule}
                                                                       editRule={editRule}
                                                                       companyId={companyId} fetchData={getRules}>
                                                        <Icon
                                                            name="pencil alternate"
                                                            link
                                                            className="m-l-15"
                                                            title="Редактировать"
                                                            size="large"
                                                            color="blue"
                                                            onClick={() => {
                                                            }}
                                                        />
                                                    </AddModalComponent>
                                                    <Icon
                                                        name="copy outline"
                                                        link
                                                        className="m-l-15"
                                                        title="Копировать"
                                                        size="large"
                                                        color="grey"
                                                        onClick={() => handleCopy(row.id)}
                                                    />
                                                    <Icon
                                                        name="trash alternate"
                                                        link
                                                        className="m-l-15"
                                                        title="Удалить"
                                                        size="large"
                                                        color="red"
                                                        onClick={() => handleDelete(row.id)}
                                                    />
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    }
                                    {
                                        row.rules.map((extRow, index) => (
                                            <React.Fragment key={extRow.name}>
                                                {index !== 0 && (
                                                    <Table.Row
                                                        className={`${highlight(row, index)} ${isNew === row.id ? 'registry_cell_highlight' : ''}`}>
                                                        <Table.Cell>{extRow.name}</Table.Cell>
                                                        <Table.Cell
                                                            textAlign="center">{fieldCompareOptions.find(option => option.value === extRow.type).text}</Table.Cell>
                                                        <Table.Cell
                                                            textAlign="center">
                                                            <div style={{
                                                                width: '100px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}>
                                                            <span
                                                                title={extRow.fieldType === fieldsTypeEnum.Date ? moment(extRow.values.join(', ')).format('DD.MM.YYYY') : extRow.values.join(', ')}>
                                                                {extRow.fieldType === fieldsTypeEnum.Date ? moment(extRow.values.join(', ')).format('DD.MM.YYYY') : extRow.values.join(', ')}
                                                            </span>
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </React.Fragment>
                                        ))
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                </Table.Body>
            </Table>
            <Modal open={isDeleteConfirmOpen.open}>
                <Modal.Header>Удаление правила</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={<div>
                            <div>Выбранное вами правило будет удалено.</div>
                            <div>Чтобы подтвердить действие нажмите на кнопку "Удалить", если не хотите подтверждать
                                действие, то нажмите на кнопку "Отмена".
                            </div>
                        </div>}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setIsDeleteConfirmOpen({open: false})}
                    >
                        Отмена
                    </Button>
                    <Button
                        color="green"
                        loading={deleteProgress}
                        onClick={confirmDelete}
                    >
                        Удалить
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
};

export default TableComponent;
