import React, {useContext, useEffect, useState} from 'react';
import './style.css';
import {Button, Icon, Message} from 'semantic-ui-react';
import {getReservationApi} from '../../../api/waybillAviz';
import WbAvisModal from './WbAvisModal';
import moment from 'moment';
import {LoadCapacityOptions} from '../../../api/model/Point';
import Shield from '../../../layout/page/shield/Shield';
import WbAvisTable from './WbAvisTable';
import WbAddModal from './WbAddModal';
import WbAvizModal from '../wbGrid/WbAvizModal';
import {ContextWb} from '../../../services/context';
import useReactRouter from 'use-react-router';
import WbEditAvisTimeslot from "./WbEditAvisTimeslot";
import {WaybillStatusEnum} from "../../../api/model/Waybill";

const WbViewAvisation = ({ wb, id }) => {
    let [form, setForm] = useState({
        timeslot: {},
        transport: {},
        units: {},
        waybills: [],
    });

    const { history } = useReactRouter();

    const isDisabled = wb.status === WaybillStatusEnum.DOCS_SENT;
    const isArchived = wb.isArchive;
    const contextWb = useContext(ContextWb);
    let [isEditTimeslot, setIsEditTimeslot] = useState(false);
    let [editTimeslotData, setEditTimeslotData] = useState(null);
    let [typeForm, setTypeForm] = useState(null);
    let [loading, setLoading] = useState(false);
    let [isOpenAdd, setIsOpenAdd] = useState(false);

    let [isOpenAvisModal, setIsOpenAvisModal] = useState(false);
    let [waybillsWithVisit, setWaybillsWithVisit] = useState([]);

    useEffect(() => {
        getReservation();
    }, []);

    const getReservation = async id => {
        if (id || wb.reservationId) {
            setLoading(true);
            const result = await getReservationApi(id || wb.reservationId);
            setForm(result);

            contextWb.setWb(wb => ({
                ...wb,
                canCancelReservation: result.canCancel,
            }));

            setLoading(false);
        }
    };

    const handleClickEditTimeslot = type => {
        setIsEditTimeslot(true);
        setTypeForm(type);
    };

    const handleCloseEditTimeslot = () => {
        setIsEditTimeslot(false);
        setTypeForm(null);
    };

    const add = () => {
        setIsOpenAdd(true);
    };

    const onCloseAdd = () => {
        setIsOpenAdd(false);
        getReservation();
    };

    const onCloseAvisModal = () => {
        setIsOpenAvisModal(false);
    };

    const onAvisation = async () => {
        onCloseAvisModal();
        setWaybillsWithVisit([]);

        const wb = await contextWb.fetchWb();
        await getReservation(wb.reservationId);
    };

    const crossDockFmId = waybill => {
        const fmIds = Array.from(new Set(waybill.pointsLoading.filter(pl => pl.crossDockWithActivity)
            .map(item => item.pointFMID)));
        return fmIds[0];
    }

    return (
        <Shield loading={loading} loadingOver={loading}>
            <div className="view-avisation">
                <div className="view-avisation_blok-edit">
                    {!isDisabled && <Icon
                        name="pencil alternate"
                        color="blue"
                        className="view-avisation_edit-btn"
                        onClick={() => handleClickEditTimeslot('timeslot')}
                    />}
                    <div className="m-b-5">{`Дата привоза: ${
                        form.timeslot.deliveryDate
                            ? moment(form.timeslot.deliveryDate).format('DD.MM.YYYY')
                            : ''
                    }`}</div>
                    <div>{`Тайм слот: ${
                        form.timeslot.start ? `${form.timeslot.start} - ${form.timeslot.end}` : ''
                    }`}</div>
                </div>
                <div className="view-avisation_blok-edit">
                    {!isDisabled && <Icon
                        name="pencil alternate"
                        color="blue"
                        className="view-avisation_edit-btn"
                        onClick={() => handleClickEditTimeslot('transport')}
                    />}
                    <div className="m-b-5">{`Тип ТС: ${
                        form.transport.weightCapacity
                            ? LoadCapacityOptions.find(
                                  item => item.value === form.transport.weightCapacity,
                              ) &&
                              LoadCapacityOptions.find(
                                  item => item.value === form.transport.weightCapacity,
                              ).text
                            : ''
                    }`}</div>
                    <div className="m-b-5">{`Водитель: ${
                        form.transport.driver ? form.transport.driver : ''
                    }`}</div>
                    <div className="m-b-5">{`Телефон водителя: ${
                        form.transport.driverPhoneNumber ? form.transport.driverPhoneNumber : ''
                    }`}</div>
                    <div>{`Номер ТС: ${
                        form.transport.transportNumber ? form.transport.transportNumber : ''
                    }`}</div>
                </div>
                <div className="view-avisation_blok-edit">
                    <div className="m-b-5">{`Общее кол-во грузовых единиц: ${
                        form.units.count ? form.units.count : ''
                    }`}</div>
                    <div className="m-b-5">{`Общее кол-во паллет: ${
                        form.units.epCount ? form.units.epCount : ''
                    }`}</div>
                    <div>{`Общее кол-во коробов: ${
                        form.units.bxCount ? form.units.bxCount : ''
                    }`}</div>
                </div>
                <WbAvisModal
                    open={isEditTimeslot}
                    type={typeForm}
                    wb={wb}
                    data={form}
                    loadWb={contextWb.fetchWb}
                    loadForm={getReservation}
                    onClose={handleCloseEditTimeslot}
                />
            </div>
            <div className="view-avisation_info">
                <Message
                    warning
                    icon="question"
                    content={'Вы можете управлять заявками внутри данной брони.'.t}
                />
                {
                    form.canAddWaybill && !isDisabled && !isArchived && (
                        <Button onClick={add}>
                            <Icon name="add" />
                            Добавить заявку в эту бронь
                        </Button>
                    )
                }
            </div>
            <WbAvisTable
                isArchived={isArchived}
                items={form.waybills}
                id={id}
                reservationId={wb.reservationId}
            />
            <WbAddModal
                reservationId={wb.reservationId}
                loadingPointIdFm={crossDockFmId(wb)}
                setWaybillsWithVisit={setWaybillsWithVisit}
                setIsOpenAvisModal={setIsOpenAvisModal}
                setEditTimeslotData={setEditTimeslotData}
                open={isOpenAdd}
                onClose={onCloseAdd}
            />
            <WbAvizModal
                open={isOpenAvisModal}
                onClose={onCloseAvisModal}
                selectedRows={waybillsWithVisit}
                optionsTypeTransport={LoadCapacityOptions}
                onAvisation={onAvisation}
            />
            <WbEditAvisTimeslot
                onClose={()=>setEditTimeslotData(null)}
                editTimeslotData={editTimeslotData}
            />
        </Shield>
    );
};

export default WbViewAvisation;
