import O from '../components/Option';
import {get, post, upload} from '../services/http';
import Point from './model/Point';
import qs from "query-string";
import {trimStrings} from "../services/utils"; // eslint-disable-line

export class Od extends O {
    constructor(id, text, description) {
        super(id, text);
        this.description = description;
    }
}

export class Oc extends O {
    constructor(id, text, content) {
        super(id, text);
        this.content = content;
    }
}

export async function getPoints(search = '') {
    const result = await get(`points${search}`);
    return result;
}

export async function getConsigneesFromPoints(search = '') {
    return await get(`companies/search/reciever/${search}/options`); //todo указать адрес
}

export async function getPointsFromSchedule() {
    const result = await get(`points/by/type/fm`);
    return result;
}

export async function getPointById(id) {
    const result = await get(`point/id/${id}`);
    return new Point(result);
}

export async function getPointOptionById(id) {
    const result = await get(`point/id/${id}/options`);
    return result;
}

export async function getPointsBySearch(term, companyName) {
    const result = await get(`points/search?term=${term}&companyId=${companyName}`);
    return result.map(p => new Point(p));
}

export async function getPointsBy(searchKey, searchVal, search = '') {
    const result = await get(`points/by/${searchKey}/${searchVal}${search}`);
    return result;
}

export async function getSimilarPoint(search = '') {
    const result = await get(`points/similar/${search}`);
    return result;
}

export async function getPointsByCompanyFMID(companyName) {
    const result = await get(`points/company/${companyName}`);
    return result.map(p => new Point(p));
}

export async function getPointsRecent(companyName, wbPointId) {
    const result = await get(`points/recent/${companyName}${wbPointId ? '?pointId=' + wbPointId : ''}`);
    return result.map(p => new Point(p));
}

export async function pointRegister(id, dto) {
    const result = await post(`point/register/${id}`, dto);
    return result;
}

export async function pointReject(id, dto) {
    const result = await post(`point/${id}/reject`, dto);
    return result;
}

export async function pointCorrect(id, dto) {
    const result = await post(`point/${id}/correct`, dto);
    return result;
}

export async function pointApprove(id, dto) {
    const result = await post(`point/${id}/approve`, dto);
    return result;
}

export async function pointReplace(id, dto) {
    const result = await post(`point/${id}/replace`, dto);
    return result;
}

export async function pointDelete(id) {
    try {
        return await get(`point/${id}/delete`);
    } catch (error) {
        return false;
    }
}


export async function pointSetLocked(id, data) {
    try {
        return await post(`points/${id}/setLocked`, data);
    } catch (error) {
        return false;
    }
}

export async function pointUpdate(dto) {
    let result = null;
    let updateUrl =`point/update/${dto.id}`;
    try {
        result = await post(updateUrl, dto);
    } catch (error) {

    }
    return result;
}

export async function pointAddressPropUpdate(pointId, propName, propValue) {
    const updateUrl = `point/${pointId}/update/${propName}`;
    const result = await post(updateUrl, { value: propValue });
    return result;
}

/** @param {Point} dto */
export async function pointCreateAccepted(dto, exportOnSave) {
    let url = "point/create/accepted";
    if (exportOnSave) {
        url = "point/create/register";
    }
    try {
        return await post(url, trimStrings(dto, ['fmid']));
    } catch (error) {
        return null;
    }
}

/** @param {Point} dto */
export async function pointCreate(dto, exportOnSave) {

    let url = "point/create";
    try {
        return await post(url, dto);
    } catch (error) {
        return null;
    }
}

export async function pointsActivate(ids) {
    const result = await post(`point/activate`, ids);
    return result;
}

export async function pointsExportCount() {
    const result = await get(`point/export/count`);
    return result;
}

export async function pointsExportNext(count = 1) {
    const result = await get(`point/export/next/${count}`);
    return result;
}

export async function pointsExport(id) {
    const result = await get(`point/export/${id}`);
    return result;
}

export async function pointsExportReport(id) {
    const result = await get(`point/export/${id}/report`);
    return result;
}

export async function pointsImport(formData, autoexport = false) {
    var result = await upload(`point/import/excel?autoexport=${autoexport}`, formData);
    return result;
}

export async function pointsImportMyPoints(formData, autoexport = false) {
    var result = await upload(`point/import/client/mappingcode`, formData);
    return result;
}

export async function pointsImportClient(formData, autoexport = false, company) {
    var result = await upload(`point/import/client/excel?company=${company}`, formData);
    return result;
}

export async function pointCommentCreate(pointId, text) {
    var result = await post(`point/${pointId}/comment`, {text});
    return result;
}

export async function pointResend(pointId) {
    var result = await get(`point/${pointId}/resend`);
    return result;
}

export async function pointsExportExcel(query = "", selectedPoints) {
    var result = await post(`points/export/excel${query}`, selectedPoints);
    return result;
}

export async function pointsExportJson(query = '', selectedPoints) {
    var result = await post(`points/export/json`, selectedPoints);
    return result;
}

export async function pointsExportExcelClient(query = '', selectedPoints, selectedExport =[]) {
    const newFormatSelectedExport = selectedExport.length !== 0 ? selectedExport.map(i => i.forExport) : selectedPoints;
    const result = await post(`points/exportclient/excel${query}`, newFormatSelectedExport);
    return result;
}

export async function hasPointForExport(query = '') {
    var result = await get(`points/hasexport${query}`);
    return result;
}

export async function hasPointForExportClient(query = '') {
    var result = await get(`points/hasexport/client${query}`);
    return result;
}


export async function getPointOptionsFmidBySearch(fmid, companyName) {
    const result = await get(`points/search/fmid/${fmid}/${companyName}`);

    return result;
}

export async function pointCustomCreate(form) {
    return await post(`point/create/auction`, form);
}


export async function pointGetSimilar(form) {
    return await get(`point/getSimilar/auction?${qs.stringify(form)}`);
}

export const dadataSearchAddress = (xhr, requestPayload, setState, callback) => {
    const token = "0fb6ae1c0c887e551252998350e4447074ab0af0";
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5";

    xhr.open("POST", url);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Authorization", `Token ${token}`);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(JSON.stringify(requestPayload));

    xhr.onreadystatechange = () => {
        if (!xhr || xhr.readyState != 4) { // eslint-disable-line
            return;
        }

        if (xhr.status == 200) { // eslint-disable-line
            const responseJson = JSON.parse(xhr.response);

            if (setState && responseJson && responseJson.suggestions) {
                setState({ suggestions: responseJson.suggestions, suggestionIndex: -1 });
            }

            // is payload is custom -> response is returned
            if (callback) {
                callback(responseJson && responseJson.suggestions);
            }
        }
    };
};