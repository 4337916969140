export default result => {
    const { data, headers: headerLine = '' } = result;
    //let headerLine = headers['content-disposition'];
    let startFileNameIndex = headerLine.indexOf('filename=') + 9;
    let endFileNameIndex = headerLine.lastIndexOf(';');
    let name = headerLine.match(/filename[^;\n]*=(UTF-\d['"]*)((['"]).*?[.]$\2|[^;\n]*)?/);
    let filename = name ? decodeURI(name[0].replace("filename*=UTF-8''", '')) : headerLine.substring(startFileNameIndex, endFileNameIndex);
    if (filename[filename.length-1] === '"') filename = filename.slice(1, -1);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([data], { type: data.type }));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}
