import React from 'react';
import HeaderMessage from "./HeaderMessage";

const ImportTaskHeader = ({disabled, formFileName, fileIsBlocked}) => {
    return <div className="import-form-edit__name-block">
        <div className="import-form-edit__file-name">
            Файл "{formFileName}"
        </div>
        {disabled && <HeaderMessage
            text={`Страница доступна только для просмотра${fileIsBlocked ? ', так как происходит обработка файла системой' : ''}`}
        />}
    </div>;
};

export default ImportTaskHeader;