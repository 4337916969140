import React from 'react';
import T from "../../../components/Translate";
import {Button, Dimmer, Form, Grid, GridColumn, Loader, Message, Modal} from "semantic-ui-react";
import {PackTypeEnum, TypeAssemblyOptions} from "../../../api/model/Enums";
import InputDropdown from "../../../components/inputs/InputDropdown";
import WbAvizAvailableTimeslot from "./WbAvizAvailableTimeslot";
import {waybillAvisationRegistration, waybillAvisationSet} from "../../../api/waybills";
import moment from "moment";
import {LoadCapacityOptions} from "../../../api/model/Point";
import PhoneNumber from "../../../components/inputs/PhoneNumber";

export default class WbAvizModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.setInitial(props);
    }


    setInitial = (props) => {
        let countPallet = 0;
        let countBox = 0;
        let textCountPallet = '';

        props.selectedRows.forEach(value => {
            const {totalUnits} = value;
            for (let variable in totalUnits) {
                if (variable === PackTypeEnum.BX || variable === PackTypeEnum.LB) {
                    countBox = countBox + totalUnits[variable]
                } else if (variable !== PackTypeEnum.PP) {
                    countPallet = countPallet + totalUnits[variable]
                }
            }

        });
        if (countBox > 0) {
            textCountPallet = this.getLabelCountPallet(countBox);
        }
        return {
            dto: {
                typeAssembly: 1,
                countPallet: null,
                typeTransport: null,
                driver: null,
                transportNumber: null,
                dateTimeAvisation: {date: this.props.selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]}
            },
            step: 0,
            errors: [],
            countPallet,
            countBox,
            textCountPallet,
            loading: false
        }
    };


    componentDidMount() {
        this.setState({
            loadingDateMin: this.props.selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0],
            deliveryDateMin: this.props.selectedRows.length > 1 ? this.props.selectedRows.map(i => new Date(i.deliveryDate)).sort((a, b) => a - b)[0] : this.props.selectedRows.map(i => new Date(i.deliveryDate))[0]
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {dto: dtoPrevState} = prevState;
        const {selectedRows} = this.props;
        const {selectedRows: selectedRowsPrevProps} = prevProps;
        if (!dtoPrevState.typeTransport && this.state.dto.typeTransport ||
            !dtoPrevState.dateTimeAvisation.timeslot && this.state.dto.dateTimeAvisation.timeslot ||
            dtoPrevState.dateTimeAvisation.date && dtoPrevState.dateTimeAvisation.date !== this.state.dto.dateTimeAvisation.date && moment(this.state.dto.dateTimeAvisation.date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
            this.setState({
                errors: []
            })
        }
        if (selectedRowsPrevProps !==  this.props.selectedRows) {
            let countPallet = 0;
            let countBox = 0;
            let textCountPallet = '';
            selectedRows.forEach(value => {
                const {totalUnits} = value;
                for (let variable in totalUnits) {
                    if (variable === PackTypeEnum.BX || variable === PackTypeEnum.LB) {
                        countBox = countBox + totalUnits[variable]
                    } else if (variable !== PackTypeEnum.PP) {
                        countPallet = countPallet + totalUnits[variable]
                    }
                }

            });

            if (countBox > 0) {
                textCountPallet = this.getLabelCountPallet(countBox);
            }

            let newDto =  {...this.state.dto};
            newDto.dateTimeAvisation = {date: selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]}
            this.setState({
                deliveryDateMin: this.props.selectedRows.length > 1 ? this.props.selectedRows.map(i => new Date(i.deliveryDate)).sort((a, b) => a - b)[0] : this.props.selectedRows.map(i => new Date(i.deliveryDate))[0],
                countPallet,
                countBox,
                textCountPallet,
                dto:newDto,
                loadingDateMin: selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0],
            })
        }
    }

    async handleOpen() {
    }

    handleClose() {
        const {onClose} = this.props;
        this.setState(this.setInitial(this.props));
        onClose && onClose();
    }

    getInfoCount() {
        const {countBox, countPallet} = this.state;
        const {selectedRows} = this.props;
        const numbers = selectedRows.map(item => item.id);

        return (
            <div>
                <T>Ваши заявки</T> {numbers.join(', ')} <T>содержат</T>:
                {countBox > 0 && <div>{countBox} <T>короба</T></div>}
                {countPallet > 0 && <div>{countPallet} <T>паллет</T></div>}
            </div>)
    }


    getInfoAvisation() {
        const {countBox, countPallet, loadingDateMin} = this.state;
        const {selectedRows} = this.props;

        return (
            <div>
                <T>Выберите доступный таймслот для</T>:
                {countBox > 0 && <span> {countBox} <T>короба</T></span>}
                {countPallet > 0 && countBox > 0 && <span> <T>и</T></span>}
                {countPallet > 0 && <span> {countPallet} <T>паллет</T></span>}
                {<span> <T>на</T></span>}
                {<span> {new Date(loadingDateMin).toLocaleDateString()}. </span>}
                {<span><T>Выберите подходящий день, и дата прибытия изменится у</T> </span>}
                {<span>{selectedRows.length} </span>}
                {<span>{selectedRows.length > 1 ? <T>заявок</T> : <T>заявки</T>}</span>}:
                {selectedRows.map((i, index) => (
                    <div key={index}>{i.id} : {new Date(i.loadingDate).toLocaleDateString()}</div>
                ))}
            </div>)
    }

    handleOnChangeDto(param, value) {
        const {dto} = this.state;
        let newDto = {...dto};
        newDto[param] = value;
        this.setState({
            dto: newDto,
            errors: []
        })
    }

    onChangeDtoTimeSlot(value) {
        const {dto} = this.state;
        let newDto = {...dto};
		newDto.dateTimeAvisation = value;
        this.setState({
            dto: newDto
        });
        if (
            moment(newDto.dateTimeAvisation.date).format('YYYY-MM-DD') <
            moment().format('YYYY-MM-DD')
        ) {
            this.setState(prevState => {
                const { errors: prevErrors } = prevState;
                if (prevErrors.includes('dateInvalid')) {
                    return { errors: prevErrors };
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('dateInvalid');
                return { errors: errorsNew };
            });
        } else {
            this.setState({
                errors: []
            })
        }
    }

    async onClickChooseTimeAvisation() {
        const {dto, countBox, loadingDateMin} = this.state;
        if (!dto.typeTransport) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('typeTransport')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('typeTransport');
                return {errors: errorsNew};
            })
        }
        if (dto.typeAssembly === 2 && countBox > 0 && !dto.countPallet) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('countPallet')) {
                    return {errors: prevErrors}
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('countPallet')
                return {errors: errorsNew}
            })
        }
        const param = {
            date: `${moment(loadingDateMin).format('YYYY-MM-DD')}`,
            waybillIds: this.props.selectedRows.map(i => i.id),
            weightCapacity: dto.typeTransport,
            driver: dto.driver,
            transportNumber: dto.transportNumber,
            driverPhoneNumber: dto.driverPhoneNumber,
            bXTypeLaying: {
                EPCount: dto.countPallet || 0,
                BXPalletizing: dto.typeAssembly === 2
            }
        };
        this.setState({
            loading: true
        });
        await waybillAvisationRegistration(param).then(res=>{
            if (res && res.errors) {
                return;
            }
            this.setState({
                loading: false,
                step: 1
            });
        }).catch(() => {
            this.handleClose();
            this.props.onAvisation && this.props.onAvisation();
        });

    }

    async onClickAvisation() {
        const {dto: {dateTimeAvisation}} = this.state;
        const {selectedRows, onAvisation} = this.props;
        if (!dateTimeAvisation.date) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('date')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('date');
                return {errors: errorsNew};
            })
        }
        if (moment(dateTimeAvisation.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('dateInvalid')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('dateInvalid');
                return {errors: errorsNew};
            })
        }
        if (!dateTimeAvisation.timeslot) {
            return this.setState((prevState) => {
                const {errors: prevErrors} = prevState;
                if (prevErrors.includes('timeslot')) {
                    return {errors: prevErrors};
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('timeslot');
                return {errors: errorsNew};
            })
        }

        if (this.state.errors.length > 0) {
            return
        }
        const params = {
            waybillIds: selectedRows.map(i => i.id),
            timeslot: {...dateTimeAvisation.timeslot, date: `${moment(dateTimeAvisation.date).format('YYYY-MM-DD')}`}
        };
        this.setState({
            loading: true
        });
        const res = await waybillAvisationSet(params);
        this.setState({
            loading: false
        });
        if (res && res.errors) {
            return;
        }
        this.handleClose();
        onAvisation && onAvisation();
    }

    getLabelCountPallet(countBox) {
        return  `Количество паллет для ${countBox} коробов`
    }

    render() {
        const {selectedRows, optionsTypeTransport} = this.props;
        const {dto, countBox, errors, loadingDateMin, step, textCountPallet, loading} = this.state;

        return (
            <Modal
                trigger={this.props.children}
                closeOnDimmerClick={false}
                size="small"
                dimmer="inverted"
                closeIcon
                open={this.props.open}
                onOpen={this.handleOpen.bind(this)}
                onClose={this.handleClose.bind(this)}

            >
                <Modal.Header><T>Авизация для</T> {selectedRows.length} {selectedRows.length > 1 ? <T>заявок</T> :
                    <T>заявки</T>}</Modal.Header>
                <Modal.Content>
                    <div className="m-b-10">
                        <Dimmer active={loading} inverted>
                            <Loader inverted/>
                        </Dimmer>
                        {step === 1 &&
                        <>
                            <Message compact info>
                                {this.getInfoAvisation()}
                            </Message>
                            <WbAvizAvailableTimeslot
                                typeTransport={LoadCapacityOptions.find(l=>l.key===dto.typeTransport)}
                                dateArrival={dto.dateTimeAvisation.date || loadingDateMin}
                                deliveryDateMin={this.state.deliveryDateMin}
                                waybillIds={selectedRows.map(i => i.id)}
                                onChange={this.onChangeDtoTimeSlot.bind(this)}
                                errors={errors}
                            />
                        </>
                        }
                        {step === 0 && <>
                            <Message compact info>
                                {this.getInfoCount()}
                            </Message>
                            <Form>
                                <Grid columns='equal'>
                                    {countBox > 0 &&
                                    <Grid.Row>
                                        <GridColumn>
                                            <Form.Field
                                                control={InputDropdown}
                                                label={'Тип сборки коробов'.t}
                                                options={TypeAssemblyOptions}
                                                placeholder={'Тип сборки коробов'.t}
                                                value={dto.typeAssembly}
                                                onChange={(value) => this.handleOnChangeDto('typeAssembly', value)}>
                                            </Form.Field>
                                        </GridColumn>
                                        {dto.typeAssembly === 2 && <GridColumn>
                                            <Form.Input
                                                error={errors.includes('countPallet')}
                                                required={dto.typeAssembly === 2}
                                                label={textCountPallet}
                                                placeholder={textCountPallet}
                                                value={dto.countPallet || ''}
                                                onChange={e => this.handleOnChangeDto('countPallet', Number.parseInt(e.target.value.replace(/[^\d,.]/g, '')))}
                                            />

                                        </GridColumn>
                                        }
                                    </Grid.Row>
                                    }
                                    <Grid.Row>
                                        <GridColumn>
                                            <Form.Field
                                                control={InputDropdown}
                                                required
                                                fluid
                                                error={errors.includes('typeTransport')}
                                                clearable
                                                label={'Тип транспорта'.t}
                                                options={optionsTypeTransport}
                                                placeholder={'Тип транспорта'.t}
                                                value={dto.typeTransport}
                                                onChange={(value) => this.handleOnChangeDto('typeTransport', value)}>
                                            </Form.Field>
                                        </GridColumn>
                                       {/* <GridColumn>
                                            {false && <Segment>
                                                <div><Header as='h4'><T>Водитель</T> <T>и</T> <T>ТС</T></Header></div>
                                                <Form.Input
                                                    readOnly
                                                    label={'ФИО'.t}
                                                    placeholder={'ФИО'.t}
                                                    value={dto.driver.fullName || ''}
                                                />
                                                <Form.Input
                                                    readOnly
                                                    label={'Телефон'.t}
                                                    placeholder={'Телефон'.t}
                                                    value={dto.driver.phoneNumber || ''}
                                                />
                                                <Form.Input
                                                    readOnly
                                                    label={'Марка ТС'.t}
                                                    placeholder={'Марка ТС'.t}
                                                    value={dto.driver.markTransport || ''}
                                                />
                                                <Form.Input
                                                    readOnly
                                                    label={'Номер ТС'.t}
                                                    placeholder={'Номер ТС'.t}
                                                    value={dto.driver.numberTransport}
                                                />
                                            </Segment>
                                            }
                                        </GridColumn>*/}
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label={'Номер ТС'.t}
                                                placeholder={'Номер ТС'.t}
                                                value={dto.transportNumber}
                                                onChange={(e, {value}) => this.handleOnChangeDto('transportNumber', value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label={'Водитель'.t}
                                                placeholder={'Водитель'.t}
                                                value={dto.driver}
                                                onChange={(e, {value}) => this.handleOnChangeDto('driver', value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <PhoneNumber
                                                label={'Телефон водителя'.t}
                                                placeholder={'Телефон водителя'.t}
                                                value={dto.driverPhoneNumber}
                                                onChange={(e, { value }) => this.handleOnChangeDto('driverPhoneNumber', value)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </>}
                    </div>
                    {errors.includes('typeTransport') && <Message warning>
                        <p><T>Заполните тип транспорта</T></p>
                    </Message>
                    }
                    {errors.includes('countPallet') && <Message warning>
                        <p><T>Заполните количество паллет для коробов</T></p>
                    </Message>
                    }
                    {errors.includes('timeslot') && <Message warning>
                        <p><T>Заполните таймслот</T></p>
                    </Message>
                    }
                    {errors.includes('date') && <Message warning>
                        <p><T>Заполните дату привоза</T></p>
                    </Message>
                    }
                    {errors.includes('dateInvalid') && <Message warning>
                        <p><T>Невозможно авизовать заявка, так как дата привоза на ФМ в загрузке меньше текущей</T></p>
                    </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    {step === 0 &&
                    <Button color='yellow' onClick={this.onClickChooseTimeAvisation.bind(this)}><T>Выбрать время
                        авизации</T></Button>}
                    {step === 1 &&
                    <Button color='yellow' onClick={this.onClickAvisation.bind(this)}><T>Авизовать</T></Button>}
                </Modal.Actions>
            </Modal>
        )
    }
}
