import React from 'react';
import {Message} from "semantic-ui-react";

const HeaderMessage = ({text}) => {
    return <Message
        className="import-form-edit__block-message"
        icon="info"
        color="orange"
        content={text}
    />;
};

export default HeaderMessage;