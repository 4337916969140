const tokenKey = '_tokenKey';
const userDataKey = '_userDataKey';

export function saveUserContext(userContext) {
    sessionStorage.setItem(
        userDataKey,
        JSON.stringify(userContext)
    );
    localStorage.setItem(tokenKey, userContext.token);
};

export function clearUserContext() {
    sessionStorage.removeItem(userDataKey);
    localStorage.removeItem(tokenKey);
};

export function getUserContext() {
    let userctx = sessionStorage.getItem(userDataKey);
    let res;
    try {
        res = userctx ? JSON.parse(userctx) : null;
    } catch {
        res = null;
    }
    return res;
};

export function getRole() {
    let item = sessionStorage.getItem(userDataKey);
    let role = '';
    if (item) {
        try {
            role = JSON.parse(item).userRole;
        } catch {
            role = '';
        }
    }
    return role;
};

export function getToken() {
    return localStorage.getItem(tokenKey);
};

export function isLogged() {
    let item = localStorage.getItem(tokenKey);
    if (item) {
        return true;
    } else {
        return false;
    }
};

