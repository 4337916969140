import React from 'react';
import {Grid} from "semantic-ui-react";
import OCard from "./OCard";
import './styles.css';
import AddressesAndCargoRoute from "./AddressesAndCargoRoute";

const AddressesAndCargo = ({wb, routes}) => {
    return (
        <Grid className='addresses-cargo' divided>
            <Grid.Row columns={2}>
                <Grid.Column>
                    {
                        wb.pointsLoading.map(point => (
                            <OCard key={point.id} isLoading point={point}/>
                        ))
                    }
                </Grid.Column>
                <Grid.Column>
                    {
                        wb.pointsUnloading.map(point => (
                            <OCard key={point.id} point={point}/>
                        ))
                    }
                </Grid.Column>
            </Grid.Row>
            {
                routes.map((route, index) => <AddressesAndCargoRoute key={index} index={index} route={route}/>)
            }
        </Grid>
    );
};

export default AddressesAndCargo;