import React from 'react';
import Dropzone from 'react-dropzone';
import { Button, Modal } from 'semantic-ui-react';
import T from '../Translate';
import './FormImport.css';

const ImportStatus = {
    START: 1,
    FILES_SELECTED: 2,
    IMPORTING: 3,
    SUCCESS: 4, // not used -> redirecting on success
    ERROR: 5,
};

const initial = {
    open: false,
    status: ImportStatus.START,
    acceptedFiles: null,
    errors: ""
};

export default class FormImport extends React.Component {

    state = {
        ...initial
    };

    handleOpen = () => this.setState({ open: true })
    handleClose = () => this.setState({ ...initial })

    onDrop(acceptedFiles) {
        this.setState({ status: ImportStatus.FILES_SELECTED, acceptedFiles });
    }

    async import() {

        const { acceptedFiles } = this.state;
        const { id, callbackFunction, payload = {}, filter, importMethod } = this.props;

        let formData = new FormData();

        formData.append('files', acceptedFiles[0]);
        for (let key in payload) {
            formData.append(key, payload[key]);
        }

        this.setState({ status: ImportStatus.IMPORTING }, async () => {
            try {
                let data = await importMethod(id || 0, formData, filter);
                callbackFunction && callbackFunction(data);
                this.handleClose();
            } catch (error) {
                this.handleClose();
            }
        });
    }

    render() {
        const { status, acceptedFiles, errors } = this.state;
        const { exampleBtnText, exampleFile } = this.props;

        const statusInitial = (
            <div>
                <Dropzone
                    onDrop={this.onDrop.bind(this)}
                    multiple={false}
                    className='dropzone'
                >
                    <T>Перетащите файл с {this.props.dropzoneName || 'адресами'} или кликните на это поле</T>
                </Dropzone>
                {exampleFile && <div className="m-t-15">
                    <a className="ui positive button" href={`/files/${exampleFile}`} target={"_blank"}>
                        <i aria-hidden="true" className="download icon"></i>{exampleBtnText || 'Скачать пример файла импорта'}
                    </a>
                </div>}
            </div>
        );

        const statusFilesSelected = <div>
            <p>{acceptedFiles && acceptedFiles.length && acceptedFiles[0].name}</p>
        </div>;
        const statusLoading = <p><T>Загрузка файлов...</T></p>;
        const statusError = <p><T>Ошибка импорта</T>: {errors}</p>;

        return (
            <Modal trigger={this.props.children}
                dimmer="inverted" size="small" closeIcon
                open={this.state.open}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
            >
                <Modal.Header><T>{this.props.header}</T></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {status === ImportStatus.START
                            ? statusInitial
                            : status === ImportStatus.FILES_SELECTED
                                ? statusFilesSelected
                                : status === ImportStatus.IMPORTING
                                    ? statusLoading
                                    : status === ImportStatus.SUCCESS
                                        ? ""
                                        : statusError
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content={'Отмена'.t} onClick={this.handleClose} />
                    <Button loading={status === ImportStatus.IMPORTING}
                        icon='check'
                        disabled={status !== ImportStatus.FILES_SELECTED}
                        positive
                        content={this.props.saveBtnName || 'Загрузить'.t}
                        onClick={this.import.bind(this)} />
                </Modal.Actions>
            </Modal>
        );
    }
};
