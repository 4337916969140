import React, {useCallback, useEffect, useState} from 'react';
import {Divider, Form, Grid, Header, Input} from "semantic-ui-react";
import declOfNum from "../../../../services/declOfNum";
import {getApprovalRegistry, setApprovalRegistry} from "../../../../api/companies";
import {debounce} from "awesome-debounce-promise";

const RenewalOfRegistryApproval = ({match}) => {
    const [days, setDays] = useState(null);
    const [renewal, setRenewal] = useState(false);
    const [indefiniteRenewal, setIndefiniteRenewal] = useState(false);

    useEffect(() => {
        if (indefiniteRenewal) {
            setRenewal(false);
            setDays(null);
        }
    }, [indefiniteRenewal]);

    useEffect(() => {
        const approvalRegistry = async () => {
            return await getApprovalRegistry(match.params.id);
        };
        approvalRegistry().then(r => {
            setRenewal(r.renewal);
            setIndefiniteRenewal(r.renewalIndefinite);
            setDays(r.renewalDays);
        });
    }, []);

    const setData = useCallback(debounce(async (params) => {
        await setApprovalRegistry(match.params.id, params);
    }, 500), [match]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Header className="m-b-10">Продление одобрения реестра</Header>
                    <Divider className="m-t-0"/>
                    <Form size="small">
                        <div className="checkbox-with-input">
                            <Form.Checkbox
                                disabled={indefiniteRenewal}
                                label={'Продление включено на'.t}
                                checked={renewal}
                                onChange={(e, {checked}) => {
                                    setRenewal(checked);
                                    setData({
                                        renewal: checked
                                    });
                                }}/>
                            <Input
                                disabled={!renewal}
                                type="number"
                                min={1}
                                value={days || ''}
                                onChange={(e, {value}) => {
                                    if (!value || value > 0) {
                                        setDays(value);
                                        setData({
                                            renewal,
                                            renewalDays: value
                                        });
                                    }
                                }}
                            />
                            <div className={indefiniteRenewal ? 'disabled' : ''}>{declOfNum(days || 0, ['день', 'дня', 'дней'], true)}</div>
                        </div>
                        <Form.Field>
                            <Form.Checkbox
                                label={'Бессрочное продление'.t}
                                checked={indefiniteRenewal}
                                onChange={(e, {checked}) => {
                                    setIndefiniteRenewal(checked);
                                    setData({
                                        renewal: false,
                                        renewalDays: null,
                                        renewalIndefinite: checked
                                    });
                                }}/>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default RenewalOfRegistryApproval;