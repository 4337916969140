import React from 'react';
import { toast } from './toast';


export function isBrowserSupported() {
    let recomended = true, modr = window.Modernizr;
    for (let feature in modr) {
        if (typeof modr[feature] === "boolean" && modr[feature] === false) {
            recomended = false;
            break;
        }
    }
    return recomended;
}

export function isBrowserSupportedMin() {
    if (window.Modernizr.fetch) {
        window.needBrowserRecomendation = !isBrowserSupported();
        return true;
    } 
    return false;
}
 
export function checkForBrowserRecommendation() {

    if (window.needBrowserRecomendation) {
        const text = <span>Для работы c сайтом FM Клиентский портал рекомендуется использовать<br />последнюю версию браузера <a href="https://www.google.ru/intl/ru/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></span>;

        toast.warning(text, "Ваш браузер не прошел проверку на совместимость");
        window.needBrowserRecomendation = false;
    }
}