import T from "../../../../components/Translate";
import {Form} from "semantic-ui-react";
import React from "react";
import InputDropdown from "../../../../components/inputs/InputDropdown";
import {ProviderStatusOptions} from "../../../../api/model/Provider";

export default function ProviderFrom({companiesList, data, setData, errors, isAdd}) {
    const update = prop => setData((prevState) => ({...prevState, ...prop}));
    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        required
                        error={errors.includes('legalName')}
                        label={'Юр.наименование поставщика'.t}
                        placeholder={'Юр.наименование поставщика'.t}
                        value={data.legalName}
                        onChange={(e, {value}) => update({legalName: value})}
                    />
                </Form.Field>
                <Form.Input
                    required
                    error={errors.includes('fmid')}
                    label={'Название в системе FM'.t}
                    placeholder={'Название в системе FM'.t}
                    value={data.fmid}
                    onChange={(e, {value}) => update({fmid: value})}
                />
            </Form.Group>
            <Form.Field>
                <label><T>Клиент</T></label>
                <InputDropdown
                    multiple
                    placeholder={"Клиент".t}
                    options={companiesList}
                    value={data.clientIds}
                    onChange={val => update({clientIds: val})}
                    closeOnChange={false}
                    search
                />
            </Form.Field>
            {!isAdd && <Form.Field>
                <label><T>Статус</T></label>
                <InputDropdown
                    placeholder={"Статус".t}
                    options={ProviderStatusOptions}
                    value={data.status}
                    onChange={val => update({status: val})}
                />
            </Form.Field>}
        </Form>
    );
}
