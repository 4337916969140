import React, {useEffect, useState} from 'react';
import RuleInput from "../ruleInput";
import {fieldTypes} from "../../../../api/model/ImportForms";
import {getSplitters} from "../../../../api/importForms";

const Splitters = ({value, onChange, delBtn, disabled, name}) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getSplitters().then(res => {
            setOptions(res);
        });
    }, []);

    const renderLabel = (label) => ({
        content: label.value !== ' ' ? label.value : label.text,
    });

    return (
        <div className='rule-value__input splitters'>
            <RuleInput
                renderLabel={renderLabel}
                disabled={disabled}
                inputType={fieldTypes.MULTISELECT}
                options={options}
                onChange={onChange}
                clearable
                value={value}
                name={name}
                info={'Пожалуйста, укажите символы в порядке клиента'}
            />
            {!disabled && delBtn}
        </div>
    );
};

export default Splitters;