import React, {useContext, useEffect, useState} from 'react';
import {
    copyStandardAutocompleteApi,
    createStandardAutocompleteApi,
    deleteStandardAutocompleteApi,
    getStandardAutocompleteApi,
    getStandardAutocompleteHistoryApi,
    getStandardAutocompleteListApi,
    saveStandardAutocompleteApi
} from "../../api/calculatingFieldsRules";
import TableComponent from "./TableComponent";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import Shield from "../../layout/page/shield/Shield";
import {Feed, Icon, Menu, Tab} from "semantic-ui-react";
import T from "../../components/Translate";
import AddModalComponent from "./AddModalComponent";
import HistoryItem from "../_shared/HistoryItem";
import Search from "./search";
import {exportFile} from "../../services/export";

const AutocompleteList = ({match}) => {

    const contextNavi = useContext(ContextNavi);

    const [rules, setRules] = useState([]);

    useEffect(() => {
        const title = "Стандартные правила авторасчета полей".t;
        contextNavi.setItems([new LinkInfo(title, "/autocomplete")]);

        getRules();
    }, []);

    const getRules = async () => {
        const result = await getStandardAutocompleteListApi();

        setRules(result);
    };

    const confirmDelete = async (id) => {
        return await deleteStandardAutocompleteApi(id);
    };

    const handleCopy = async (id) => {

        return await copyStandardAutocompleteApi(id);

    };

    const handleGetRuleById = async (id) => {
        return await getStandardAutocompleteApi(id);
    };

    const handleRuleEdit = async (id, params) => {
        return await saveStandardAutocompleteApi(id, params);
    };

    const handleRuleCreate = async (params) => {
        return await createStandardAutocompleteApi(params);
    };

    const [rulesWithSearch, setRulesWithSearch] = useState([]);

    useEffect(() => {
        !rulesWithSearch.length && setRulesWithSearch(rules);
    }, [rules]);

    return (
        <Shield loading={false} loadingOver={false}>
            <Menu
                className="waybills-toolbar shd-inset"
                style={{marginBottom: "0"}}
                size="small"
                borderless
            >
                <Menu.Menu>
                    <Search
                        className='calc-search'
                        setRulesWithSearch={setRulesWithSearch}
                        rules={rules}
                    />
                </Menu.Menu>
                <Menu.Menu position="right">
                    <Menu.Item onClick={() => exportFile()}>
                        <Icon color="blue" name="arrow up"/>
                        <T>Экспорт конструктора</T>
                    </Menu.Item>
                    <AddModalComponent indexRule={rules.length + 1} fetchData={getRules}
                                       getRuleById={handleGetRuleById}
                                       createRule={handleRuleCreate}
                                       editRule={handleRuleEdit}
                    >
                        <Menu.Item>
                            <Icon color="blue" name="add"/>
                            <T>Добавить правило</T>
                        </Menu.Item>
                    </AddModalComponent>
                </Menu.Menu>
            </Menu>
            <div className="table-wrapper-grid table-wrapper-grid-175">
            <TableComponent
                isHeaderFixed
                companyId={match.params.id}
                rules={rulesWithSearch}
                getRules={getRules}
                deleteRule={confirmDelete}
                getRuleById={handleGetRuleById}
                copyRule={handleCopy}
                createRule={handleRuleCreate}
                editRule={handleRuleEdit}
            />
                </div>
        </Shield>
    );
};

const AutocompleteHistory = () => {
    const [history, setHistory] = useState([]);
    
    const getHistory = async () => {
        await getStandardAutocompleteHistoryApi()
            .then(d => setHistory(d))
            .catch(e => console.log(e));
    };
    
    useEffect(() => {
        getHistory();
    }, []);

    return (
        <div className='autocomplete-history'>
            <Feed>
                {history.map(i => <HistoryItem {...i} />)}
            </Feed>
        </div>

    );
};

const Autocomplete = ({match}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleTabChange = (e, {activeIndex}) => {
        setActiveIndex(activeIndex);
    };

    const panes = [
        {
            menuItem: { key: 'settings', icon: 'settings', content: 'Стандартные правила авторасчета полей' },
            render: () => <AutocompleteList match={match}/>
        },
        {
            menuItem: { key: 'history', icon: 'history', content: 'История' },
            render: () => <AutocompleteHistory/>
        }
    ];

    return (
        <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
        />
    );
};


export default Autocomplete;
