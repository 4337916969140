import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Menu, MenuHeader } from 'semantic-ui-react';
import PointAddress from '../../../../../api/model/PointAddress';
import T from '../../../../../components/Translate';
import PointFormAddressManual from './PointFormAddressManual';
import PointFormAddressDadata from './PointFormAddressDadata';

import './PointFormAddress.css';

export default function PointFormAddress({ onChange, addressDadata, setAddressDadata, addressManual,
    setAddressManual, validationErrors = [], onAddressTabChange = () => {}, activeAddressTab, setActiveAddressTab }
) {

    const tabDadataName = 'Найти адрес'.t;
    const tabManualName = 'Ввести адрес вручную'.t;

    const defaultQuery = addressDadata
        ? addressDadata.value || new PointAddress(addressDadata).toString()
        : '';
    const [query, setQuery] = useState(defaultQuery);

    function compileSettlement(adr) {
        const result = [];

        adr.city_with_type && result.push(adr.city_with_type);
        adr.settlement_with_type && result.push(adr.settlement_with_type);

        return result.length ? result.join(', ') : "";
    }

    function compileHouse(adr) {
        const result = [];

        adr.house_type && result.push(adr.house_type);
        adr.house && result.push(adr.house);

        return result.length ? result.join(' ') : "";
    }

    function compileBuilding(adr) {
        const result = [];

        adr.block_type && result.push(adr.block_type);
        adr.block && result.push(adr.block);

        return result.length ? result.join(' ') : "";
    }

    /** @param {ReactDadata.DadataSuggestion} dds  */
    function onChangeDD(dds) {
        const adr = dds.data;

        const dto = new PointAddress({
            ...addressDadata,
            country: adr.country,
            region: adr.region_with_type,
            area: adr.area_with_type,
            postalCode: adr.postal_code,
            settlement: compileSettlement(adr),
            streetName: adr.street_with_type,
            //streetType: adr.street_type,
            streetNo: compileHouse(adr),
            building: compileBuilding(adr),
            appt: adr.flat,
            kladr: adr.kladr_id,
            fias_level: adr.fias_level,
            fias: adr.fias_id,
            geoLat: adr.geo_lat,
            geoLon: adr.geo_lon,
            value: dds.value,
            timezone: adr.timezone
        });

        setQuery(dds.value);
        setAddressDadata(dto);
        onChange(dto);
    }

    const mergeToAddress = (data) => {
        let newAddress = { ...addressManual, ...data };
        const value = new PointAddress(newAddress).toString();
        newAddress = { ...newAddress, value };
        setAddressManual(newAddress);
        onChange(newAddress);
    };

    // after level 7 we want to get coordinates
    const count = addressDadata && addressDadata.fias_level > 7 ? 1 : 10;

    const ve = validationErrors;

    const isDatataInvalid = ['country', 'region', 'settlement', 'postalCode'].some(field => ve.includes(field));
    const emptyAddress = {
        country: '',
        region: '',
        area: '',
        settlement: '',
        postalCode: '',
        streetName: '',
        streetNo: '',
        building: '',
        office: ''
    };

    const onDadataInputChange = (value) => {
        setAddressDadata(emptyAddress);
        setQuery(value);
    };

    const handleItemClick = (e, { id }) => {
        setActiveAddressTab(id);
        if (id === tabDadataName) {
            onChange(addressDadata);
        } else {
            onChange(addressManual);
        }
        onAddressTabChange(id);
    };

    const tab1 = <PointFormAddressDadata
        count={count}
        query={query}
        address={addressDadata}
        onChangeDD={onChangeDD}
        onDadataInputChange={onDadataInputChange}
        isDatataInvalid={isDatataInvalid}
        ve={ve}
    />;
    const tab2 = <PointFormAddressManual
        ve={ve}
        address={addressManual}
        mergeToAddress={mergeToAddress}
    />;
    
    return (
        <>
            <Menu tabular widths={2}>
                <Menu.Item id={tabDadataName} active={activeAddressTab === tabDadataName} onClick={handleItemClick}>
                    <MenuHeader><T>{tabDadataName}</T></MenuHeader>
                </Menu.Item>
                <Menu.Item id={tabManualName} active={activeAddressTab === tabManualName} onClick={handleItemClick}>
                    <MenuHeader><T>{tabManualName}</T></MenuHeader>
                </Menu.Item>
            </Menu>

            {activeAddressTab === tabDadataName ? tab1 : tab2 }
        </>
    );

}

PointFormAddress.propTypes = {
    address: PropTypes.shape(PointAddress),
    onChange: PropTypes.func.isRequired
};