import {del, download, get, post, put, upload} from '../services/http';
import O from '../components/Option'

export const fieldsTypeEnum = {
    Int: 0,
    Double: 1,
    String: 2,
    Enum: 3,
    Date: 4,
    Time: 5,
    Boolean: 6,
    TimePeriod: 7,
    Decimal: 8,
    DateTime: 9,
};

export const fieldCompareEnum = {
    Equal: 0,
    NotEqual: 1,
    Greater: 2,
    Less: 3,
    GreaterEqual: 4,
    LessEqual: 5,
    Contains: 6,
    NotContains: 7
};

export const fieldCompareOptions = [
    new O(fieldCompareEnum.Equal, 'Равно'),
    new O(fieldCompareEnum.NotEqual, 'Не равно'),
    new O(fieldCompareEnum.Greater, 'Больше'),
    new O(fieldCompareEnum.Less, 'Меньше'),
    new O(fieldCompareEnum.GreaterEqual, 'Больше либо равно'),
    new O(fieldCompareEnum.LessEqual, 'Меньше либо равно'),
    new O(fieldCompareEnum.Contains, 'Содержит'),
    new O(fieldCompareEnum.NotContains, 'Не содержит'),
];

export const getRulesListApi = async (companyId) => {
    return await get(`companies/${companyId}/autocomplete`);
};

export const getStandardAutocompleteListApi = async () => {
    return await get(`companies/standard-autocomplete`);
};

export const getStandardAutocompleteHistoryApi = async () => {
    return await get(`companies/history?type=Autocomplete`);
};

export const getFieldsApi = async () => {
    const result = await get(`companies/autocomplete/fields/target`);

    return  result.map(item => ({
        ...item,
        key: item.key,
        value: item,
        text: item.field.name
    }))
};


export const getRulesApi = async (companyId, id) => {

    return await get(`companies/${companyId}/autocomplete/${id}`);
};

export const getStandardAutocompleteApi = async (id) => {

    return await get(`companies/standard-autocomplete/${id}`);
};

export const getFieldsFromRulesApi = async () => {
    return await get(`companies/autocomplete/fields/rule`);
};

export const createRuleApi = async (companyId, params) => {
    return await post(`companies/${companyId}/autocomplete`, params)
};

export const createStandardAutocompleteApi = async (params) => {
    return await post(`companies/standard-autocomplete`, params)
};

export const saveRuleApi = async (companyId, id, params) => {
    return await put(`companies/${companyId}/autocomplete/${id}`, params)
};

export const saveStandardAutocompleteApi = async (id, params) => {
    return await put(`companies/standard-autocomplete/${id}`, params)
};

export const deleteRuleApi = async (companyId, id)=> {
    return await del(`companies/${companyId}/autocomplete/${id}`)
};

export const deleteStandardAutocompleteApi = async (id)=> {
    return await del(`companies/standard-autocomplete/${id}`)
};

export const copyRuleApi = async (companyId, id) => {
    return await post(`companies/${companyId}/autocomplete/${id}/copy`)
};

export const copyStandardAutocompleteApi = async (id) => {
    return await post(`companies/standard-autocomplete/${id}/copy`)
};

export const getAutocompleteExcel = async (id) => {
    return await get(`autocompletes/rule/excel/${id}`);
};

export const searchnamesFromExcel = async (id, files) => {
    return await upload(`autocompletes/rule/excel`, files);
};

export const exportExcel = async (clientFMID) => {
    return await get(`companies/autocomplete/export${clientFMID ? `?clientFMID=${clientFMID}` : ''}`);
};
