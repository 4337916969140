import React from 'react';
import { Form } from 'semantic-ui-react';

import PointFormDays from './PointFormDays';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import { LoadCapacityOptions, PointLoadingPositionEnum } from '../../../../api/model/Point';
import T from '../../../../components/Translate';
import InputDropdownMultiple from "../../../../components/inputs/InputDropdownMultiple";

const PointFormStep3 = ({ ve, data, update, exportOnSaveCheck, exportOnSave, onSetExportOnSave, isClient }) => {
    const rowLoadingUnloading = (
        <React.Fragment>
            <Form.Group inline>
                <label><T>Загрузка-выгрузка</T>:</label>
                <Form.Radio
                    label={'задняя'.t}
                    value={PointLoadingPositionEnum.BACK}
                    checked={data.dockPosition === PointLoadingPositionEnum.BACK}
                    onChange={(e, item) => update({ dockPosition: item.value })}
                />
                <Form.Radio
                    label={'боковая'.t}
                    value={PointLoadingPositionEnum.SIDE}
                    checked={data.dockPosition === PointLoadingPositionEnum.SIDE}
                    onChange={(e, item) => update({ dockPosition: item.value })}
                />
            </Form.Group>
        </React.Fragment>
    );

    return <React.Fragment>
        <div className="m-b-10">
            <PointFormDays
                days={data.days}
                onChange={val => update({ days: val })}
            />
        </div>
        <Form.Field required error={ve.includes("dockCapacity")}>
            <label><T>Грузоподъемность</T></label>
            <InputDropdownMultiple
                placeholder={"Грузоподъемность".t}
                options={LoadCapacityOptions}
                value={data.dockCapacity}
                onChange={val => update({ dockCapacity: val })}
            />
        </Form.Field>
        {rowLoadingUnloading}
        <Form.TextArea
            className="te-100"
            label={"Особые примечания".t}
            value={data.comment || ''}
            onChange={e => update({ comment: e.target.value })}
        />

        {!isClient && exportOnSaveCheck && <Form.Group>
            <Form.Checkbox
                label={'Создать и зарегистрировать в FM'.t}
                checked={exportOnSave}
                onChange={(e, item) => onSetExportOnSave(item.checked)}
            />
        </Form.Group>}
    </React.Fragment>;
};

export default PointFormStep3;
