import React, {useState} from 'react';
import {Modal} from "semantic-ui-react";
import WbHistory from "../wbViewHistory/WbViewHistory";

const HistoryModal = ({children}) => {
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            className='history-modal'
            trigger={children}
            dimmer="inverted"
            size="fullscreen"
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={onClose}
        >
            <Modal.Header
                className='history-modal__header'
            >
                История
            </Modal.Header>
            <Modal.Content
                className='history-modal__content'
            >
                <WbHistory isAuction/>
            </Modal.Content>
        </Modal>
    );
};

export default HistoryModal;