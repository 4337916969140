import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import UserPermissions from '../api/model/UserPermissions';
import {userFirstRunClear} from '../api/users';
import {Authorized} from '../components/Authorized';
import Drawer2 from '../layout/drawer2/Drawer2';
import Footer from '../layout/footer/Footer';
import Navbar from '../layout/navbar/Navbar';
import {clearUserContext} from '../services/auth';
import {ContextFooterProvider, ContextGridSettingsProvider, ContextUser} from '../services/context';
import '../services/extensions';
import {checkForBrowserRecommendation} from '../services/ua';
import Entry from './access/Entry';
import {Admin} from './admin/Admin';
import ErrorNotFound from './ErrorNotFound';
import './Pages.css';
import Points from './points/Points';
import PointView from './points/PointView';
import {Waybills} from './waybills/Waybills';
import Downloads from "./downloads";
import Registries from "./registries";
import Banners from "./banners";
import Schedules from "./schedules";
import AutocompleteList from "./autocomplete";
import Notifications from "./notifications";
import Dictionaries from "./dictionaries";
import Import from "./import/Import";

export default function Pages() {

    const contextUser = useContext(ContextUser);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        if (verified) {
            checkForBrowserRecommendation();
        }
    }, [verified]);

    if (!verified) {
        return <Entry verified={setVerified} />;
    }

    const user = contextUser.current;
    const userStartUrl = user.startUrl;


    if (user && user.firstRun) {

        userFirstRunClear().then(x => {
            clearUserContext();
            location.assign("https://lk.fmlogistic.ru/lp"); // eslint-disable-line
        });
        return null;
    }

    function redirect() {

        if (userStartUrl) {
            return <Redirect to={userStartUrl} />;
        } else {
            // can we refrain from startup usrls7
            // or can we give startup page for a user&
        }
    };

    return (
        <ContextGridSettingsProvider>
            <div className="pages-container">
                <div className="pages-bar-left">
                    <Drawer2 userStartUrl={userStartUrl} />
                </div>
                <div className="pages-content">
                    <ContextFooterProvider>
                        <div className="pages-content-top">
                            <Navbar user={user} />
                        </div>
                        <div className="pages-content-body">
                            <Switch>
                                <Route
                                    path='/' exact
                                    render={redirect}
                                />
                                <Authorized
                                    path='/points'
                                    component={Points}
                                    permission={UserPermissions.COMPANY_POINTS}
                                    exact={true}
                                />
                                <Authorized
                                    path='/points/:id/company/:companyId/:tab'
                                    component={PointView}
                                    permission={UserPermissions.COMPANY_POINTS}
                                />
                                <Authorized
                                    path='/waybills'
                                    component={Waybills}
                                    permission={UserPermissions.WAYBILLS}
                                />
                                <Authorized
                                    path='/import'
                                    component={Import}
                                    permission={[UserPermissions.IMPORT_FORMS_AND_RULES_VIEW, UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE]}
                                />
                                <Authorized
                                    path='/registries'
                                    permission={UserPermissions.REGISTRIES}
                                    component={Registries}
                                />
                                <Authorized
                                    path='/autocomplete'
                                    permission={UserPermissions.STANDARD_AUTOCOMPLETE}
                                    component={AutocompleteList}
                                />
                                <Authorized
                                    path='/banners'
                                    permission={UserPermissions.BANNERS}
                                    component={Banners}
                                />
                                <Authorized
                                    path='/dictionaries'
                                    permission={UserPermissions.DICTIONARIES_EDIT}
                                    component={Dictionaries}
                                />
                                <Authorized
                                    path='/notifications'
                                    permission={UserPermissions.MANGING_NOTIFICATIONS}
                                    component={Notifications}
                                />
                                <Authorized
                                    path='/schedules'
                                    permission={[UserPermissions.SCHEDULES, UserPermissions.SCHEDULES_SETTINGS_VIEAW]}
                                    component={Schedules}
                                />
                                <Route
                                    path='/admin'
                                    component={Admin}
                                />
                                <Route exact path='/download/pod' component={Downloads} />
                                <Route component={ErrorNotFound} />
                            </Switch>
                            {/* {user.companies.length === 0 && <ErrorCompanyBlocked />} */}
                        </div>
                        <div className="pages-content-footer">
                            <Footer />
                        </div>
                    </ContextFooterProvider>
                 </div>
            </div>
        </ContextGridSettingsProvider>
    );

}
