import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import './ActionMenu.css';

export default function ActionMenu({children}) {
 
    return (
        <Dropdown 
            direction='left'
            text={"Действия".t} 
            icon='cogs' 
            floating 
            labeled 
            button 
            className='icon dropdown-actionmenu' 
            closeOnChange
        >
            <Dropdown.Menu>
                <Dropdown.Header content={'Доступные операции'.t} />
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}