import React, {useState, useMemo} from 'react';
import {Button, Divider, Form, Modal} from 'semantic-ui-react';
import {RequestTypeOptions} from '../../../api/model/Enums';
import {getHasToMirror, getMaxIndex} from '../../../api/waybills';
import InputDropdown from '../../../components/inputs/InputDropdown';
import T from '../../../components/Translate';
import {toast} from '../../../services/toast';
import {formatNumber, getFloatOrString, getValuePassZero, restrictionFloat} from "../../../services/utils";

function getPrevMaxPosition(points) {
    let prevMaxPosition = 0;

    points.forEach(p => {
        p.loadUnitsToOrderMappings.forEach(pm => {
            if (pm.loadUnitPositionEnd > prevMaxPosition) {
                prevMaxPosition = pm.loadUnitPositionEnd;
            }
        });
    });

    return prevMaxPosition;
};

export default function WbPointCardTabsPositionsUnloadForm({
    header, submit, value = {}, unitsLoadedTotal, wb, points, pointId, isAdding, children, isClient, disabled, isPoolingCanEdit, isPoolingEdit, isPoolingCanEditCompletely
                                                           }) {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const loadUnitSets = useMemo(() => wb.pointsLoading || []).reduce((val, p) => val.concat(p.loadUnitSets), [], [wb]);

    const totalWeightLoadUnit = useMemo(() => {
        return loadUnitSets && loadUnitSets.length ? loadUnitSets.reduce((sum = 0, item) => sum + parseFloat(item.loadTotalWeigthBruttoKg), 0) : 0;
    }, [loadUnitSets]);

    const totalVolumeM3LoadUnit = useMemo(() => {
        return loadUnitSets && loadUnitSets.length ? loadUnitSets.reduce((sum = 0, item) => sum + parseFloat(item.loadTotalVolumeM3), 0) : 0;
    }, [loadUnitSets]);

    const loadUnitsToOrderMappings = useMemo(() => {
        return points ? points.reduce((val, p) => val.concat(p.loadUnitsToOrderMappings), []) : [];
    }, [points]);


    function validate(position) {

        const {loadUnitPositionStart, loadUnitPositionEnd} = position;

        if (!(loadUnitPositionStart > 0)) {
            setErrors(['loadUnitPositionStart']);
            //toast.warning('Поле Начальный индекс не заполнено'.t);
            return false;
        }
        if (!(loadUnitPositionEnd > 0)) {
            setErrors(['loadUnitPositionEnd']);
            //toast.warning('Поле Конечный индекс не заполнено'.t);
            return false;
        }
        if (loadUnitPositionStart > loadUnitPositionEnd) {
            setErrors(['loadUnitPositionStart']);
            toast.warning('Начальный индекс не может быть больше конечного'.t);
            return false;
        }

        if (!position.recipientOrderNo) {
            setErrors(['recipientOrderNo']);
            //toast.warning('Поле номер грузополузателя не заполнено'.t);
            return false;
        }

        if (position.recipientOrderNo.length > 200) {
            setErrors(['recipientOrderNo']);
            toast.warning('Поле номер грузополузателя не может превышать 200 символов'.t);
            return false;
        }

        if (position.recipientOrderNo.split(';').some(i => i.length > 70)) {
            setErrors(['recipientOrderNo']);
            toast.warning('Номера заказов грузополучателя указаны по неверному формату, используйте ";" для разделения номеров'.t);
            return false;
        }

        if (position.shipperOrderNo && position.shipperOrderNo.length > 200) {
            setErrors(['shipperOrderNo']);
            toast.warning('Поле номер грузопоотправителя не может превышать 200 символов'.t);
            return false;
        }

        if (position.torg12No && position.torg12No.length > 500) {
            setErrors(['torg12No']);
            toast.warning('Поле ТОРГ-12 не может превышать 500 символов'.t);
            return false;
        }

       if (parseFloat((loadUnitsToOrderMappings.filter(item => item.id !== position.id)
           .reduce((sum, item) => sum + parseFloat(item.weightKgBrutto || 0), 0) + parseFloat(position.weightKgBrutto || 0)).toFixed(3)) > parseFloat((totalWeightLoadUnit || 0).toFixed(3))) {
            setErrors(['weightKgBrutto']);
            toast.warning('Значение по каждой грузовой единице не соответствует общему весу'.t);
            return false;
        }

        if (parseFloat((loadUnitsToOrderMappings.filter(item => item.id !== position.id)
            .reduce((sum, item) => sum + parseFloat(item.volumeM3 || 0), 0) + parseFloat(position.volumeM3 || 0)).toFixed(3)) > parseFloat((totalVolumeM3LoadUnit || 0).toFixed(3))) {
            setErrors(['volumeM3']);
            toast.warning('Значение по каждой грузовой единице не соответствует общему объему'.t);
            return false;
        }

        if (parseFloat(parseFloat(loadUnitsToOrderMappings.filter(item => item.id !== position.id)
            .reduce((sum, item) => sum + parseFloat(item.cost || 0), 0)) + parseFloat(position.cost || 0)).toFixed(2) > parseFloat(wb.cargoCost || 0)) {
            setErrors(['cost']);
            toast.warning('Значение по каждой грузовой единице не соответствует общей стоимости'.t);
            return false;
        }

        return true;
    };

    function onChange(key, val) {
        let dataCloned = {...data};
        dataCloned[key] = val;

        const {loadUnitPositionEnd, loadUnitPositionStart} = dataCloned;

        if (!getHasToMirror(wb) || isAdding) {
            const maxIndex = getMaxIndex(wb);
            if (loadUnitPositionEnd > maxIndex) {
                dataCloned = {
                    ...dataCloned,
                    loadUnitPositionEnd: maxIndex
                };
            }
        }

        if (loadUnitPositionStart < 1) {
            dataCloned = {
                ...dataCloned,
                loadUnitPositionStart: 1
            };
        }

        setErrors([]);
        setData(dataCloned);
        setCanSave(validate(dataCloned));
    };

    function onOpen() {
        if (disabled) {
            return
        }
        const prevMaxPosition = getPrevMaxPosition(points);

        if (isAdding) {
            let loadUnitPositionStart = prevMaxPosition + 1;
            if (loadUnitPositionStart > unitsLoadedTotal) {
                loadUnitPositionStart = unitsLoadedTotal;
            }
            const presetFormData = unitsLoadedTotal
                ? {
                    pointToId: pointId,
                    loadUnitPositionStart: prevMaxPosition + 1,
                    loadUnitPositionEnd: unitsLoadedTotal
                }
                : {pointToId: pointId};

            setData(presetFormData);
        } else {
            setData(value);
        }
        setOpen(true);
    }

    function onClose() {
        setOpen(false);
        setData({});
        setErrors([]);
    };

    async function onSave() {
        try {
            setLoading(true);
            await submit(data);
        } finally {
            setLoading(false);
            onClose();
        }
    }

    function getDeleteExtraChart(str, ignoreSlash) {
        let strNew = '';
        for (let char of str) {
            if (/[\s+*"№%:?*<>.,|\\()_!'@#%$^&=`]/i.test(char) || (!ignoreSlash && /\//i.test(char))) {
                strNew = `${strNew};`;
            } else strNew = `${strNew}${char}`;
        }
        return strNew.replace(/\;{2,}/i, ';');
    }

    return (
        <Modal
            trigger={children}
            centered={true}
            dimmer="inverted"
            size="small"
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header><T>{header}</T></Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input
                            id={`input_loadUnitPositionStart`}
                            required
                            type="number"
                            min="1"
                            error={errors.includes('loadUnitPositionStart')}
                            label={'Номер грузовой единицы (с)'.t}
                            placeholder={'Номер грузовой единицы (с)'.t}
                            value={data.loadUnitPositionStart || ''}
                            onChange={e => onChange('loadUnitPositionStart', Number.parseInt(e.target.value))}
                            disabled={isClient && !isPoolingCanEdit && !isPoolingCanEditCompletely}
                        />
                        <Form.Input
                            id={`input_loadUnitPositionEnd`}
                            required
                            type="number"
                            min="1"
                            max={unitsLoadedTotal}
                            error={errors.includes('loadUnitPositionEnd')}
                            label={'Номер грузовой единицы (по)'.t}
                            placeholder={'Номер грузовой единицы (по)'.t}
                            value={data.loadUnitPositionEnd || ''}
                            onChange={e => onChange('loadUnitPositionEnd', Number.parseInt(e.target.value))}
                            disabled={isClient && !isPoolingCanEdit && !isPoolingCanEditCompletely}
                        />
                    </Form.Group>
                    <Divider hidden/>
                    <Form.Group widths='equal'>
                        <Form.Input
                            id={`input_recipientOrderNo`}
                            disabled={isPoolingEdit && !isPoolingCanEditCompletely}
                            required
                            error={errors.includes('recipientOrderNo')}
                            label={'Номер заказа грузополучателя (список через ";")'.t}
                            placeholder={'Введите номер заказа (или список через ";")'.t}
                            value={data.recipientOrderNo || ''}
                            onChange={e => onChange('recipientOrderNo', getDeleteExtraChart(e.target.value))}
                        />
                        <Form.Input
                            id={`input_shipperOrderNo`}
                            disabled={isPoolingEdit && !isPoolingCanEditCompletely}
                            error={errors.includes('shipperOrderNo')}
                            label={'Номер заказа грузоотправителя'.t}
                            placeholder={'Номер заказа грузоотправителя'.t}
                            value={data.shipperOrderNo || ''}
                            onChange={e => onChange('shipperOrderNo', getDeleteExtraChart(e.target.value, true))}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            id={`input_torg12No`}
                            disabled={isPoolingEdit && !isPoolingCanEditCompletely}
                            error={errors.includes('torg12No')}
                            label={'Номер ТОРГ-12'.t}
                            placeholder={'Номер ТОРГ-12'.t}
                            value={data.torg12No || ''}
                            onChange={e => onChange('torg12No', e.target.value)}
                        />
                        <Form.Field
                            id={`input_orderType`}
                            control={InputDropdown}
                            label={'Тип заказа'.t}
                            options={RequestTypeOptions}
                            placeholder={'Тип заказа'.t}
                            value={data.orderType}
                            onChange={val => onChange('orderType', val)}
                            disabled={isClient && !isPoolingCanEditCompletely}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            id={`input_weightKgBrutto`}
                            error={errors.includes('weightKgBrutto')}
                            type="number"
                            min="0"
                            step="0.1"
                            label={'Вес, кг'.t}
                            placeholder={'Вес, кг2'.t}
                            value={getValuePassZero(data.weightKgBrutto)}
                            onChange={e => onChange('weightKgBrutto', restrictionFloat(e.target.value, 3))}
                            onBlur={(e) =>  onChange('weightKgBrutto', formatNumber(Number.parseFloat(e.target.value)))}
                            disabled={isClient && !isPoolingCanEditCompletely}
                        />
                        <Form.Input
                            id={`input_volumeM3`}
                            error={errors.includes('volumeM3')}
                            type="number"
                            min="0"
                            step="0.1"
                            label={'Объем, м3'.t}
                            placeholder={'Объем, м3'.t}
                            value={getValuePassZero(data.volumeM3)}
                            onChange={e => onChange('volumeM3', restrictionFloat(e.target.value, 3))}
                            onBlur={(e) =>  onChange('volumeM3', formatNumber(Number.parseFloat(e.target.value)))}
                            disabled={isClient && !isPoolingCanEditCompletely}
                        />
                    </Form.Group>
                </Form>


            </Modal.Content>
            <Modal.Actions>
                <Button
                    id={`btn-unload_close`}
                    onClick={onClose}
                    disabled={loading}><T>Отмена</T></Button>
                <Button
                    id={`btn-load_save`}
                    primary
                    disabled={!canSave}
                    loading={loading}
                    onClick={onSave}><T>Сохранить</T></Button>
            </Modal.Actions>
        </Modal>
    );
}
