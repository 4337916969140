import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Confirm, Divider, Icon, Menu } from 'semantic-ui-react';
import { PickupTypeEnum } from '../../../../api/model/Enums';
import Waybill, {
    nonDeleteErrorAvisStatusSet,
    nonDeleteErrorStatusSet,
    WaybillAvisationStatus,
    WaybillStatusAvizationEnum,
    WaybillStatusEnum,
    WaybillTypeEnum
} from '../../../../api/model/Waybill';
import WaybillPoint from '../../../../api/model/WaybillPoint';
import {
    cancelWaybills,
    copyWaybill,
    createWaybill,
    waybillDelete,
    waybillExport,
    waybillExportAll,
    waybillsAvizo,
    waybillsDeleteSoft,
    wbRestoreFromDB,
    wbsCutoffs
} from '../../../../api/waybills';
import { createPickup2 } from '../../../../api/waybillsMerge';
import T from '../../../../components/Translate';
import { toast } from '../../../../services/toast';
import WbProcessing from '../../common/WbProcessing';
import WbPointAddForm from '../../wbEdit/WbPointAddForm';
import WbColumnsSetup from './wbColumnsSetup/WbColumnsSetup';
import './WbGridToolbar.css';
import WbsImportForm from './WbsImportForm';
import UserPermissions from "../../../../api/model/UserPermissions";
import { ContextUser } from "../../../../services/context";
import moment from "moment";
import useReactRouter from 'use-react-router';
import WbGridClientEditModal from "./WbGridClientEditModal";
import WbGridEditServicesModal from "./WbGridEditServicesModal";
import DocsModal from "../docsModal";
import { UserRolesEnum } from "../../../../api/usersRoles";
import WbGridEditCostModal from "./WbGridEditCostModal";
import {columnsByRole, waybillColumnsSet} from "../../../../api/model/WaybillColumn";


export default function OrdersToolbar({ selectedRows, setSelectedIds, refresh, loading, updateFMIDs, hasRows, setIsOpenAvizModal, setEditTimeslotData }) {
    const { history } = useReactRouter();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [canEditServices, setCanEditServices] = useState(false);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [downloadDocsModalOpen, setDownloadDocsModalOpen] = useState(false);
    const [sendDocsModalOpen, setSendDocsModalOpen] = useState(false);
    const contextUser = useContext(ContextUser);

    const noSelection = selectedRows.length === 0;
    const selectedIds = selectedRows.map(r => r.id);
    const isFirstLoad = false;

    const canRestoreFromDB = selectedRows.length > 0 && selectedRows.every(wb => wb.isArchive);
    const archivedWb = selectedRows.length > 0 && selectedRows.find(wb => wb.isArchive);

    const notFmDeleted = selectedRows.every(wb => !(wb.status === WaybillStatusEnum.FM_DELETED));

    const canSend = notFmDeleted && !archivedWb && selectedRows.length && selectedRows.every(wb =>
        !(wb.isPooling && wb.status === WaybillStatusEnum.DRAFT)
        && wb.status === WaybillStatusEnum.DRAFT
        && wb.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES && wb.isValid);

    const canChangeShippingMethod = notFmDeleted && !archivedWb && selectedRows.length &&
        selectedRows.every(wb => wb.status === WaybillStatusEnum.DRAFT
            && wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES
            && wb.isValid && !(wb.pickupType === PickupTypeEnum.OTHER && !wb.crossDockWithActivity)
            && !(wb.isPooling && wb.status === WaybillStatusEnum.DRAFT));

    const avisationAllowedStatuses = [WaybillStatusEnum.SUBMITTED, WaybillStatusEnum.ON_APPROVAL, WaybillStatusEnum.EXECUTING]

    const canAvizoOld = selectedRows.length && !archivedWb && selectedRows.every(wb => {
        return avisationAllowedStatuses.includes(wb.status)
            && wb.hubWithOldAvization && wb.canHaveAvisation
            && wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES
            && !(wb.isPooling && wb.status === WaybillStatusEnum.DRAFT);
    });

    const canAvizo = selectedRows.length && !archivedWb && selectedRows.every(wb => {
        return avisationAllowedStatuses.includes(wb.status)
            && wb.crossDock && wb.canHaveAvisation
            && wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES
            && !contextUser.current.permissions.includes(UserPermissions.DISALLOW_AVISATION)
            && !(wb.isPooling && wb.status === WaybillStatusEnum.DRAFT);
    }) && Array.from(new Set(selectedRows.map(wb => wb.crossDock))).length === 1;

    const nonCancellableStatusSet = [
        WaybillStatusEnum.EXECUTING,
        WaybillStatusEnum.DRAFT,
        WaybillStatusEnum.DOCS_SENT,
        WaybillStatusEnum.CARGO_DELIVERED,
        WaybillStatusEnum.CANCELLED
    ];

    const canClientEdit = notFmDeleted && selectedRows.length
        && selectedRows.every(wb => !(wb.isPooling && wb.status === WaybillStatusEnum.DRAFT))
        && contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT_COMPANY)
        && !archivedWb;

    const canCancel =
        notFmDeleted && !archivedWb && selectedRows.length === 1 && selectedRows.every(wb => !(wb.isPooling && wb.status === WaybillStatusEnum.DRAFT)) &&
        (selectedRows.every(wb => !nonCancellableStatusSet.includes(wb.status) && !wb.isPooling && wb.type !== WaybillTypeEnum.Return)
            || selectedRows.every(wb => wb.type === WaybillTypeEnum.Return && contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT)
                && [WaybillStatusEnum.EXECUTING, WaybillStatusEnum.SUBMITTED, WaybillStatusEnum.ON_APPROVAL].includes(wb.status)));

    const canDelete = notFmDeleted && !archivedWb && selectedRows.length > 0 && selectedRows.every(wb => wb.status === WaybillStatusEnum.DRAFT && !wb.isPooling);

    const canDownloadDocs = selectedRows.length > 0 && selectedRows.map(r => r.clientId).every(id => id === selectedRows[0].clientId);

    const canExport = selectedRows.length > 0;

    let canExportAll = selectedRows.length === 0 && Object.keys(qs.parse(history.location.search)).length !== 0;

    const canDeleteErroneous = selectedRows.length > 0 && !archivedWb &&
        selectedRows.every(wb => contextUser.current.permissions.includes(wb.type === WaybillTypeEnum.Return ? UserPermissions.RETURN_WAYBILLS_EDIT : UserPermissions.WAYBILL_SOFT_DELETE)) &&
        selectedRows.every(wb => nonDeleteErrorStatusSet.includes(wb.status)) &&
        selectedRows.every(wb => nonDeleteErrorAvisStatusSet.includes(wb.avisationStatus)) &&
        selectedRows.every(wb => !wb.isPooling)

    const getCutoffs = async (ids) => {
        const cutoffsRes = await wbsCutoffs(ids);
        setCanEditServices(!cutoffsRes.cutOff);
    }

    const roleUser = ((contextUser.current || {}).role || {}).id;
    const permissions = (contextUser.current || {}).permissions || [];

    useEffect(() => {
        if (selectedRows.length > 0
            && !archivedWb
            && (([UserRolesEnum.ADMIN, UserRolesEnum.COORDINATOR_CS].includes(roleUser) && permissions.includes(UserPermissions.WAYBILL_EDIT))
                || selectedRows.every(wb => ![
                    WaybillStatusEnum.DRAFT,
                    WaybillStatusEnum.CANCELLED,
                    WaybillStatusEnum.FM_CANCELLED,
                    WaybillStatusEnum.CARGO_DELIVERED,
                    WaybillStatusEnum.DOCS_SENT
                ].includes(wb.status)))) {
            getCutoffs(selectedRows.map(row => row.id));
        }
        else setCanEditServices(false);
    }, [selectedRows]);

    const canEditCost = selectedRows.length > 0 && [UserRolesEnum.ADMIN, UserRolesEnum.COORDINATOR_CS].includes(roleUser) && permissions.includes(UserPermissions.WAYBILL_EDIT) && !archivedWb;

    const restoreFromDB = () => {
        wbRestoreFromDB(selectedRows.map(r => r.id))
            .then(() => {
                refresh && refresh();
            });
    };

    const setChangeLoading = (value) => {
        return new Promise((resolve) => {
            loading(value);
            resolve();
        });
    };
    const promiseSetSelectedIds = (list) => {
        return new Promise((resolve) => {
            setSelectedIds(list);
            resolve();
        });
    };

    const cancel = async () => {

        await setChangeLoading(true);
        const data = await cancelWaybills(selectedRows.map(sr => sr.id));
        if (data && data.newId) {
            history.push(`/waybills/${data.newId}/edit`);
        }
        let ids = !data.isUpdated ? data.reservationWaybillIds : null;
        if (data.isUpdated) {
            setEditTimeslotData(data);
        }
        if (data.isAvisationCanceled) {
            if (!data.reservationWaybillIds || !data.reservationWaybillIds.length) {
                toast.success(`Бронь № ${data.canceledReservation.visitNumber} отменена`);
            }
        }
        if (ids && ids.length === selectedRows.length) {
            selectedRows.forEach(wb => {
                wb.status = WaybillStatusEnum.CANCELLED;
            });
        }

        if (ids && ids.length > 0) {
            await setChangeLoading(false);
            await promiseSetSelectedIds(ids);
            setIsOpenAvizModal(true);

        } else {
            await refresh();
        }

    };

    const canPickupStatusSet = [
        WaybillStatusEnum.ON_APPROVAL,
    ];
    const canPickup = notFmDeleted && !noSelection && !archivedWb && (selectedRows.every(i => i.type === WaybillTypeEnum.Return) || selectedRows.every(i =>
        i.type === WaybillTypeEnum.LTL &&
        canPickupStatusSet.includes(i.status)
    ) && selectedRows.every((i) => {
        return i.pickupType !== PickupTypeEnum.OTHER
            && !(i.isPooling && i.status === WaybillStatusEnum.DRAFT);
    }));

    const createPickupLocal = async (point) => {

        loading(true);

        try {

            const id = await createPickup2(point.pointId, selectedIds);

            if (id) {

                const url = `/waybills/${id}/edit`;

                history.push(url);
            } else {

                loading(false);

                toast.error("Интетификатор не получен".t, "Ошибка создания FTL".t);
            }

        } catch (error) {

            loading(false);
        }
    };

    const numberStyle = {
        fontSize: '15px',
        //fontWeight: 'bold'
    };
    const descrStyle = {
        color: '#9e9e9e'
    };

    const totalWeight = selectedRows
        ? selectedRows.reduce((acc, row) => acc + Number.parseInt(row.totalWeight || 0), 0)
        : null;

    const totalUnits = selectedRows && selectedRows.length
        ? selectedRows.reduce((x, r) => x += Object.keys(r.totalUnits).reduce((x2, key) => x2 += r.totalUnits[key], 0), 0)
        : 0;


    const iconName = "weight"; //"clipboard check";

    const canCopy = notFmDeleted && !archivedWb && selectedIds && selectedIds.length === 1 && selectedRows[0].canCopy;

    const create = async () => {

        loading(true);

        const query = qs.parse(history.location.search) || {};

        const type = query.type || "LTL";

        const dto = await createWaybill();

        if (dto && dto.id) {
            history.push(`/waybills/${dto.id}/edit`);
        } else {
            loading(false);
        }
    };

    const createCopy = async () => {

        loading(true);
        try {
            const newId = await copyWaybill(selectedIds[0]);

            history.push(`/waybills/${newId}/edit`);
        } catch (err) {

            loading(false);
        }
    };

    const onCLickDeleteErroneous = async () => {
        loading(true);
        try {
            const wbIds = selectedRows.map(sr => sr.id);
            await waybillsDeleteSoft(wbIds).then(res => {
                if (res && res.newId) {
                    history.push(`/waybills/${res.newId}/edit`);
                }
            });
            refresh();
        } finally {
            loading(false);
        }
    };

    const waybillExportClick = async (event, data) => {
        loading(true);
        try {
            if (event.ctrlKey || event.metaKey) {
                window.open(`/api/waybill/${selectedIds[0]}/export/json`, "_blank");
            } else {
                var fileInfo = await waybillExport(selectedIds);
                if (!fileInfo.error) {
                    window.open(`/api/file/${fileInfo.id}`, "_blank");
                }
            }
        } finally {
            loading(false);
        }
    };

    const waybillExportAllClick = async (event, data) => {
        loading(true);
        const query = history.location.search;
        try {
            if (event.ctrlKey || event.metaKey) {
                window.open(`/api/waybill/${selectedIds[0]}/export/json`, "_blank");
            } else {
                let fileInfo = await waybillExportAll(query);
                if (!fileInfo.error) {
                    window.open(`/api/file/${fileInfo.id}`, "_blank");
                }
            }
        } finally {
            loading(false);
        }
    };

    async function avizo() {
        loading(true);
        try {
            await waybillsAvizo(selectedIds);
            selectedRows.forEach(sr => {
                sr.avisationStatus = WaybillAvisationStatus.REQUESTED;
            });
        } finally {
            loading(false);
        }
    }

    function onClickAvizoOpenModal() {
        if (moment(selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            toast.error('Невозможно авизовать заявку, так как дата привоза на ФМ в загрузке меньше текущей'.t);
            return;
        }
        setIsOpenAvizModal(true);
    }

    async function waybillDeleteLocal() {
        setIsDeleteConfirmOpen(false);
        loading(true);
        try {
            const wbIds = selectedRows.map(sr => sr.id);
            await waybillDelete(wbIds);
            refresh();
        } finally {
            loading(false);
        }
    }

    const onFormClose = () => {
        setIsFormOpen(false);
    };
    return (
        <Menu className="waybills-toolbar shd-inset" style={{ marginBottom: '0' }} size='small' borderless>
            <Menu.Item as="div" className="p-t-0 p-b-0" style={{ color: selectedIds.length ? 'inherit' : '#9e9e9e' }}>
                <Icon
                    name={selectedIds.length ? iconName : iconName}
                    style={{
                        fontSize: "15px",
                        marginLeft: "7px",
                        color: selectedIds.length ? '#106ebe' : '#9e9e9e',
                        marginTop: "-2px"
                    }}
                />
                &nbsp;<span style={numberStyle}>{totalUnits}</span>&nbsp;<span
                    style={descrStyle}><T>грузовых ед.,</T></span>
                &nbsp;<span style={numberStyle}>{totalWeight}</span>&nbsp;<span style={descrStyle}><T>кг</T></span>
            </Menu.Item>
            <Menu.Menu position='right'>
                <WbGridEditCostModal selectedRows={selectedRows} refresh={refresh}>
                    <Menu.Item disabled={!canEditCost}>
                        <Icon name="money bill alternate outline" /><T>Редактирование стоимости груза</T>
                    </Menu.Item>
                </WbGridEditCostModal>
                <WbGridEditServicesModal selectedRows={selectedRows} refresh={refresh}>
                    <Menu.Item disabled={!canEditServices}>
                        <Icon name="edit outline" /><T>Редактирование Сервисов</T>
                    </Menu.Item>
                </WbGridEditServicesModal>
                <WbGridClientEditModal selectedRows={selectedRows} refresh={refresh}>
                    <Menu.Item title={"редактировать клиента".t} disabled={!canClientEdit} onClick={() => {
                    }}>
                        <Icon name="address card" /><T>Редактирование клиента</T>
                    </Menu.Item>
                </WbGridClientEditModal>
                {<Menu.Item title={"авизовать по email".t} onClick={avizo} disabled={!canAvizoOld}>
                    <Icon name="clock outline" /><T>Авизация по email</T>
                </Menu.Item>
                }
                <Menu.Item onClick={onClickAvizoOpenModal} disabled={!canAvizo}>
                    <Icon name="clock outline" /><T>Авизация</T>
                </Menu.Item>
                <Menu.Item
                    //onClick={createPickupLocal}
                    disabled={!canPickup}
                    title={"cоздать FTL заявку из выбранных LTL заявок на хаб FM".t}
                    onClick={() => setIsFormOpen(true)}
                >
                    <Icon name="truck" disabled={!canPickup} /><T>Создать Pickup</T>
                </Menu.Item>
                <WbPointAddForm
                    profile="pointsLoading"
                    wbClientId={selectedRows.length && selectedRows[0].clientId}
                    pointsExistingIds={[]}
                    submit={createPickupLocal}
                    value={new WaybillPoint()}
                    open={isFormOpen}
                    onClose={onFormClose}
                >
                </WbPointAddForm>
                <WbProcessing type={"FTL"} selectedRows={selectedRows} updateFMIDs={updateFMIDs} refresh={refresh}>
                    <Menu.Item disabled={!canSend} title={"проверить и отправить выбранные заявки на исполнение".t}>
                        <Icon name="upload" disabled={!canSend} /><T>На исполнение</T>
                    </Menu.Item>
                </WbProcessing>
                <WbProcessing type={"FTL"} selectedRows={selectedRows} updateFMIDs={updateFMIDs} refresh={refresh}>
                    <Menu.Item disabled={!canChangeShippingMethod}
                        title={"изменить способ доставки и отправить выбранные заявки".t}>
                        <Icon name="upload" disabled={!canChangeShippingMethod} /><T>Изменить способ доставки</T>
                    </Menu.Item>
                </WbProcessing>
                <Menu.Item onClick={cancel} disabled={!canCancel}>
                    <Icon name="ban" disabled={!canCancel} /><T>Отменить</T>
                </Menu.Item>
                <Menu.Item onClick={createCopy} disabled={!canCopy}>
                    <Icon name="copy" /><T>Создать копию заявки</T>
                </Menu.Item>
                <Menu.Item onClick={() => setIsDeleteConfirmOpen(true)} disabled={!canDelete}>
                    <Icon name="trash" /><T>Удалить черновик(и)</T>
                </Menu.Item>
                <Menu.Item onClick={onCLickDeleteErroneous} disabled={!canDeleteErroneous}>
                    <Icon name="trash" /><T>Удалить ошибочные</T>
                </Menu.Item>
                <Confirm open={isDeleteConfirmOpen}
                    dimmer="inverted"
                    header={'Удаление черновика(ов)'.t}
                    content={'Восстановление данных черновика после удаления невозможно. Вы уверены?'.t}
                    confirmButton={'Удалить'.t}
                    cancelButton={'Отмена'.t}
                    onCancel={() => setIsDeleteConfirmOpen(false)}
                    onConfirm={waybillDeleteLocal} />
                <Menu.Item onClick={() => setSendDocsModalOpen(true)} disabled={!canDownloadDocs}>
                    <Icon name="mail" /><T>Отправить документы в письме</T>
                </Menu.Item>
                <DocsModal
                    open={sendDocsModalOpen}
                    onClose={() => setSendDocsModalOpen(false)}
                    rows={selectedRows}
                />
                <Menu.Item onClick={() => setDownloadDocsModalOpen(true)} disabled={!canDownloadDocs}>
                    <Icon name="download" /><T>Скачать документы</T>
                </Menu.Item>
                <DocsModal
                    open={downloadDocsModalOpen}
                    onClose={() => setDownloadDocsModalOpen(false)}
                    isDownload
                    rows={selectedRows}
                />
                <Menu.Item onClick={restoreFromDB} disabled={!canRestoreFromDB}>
                    <Icon name="folder open outline" /><T>Восстановить из БД</T>
                </Menu.Item>
                <Menu.Item onClick={create} disabled={!noSelection}
                    className={isFirstLoad ? "waybill-button-highlight" : ""}>
                    <Icon name="plus" /><T>Новая заявка</T>
                </Menu.Item>
                <WbsImportForm history={history} refresh={refresh}>
                    <Menu.Item title={"импорт заявок в формате Excel".t} disabled={!noSelection}>
                        <Icon name="arrow down" /><T>Импорт заявок</T>
                    </Menu.Item>
                </WbsImportForm>
                <Menu.Item
                    onClick={waybillExportClick}
                    disabled={!canExport}
                    title={"экспорт выбранных заявок в формате Excel".t}>
                    <Icon disabled={!canExport} name="arrow up" /><T>Экспорт заявок</T>
                </Menu.Item>
                <Menu.Item
                    onClick={waybillExportAllClick}
                    disabled={!canExportAll}
                    title={"экспорт всех заявок по фильтру в формате Excel".t}>
                    <Icon disabled={!canExportAll} name="arrow up" /><T>Экспорт заявок</T>
                </Menu.Item>
                <Menu.Item fitted>
                    <Divider vertical />
                </Menu.Item>
                <WbColumnsSetup
                    allColumns={columnsByRole(waybillColumnsSet, contextUser.current.role)}
                >
                    <Menu.Item style={{ display: 'inherit' }} title={"Настройка полей таблицы".t}>
                        <Icon name="setting" />
                    </Menu.Item>
                </WbColumnsSetup>
            </Menu.Menu>
        </Menu>
    );

}
