import {debounce} from 'awesome-debounce-promise';
import React, {useContext, useEffect, useState} from 'react';
import {Confirm, Grid, GridColumn, Icon, Segment, Step} from 'semantic-ui-react';
import Point, {PointStatusEnum} from '../../../../api/model/Point';
import PointAddress from '../../../../api/model/PointAddress';
import {getPointById, pointUpdate} from '../../../../api/points';
import {getPointClientById} from '../../../../api/pointsClient';
import T from '../../../../components/Translate';
import {LinkInfo} from '../../../../layout/navbar/Navbar';
import Shield from '../../../../layout/page/shield/Shield';
import {ContextFooter, ContextNavi} from '../../../../services/context';
import {StepData} from "../../../waybills/wbView/StepInfo";
import './PointView.css';
import PointViewFooter from './PointViewFooter';
import PointViewHeader from './PointViewHeader';
import PointViewMap from './PointViewMap';
import PointViewAddr from "./PointViewTabAddr";
import PointViewAddrParams from './PointViewTabAddrParams';
import PointViewComments from './PointViewTabComments';
import {onBeforeUnloadOff, onBeforeUnloadOn, trimStrings} from "../../../../services/utils";
import {Prompt} from "react-router";

const saveState = debounce((pt, setPoint) => pointUpdate(pt).then(d=> setPoint(d ? d : pt)), 1200);

export function PointView({ match, history, isClient }) {

    const [point, setPoint] = useState(new Point());
    const [notSaved, setNotSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [newLocation, setNewLocation] = useState(null);
    const [errors, setErrors] = useState([]);

    const contextNavi = useContext(ContextNavi);
    const contextFooter = useContext(ContextFooter);

    const tabKeyActive = match.params.tab;
    const companyId = match.params.companyId;

    const styleNoBorderYellow = {
        borderTop: '2px solid #fbbd08',
    };

    async function fetchData() {
        const pointId = match.params.id;
        const point = companyId
            ? await getPointClientById(pointId, companyId)
            : await getPointById(pointId);

        setPoint(point);
        setIsLoading(false);

        contextNavi.setItems([
            new LinkInfo("Адреса", `${isClient ? '' : '/admin'}/points`),
            new LinkInfo(point.address.value || new PointAddress(point.address).toString(), `${isClient ? '' : '/admin'}/points/${point.id}`),
        ]);
    }

    function backToList() {
        const url = isClient ? '/points' : '/admin/points';
        history.push(url);
    }

    function updatePoint(pointChanged, save = isClient) {
        setNotSaved(true);
        setPoint(pointChanged);
        save && saveState(trimStrings(pointChanged, ['fmid']), setPoint);
    }

    const saveData = async () => {
        let noErrors = false;
        await pointUpdate(trimStrings(point, ['fmid'])).then(d => {
            noErrors = !!d;
            setNotSaved(false);
        });
        return noErrors;
    };

    useEffect(() => {
        if (notSaved && !isClient) onBeforeUnloadOn();
        else onBeforeUnloadOff();
        return () => onBeforeUnloadOff();
    }, [notSaved]);

    const promtFunc = (location) => {
        const isThisPage = location.pathname.includes(`/admin/points/${point.id}`);
        setNewLocation(!isThisPage ? location : null);
        return isThisPage;
    };

    const onConfirm = () => {
        let location = {...newLocation};
        setNewLocation(null);
        history.push(location);
    };

    let steps = [
        new StepData(() => <PointViewAddrParams
            point={point}
            companyId={companyId}
            updatePoint={updatePoint}
            isClient={isClient}
            errors={errors}
            setErrors={setErrors}
            />,
            "params",
            "Грузополучатели и параметры",
            "info"
        ),
        new StepData(() => <PointViewComments
            isClient={isClient}
            point={point}
            updatePoint={(point) => setPoint(point)} />
            ,
            "comments",
            "История",
            "history"
        )
    ];

    if (!isClient || point.statusId === PointStatusEnum.NEEDS_CORRECTION) {
        steps = [
            new StepData(() => <PointViewAddr point={point} updatePoint={updatePoint} />, "location", "Локация", "map marker alternate"),
            ...steps
        ];
    }

    const stepActive = steps.find(s => s.index === tabKeyActive);

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        point && contextFooter.setIndicator(<PointViewFooter point={point} />);
        return () => contextFooter.setIndicator(null);
    }, [point]);

    return (
        <Shield loading={isLoading} loadingOver={point.id}>
            <Grid columns={2} className="m-t-0 h-100">
                <Grid.Row stretched className="p-t-0 p-b-0 m-t-1-negative h-100">
                    <Grid.Column className="p-b-0 p-r-0 m-r-1-negative" style={{ width: "16%" }}>
                        <Step.Group fluid vertical size='tiny' className="m-b-0 steps-grey">
                            <div className="step-wrapper">
                                {steps.map((s) => (
                                    <Step
                                        key={s.index}
                                        active={tabKeyActive === s.index}
                                        link
                                        onClick={() => {
                                            const url = isClient ?
                                                `/points/${point.id}/company/${companyId}/${s.index}` :
                                                `/admin/points/${point.id}/${s.index}`;
                                            history.push(url);
                                        }}
                                    >
                                        <Icon name={s.icon} />
                                        <Step.Content>
                                            <Step.Title><T>{s.text}</T></Step.Title>
                                        </Step.Content>
                                    </Step>
                                ))}
                            </div>
                        </Step.Group>
                    </Grid.Column>
                    <Grid.Column className="editor-rightcol p-l-0 h-100" style={{width: "84%"}}>
                        <Segment className="h-100 p-b-0 flex-col" style={{
                            marginRight: '25px',
                            paddingLeft: "20px",
                            paddingRight: "15px",
                            }}
                        >
                            <PointViewHeader
                                companyId={companyId}
                                isClient={isClient}
                                point={point}
                                setLoading={setIsLoading}
                                fetchData={fetchData}
                                backToList={backToList}
                                saveData={saveData}
                                errors={errors}
                            />

                            <div className="scrollable-v m-t-10 p-t-15 h-100 flex-grow" style={styleNoBorderYellow}>
                                <Grid className="h-100">
                                    <GridColumn width={11}>
                                        {stepActive.component()}
                                    </GridColumn>
                                    <GridColumn width={5}>
                                        <PointViewMap point={point} />
                                    </GridColumn>
                                </Grid>
                            </div>

                            <Prompt when={notSaved && !newLocation && !isClient} message={promtFunc}/>
                            <Confirm open={!!newLocation && !isClient}
                                     dimmer="inverted"
                                     header={'Изменения в адресе не будут сохранены'.t}
                                     content={'Для сохранения изменений нажмите на кнопку “Зарегистрировать” во вкладке “Действия”.'.t}
                                     confirmButton={'Ок'.t}
                                     cancelButton={'Вернуться к редактированию адреса'.t}
                                     onCancel={() => setNewLocation(null)}
                                     onConfirm={onConfirm}
                            />

                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Shield >
    );
}
