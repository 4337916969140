import React from 'react';
import {
    dataTypes,
    fieldTypeByDataType,
    fieldTypes,
    FieldTypesEnum,
    optionsDate
} from "../../../../api/model/ImportForms";
import RuleInput from "../ruleInput";
import {normalizeTime} from "../../../../services/normalizeTime";
import DropdownForDictionary from "./dropdownForDictionary";
import {ThermoModeOptions} from "../../../../api/model/Enums";

const Constant = ({type, disabled, delBtn, onChange, icon, dictionaryType, dataType, ...props}) => {
    const componentByType = (type) => {
        if (dictionaryType) {
            return <DropdownForDictionary
                {...props}
                disabled={disabled}
                dictionaryType={dictionaryType}
                onChange={onChange}
            />;
        }

        switch (type) {
            case FieldTypesEnum.TIME:
                return <RuleInput
                    {...props}
                    disabled={disabled}
                    icon={icon}
                    onChange={(e, {name, value}) => onChange(null, {
                        name,
                        value: normalizeTime(value)
                    })}
                />;
            case FieldTypesEnum.DATE:
                return <RuleInput
                    {...props}
                    disabled={disabled}
                    onChange={onChange}
                    inputType={fieldTypes.DROPDOWN}
                    options={optionsDate}
                    clearable
                />;
            case FieldTypesEnum.TEMPERATURE:
                return <RuleInput
                    {...props}
                    disabled={disabled}
                    onChange={onChange}
                    inputType={fieldTypes.DROPDOWN}
                    options={ThermoModeOptions}
                    clearable
                />;
            case FieldTypesEnum.BASIC:
            default:
                return <RuleInput
                    {...props}
                    disabled={disabled}
                    inputType={fieldTypeByDataType[dataType]}
                    isInt={dataType === dataTypes.INT}
                    canBeNegative={false}
                    icon={icon}
                    onChange={onChange}
                />;
        }
    };

    return <div className="constant">
        {componentByType(type)}
        {!disabled && delBtn}
    </div>;
};

export default Constant;