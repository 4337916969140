import React, {useEffect, useState} from 'react';
import './style.css';
import {Button, Dropdown, Form, Grid, Modal} from "semantic-ui-react";
import T from "../../components/Translate";
import {DictionariesEnum, DictionariesSettings} from "../../api/model/Dictionaries";
import InputSelectMultiple from "../../components/inputs/InputSelectMultiple";
import {
    getRegions,
    searchCompanyNames,
    getPointFMIDsByCompanies,
    searchClientNames,
    getDictionaryItemData,
    createDictionaryItem,
    updateDictionaryItem
} from "../../api/dictionaries";


const EditModal = ({open, id, onClose, dictionary}) => {

    const [form, setForm] = useState({});
    const [companyNames, setCompanyNames] = useState([]);
    const [pointFMIDs, setPointFMIDs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [regions, setRegions] = useState([]);
    const [clientNames, setClientNames] = useState([]);
    const [loading, setLoading] = useState(false);

    const update = (value) => {
        setForm(form => {
            return {
                ...form,
                ...value
            };
        });
    };

    const dictionarySettings = DictionariesSettings.find(h => h.key.toString() === dictionary);

    const dictionaryName = () => {
        return dictionarySettings && dictionarySettings.wordForModalLabel;
    };

    const disabledSave = () => {
        switch (dictionary && dictionary.toString()) {
            case DictionariesEnum.NETWORKS:
                return !form.name;
            case DictionariesEnum.REGIONS:
                return !form.name || !form.timezone || !form.country;
            case DictionariesEnum.CITIES:
                return !form.name || !form.region;
            case DictionariesEnum.AVISATION:
                return !form.name || !((form.clientNames || []).length);
            default: return null;
        }
    };

    useEffect(()=>{
        dictionary && dictionary.toString() === DictionariesEnum.CITIES && getRegions('').then(rs=>setRegions(rs));
    }, [dictionary]);

    useEffect(()=>{
        dictionary && dictionary.toString() === DictionariesEnum.NETWORKS && getPointFMIDsByCompanies(form.companyNames ? form.companyNames : '').then(rs=>setPointFMIDs(rs));
    }, [form.companyNames]);

    useEffect(()=>{
        if (dictionary && dictionary.toString() === DictionariesEnum.NETWORKS) {
            let formCompanies = form.companyNames ? form.companyNames : [];
            searchQuery !== '' ? searchCompanyNames(searchQuery).then(names => setCompanies(names.map(n=>n.name), formCompanies)) : setCompanies([], formCompanies);
        }
    }, [searchQuery, form.companyNames]);

    useEffect(()=>{
        if (dictionary && dictionary.toString() === DictionariesEnum.AVISATION) {
            let formClients = form.clientNames ? form.clientNames : [];
            searchQuery !== '' ? searchClientNames(searchQuery).then(names => setClients(names, formClients)) : setClients([], formClients);
        }
    }, [searchQuery, form.clientNames]);

    const setCompanies = (companies = [], formCompanies = []) => {
        setCompanyNames([...new Set([...companies, ...formCompanies])]);
    };

    const setClients = (clients = [], formClients = []) => {
        setClientNames([...new Set([...clients, ...formClients])]);
    };

    const setData = (data) => {
        setForm({...data});
        setLoading(false);
    };

    const loadingError = () => {
        setLoading(false);
        onClose();
    };

    useEffect(()=>{
         if (id) {
             setLoading(true);
             dictionarySettings && getDictionaryItemData(dictionarySettings.urlName, id).then(r => setData(r)).catch(loadingError);
         } else setForm({});
    }, [id, open]);

    const formDictionary = (dictionary) => {
        switch (open && dictionary ? dictionary.toString() : '') {
            case DictionariesEnum.NETWORKS:
                return <Form.Group  widths='equal'>
                    <Form.Input
                        key={'name-network'}
                        maxLenght={255}
                        required
                        placeholder={'Введите наименование сети'.t}
                        label={'Сеть'.t}
                        value={form.name}
                        onChange={e => update({name: e.target.value})}
                    />
                    <Form.Field key={'companies-network'}>
                        <label>Грузополучатели</label>
                        <Dropdown
                            onClose={()=>setSearchQuery('')}
                            onSearchChange={(e, {searchQuery})=>setSearchQuery(searchQuery)}
                            className='InputDropdown'
                            placeholder={"Введите часть названия грузополучателя".t}
                            options={companyNames.map(c=>{return {key: c, value: c, text: c}})}
                            value={form.companyNames}
                            onChange={(e, {value}) => update({companyNames: value})}
                            name="companyNames"
                            noResultsMessage={'Результатов не найдено'.t}
                            fluid
                            multiple
                            search
                            selection
                        />
                    </Form.Field>
                    <Form.Field key={'pointFMIDs-network'}>
                        <label>Название в системе FM</label>
                        <Dropdown
                            className='InputDropdown'
                            placeholder={"Введите часть Названия в системе FM".t}
                            options={[...new Set([...pointFMIDs, ...(form.pointFMIDs || [])])].map(c=>{return {key: c, value: c, text: c}})}
                            value={form.pointFMIDs}
                            onChange={(e, {value}) => update({ pointFMIDs: value })}
                            name="pointFMIDs"
                            noResultsMessage={'Результатов не найдено'.t}
                            fluid
                            multiple
                            search
                            selection
                        />
                    </Form.Field>
                </Form.Group>;
            case DictionariesEnum.REGIONS:
                return <Grid columns='equal' divided='vertically'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Input
                                maxLenght={255}
                                required
                                label={'Страна'.t}
                                placeholder={'Страна'.t}
                                value={form.country}
                                onChange={e => update({country: e.target.value})}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                maxLenght={255}
                                required
                                label={'Наименование региона'.t}
                                placeholder={'Наименование региона'.t}
                                value={form.name}
                                onChange={e => update({name: e.target.value})}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Почтовые коды (индексы)</label>
                                <InputSelectMultiple
                                    placeholder={"Почтовые коды (индексы)".t}
                                    value={form.postalCodes}
                                    onChange={(e, {value}) => update({postalCodes: value})}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                maxLenght={10}
                                required
                                label={'Часовой пояс'.t}
                                placeholder={'Часовой пояс'.t}
                                value={form.timezone}
                                onChange={e => update({timezone: e.target.value})}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>;
            case DictionariesEnum.CITIES:
                return <Form.Group  widths='equal'>
                    <Form.Input
                        maxLenght={255}
                        required
                        label={'Наименование города'.t}
                        placeholder={'Наименование города'.t}
                        value={form.name}
                        onChange={e => update({name: e.target.value})}
                    />
                    <Form.Field required>
                        <label>Регион</label>
                        <Dropdown
                            placeholder={"Регион".t}
                            value={form.region}
                            onChange={(e, {value}) => {
                                update({region: value});
                            }}
                            className='InputDropdown'
                            options={regions.map(r=>{return {key: r.id, value: r, text: r.name}})}
                            name="region"
                            fluid
                            search
                            selection
                            selectOnBlur={false}
                        />
                    </Form.Field>
                </Form.Group>;
            case DictionariesEnum.AVISATION:
                return <Form.Group  widths='equal'>
                    <Form.Input
                        maxLenght={255}
                        required
                        label={'Наименование группы'.t}
                        placeholder={'Наименование группы'.t}
                        value={form.name || ''}
                        onChange={e => update({name: e.target.value})}
                    />
                    <Form.Field required>
                        <label>Клиенты, входящие в группу</label>
                        <Dropdown
                            multiple
                            onClose={()=>setSearchQuery('')}
                            onSearchChange={(e, {searchQuery})=>setSearchQuery(searchQuery)}
                            placeholder={"Клиенты, входящие в группу".t}
                            value={form.clientNames || []}
                            onChange={(e, {value}) => {
                                update({clientNames: value});
                            }}
                            className='InputDropdown'
                            options={[...new Set([...clientNames, ...(form.clientNames || [])])].map(r=>({key: r, value: r, text: r}))}
                            name="clientNames"
                            fluid
                            search
                            selection
                            noResultsMessage={'Результатов не найдено'.t}
                        />
                    </Form.Field>
                </Form.Group>;
            default :
                return null;
        }
    };

    const onSave = () => {
        if (dictionarySettings) {
            !id ? createDictionaryItem(dictionarySettings.urlName, form).then(()=>onClose()) : updateDictionaryItem(dictionarySettings.urlName, form).then(()=>onClose());
        }
    };

    return (
        <Modal
            closeIcon
            open={open}
            onClose={onClose}
        >
            <Modal.Header>{id ? `Редактировать` : `Добавить`} {dictionaryName(dictionary)}</Modal.Header>
            <Modal.Content>
                <div className="m-b-10">
                    <Form loading={loading}>
                        {formDictionary(dictionary, form, update)}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}
                ><T>Отменить</T></Button>
                <Button
                    primary
                    onClick={onSave}
                    disabled={disabledSave()}
                >{id ? "Сохранить" : "Создать"}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EditModal;
