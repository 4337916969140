import React, {useEffect, useState} from 'react';
import RuleWithOpenBlock from "./ruleWithOpenBlock";
import {
    BoolOptions,
    dateInputs,
    defaultTemplate,
    FieldTypesEnum,
    timeInputs
} from "../../../../api/model/ImportForms";
import {Form} from "semantic-ui-react";
import FormField from "../formField";
import {getDateFormats, getSplitters} from "../../../../api/importForms";


const CustomReplaceFields = ({inputs, value, options, propsForInput}) => {
    return <>
        {
            inputs.map(i => {
                return <FormField
                    required
                    name={i.name}
                    label={i.label}
                    value={(value || {})[i.name]}
                    inputType={i.inputType}
                    options={options[i.optionsName]}
                    {...propsForInput}
                />;
            })
        }
    </>;
};

const CustomReplace = ({value, onChange, delBtn, icon, disabled, type, name}) => {
    const [options, setOptions] = useState({
        bool: BoolOptions
    });

    const resToOptions = (res) => {
        const options = {};
        Object.keys(res).forEach(k => {
            options[k] = res[k].map(r => ({
                text: r.displayName,
                value: `${r.value}`,
                key: r.value,
                description: r.description,
                values: r.values
            }));
        });
        return options;
    };

    useEffect(() => {
        getDateFormats().then(res => {
            setOptions(o => ({...o, ...resToOptions(res)}));
        });
        getSplitters().then(res => {
            setOptions(o => ({...o, splitterTypes: res}));
        });
    }, []);

    const getValueText = () => {
        switch (type) {
            case FieldTypesEnum.TIME: {
                const val = (options.timeOrderFormats || []).find(v => v.value === (value || {}).timeOrderFormat);
                const defaultTime = defaultTemplate[FieldTypesEnum.TIME];
                const text = (((val || {}).values || []).toString() || '')
                    .replaceAll(',', (value || {}).splitterType || '')
                    .replace('HH', defaultTime.split(':')[0])
                    .replace('mm', defaultTime.split(':')[1])
                    .replace('ss', '15')
                    + ((value || {}).amPm ? ' AM' : '');
                return `${text || ''} --> ${defaultTime}`;
            }
            case FieldTypesEnum.DATE:
            default: {
                const val = (options.orderFormats || []).find(v => v.value === (value || {}).orderFormat);
                const defaultDate = defaultTemplate[FieldTypesEnum.DATE];
                const monthFormat = (options.monthFormats || []).find(v => v.value === (value || {}).monthFormat);
                const yearFormat = (options.yearFormats || []).find(v => v.value === (value || {}).yearFormat);
                const text = (((val || {}).values || []).toString() || '')
                    .replaceAll(',', (value || {}).splitterType || '')
                    .replace('yy', (yearFormat || {}).text || '')
                    .replace('mm', (monthFormat || {}).text || '')
                    .replace('dd', defaultDate.split('.')[0]);
                return `${text || ''} --> ${defaultDate}`;
            }
        }
    };

    const valueText = getValueText();

    const getInputs = (type) => {
        switch (type) {
            case FieldTypesEnum.TIME:
                return timeInputs;
            case FieldTypesEnum.DATE:
                return dateInputs;
            default:
                return [];
        }
    };

    const changeValue = (e, {name: valueName, value: val}) => {
        const newValue = {...value};
        newValue[valueName] = val;

        onChange(null, {
            name,
            value: newValue
        });
    };

    const propsForInput = {
        disabled,
        onChange: changeValue
    };

    return (
        <RuleWithOpenBlock
            value={valueText}
            title={valueText}
            delBtn={delBtn}
            icon={icon}
            disabled={disabled}
            readOnly
        >
            <Form className="custom-replace">
                <CustomReplaceFields
                    value={value}
                    options={options}
                    propsForInput={propsForInput}
                    inputs={getInputs(type)}
                />
            </Form>
        </RuleWithOpenBlock>
    );
};

export default CustomReplace;