import React, {useContext, useEffect} from 'react';
import ScrollTable from "../../layout/table/scrollTable";
import {getClients} from "../../api/importForms";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi, ContextUser} from "../../services/context";
import AppTableHeader from "../../components/tables/AppTableHeader";
import AddFormModal from "./addFormModal";
import AppToolbarButton from "../../components/buttons/AppToolbarButton";
import UserPermissions from "../../api/model/UserPermissions";

const ImportClientsPage = ({location, history}) => {
    const contextUser = useContext(ContextUser);
    const permissions = contextUser.current.permissions;
    const isOnlyView = !permissions.includes(UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE);

    const headers = [
        new AppTableHeader('name', 'Клиент, наименование'),
        new AppTableHeader('fmid', 'Клиент, searchname'),
        new AppTableHeader('formsCount', 'Количество форм клиента'),
        new AppTableHeader('status', 'Статус клиента'),
    ];

    const contextNavi = useContext(ContextNavi);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("Импорт заявок", "/import")]);
    }, []);

    const onClickRow = (row) => {
        history.push(`/import/${row.fmid}`);
    };

    return (
       <ScrollTable
            onClickRow={onClickRow}
            location={location}
            headers={headers}
            getRowsFunc={getClients}
            rightHeaderData={
                !isOnlyView && <AddFormModal history={history}>
                    <AppToolbarButton icon="plus">
                        Добавить форму
                    </AppToolbarButton>
                </AddFormModal>
            }
       />
    );
};

export default ImportClientsPage;