import React, {useEffect, useState} from 'react';
import {Form} from 'semantic-ui-react';

import {PointTypeEnum} from '../../../../api/model/Point';
import {LegalFormOptions} from '../../../../api/model/Company';
import InputDropdownSearch from '../../../../components/inputs/InputDropdownSearch';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import T from '../../../../components/Translate';
import O from '../../../../components/Option';
import {getActivitiesRequest, getPlatformsRequest} from "../../../../api/pointsClient";
import PointTypeRadioButton from "./PointTypeRadioButton";

const PointFormStep2 = ({ data, update, ve, hasPermissions, companyOptions, selectedCompany, setSelectedCompany, isClient }) => {

    const [legalFormOptions, setLegalFormOptions] = useState(LegalFormOptions);

    const [platformOptions, setPlatformOptions] = useState([]);

    const [activityOptions, setActivityOptions] = useState([]);

    useEffect(() => {
        getPlatforms();
    }, []);

    useEffect(() => {
        data.platformId && getActivities();
    }, [data.platformId]);

    const getPlatforms = async () => {
        const {platforms} = await getPlatformsRequest();

        setPlatformOptions(platforms.map(i => new O(i.id, i.name)))
    };

    const getActivities = async () => {
        const {activities} = await getActivitiesRequest(data.platformId);

        setActivityOptions(activities.map(i => new O(i.id, i.name)))
    };

    function handleLegalFormOptionAdd(e, { value }) {
        setLegalFormOptions([new O(value, value), ...legalFormOptions]);
        //setValue(value);
    }

    const contact = (data.contacts && data.contacts.length && data.contacts[0]) || {};

    const companyOption = new O(data.companyName, data.companyName);

    const rowCompany = (
        <Form.Group>
            <Form.Field required error={ve.includes("companyLegalForm")} width="4">
                <label><T>Юр. форма</T></label>
                <InputDropdown
                    allowAdditions
                    placeholder={"Юр. форма".t}
                    options={legalFormOptions}
                    value={data.companyLegalForm}
                    onChange={(value, text) => update({
                        companyLegalForm: value,
                    })}
                    handleAdd={handleLegalFormOptionAdd}
                />
            </Form.Field>
            <Form.Field required error={ve.includes("companyName")} width="12">
                <label><T>Название грузополучателя</T></label>
                <InputDropdownSearch
                    allowAdditions
                    placeholder={"Найдите или введите название грузополучателя".t}
                    options={data.companyName ? [companyOption] : []}
                    value={data.companyName}
                    onChange={(value, text) => update({
                        companyName: text,
                    })}
                />
            </Form.Field>
        </Form.Group>
    );

    return <React.Fragment>

        {isClient && <Form.Field
            required
            error={ve.includes('selectedCompany')}
        >
            <label><T>Выбрать клиента</T></label>
            <InputDropdown
                placeholder={"Выбрать клиента".t}
                options={companyOptions}
                value={selectedCompany}
                onChange={val => {
                    setSelectedCompany(val);
                    update({});
                }}
            />
        </Form.Field>}
        {rowCompany}
        <PointTypeRadioButton
            data={data}
            update={update}
            hasPermissions={hasPermissions}
        />
        <Form.Group widths="equal">
            <Form.Input
                required={!!contact.phone}
                error={ve.includes("contactName")}
                label={'Контактное лицо'.t}
                placeholder={'Контактное лицо'.t}
                value={contact.name}
                onChange={e => update({
                    contacts: [{ ...contact, name: e.target.value }]
                })}
            />
            <Form.Input
                required={!!contact.name}
                error={ve.includes("contactPhone")}
                label={'Телефон'.t}
                placeholder={'Телефон'.t}
                value={contact.phone}
                onChange={e => update({
                    contacts: [{ ...contact, phone: e.target.value }]
                })}
            />
        </Form.Group>
        {
            data.pointType === PointTypeEnum.FM &&
                <Form.Group widths="equal">
                    <Form.Field>
                        <label><T>Платформа</T></label>
                        <InputDropdown
                            placeholder={"Выбрать платформу".t}
                            options={platformOptions}
                            value={data.platformId}
                            clearable
                            onChange={val => update({platformId: val, activityId: null})}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label><T>Активность</T></label>
                        <InputDropdown
                            placeholder={"Выбрать активность".t}
                            options={activityOptions}
                            disabled={!data.platformId}
                            clearable
                            value={data.activityId}
                            onChange={val => update({activityId: val})}
                        />
                    </Form.Field>
                </Form.Group>
        }
    </React.Fragment>;
};

export default PointFormStep2;
