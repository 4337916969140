import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Form, Grid, GridColumn, Header, Popup } from 'semantic-ui-react';
import T from '../../../components/Translate';


CargoSSCCInputs.propTypes = {
	valueRaw: PropTypes.string,
	updateSSCC: PropTypes.func.isRequired
};

export default function CargoSSCCInputs({
	valueRaw,
	countRaw,
	updateSSCC,
	focusInput,
	children
}) {
	const [open, setOpen] = useState(false);
	const count = Number.parseInt(countRaw) || 0;

	if (count < 1 || count > 25) {
		return children;
	}

	const value = valueRaw ? valueRaw.split(',') : [];
	const elements = [];

	for (let index = 0; index < count; index++) {

		const element = (
			<Form.Group key={index}>
				<Form.Input
					inline
					label={(index + 1).toString().padStart(3, "0")}
					placeholder='Код SSCC'
					value={value[index] || ''}
					onChange={e => {
						onValueChanged(index, e.target.value)}}
					onKeyPress={onKeyPressed}
				/>
			</Form.Group>
		);
		elements.push(element);
	}

	const elementsCols = elements.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / 5);

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new chunk
		}
		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);


	function onValueChanged(index, valueIn) {
		const arrayInitial = value || [];
		const arrayOfCountLength = Array.from({ length: count }, (v, i) => arrayInitial[i]);

		arrayOfCountLength[index] = valueIn;
		updateSSCC(arrayOfCountLength);
	}

	function onKeyPressed(event) {
		if (event.which === 13) { // key === "Enter"
			focusInput();
			setOpen(false);
		}
	}

	return (
		<Popup
			flowing
			trigger={children}
			hideOnScroll
			on='click'
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
		>
			<Form size="small">
				<Header><T>Грузовые единицы</T></Header>
				<Grid columns={elementsCols.length || 1}>
					{elementsCols.map((col, idx) => <GridColumn key={idx}>{col}</GridColumn>)}
				</Grid>
			</Form>
		</Popup>
	);

}

