import React, {useEffect, useRef, useState} from 'react';
import {MultiGrid} from 'react-virtualized';
import {SelectableGroup, createSelectable} from 'react-selectable';
import 'react-virtualized/styles.css';
import './style.css';
import Cell from "./cell";
import ResizeCell from "./resizeCell";
import TextAreaForTable from "./textAreaForTable";
import AddCell from "./addCell";
import {divideArray} from "./const";

const BigTable = ({
                      selectedCells,
                      setSelectedCells,
                      data,
                      height = 0,
                      width = 0,
                      disabled = false,
                      addRow,
                      addCol,
                      setSizes,
                      setValue
                  }) => {
    const [open, setOpen] = useState(null);

    const Element = ({children}) => {
        return children;
    };

    const SelectableComponent = createSelectable(Element);

    const columnCount = Object.keys((data[0] || {}).cells || {}).length + 2;
    const rowCount = data.length + 2;

    const cellRenderer = ({columnIndex, key, rowIndex, style}) => {
        const isLastRow = rowIndex === (rowCount - 1);
        const isLastCol = columnIndex === (columnCount - 1);
        const isLast = isLastRow || isLastCol;
        const isInput = !(rowIndex === 0 || columnIndex === 0) && !isLast;

        const fixRowIndex = rowIndex - 1;
        const fixColIndex = columnIndex - 1;

        return (
            <span
                className={`${rowIndex === 0 ? 'big-table__cell-renderer_first-row' : ''} ${columnIndex === 0 ? 'big-table__cell-renderer_first-col' : ''}`}
                key={key}
                style={style}
            >
                {
                    isLast && <AddCell
                        disabled={disabled}
                        add={isLastRow ? addRow : addCol}
                        isRow={isLastRow}
                        isFirst={isLastRow ? columnIndex === 0 : rowIndex === 0}
                    />
                }
                {
                    !isLast && (isInput
                        ? <SelectableComponent
                            selectableKey={key}
                        >
                            <Cell
                                borders={borders}
                                disabled={disabled}
                                setOpen={setOpen}
                                setValue={(value) => setValue(fixRowIndex, fixColIndex, value)}
                                style={style}
                                columnIndex={fixColIndex}
                                rowIndex={fixRowIndex}
                                keyId={key}
                                data={data}
                                selectedCells={selectedCells}
                                setSelectedCells={setSelectedCells}
                            />
                        </SelectableComponent>
                        : <ResizeCell
                            selectedCells={selectedCells}
                            setSizes={(val) => setSizes(fixRowIndex, fixColIndex, val)}
                            data={data}
                            setSelectedCells={handleSelection}
                            isCol={rowIndex === 0}
                            index={rowIndex === 0 ? columnIndex : rowIndex}
                        />)
                }
            </span>
        );
    };


    const setOpenSelected = (selectedKeys) => {
        const keyId = selectedKeys[0];
        const index = keyId.split('-');
        const rowIndex = Number(index[0]);
        const columnIndex = Number(index[1]);
        setOpen({
            keyId,
            columnIndex,
            rowIndex,
        });
    };

    const handleSelection = (selectedKeys) => {
        setSelectedCells(selectedKeys);
        if (selectedKeys.length === 1) {
            document.getElementById(`input-${selectedKeys[0]}`).click();
            setOpenSelected(selectedKeys);
        }
    };

    const colWidth = ({index}) => {
        if (index === 0 || index === columnCount - 1) return 50;
        return data[0].cells[index - 1].width || 100;
    };

    const rowHeight = ({index}) => {
        if (index === 0 || index === rowCount - 1) return 30;
        return data[index - 1].height || 30;
    };

    const ref = useRef();

    useEffect(() => {
        ref.current && ref.current.recomputeGridSize();
    }, [data]);

    const onSelection = (selectedKeys) => {
        const el = document.querySelector('.big-table > div:first-child[style*=\'z-index: 9000\']');
        el.style.display = !selectedKeys.length && 'none';
    };

    const onBlur = () => {
        setOpen(null);
    };

    const [borders, setBorders] = useState({});

    useEffect(() => {
        const [top, bottom, left, right] = divideArray(selectedCells);

        setBorders({
            top, bottom, left, right
        });
    }, [selectedCells]);

    const onTab = (e) => {
        if (open) {
            const {columnIndex: colOld, rowIndex: rowOld} = open;
            const rowIndex = colOld === (columnCount - 3) ? rowOld + 1 : rowOld;
            const columnIndex = colOld < (columnCount - 3) ? colOld + 1 : 0;

            if (rowIndex < rowCount - 2) {
                e.preventDefault();
                const keyId = `${rowIndex}-${columnIndex}`;

                const els = document.getElementsByClassName('ReactVirtualized__Grid');
                const el = els && els[els.length - 1];
                const cell = document.getElementById(`input-${keyId}`);

                const open = () => setOpen({
                    keyId,
                    columnIndex,
                    rowIndex
                });

                if (el && (!cell || (window.innerWidth - cell.getBoundingClientRect().left < 150))) {
                    if (columnIndex !== 0) {
                        el.scrollBy(300, 0);
                    } else {
                        el.scrollTo(0, el.scrollTop);
                    }
                    setTimeout(open, 10);
                } else {
                    open();
                }

                setSelectedCells([keyId]);
            }
        }
    };

    return (
        <div
            onBlur={onBlur}
        >
            <SelectableGroup
                enabled
                className="big-table"
                onEndSelection={handleSelection}
                onSelection={onSelection}
            >
                <MultiGrid
                    ref={ref}
                    cellRenderer={cellRenderer}
                    columnCount={columnCount}
                    rowCount={rowCount}
                    columnWidth={colWidth}
                    height={height}
                    rowHeight={rowHeight}
                    width={width}
                    hideTopRightGridScrollbar
                    hideBottomLeftGridScrollbar
                    enableFixedColumnScroll
                    enableFixedRowScroll
                    fixedColumnCount={1}
                    fixedRowCount={1}
                />
            </SelectableGroup>
            <TextAreaForTable
                disabled={disabled}
                table={data}
                data={open}
                setValue={setValue}
                onTab={onTab}
            />
        </div>
    );
};

export default BigTable;