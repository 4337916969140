import React, {useEffect, useState} from 'react';
import RuleInput from "../ruleInput";
import {defaultTemplate, fieldTypes, FieldTypesEnum} from "../../../../api/model/ImportForms";
import {getDateFormats} from "../../../../api/importForms";

const Template = ({value, onChange, delBtn, disabled, name, type}) => {
    const [options, setOptions] = useState([]);

    const getName = (type) => {
        switch (type) {
            case FieldTypesEnum.TIME:
                return 'time';
            case FieldTypesEnum.DATE:
                return 'date';
            default:
                return null;
        }
    };

    useEffect(() => {
        getDateFormats().then(res => {
            setOptions(res[`${getName(type)}Formats`].map(r => ({
                text: r.displayName,
                value: r.value,
                key: r.value,
            })));
        });
    }, []);

    const text = `${((options || []).find(o => o.value === value) || {}).text || ''} --> ${defaultTemplate[type]}`;

    return (
        <div className="rule-value__input splitters">
            <RuleInput
                disabled={disabled}
                inputType={fieldTypes.DROPDOWN}
                options={options}
                onChange={onChange}
                clearable
                value={value}
                name={name}
                text={text}
            />
            {!disabled && delBtn}
        </div>
    );
};

export default Template;