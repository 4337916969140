import React, {useEffect, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';

export default function InputSelectMultiple({onChange, value, placeholder, disabled = false}) {

    const [codesOptions, setCodesOptions] = useState(value ? value.map(p => {return {key: p, text: p, value: p};}) : []);
    const [values, setValues] = useState(value);

    useEffect(() => {
        if (value){
            setCodesOptions(value.map(p => {
                return {key: p, text: p, value: p};
            }));
        }
        setValues(value);
    }, [value]);

    return (
        <Dropdown
            className="InputDropdown"

            options={codesOptions}
            placeholder={placeholder}
            search
            selection
            fluid
            multiple
            allowAdditions
            value={values}
            onAddItem={(e, {value}) => {
                setCodesOptions([...codesOptions, {key: value, text: value, value: value}]);
            }}
            onChange={onChange}
            noResultsMessage={'Результатов не найдено'.t}
            additionLabel={'Добавить '.t}

            disabled={disabled}
        />
    );
}
