import React, { useState, useMemo, useEffect } from 'react';
import {
    Button,
    Dimmer,
    Form,
    Grid,
    GridColumn,
    Input,
    Loader,
    Message,
    Modal,
} from 'semantic-ui-react';
import WbAvizAvailableTimeslot from '../wbGrid/WbAvizAvailableTimeslot';
import moment from 'moment';
import InputDropdown from '../../../components/inputs/InputDropdown';
import { LoadCapacityOptions } from '../../../api/model/Point';
import T from '../../../components/Translate';
import { waybillAvisationRegistration, waybillAvisationSet } from '../../../api/waybills';
import { waybillAvisationSaveTransport } from '../../../api/waybillAviz';
import PhoneNumber from "../../../components/inputs/PhoneNumber";

const WbAvisModal = ({ open, onClose, wb, type, data, loadForm, loadWb }) => {
    const title = useMemo(
        () => {
            if (type === 'timeslot') {
                return 'Редактирование Даты/Времени авизации';
            }

            if (type === 'transport') {
                return 'Редактирование данных по ТС и Водителю';
            }
        },
        [type],
    );

    let [form, setForm] = useState({
        dateTimeAvisation: {},
    });

    let [errors, setErrors] = useState([]);

    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors([]);
    }, [form]);

    useEffect(
        () => {
            setForm({
                dateTimeAvisation: {
                    date: data.timeslot.deliveryDate,
                    timeslot: {
                        date: data.timeslot.deliveryDate,
                        start: data.timeslot.start,
                        end: data.timeslot.end,
                    },
                },
                weightCapacity: data.transport.weightCapacity,
                driver: data.transport.driver,
                transportNumber: data.transport.transportNumber,
                driverPhoneNumber: data.transport.driverPhoneNumber,
            });
        },
        [data, open],
    );

    const handleOnChangeDtoTimeSlot = value => {
        let newForm = { ...form };
        newForm.dateTimeAvisation = value;
        setForm(newForm);

        if (
            moment(newForm.dateTimeAvisation.date).format('YYYY-MM-DD') <
            moment().format('YYYY-MM-DD')
        ) {
            setErrors(prevErrors => {
                if (prevErrors.includes('dateInvalid')) {
                    return prevErrors;
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('dateInvalid');
                return errorsNew;
            });
        }
    };

    const handleOnChangeDto = (param, value) => {
        let newDto = { ...form };
        newDto[param] = value;
        setForm(newDto);
    };

    const saveTimeslot = async () => {
        const { dateTimeAvisation } = form;

        if (!dateTimeAvisation.date) {
            return setErrors(prevErrors => {
                if (prevErrors.includes('date')) {
                    return prevErrors;
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('date');
                return errorsNew;
            });
        }
        if (moment(dateTimeAvisation.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            return setErrors(prevErrors => {
                if (prevErrors.includes('dateInvalid')) {
                    return prevErrors;
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('dateInvalid');
                return errorsNew;
            });
        }
        if (!dateTimeAvisation.timeslot) {
            return setErrors(prevErrors => {
                if (prevErrors.includes('timeslot')) {
                    return prevErrors;
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('timeslot');
                return errorsNew;
            });
        }

        if (errors.length > 0) {
            return;
        }
        const params = {
            waybillIds: data.waybillIds,
            timeslot: {
                ...dateTimeAvisation.timeslot,
                date: `${moment(dateTimeAvisation.date).format('YYYY-MM-DD')}`,
            },
        };

        setLoading(true);

        const res = await waybillAvisationSet(params);

        setLoading(false);

        if (res && res.errors) {
            return;
        }

        handleClose();
        loadForm && loadForm();
    };

    const saveTransport = async () => {
        if (!form.weightCapacity) {
            return setErrors(prevErrors => {
                if (prevErrors.includes('typeTransport')) {
                    return prevErrors;
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('typeTransport');
                return errorsNew;
            });
        }
        if (!form.driver) {
            return setErrors(prevErrors => {
                if (prevErrors.includes('driver')) {
                    return prevErrors;
                }
                const errorsNew = [...prevErrors];
                errorsNew.push('driver');
                return errorsNew;
            });
        }
        const param = {
            weightCapacity: form.weightCapacity,
            driver: form.driver,
            transportNumber: form.transportNumber,
            driverPhoneNumber: form.driverPhoneNumber,
        };

        setLoading(true);

        const res = await waybillAvisationSaveTransport(param, wb.reservationId);

        if (res && res.errors) {
            return;
        }

        setLoading(false);

        handleClose();
        loadWb && loadWb();
        loadForm && loadForm();
    };

    const handleClickSave = () => {
        if (type === 'timeslot') {
            saveTimeslot();
        }

        if (type === 'transport') {
            saveTransport();
        }
    };

    const handleClose = () => {
      setForm({
          dateTimeAvisation: {},
      });

      setErrors([]);

      onClose && onClose();
    };

    return (
        <Modal
            closeOnDimmerClick={false}
            size={(type !== 'transport') && "small"}
            dimmer="inverted"
            closeIcon
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Dimmer active={loading} inverted>
                    <Loader inverted />
                </Dimmer>
                {type === 'timeslot' ? (
                    <WbAvizAvailableTimeslot
                        typeTransport={LoadCapacityOptions.find(l=>l.id===wb.typeTransport)}
                        dateArrival={form.dateTimeAvisation.date}
                        deliveryDateMin={wb.deliveryDate}
                        timeslot={form.dateTimeAvisation.timeslot}
                        waybillIds={data.waybillIds}
                        onChange={handleOnChangeDtoTimeSlot}
                        errors={errors}
                    />
                ) : null}
                {type === 'transport' ? (
                    <Form>
                        <Grid columns="equal">
                            <GridColumn>
                                <Form.Field
                                    control={InputDropdown}
                                    required
                                    error={errors.includes('typeTransport')}
                                    clearable
                                    label={'Тип транспорта'.t}
                                    options={LoadCapacityOptions}
                                    placeholder={'Тип транспорта'.t}
                                    value={form.weightCapacity}
                                    onChange={value => handleOnChangeDto('weightCapacity', value)}
                                />
                            </GridColumn>
                            <GridColumn>
                                <Form.Field
                                    control={Input}
                                    required
                                    error={errors.includes('driver')}
                                    label={'Водитель'.t}
                                    placeholder={'Водитель'.t}
                                    value={form.driver}
                                    onChange={(e, { value }) => handleOnChangeDto('driver', value)}
                                />
                            </GridColumn>
                            <GridColumn>
                                <PhoneNumber
                                    error={errors.includes('driverPhoneNumber')}
                                    label={'Телефон водителя'.t}
                                    placeholder={'Телефон водителя'.t}
                                    value={form.driverPhoneNumber}
                                    onChange={(e, { value }) => handleOnChangeDto('driverPhoneNumber', value)}
                                />
                            </GridColumn>
                            <GridColumn>
                                <Form.Field
                                    control={Input}
                                    error={errors.includes('transportNumber')}
                                    label={'Номер ТС'.t}
                                    placeholder={'Номер TC'.t}
                                    value={form.transportNumber}
                                    onChange={(e, { value }) => handleOnChangeDto('transportNumber', value)}
                                />
                            </GridColumn>
                        </Grid>
                    </Form>
                ) : null}
                {errors.includes('typeTransport') && (
                    <Message warning>
                        <p>
                            <T>Заполните тип транспорта</T>
                        </p>
                    </Message>
                )}
                {errors.includes('driver') && (
                    <Message warning>
                        <p>
                            <T>Заполните поле "Водитель"</T>
                        </p>
                    </Message>
                )}
                {errors.includes('countPallet') && (
                    <Message warning>
                        <p>
                            <T>Заполните количество паллет для коробов</T>
                        </p>
                    </Message>
                )}
                {errors.includes('timeslot') && (
                    <Message warning>
                        <p>
                            <T>Заполните таймслот</T>
                        </p>
                    </Message>
                )}
                {errors.includes('date') && (
                    <Message warning>
                        <p>
                            <T>Заполните дату привоза</T>
                        </p>
                    </Message>
                )}
                {errors.includes('dateInvalid') && (
                    <Message warning>
                        <p>
                            <T>
                                Невозможно авизовать заявка, так как дата привоза на ФМ в загрузке
                                меньше текущей
                            </T>
                        </p>
                    </Message>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button primary onClick={handleClickSave}>
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default WbAvisModal;
