import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import {
    getCompanyTypeName,
    getPointStatusColor,
    getPointStatusName,
    PointBookmarkTypeColor,
    PointBookmarkTypeName,
    PointBookmarkTypeOptions
} from '../../../../api/model/Point';
import {getProviderStatusName, getProviderStatusColor} from '../../../../api/model/Provider';
import InputDropdown from '../../../../components/inputs/InputDropdown';
import T from '../../../../components/Translate';
import PointViewFooterExportStatusLabel from '../pointView/PointViewFooterExportStatusLabel';
import LockedIcon from "../../../points/LockedIcon";
import {
    getImportFormStatusColor,
    getImportFormStatusName,
    ImportFormFileStatusOptions
} from "../../../../api/model/ImportForms";

const PointGridCell = ({ item, keyName, onChange = () => {}, actions, tableName }) => {
    const val = item[keyName];

    const getStatusColor = (val) => {
        switch (tableName) {
            case 'importClientForms': return getImportFormStatusColor(val);
            default: return getProviderStatusColor(val);
        }
    };

    const getStatusName = (val) => {
        switch (tableName) {
            case 'importClientForms': return getImportFormStatusName(val);
            default: return getProviderStatusName(val);
        }
    };

    switch (keyName) {
        case 'formFileTaskStatus':
        case 'taskStatus':
            const data = ImportFormFileStatusOptions.find(o => o.value === val);
            return data ? <span>
                <Icon name={data.icon} color={data.color}/>
                {data.text}
            </span> : '';
        case 'mappingCode':
            return (val && val.length !== 0) ? val.join('; ') : <span style={{color: "#cccccc"}}><T>не&nbsp;задано</T></span>;
        case 'companyName':
            return item.companyLegalForm ? `${item.companyLegalForm} ${val}` : val;
        case 'status':
            return <Label
                key={keyName}
                color={getStatusColor(val)}>
                <T>{getStatusName(val)}</T>
            </Label>;
        case 'companySearchName':
        case 'fmid':
        case 'address':
            return val || <span style={{color: "#cccccc"}}><T>не&nbsp;задано</T></span>;
        case 'exportStatus':
            return <PointViewFooterExportStatusLabel value={val}/>;
        case 'companyType':
        case 'pointType':
            return getCompanyTypeName(val);
        case 'pointStatus':
        case 'statusId':
            return <span>
                <Label
                    key={keyName}
                    color={getPointStatusColor(val)}>
                    <T>{getPointStatusName(val)}</T>
                </Label>
                {item['isLocked'] && <LockedIcon color={'grey'}/>}
            </span>;
        case 'bookmarkType':
            if (item.pointStatus < 11) {
                return null;
            }
            return <>
                <Icon
                    name="bookmark outline"
                    color={PointBookmarkTypeColor[val]}
                    title={PointBookmarkTypeName[val]}
                    style={{
                        position: 'absolute',
                        marginTop: 5
                    }}
                />

                <InputDropdown
                    placeholder={"В избранном".t}
                    options={PointBookmarkTypeOptions}
                    value={val}
                    onChange={val => onChange('bookmarkType', val)}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        display: 'block',
                        marginLeft: -8,
                        marginRight: -8,
                        marginTop: -5,
                        marginBottom: -5,
                        boxShadow: 'none',
                        paddingLeft: 30
                    }}
                />
            </>;
        case 'clientNames':
            const text = val ? val.join(', ') : '';
            return <span title={text}>{text}</span>;
        case 'actions':
            return actions && actions(item);
        case 'filename':
            return <span className='table-link'>{val}</span>;
        default:
            return keyName.includes('.')
                ? keyName.split('.').reduce((o, i) => o[i], item)
                : val || <span style={{color: "#cccccc"}}><T>не&nbsp;задано</T></span>;
    }
};

export default PointGridCell;
