import {get, post, upload} from "../services/http";

export async function getClientsForInputWithSearch(search, filters) {
    return await post(`waybills/import/forms/clients/dropdown`, {search, ...(filters || {})});
}

export async function getFormsForInputWithSearch(search, filters) {
    return (filters || {}).clientFmid
        ? await post(`waybills/import/forms/dropdown`, {search, ...(filters || {})})
        : [];
}

export async function importByForm(idForm, file) {
    return await upload(`waybills/import/forms/${idForm}/tasks`, file);
}

export async function getClients(data) {
    return await post(`waybills/import/forms/clients`, data);
}

export async function getForm(id) {
    return await get(`waybills/import/forms/${id}`);
}

export async function getFiles(id) {
    return await get(`waybills/import/forms/${id}/tasks`);
}

export async function getFormsOfClient(companyFmid, data) {
    return await post(`waybills/import/forms/all`, {...data, companyFmid});
}

export async function blockCheck(id) {
    return await get(`waybills/import/forms/checkblock/${id}`);
}

export async function deleteForm(id) {
    return await post(`waybills/import/forms/delete/${id}`);
}

export async function copyForm(id) {
    return await post(`waybills/import/forms/copy/${id}`);
}

export async function saveForCreateForm(client) {
    return await post(`waybills/import/forms/add`, {clientFmid: client});
}

export async function saveForm(data) {
    return await post(`waybills/import/forms/update`, data);
}

export async function saveRule(id, data) {
    return await post(`waybills/import/forms/sequence/${id}`, data);
}

export async function saveDemoFile(formId, formData) {
    return await upload(`waybills/import/forms/${formId}/tasks/form-file`, formData);
}

export async function getTask(id) {
    return await get(`waybills/import/tasks/${id}`);
}

export async function updateTask(id, data) {
    return await post(`waybills/import/tasks/${id}/update`, data);
}

export async function getFieldsData() {
    return await get(`waybills/import/forms/dicts/fields`);
}

export async function getSplitters() {
    const res = await get(`waybills/import/forms/dicts/splitters`);
    return res
        ? res.map(r => ({
            text: r.displayName,
            value: r.value,
            key: r.value,
            description: r.value !== ' ' && r.description
        }))
        : [];
}

export async function getDateFormats() {
    return await get(`waybills/import/forms/dicts/dateformats`);
}

export async function getDictionaryItems(dictionaryType, searchValue, selectedValueId, companyId) {
    return await get(`waybills/import/forms/dicts/${dictionaryType}/${companyId}/${searchValue || 'NULL'}/${selectedValueId || 0}`);
}

export async function processFileById(id) {
    return await post(`waybills/import/tasks/${id}/manual`);
}

export async function loadWaybills(id) {
    return await post(`waybills/import/tasks/${id}/load`);
}

export async function searchClients(search) {
    const res = await get(`waybills/import/forms/clients/${search}`);
    return (res || []).map(v => ({
        text: v.name,
        value: v.fmid,
        description: v.fmid,
    }));
}

export async function setActiveDouble(id) {
    return await post(`scheduleStandardFormDictionary/toggleIsDefault/${id}`);
}