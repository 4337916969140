import React from 'react';
import {Route, Switch} from "react-router-dom";
import ErrorNotFound from "../ErrorNotFound";
import ImportClientsPage from "./ImportClientsPage";
import ImportClientPage from "./ImportClientPage";
import ImportFilesPage from "./ImportFilesPage";
import './styles.css';
import ImportTaskPage from "./ImportTaskPage";
import ImportFormPage from "./ImportFormPage";

const Import = () => {
    return (
        <Switch>
            <Route exact path='/import' component={ImportClientsPage} />
            <Route exact path='/import/:fmid' component={ImportClientPage} />
            <Route exact path='/import/:fmid/:id' component={ImportFilesPage} />
            <Route exact path='/import/:fmid/:id/edit' component={ImportFormPage} />
            <Route exact path='/import/:fmid/:id/tasks/:taskId' component={ImportTaskPage} />
            <Route component={ErrorNotFound} />
        </Switch>
    );
};

export default Import;