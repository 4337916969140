import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Grid, GridColumn, GridRow, Header, Icon, Label, Modal, Segment} from "semantic-ui-react";
import T from "../../../../../components/Translate";
import {ContextGridSettings} from "../../../../../services/context";
import "./WbColumnsSetup.css";

const zoneShownKey = "shown";

function LabelDraggable({i, color, draggable, setOrigin}) {

    function onDragStart(e, key) {
        e.dataTransfer.setData("text/plain", key);
        e.dataTransfer.effectAllowed = "move";

        setOrigin(e.target);
    }

    const draggableConfig =
        draggable && !i.system
            ? {draggable}
            : {
                style: {
                    userSelect: "none",
                    cursor: "no-drop"
                }
            };

    const systemClass = i.system ? "opacity-50" : "";

    const labelClass = `grid-col-setup-label hover-cursor-move ${systemClass}`;

    return (
        <Label
            {...draggableConfig}
            onDragStart={e => onDragStart(e, i.key)}
            size="medium"
            color={color}
            className={labelClass}
            key={i.key}
            id={i.key}
            title={i.system ? "Системное поле, скрытие невозможно" : ""}
        >
            <Icon name="move"/> {i.text}
        </Label>
    );
}

export default function WbColumnsSetup({children, allColumns, colsKey, setColsKey}) {
    const contextGridSettings = useContext(ContextGridSettings);
    const [sortSetupHide, setSortSetupHide] = useState([]);
    const [open, setOpen] = useState(false);
    const [setup, setSetup] = useState(
        allColumns.map(item => ({
            ...item,
            ...contextGridSettings[colsKey || 'columns'].find(i => i.key === item.key)
        }))
            .sort((a, b) => a.order - b.order)
    );
    const [draggedOverTarget, setDraggedOverTarget] = useState("");

    const enterCounter = useRef(0);
    const dragStartZone = useRef("");
    const dragElement = useRef(null);

    const shownItems = setup.filter(i => !i.hide);
    const hiddenItems = setup.filter(i => i.hide);
    const countFieldMax = setup.length;
    const counterColor = shownItems.length >= countFieldMax ? "color-red" : "";

    function applySetup() {
        let setNew = [...setup];
        for (let i = 0; i < setup.length; i++) {
            const objNew = sortSetupHide.find(value => value.key === setup[i].key);
            if (objNew) {
                setNew.splice(i, 1, objNew);
            }
        }
        const res = setNew.sort(function (a, b) {
            return a.order - b.order;
        });
        contextGridSettings[setColsKey || 'setColumns'](res);
        setOpen(false);
    }

    function discardSetup() {
        setSetup([...contextGridSettings.columns]);
        setOpen(false);
    }

    function setStartZoneAndDragged(zone, draggedEl) {
        dragStartZone.current = zone;
        dragElement.current = draggedEl;
    }

    function onDragEnter(e, target) {
        console.log("on drag ENTER: " + target, dragStartZone.current);

        if (
            dragStartZone.current !== target ||
            dragStartZone.current === zoneShownKey
        ) {
            enterCounter.current++;

            setDraggedOverTarget(target);
        }

        e.preventDefault(); // needed for IE
    }

    function onDragLeave(e, target) {
        console.log("on drag LEAVE: " + target);

        if (
            dragStartZone.current !== target ||
            dragStartZone.current === zoneShownKey
        ) {
            enterCounter.current--;

            if (enterCounter.current === 0) {
                setDraggedOverTarget("");
            }
        }
        if (dragStartZone.current === zoneShownKey && target === zoneShownKey) {
            e.preventDefault(); // set cursor and drop ability

            console.log("allowing drop onto self");

            const targetEl = e.target;

            const targetQqCurrent =
                targetEl.innerText === dragElement.current.innerText;

            if (!targetQqCurrent) {
                const targetIsLabel = targetEl.classList.contains("label");

                if (targetIsLabel) {
                    const parent = targetEl.parentElement;

                    parent.insertBefore(
                        dragElement.current,
                        targetEl.nextSibling || targetEl
                    );

                    let sortNew = [...shownItems];
                    let res = [];
                    for (let i = 0; i < parent.children.length; i++) {
                        const objNew = sortNew.filter(
                            item => item.key === parent.children[i].id
                        )[0];
                        objNew.order = i;
                        res.push(objNew);
                    }
                    setSortSetupHide(res);
                }
            }
        }
    }

    function onDragOver(e, target) {
        // drop marker

        if (
            (dragStartZone.current !== target && dragElement) ||
            dragStartZone.current === zoneShownKey
        ) {
            e.preventDefault();
        }
    }

    function onDragOverSorted(e, target) {
        // eslint-disable-line

        if (dragStartZone.current === target) {
            if (dragStartZone.current === zoneShownKey && target === zoneShownKey) {
                e.preventDefault(); // set cursor and drop ability

                console.log("allowing drop onto self");

                const targetEl = e.target;

                const targetQqCurrent =
                    targetEl.innerText === dragElement.current.innerText;

                if (!targetQqCurrent) {
                    const targetIsLabel = targetEl.classList.contains("label");

                    if (targetIsLabel) {
                        const parent = targetEl.parentElement;

                        parent.insertBefore(
                            dragElement.current,
                            targetEl.nextSibling || targetEl
                        );

                        //dragElement.current.classList.push("opacity-50");
                    }
                }
            }
        } else {
            if (dragElement) {
                e.preventDefault();
            }
        }
    }

    function onDrop(e, target) {
        const colkey = e.dataTransfer.getData("text/plain");

        console.log("on DROP: " + colkey);

        setDraggedOverTarget("");

        const setupNew = setup.map(c => ({...c}));

        const setupColumn = setupNew.find(c => c.key === colkey);

        if (setupColumn) {
            const isSortingCase = target === zoneShownKey && !setupColumn.hide;

            if (isSortingCase) {
                console.log("2 " + colkey);
                const newIndex = e.target.children; // eslint-disable-line
            } else {
                // moving case
                console.log("3 " + colkey);
                setupColumn.hide = target === "hidden" ? true : false;
                setSetup(setupNew);
            }
        }
    }

    useEffect(() => {
        // cleanup
        return () => {
            /*dragElement.current = null;*/
        };
    }, []);

    return (
        <Modal
            trigger={children}
            centered
            dimmer="inverted"
            size={"small"}
            closeIcon
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                <T>Настройка полей таблицы</T>
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <Grid columns={2} className="grid-col-setup" divided="vertically">
                        <GridColumn
                            className={
                                draggedOverTarget === "shown"
                                    ? "grid-col-setup-dropzone-active"
                                    : "grid-col-setup-dropzone"
                            }
                        >
                            <GridRow>
                                <Header className="m-b-10">
                                    {/* <Icon name='settings' /> */}
                                    <Header.Content className="user-select-none">
                                        Отображаемые
                                    </Header.Content>
                                    <Header.Subheader
                                        className={`grid-col-setup-subheader ${counterColor}`}
                                    >
                                        {`${shownItems.length}/${countFieldMax}`}
                                    </Header.Subheader>
                                </Header>
                            </GridRow>
                            <GridRow
                                className="height-245 scrollable-v"
                                onDragEnter={e => onDragEnter(e, "shown")}
                                onDragLeave={e => onDragLeave(e, "shown")}
                                onDragOver={e => onDragOver(e, "shown")}
                                onDrop={e => onDrop(e, "shown")}
                            >
                                {shownItems.map(i => (
                                    <LabelDraggable
                                        key={i}
                                        i={i}
                                        draggable
                                        color="blue"
                                        setOrigin={el => setStartZoneAndDragged("shown", el)}
                                    />
                                ))}
                            </GridRow>
                        </GridColumn>
                        <GridColumn
                            className={
                                draggedOverTarget === "hidden"
                                    ? "grid-col-setup-dropzone-active"
                                    : "grid-col-setup-dropzone"
                            }
                            onDragEnter={e => onDragEnter(e, "hidden")}
                            onDragLeave={e => onDragLeave(e, "hidden")}
                            onDragOver={e => onDragOver(e, "hidden")}
                            onDrop={e => onDrop(e, "hidden")}
                        >
                            <GridRow>
                                <Header className="m-b-10 user-select-none">
                                    <T>Скрытые</T>
                                </Header>
                            </GridRow>
                            <GridRow className="height-245 scrollable-v">
                                {hiddenItems.map(i => (
                                    <LabelDraggable
                                        key={i}
                                        i={i}
                                        draggable={shownItems.length < countFieldMax}
                                        setOrigin={el => setStartZoneAndDragged("hidden", el)}
                                    />
                                ))}
                            </GridRow>
                        </GridColumn>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={discardSetup}>
                    <T>Отмена</T>
                </Button>
                <Button
                    primary
                    positive
                    onClick={applySetup}
                    disabled={shownItems.length > countFieldMax}
                >
                    <T>Применить</T>
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
