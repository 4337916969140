import React from 'react';
import {Icon, Step} from 'semantic-ui-react';
import T from '../Translate';

export default function StepContent({ icon, title }) {
    return (
        <React.Fragment>
            <Icon name={icon} />
            <Step.Content>
                <Step.Title>
                    <T>{title}</T>
                </Step.Title>
            </Step.Content>
        </React.Fragment>
    );
}
