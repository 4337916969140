import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { companyPointUpdate } from '../../../api/companies';
import { getPoints, hasPointForExport } from '../../../api/points';
import { getUserCompanyOptions } from '../../../api/users';
import AppTableHeader from '../../../components/tables/AppTableHeader';
import { LinkInfo } from '../../../layout/navbar/Navbar';
import Shield from '../../../layout/page/shield/Shield';
import { ContextFooter, ContextNavi } from '../../../services/context';
import PointsScrollPreloader from '../../_shared/pointsScrollPreloader/PointsScrollPreloader';
import './Points.css';
import PointGridHeader from './pointsGrid/PointGridHeader';
import PointGridRows from './pointsGrid/PointGridRows';
import PointsToolbar from './pointsToolbar/PointsToolbar';

const headers = [
    new AppTableHeader("createdByClient", "От".t),
    new AppTableHeader("statusId", "Статус".t),
    new AppTableHeader("postalCode", "Индекс".t),
    new AppTableHeader("address", "Адрес".t),
    new AppTableHeader("companyName", "Грузополучатель".t),
    new AppTableHeader("companyType", "Тип грузополучателя".t),
    new AppTableHeader("fmid", "Название в системе FM".t),
    new AppTableHeader("exportStatus", "Статус регистрации".t, 2),
];

export default withRouter(function Points({ location, history }) {
    const contextNavi = useContext(ContextNavi);
    const contextFooter = useContext(ContextFooter);

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [canExport, setCanExport] = useState(true);
    const [isAll, setIsAll] = useState(false);

    const keys = headers.map(h => h.key);

    async function fetchData(query) {
        setLoading(true);
        try {
            let result;
            const stringQuery = `?${qs.stringify(query)}`;
            result = await getPoints(stringQuery);
            const hasExportPoints = await hasPointForExport(stringQuery);
            setCanExport(hasExportPoints);
            return result;
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    async function updateRows() {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        const rows = await fetchData(query);
        setRows(rows);
    };

    async function onPointChange(data) {
        const { companyId, pointId } = data;
        await companyPointUpdate(companyId, pointId, data);
        updateRows();
    };

    useEffect(() => {
        (async () => {
            const options = await getUserCompanyOptions();
            setCompanyOptions(options);
            if (options.length === 1) {
                setSelectedCompany(options[0].value);
            }
        })();
    }, []);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("Адреса".t, "/points")]);
    }, []);

    useEffect(() => {
        setSelectedPoints([]);
        if (isAll) {
            setSelectedPoints(rows.map(i => i.id))
            ;        }
    }, [rows])

    useEffect(() => {
        const load = async () => {
            updateRows();
        };
        load();
    }, [location.search]);

    useEffect(() => {
        rows.length
            ? contextFooter.setIndicator(() =>
            <div className="table-footer">
                {selectedPoints.length
                    ? `Выбрано: ${selectedPoints.length} / `
                    : null}
                {rows.length} записей
            </div>)
            : contextFooter.setIndicator(null);
        return () => contextFooter.setIndicator(null);
    }, [rows.length, selectedPoints.length]);
    return (
        <Shield loading={loading && !loaded} loadingOver={false}>
            <PointsToolbar {...{
                location,
                selectedPoints,
                companyOptions,
                selectedCompany,
                canExport,
                updateRows,
                setSelectedCompany
            }} />
            <div className="table-wrapper">
                <div className="table-scroll">
                    <PointGridHeader
                        headers={headers}
                        hasRows={rows.length}
                        selectedPoints={selectedPoints || []}
                        setSelectedPoints={setSelectedPoints}
                        getRowsIds={() => rows.map(r => r.id)}
                        isClient={false}
                        setIsAll={setIsAll}
                    />
                </div>
                <div className="table-scroll points-table table-empty">
                    <PointGridRows
                        rows={rows}
                        keys={keys}
                        selectedPoints={selectedPoints || []}
                        setSelectedPoints={(val) => {
                            if (isAll && val.length !== rows.length) {
                            setIsAll(false);
                        }
                            setSelectedPoints(val)}
                        }
                        isClient={false}
                        onChange={onPointChange}
                    />
                    <PointsScrollPreloader location={location} rows={rows} fetchData={fetchData} setRows={(val)=> {
                        if (isAll) {
                            setSelectedPoints(val.map(i => i.id));
                        }
                        setRows(val)}} />

                </div>
            </div>
        </Shield>
    );
});
