import React from 'react';
import { Label } from 'semantic-ui-react';

export default function WbViewFooter({statusOrder, statusShipping}) {

    const statusLabelOrder = statusOrder && statusOrder.text
        ? <Label color={statusOrder.color || null}><b>{statusOrder.text}</b></Label>
        : null;
    
    const statusLabelShipping = statusShipping
        ? <Label color="violet" className="ellipsis m-l-10"> <b>{statusShipping}</b></Label>
        : null;

    return (
        <>
            {statusLabelOrder}
            {statusLabelShipping}
        </>
    );
}