import React, {useState} from 'react';
import {Button, Form, Message, Modal} from "semantic-ui-react";
import InputDropdownSearch from "../../components/inputs/InputDropdownSearch";
import {saveForCreateForm, searchClients} from "../../api/importForms";

const AddFormModal = ({children, history}) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [client, setClient] = useState(null);

    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const save = () => {
        setLoading(true);
        saveForCreateForm(client)
            .then(res => {
                history.push(`/import/${client}/${res.id}/edit`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            trigger={children}
            dimmer="inverted"
            size='small'
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
        >
            <Modal.Header>
                Добавление формы
            </Modal.Header>
            <Modal.Content>
                <Message
                    icon='info'
                    info
                    content='Для добавления формы клиента в импорт заявок, пожалуйста, сначала выберите его наименование или Searchname в поле ниже'
                />
                <Form>
                    <Form.Field>
                        <label>Наименование или Searchname клиента</label>
                        <InputDropdownSearch
                            minLetters={3}
                            getOptionsBySearch={searchClients}
                            placeholder={"Введите 3 или более букв для поиска"}
                            value={client}
                            onChange={(value) => setClient(value)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <Button
                    positive
                    disabled={!client || loading}
                    loading={loading}
                    onClick={save}
                >
                    Сохранить и перейти к созданию формы
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AddFormModal;