import React from 'react';
import PropTypes from 'prop-types';
import FilterInputDateTimePopup from "./FilterInputDateTimePopup";

export default function FilterInputDateTime(props) {
    return (
        <div className="ui input transparent">
            <FilterInputDateTimePopup updateParent={props.onChange} value={props.date} time={props.time} updateTime={props.onChangeTime}>
                <input
                    id={`filter-input-${props.field}`}
                    className="filter-date-input"
                    type="text"
                    placeholder={props.name}
                    value={`${props.date}${props.time && props.time.from ? ', ' + props.time.from : ''}${props.time && props.time.to ? ' - ' + props.time.to : ''}`}
                />
            </FilterInputDateTimePopup>
        </div>
    );
}

FilterInputDateTime.propTypes = {
    date: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onChangeTime: PropTypes.func.isRequired,
};
