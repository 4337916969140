import React, {useState} from 'react';
import {Modal} from "semantic-ui-react";
import Dropzone from "react-dropzone";

const ModalFile = ({children, name, onDrop, accept = ".xlsx,.xlsm", size}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDrop = (...data) => {
        handleClose();
        onDrop(...data);
    };

    return (
        <Modal
            trigger={children}
            size={size || "small"}
            closeIcon
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>
                {name}
            </Modal.Header>
            <Modal.Content>
                <Dropzone
                    onDrop={handleDrop}
                    multiple={false}
                    accept={accept}
                    className="dropzone"
                >
                    Перетащите файл или кликните на это поле
                </Dropzone>
            </Modal.Content>
        </Modal>
    );
};

export default ModalFile;