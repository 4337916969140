import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ContextLocale } from '../../services/context';

const options = [
    { key: 1, text: 'RU', value: 'ru' },
    { key: 2, text: 'EN', value: 'en' },
];

export default function LanguageSelector() {
    const contextLocale = useContext(ContextLocale);
    //const [value, setValue] = useState(contextLocale.locale);

    function handleChange (e, { value }) {
        contextLocale.setLocale(value);
        //setValue(value);
    }

    return (
        <Dropdown
            item
            onChange={handleChange}
            options={options}
            basic
            value={contextLocale.locale}
        />
    );

}