import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Message, Modal} from 'semantic-ui-react';
import T from '../../../components/Translate';
import WbGridTable from '../wbGrid/WbGridTable';
import {ContextGridSettings, ContextWb} from '../../../services/context';
import {getAll, getWaybillsInVisit} from '../../../api/waybills';
import Shield from '../../../layout/page/shield/Shield';
import useReactRouter from 'use-react-router'
import qs from "query-string";
import {reservationAdd} from "../../../api/waybillAviz";
import moment from "moment";
import {toast} from "../../../services/toast";
import {WaybillAvisationStatus, WaybillStatusEnum} from '../../../api/model/Waybill';

const availableStatuses = [
    WaybillStatusEnum.DRAFT,
    WaybillStatusEnum.SUBMITTED,
    WaybillStatusEnum.ON_APPROVAL,
    WaybillStatusEnum.EXECUTING,
].join(",");

const availableAvisationStatuses = [
    WaybillAvisationStatus.REQUESTED,
].join(",");

const WbAddModal = ({ open, onClose, reservationId, setWaybillsWithVisit, setIsOpenAvisModal, loadingPointIdFm, setEditTimeslotData}) => {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [scrollLoading, setScrollLoading] = useState(false);

    const { location, history } = useReactRouter();

    const page = useRef(0);
    const scrollContainer = useRef(null);

    const queryStr = `avisationStatus=${availableAvisationStatuses}&status=${availableStatuses}&loadingPointIdFm=${loadingPointIdFm}`

    const contextGridSettings = useContext(ContextGridSettings);
    const contextWb = useContext(ContextWb);
    const headers = contextGridSettings.columns
        .filter(c => !c.hide)
        .sort(function(a, b) {
            return a.order - b.order;
        });

    async function fetchData() {
        setLoading(true);
        try {
            let searchStr = location.search ? `${location.search}&${queryStr}` : `?${queryStr}`;
            const result = await getAll(searchStr);
            setRows(result);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    }

    async function viewModal(data) {
        if (data.isAvisationCanceled) {
            if (!data.reservationWaybillIds || !data.reservationWaybillIds.length)
            {
                toast.success(`Бронь № ${data.canceledReservation.visitNumber} отменена`);
            }
            else {
                const res = await getWaybillsInVisit(data.reservationWaybillIds);
                setWaybillsWithVisit(res);
                setIsOpenAvisModal(true);
            }
        }
        if (data.isUpdated) {
            setEditTimeslotData(data);
        }
    }

    const handleClose = () => {
        history.push('?' + qs.stringify({}));
        setSelectedIds([]);
        onClose();
    };

    useEffect(() => {
        return () => {
            history.push('?' + qs.stringify({}));
        }
    }, []);

    useEffect(() => {
        fetchData();

    }, [location.search]);


    const add = async () => {
        const selectedRows = rows.filter(row => selectedIds.includes(row.id));
        if (moment(selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            toast.error('Невозможно авизовать заявку, так как дата привоза на ФМ в загрузке меньше текущей'.t);
            return;
        }
        contextWb.setLoading(true);
        try {
            const data = await reservationAdd(reservationId, {ids: selectedIds});
            await viewModal(data);
            handleClose();
        } finally {
            contextWb.setLoading(false);
        }

    };

    async function onBottomVisible() {
        if (scrollLoading) {
            return;
        }
        const nextPage = page.current + 1;

        setScrollLoading(true);

        const str = `${queryStr}&page=${page.current}`;
        let searchStr = location.search ? `${location.search}&${str}` : `?${str}`;
        const nextRows = await getAll(searchStr);

        if (nextRows && nextRows.length) {

            const allRows = rows.concat(nextRows);

            setRows(allRows);

            page.current = nextPage;
        }
        setScrollLoading(false);
    }

    return (
        <Modal
            closeOnDimmerClick={false}
            size="large"
            dimmer="inverted"
            closeIcon
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>
                <T>Добавить заявку в существующую бронь</T>
            </Modal.Header>
            <Modal.Content>
                <Shield loading={loading} loadingOver={loaded}>
                    <Message info>
                        Выберите заявки в статусе “Требует авизации”
                    </Message>
                    <div className={'add_grid'} ref={scrollContainer}>
                        <WbGridTable
                            scrollComponent={scrollContainer}
                            setLoading={setLoading}
                            headers={headers}
                            rows={rows}
                            setRows={setRows}
                            selectedIds={selectedIds || []}
                            selectIds={setSelectedIds}
                            getRowsIds={() => rows.map(r => r.id)}
                            fetchData={onBottomVisible}
                        />
                    </div>
                    <Button className="m-t-10" disabled={!selectedIds.length} onClick={add}>{`Добавить ${selectedIds.length || ''} заявки в бронь`}</Button>
                </Shield>
            </Modal.Content>
        </Modal>
    );
};

export default WbAddModal;
