import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Checkbox, Icon, Menu, Message, Modal, Popup, Table} from "semantic-ui-react";
import T from "../../components/Translate";
import AddScheduleModal from "./addScheduleModal";
import {copySchedule, deleteSchedule, exportSettings, getSettings} from "../../api/schedules";
import qs from "query-string";
import {CargoTypeOptions, WeekDayOptions} from "../../api/model/Enums";
import {nanoid} from "nanoid";
import iconClearFilters from "../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png";
import useReactRouter from 'use-react-router';
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import WbGridFilterList from "../waybills/wbGrid/wbGridFilters/WbGridFilterList";
import Shield from "../../layout/page/shield/Shield";
import {ContextFooter, ContextUser} from "../../services/context";
import UserPermissions from "../../api/model/UserPermissions";
import ScrollPreloader from "../../components/miscs/ScrollPreloader";
import SchedulesImportModal from "./schedulesImportModal";

const styleDisabledHeader = {
    background: "#f9fafb",
    cursor: "default",
};

const headers = [
    {
        key: 'viewId',
        text: 'Идентификатор',
        sortable: true,
    },
    {
        key: 'cargoTypes',
        text: 'Тип груза',
        sortable: true,
        source: CargoTypeOptions,
    },
    {
        key: 'chain',
        text: 'Сеть',
        sortable: true,
    },
    {
        key: 'consignee',
        text: 'Грузополучатель',
        sortable: true,
    },
    {
        key: 'searchname',
        text: 'searchName',
        sortable: true,
    },
    {
        key: 'sendingRegion',
        text: 'Регион отправки',
        sortable: true,
    },
    {
        key: 'destinationRegion',
        text: 'Регион доставки',
        sortable: true,
    },
    {
        key: 'destinationCity',
        text: 'Город доставки',
        sortable: true,
    },
    {
        key: 'deadlineDay',
        text: 'Крайний день размещения заявки',
        sortable: true,
        source: WeekDayOptions,
    },
    {
        key: 'deliveryDay',
        text: 'День доставки грузополучателю',
        sortable: true,
        source: WeekDayOptions,
    },
    {
        key: 'daysRoad',
        text: 'Дни в пути',
        sortable: true,
    },
    {
        key: 'recommendedTimeFrom',
        text: 'Рекомендованное временное окно С',
        sortable: true,
    },
    {
        key: 'recommendedTimeTo',
        text: 'Рекомендованное временное окно ПО',
        sortable: true,
    },
    {
        key: 'comment',
        text: 'Комментарии',
    },
    {
        key: 'additionalInformationForClient',
        text: 'Доп. информация для клиента',
    },
    {
        key: 'companies',
        text: 'Клиент',
    },
];

const SchedulesConfig = () => {

    const context = useContext(ContextUser);
    const contextFooter = useContext(ContextFooter);

    const user = context && context.current;

    const canEdit = user.permissions.includes(UserPermissions.SCHEDULES_EDIT);

    let [openModal, setOpenModal] = useState(false);
    let [selectedIds, setSelectedIds] = useState(new Set());
    let [rows, setRows] = useState([]);
    let [loading, setLoading] = useState(false);
    let [deleteProgress, setDeleteProgress] = useState(false);
    let [columns, setColumns] = useState({one: [], two_x: [], two: [], three: []});
    let [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState({open: false});
    let [isNew, setIsNew] = useState(false);

    const [scrollLoading, setScrollLoading] = useState(false);
    const [isMaxRows, setIsMaxRows] = useState(false);

    const page = useRef(0);
    const scrollComponent = useRef(null);
    const pageSize = 20;
    const needScroll = rows.length >= pageSize && rows.length % pageSize === 0 && !isMaxRows;

    let [maxPoints, setMaxPoints] = useState(0);

    const {location, history} = useReactRouter();

    let query = qs.parse(location.search);

    const {sortBy, sortDir} = query;

    function updateQuery(q) {
        scrollTop();
        setIsMaxRows(false);
        query = q;
        history.push('?' + qs.stringify(query));
    }

    useEffect(() => {
        contextFooter.setIndicator(
            () => <div className="table-footer">
                {selectedIds.size
                    ? `Выбрано: ${selectedIds.size} / `
                    : null}
                {rows.length} записей
            </div>
        );
        return (() => {
            contextFooter.setIndicator(null);
        });
    }, [selectedIds, rows]);

    const scrollTop = () => {
        page.current = 0;
        scrollComponent.current.scrollTop = 0;
    };

    async function onBottomVisible() {
        if (scrollLoading) return;
        const nextPage = page.current + 1;
        const filter = qs.parse(location.search);
        filter.page = nextPage;
        const filterString = qs.stringify(filter);

        setScrollLoading(true);
        const result = await getSettings("?" + filterString);
        const nextRows = result.schedules;
        if (nextRows && nextRows.length) {
            const allRows = rows.concat(nextRows);
            setRows(allRows);
            page.current = nextPage;
        } else setIsMaxRows(true);
        setScrollLoading(false);
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({...query, sortBy, sortDir});
    };

    function applyFilter(filter, index) {
        const [[key]] = Object.entries(filter);
        const [indexKey] = key.split('.');
        updateQuery({...query, ...filter});
        index !== undefined && updateQuery({...query, [`${indexKey}.index`]: index});
        selectedIds.size && setSelectedIds(new Set());

    }

    function removeFilter(key) {
        updateQuery({...query, [key]: undefined});
        const [keyQ] = key.split('.');
        if (key.includes('points') && Object.keys(query).filter(key => key.includes(keyQ)).length !== 3) {
            updateQuery({...query, [`${keyQ}.index`]: undefined});
        }

        selectedIds.size && setSelectedIds(new Set());
    }


    useEffect(() => {
        //getPointsRequest();
        fetchData();
    }, [location.search]);

    const fetchData = async () => {
        setLoading(true);
        try {

            const result = await getSettings(location.search);

            setRows(result.schedules || []);

            const maxPoints = result.pointsCount;

            setMaxPoints(maxPoints);

            let pointsColumns = [];

            for (let i = 0; i < maxPoints - 1; i++) {
                pointsColumns.push({
                    key: 'deliveryDay',
                    index: i + 1,
                    text: `День доставки x-dock ${i + 2}`,
                    source: WeekDayOptions,
                    //sortable: true,
                    filterKey: 'points'
                });
                pointsColumns.push({
                    key: 'sendingDay',
                    index: i + 1,
                    text: `День отправки x-dock ${i + 2}`,
                    source: WeekDayOptions,
                    //sortable: true,
                    filterKey: 'points'
                })
            }

            let columnsObj = {
                one: headers.slice(0, 8),
                two_x: [
                    {
                        key: 'deliveryDay',
                        index: 0,
                        text: 'День доставки x-dock 1',
                        source: WeekDayOptions,
                        //sortable: true,
                        filterKey: 'points'
                    },
                    {
                        key: 'sendingDay',
                        index: 0,
                        text: 'День отправки x-dock 1',
                        source: WeekDayOptions,
                        //sortable: true,
                        filterKey: 'points'
                    },
                ],
                two: [
                    ...pointsColumns,
                ],
                three: headers.slice(8)
            };

            setColumns(columnsObj);
        } finally {
            setLoading(false);
        }
    };

    const headerColumns = useMemo(() => {
        return [...columns.one, ...columns.two_x, ...columns.two, ...columns.three]
    }, [columns]);


    const handleCreateClick = (id) => {
        canEdit && setOpenModal({open: true, id});
    };

    const handleCloseModal = () => {
        setOpenModal({open: false});
    };

    const deselect = () => {
        if (selectedIds.size) {
            setSelectedIds(new Set())
        } else {
            setSelectedIds(new Set(rows.map(item => item.id)))
        }
    };

    const handleRowSelection = id => {
        const newSelectedIds = new Set(selectedIds);

        newSelectedIds[!selectedIds.has(id) ? "add" : "delete"](id);

        setSelectedIds(newSelectedIds);
    };


    const getColumnsHeader = ({points: rowPoints}) => {
        const cells = [];
        for (let i = 1; i <= columns.two.length / 2; i++) {
            cells.push(<Table.Cell
                colSpan={2}
                textAlign="center"
                key={nanoid()}
                className={"schedule-cell-header"}
            >
                {
                    rowPoints[i] ? rowPoints[i].fmid : ''
                }
            </Table.Cell>)
        }

        return cells;
    };

    const getCell = (column, row) => {
        switch (column.key) {
            case 'destinationCity':
                return Array.isArray(row[column.key]) ? row[column.key].join(', ') : row[column.key];
            case 'deadlineDay':
            case 'sendingDay':
            case 'deliveryDay':
                return WeekDayOptions.getText(row[column.key]);
            case 'cargoTypes':
                return row[column.key].map(item => CargoTypeOptions.getText(item)).join(', ');
            case 'companies':
                return <div style={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}><span
                    title={row[column.key].isAll ? 'Все' : row[column.key].ids.join(', ')}>{row[column.key].isAll ? 'Все' : row[column.key].ids.join(', ')}</span>
                </div>;
            case 'recommendedTimeFrom':
            case 'recommendedTimeTo':
                return row[column.key] && row[column.key].slice(0, 5);
            default:
                return <div style={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}><span title={row[column.key] && row[column.key.toString()]}>{row[column.key]}</span></div>
        }
    };

    const clearFilters = () => {
        updateQuery({});
        selectedIds.size && setSelectedIds(new Set());
    };

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{paddingTop: '5px', display: 'inline-block', width: '17px'}}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{height: '13px'}}/>
        </span>
    );

    const getFilter = (key, sources, filterKey, index) => {
        switch (key) {
            case 'viewId':
            case 'consignee':
            case 'chain':
            case 'sendingRegion':
            case 'destinationCity':
            case 'daysRoad':
            case 'comment':
            case 'additionalInformationForClient':
            case 'clients':
            case 'searchname':
            case 'destinationRegion':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'companies':
                return <WbGridFilterStringContains
                    field={'company'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'recommendedTimeTo':
            case 'recommendedTimeFrom':
                return <WbGridFilterStringContains
                    field={key}
                    isTime
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'cargoTypes':
            case 'deadlineDay':
            case 'sendingDay':
            case 'deliveryDay':
                const getPointFilters = (filter) => {
                    let pointFilters = {};

                    for (let key in query) {
                        if (key.includes('points')) {
                            const [key1, key2] = key.split('.');
                            pointFilters = {
                                ...pointFilters,
                                [key1]: {
                                    ...pointFilters[key1],
                                    [key2]: query[key]
                                }
                            }
                        }
                    }
                    return pointFilters;
                };

                const getKey = (keyProp) => {
                    let keyQ;

                    for (let key in query) {
                        if (key.includes('index') && query[key] == index) {
                            [keyQ] = key.split('.')
                        }
                    }


                    return keyQ ? `${keyQ}.${keyProp}` : `points[${Object.keys(getPointFilters()).length}].${keyProp}`;
                };

                return <WbGridFilterList
                    field={filterKey ? getKey(key) : key}
                    applyFilter={(key) => applyFilter(key, index)}
                    removeFilter={removeFilter}
                    options={sources || []}/>;
            default:
                return <div
                    className="filter-flex-container"/>;
        }
    };

    const confirmDelete = async () => {
        setDeleteProgress(true);
        try {
            await deleteSchedule(Array.from(selectedIds));

            setSelectedIds(new Set());

            await fetchData();
        } finally {
            setDeleteProgress(false);
            setIsDeleteConfirmOpen({open: false})
        }

    };

    const handleDeleteClick = () => {
        setIsDeleteConfirmOpen({open: true})
    };

    const handleCopyClick = async () => {
        const result = await copySchedule(Array.from(selectedIds)[0]);

        fetchData();

        setIsNew(result.id);

        setTimeout(() => setIsNew(false), 2000);
    };

    const handleExportClick = async () => {
        let pointsFilter = [];
        let filter = {};

        Object.keys(query).filter(key => !key.includes('points')).forEach(key => {
            filter = {
                ...filter,
                [key]: query[key]
            }
        });

        for (let i = 0; i < maxPoints; i++) {
            const keys = Object.keys(query).filter(key => key.includes(i.toString()));
            if (keys && keys.length) {
                let obj = {};
                keys.forEach(item => {
                    const [k1, k2] = item.split('.');
                    obj = {
                        ...obj,
                        [k2]: query[item]
                    }
                });

                pointsFilter.push(obj)
            }
        }


        const fileInfo = await exportSettings({
            ids: Array.from(selectedIds),
            filter: {
                ...filter,
                points: pointsFilter,
            }
        });


        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    return (
        <Shield loading={loading} loadingOver={true}>
            <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size='small' borderless>
                <Menu.Menu position='right'>
                    <Menu.Item
                        disabled={!selectedIds.size || !canEdit}
                        onClick={handleDeleteClick}>
                        <Icon name="trash"/><T>Удалить график</T>
                    </Menu.Item>
                    <Menu.Item
                        disabled={selectedIds.size !== 1 || !canEdit}
                        onClick={handleCopyClick}>
                        <Icon name="copy"/><T>Копировать график</T>
                    </Menu.Item>
                    <SchedulesImportModal refresh={fetchData}>
                        {canEdit && <Menu.Item>
                            <Icon name="arrow down"/><T>Импорт графиков из Excel</T>
                        </Menu.Item>}
                    </SchedulesImportModal>
                    <Menu.Item onClick={() => handleExportClick()}>
                        <Icon name="arrow up"/><T>Экспорт в Excel</T>
                    </Menu.Item>
                    <Menu.Item disabled={!canEdit}
                               onClick={() => handleCreateClick()}>
                        <Icon name="plus"/><T>Новый график</T>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <AddScheduleModal {...openModal} fetchData={fetchData} onClose={handleCloseModal}/>
            <div className="table-wrapper-grid table-wrapper-grid-175" ref={scrollComponent}>
                <div className='table-wrapper-grid-175'>
                    <Table
                        celled
                        sortable
                        singleLine
                        structured
                        className='table-max-height-175'
                    >
                        <Table.Header className="table-header-fixed">
                            <Table.Row>
                                <Table.HeaderCell
                                    className="table-first-col table-item-sticky table-registry-select-all"
                                    style={{zIndex: "6"}}
                                >
                                    <Checkbox
                                        checked={selectedIds.size && selectedIds.size === rows.length}
                                        indeterminate={
                                            selectedIds.size &&
                                            selectedIds.size !== rows.length
                                        }
                                        onChange={deselect}
                                    />
                                </Table.HeaderCell>
                                {headerColumns.map(item => (
                                    <Table.HeaderCell
                                        id={`header-th-${item.key}`}
                                        className="table-header-fixed__title"
                                        key={item.index !== undefined ? `${item.key}_${item.index}` : item.key}
                                        sorted={item.sortable && sortBy === item.key ? sortDir : null}
                                        style={item.sortable ? null : styleDisabledHeader}
                                        onClick={item.sortable ? handleSort(item.key) : null}
                                    >
                                        {item.text}
                                    </Table.HeaderCell>
                                ))}
                            </Table.Row>
                            <Table.Row key="filters" style={{height: "37px"}}>
                                <Table.Cell
                                    className="table-first-col table-item-sticky table-registry-filter-clear"
                                    style={{
                                        backgroundColor: '#f9fafb',
                                        textAlign: 'center',
                                        paddingTop: '5px',
                                        paddingBottom: '5px'
                                    }}>
                                    <Popup content={"Очистить все фильтры".t} trigger={clearFilterButton}/>
                                </Table.Cell>
                                {headerColumns.map(({key, source, filterKey, index}) => (
                                    <Table.Cell
                                        id={`filter-td-${key}`}
                                        key={key+index}
                                        sorted={sortBy === key ? sortDir : null}
                                        style={{background: '#fff;'}}
                                        className="table-select-cell table-filter-fixed__title"
                                    >
                                        {getFilter(key, source, filterKey, index)}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                rows.map(row => (
                                    <React.Fragment key={row.id}>
                                        <Table.Row style={{cursor: 'pointer'}}
                                                   className={isNew === row.id ? 'registry_cell_highlight' : ''}
                                                   key={`${row.id}_0`}
                                                   active={selectedIds.has(row.id)}>
                                            <Table.Cell
                                                rowSpan={2}
                                                className="table-first-col table-item-sticky"
                                            >
                                                <Checkbox
                                                    id={row.id}
                                                    checked={selectedIds.has(row.id)}
                                                    onChange={(e) => {
                                                        handleRowSelection(row.id)
                                                    }}
                                                />
                                            </Table.Cell>
                                            {columns.one.map(column => (
                                                <Table.Cell rowSpan={2}
                                                            key={`${row.id}_${column.key}_${column.index}`}
                                                            onClick={() => handleCreateClick(row.id)}
                                                >
                                                    {getCell(column, row)}
                                                </Table.Cell>
                                            ))}
                                            {
                                                columns.two_x.map(column => (
                                                    <Table.Cell
                                                        rowSpan={2}
                                                        key={column.index !== undefined ? `${row.id}_${column.key}_${column.index}` : `${row.id}_${column.key}`}
                                                        onClick={() => handleCreateClick(row.id)}
                                                    >
                                                        {
                                                            row.points[column.index] ? WeekDayOptions.getText(row.points[column.index][column.key]) : ''
                                                        }
                                                    </Table.Cell>
                                                ))
                                            }
                                            {getColumnsHeader(row)}
                                            {columns.three.map(column => (
                                                <Table.Cell rowSpan={2}
                                                            key={`${row.id}_${column.key}_${column.index}`}
                                                            onClick={() => handleCreateClick(row.id)}
                                                >
                                                    {getCell(column, row)}
                                                </Table.Cell>
                                            ))}
                                        </Table.Row>
                                        <Table.Row
                                            key={`${row.id}_1`}
                                            className={isNew === row.id ? 'registry_cell_highlight' : ''}
                                            style={{cursor: 'pointer'}}
                                            active={selectedIds.has(row.id)}
                                        >
                                            {
                                                columns.two.map(column => (
                                                    <Table.Cell
                                                        key={column.index !== undefined ? `${row.id}_${column.key}_${column.index}` : `${row.id}_${column.key}`}
                                                        onClick={() => handleCreateClick(row.id)}
                                                    >
                                                        {
                                                            row.points[column.index] ? WeekDayOptions.getText(row.points[column.index][column.key]) : ''
                                                        }
                                                    </Table.Cell>
                                                ))
                                            }
                                        </Table.Row>
                                    </React.Fragment>
                                ))
                            }
                        </Table.Body>
                    </Table>
                    {needScroll && <ScrollPreloader onVisible={onBottomVisible} continuous={true} />}

                </div>
            </div>
            <Modal open={isDeleteConfirmOpen.open}>
                <Modal.Header>Удаление графиков</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={<div>
                            <div>Выбранные вами графики будут удалены.</div>
                            <div>Чтобы подтвердить действие нажмите на кнопку "Удалить",
                                если не хотите подтверждать действие, то нажмите на кнопку "Отмена".
                            </div>
                        </div>}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setIsDeleteConfirmOpen({open: false})}
                    >
                        Отмена
                    </Button>
                    <Button
                        color="green"
                        loading={deleteProgress}
                        onClick={confirmDelete}
                    >
                        Удалить
                    </Button>
                </Modal.Actions>
            </Modal>
        </Shield>
    );
};

export default SchedulesConfig;
