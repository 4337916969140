import React from 'react';
import { withRouter } from "react-router-dom";
import { Table, Checkbox, Popup } from 'semantic-ui-react';
import qs from 'query-string';
import { CompanyTypeOptions, PointStatusOptions, PointRegistrationStatusOptions,
 PointBookmarkTypeOptions } from '../../../../api/model/Point';
import iconClearFilters from '../../../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png';
import WbGridFilterStringContains from '../../../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains';
import WbGridFilterList from '../../../waybills/wbGrid/wbGridFilters/WbGridFilterList';
import T from '../../../../components/Translate';
import {ProviderStatusOptions} from "../../../../api/model/Provider";
import {ImportFormFileStatusOptions, ImportFormStatusOptions} from "../../../../api/model/ImportForms";
import WbGridFilterListMulti from "../../../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";

const PointGridHeader = ({ headers, selectedPoints, setSelectedPoints, location, history, isCheckbox = true, getRowsIds, setIsAll, tableName}) => {

    const firstLoad = false;

    let query = qs.parse(location.search);

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let { sortBy, sortDir } = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({ ...query, sortBy, sortDir });
    };

    function applyFilter(filter) {
        updateQuery({ ...query, ...filter });
    }

    function removeFilter(key) {
        updateQuery({ ...query, [key]: undefined });
    }

    function clearFilters() {
        updateQuery({});
    }

    function getOptionsForStatus() {
        switch (tableName) {
            case 'importClientForms': return ImportFormStatusOptions;
            default: return ProviderStatusOptions;
        }
    }

    function getFilter(key) {
        if (firstLoad)
            return null;

        switch (key) {
            case 'client':
                return <WbGridFilterStringContains
                    field={'company'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'mappingCode':
            case 'createdByClient':
            case 'postalCode':
            case 'address':
            case 'pointName':
            case 'companyName':
            case 'companySearchName':
            case 'legalName':
            case 'name':
            case 'formsCount':
            case 'clientName':
            case 'fmid':
            case 'filename':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'pointType':
            case 'companyType':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={CompanyTypeOptions}
                />;
            case 'bookmarkType':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={PointBookmarkTypeOptions}
                />;
            case 'statusId':
            case 'pointStatus':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={PointStatusOptions}
                />;
             case 'status': {
                 return <WbGridFilterList
                     field={key}
                     applyFilter={applyFilter}
                     removeFilter={removeFilter}
                     options={getOptionsForStatus()}
                 />;
             }
             case 'formFileTaskStatus':
             case 'taskStatus': {
                 return <WbGridFilterListMulti
                     field={key}
                     applyFilter={applyFilter}
                     removeFilter={removeFilter}
                     options={ImportFormFileStatusOptions}/>;
             }
            case 'exportStatus':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={PointRegistrationStatusOptions}
                />;
            default:
                return null;
        }
    }

    const selectionExist = (selectedPoints || []).length > 0;

    function deselect() {
        if (selectionExist) {
            setSelectedPoints([]);
            setIsAll && setIsAll(false);
        }
        else {
            setIsAll && setIsAll(true);
            setSelectedPoints(getRowsIds());
        }
    }

    const { sortBy, sortDir } = query;

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{ paddingTop: '5px', display: 'inline-block', width: '17px' }}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{ height: '13px' }} />
        </span>
    );

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };

    const isSortable = (key) => {
        return ['statusId', 'exportStatus', 'bookmarkType', 'pointStatus', 'pointType', 'companyType'].includes(key);
    };

    return <Table celled fixed singleLine sortable className="table-header">
        <Table.Header>
            <Table.Row key="headers" style={{ fontSize: 'smaller' }}>

                {isCheckbox && <Table.HeaderCell className="table-first-col table-select-all-cell">
                    <Checkbox
                        checked={false}
                        indeterminate={selectionExist}
                        onChange={(e, data) => deselect()}
                    />
                </Table.HeaderCell>}
                {headers.map(({ text, key }) => (
                    <Table.HeaderCell
                        id={`header-th-${key}`}
                        key={key}
                        sorted={isSortable(key) && sortBy === key ? sortDir : null}
                        className="table-header-cell"
                        style={isSortable(key) ? null : styleDisabledHeader}
                        onClick={isSortable(key) ? handleSort(key) : null}
                    >
                        <span><T>{text}</T></span>
                    </Table.HeaderCell>
                ))}
            </Table.Row>

            <Table.Row key="filters">
                {isCheckbox &&  <Table.Cell
                    style={{ backgroundColor: '#f9fafb', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                    <Popup content={"Очистить все фильтры".t} trigger={clearFilterButton} />
                </Table.Cell>}
                {headers.map(({ key, filter }) => (
                    <Table.Cell
                        id={`filter-td-${key}`}
                        key={key}
                        sorted={sortBy === key ? sortDir : null}
                        className="table-filter-cell"
                    >
                        {getFilter(key)}
                    </Table.Cell>
                ))}
            </Table.Row>
        </Table.Header>
    </Table>;
};

export default withRouter(PointGridHeader);
