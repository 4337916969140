import React, {useState, useMemo, useRef, useEffect} from 'react';
import {Checkbox, Form, Icon, Input, Popup, Visibility} from "semantic-ui-react";

const formStyle = {
    maxHeight: "250px",
    overflowY: "auto",
    overflowX: "hidden",
};

const inputStyle = {
    textOverflow: "ellipsis",
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
};

const labelStyle = {
    fontWeight: 'bold'
};


const InputDropdownCheckbox = ({
                                   className = '',
                                   value = [],
                                   options: initialOptions = [],
                                   onChange,
                                   placeholder,
                                   multiple = false,
                                   search = false,
                                   disabled,
                                   allLabel = 'Все',
                                   onBlur
                               }) => {

    const inputRef = useRef(null);
    const context = useRef(null);
    const [options, setOptions] = useState(initialOptions);
    const [isSearch, setIsSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const popupStyle = useMemo(() => {
        return {
            marginTop: '0',
            borderTop: 'none',
            maxWidth: `${inputRef.current && inputRef.current.offsetWidth}px`,
            minWidth: `${inputRef.current && inputRef.current.offsetWidth}px`,
        }
    }, [inputRef && inputRef.current]);

    const toggle = (e, {value: newValue}) => {
        if (multiple) {
            let values = [...value];
            if (values.some(x => x === newValue)) {
                values.splice(values.indexOf(`${newValue}`), 1);
            } else {
                values.push(newValue);
            }
            onChange && onChange(values);
        } else onChange(newValue);
    };

    const toggleALL = () => {
        onChange(value.length ? [] : [...options.map(item => item.value)])
    };

    const content = (
        <div ref={context} style={formStyle}>
            <Form>
                {options.length && multiple ? <Form.Field>
                    <Checkbox checked={value.length ? value.length === options.length : false} onChange={toggleALL}
                              label={<label>{allLabel}</label>}/>
                </Form.Field> : null}
                {options.map(x => {
                    let label = (
                        <label>
                            {x.color && <Icon color={x.color} inverted={x.inverted} name="circle"/>}
                            {x.text}
                        </label>
                    );
                    return (<Form.Field key={x.key}>
                        <Checkbox value={x.value} checked={value.includes(x.value)} onChange={toggle}
                                  label={label}/>
                    </Form.Field>);
                })}
                {!options.length && isSearch && <label>Результатов не найдено</label>}
            </Form>
        </div>
    );

    useEffect(() => {
        const newOptions = initialOptions.filter(o => o.text.toUpperCase().includes(searchValue.toUpperCase()));
        setOptions(newOptions);
    }, [searchValue]);

    return (
        <div className="" onBlur={onBlur}>
            <Popup
                disabled={disabled}
                trigger={
                    <input
                        disabled={disabled}
                        className={className}
                        style={inputStyle}
                        placeholder={placeholder}
                        value={!isSearch ? (value.length === options.length ? "Все" : value.map(v => options.find(o => o.value === v).text).join(', ')) : searchValue}
                        ref={inputRef}
                        readOnly={!isSearch || !search}
                        onChange={event => setSearchValue(event.target.value)}
                        onFocus={() => search && setIsSearch(true)}
                        onBlur={() => {
                            search && setIsSearch(false);
                            setSearchValue('');
                        }}
                    />
                }
                style={popupStyle}
                basic
                content={content}
                position='bottom left'
                on='click'
            />
        </div>
    );
};

export default InputDropdownCheckbox;
