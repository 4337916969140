import {LinkInfo} from '../layout/navbar/Navbar';
import {download, get, post, upload} from '../services/http';
import User from './model/User'; // eslint-disable-line
import UserContext from './model/UserContext';
import UserPermissions from './model/UserPermissions';


const userLinks = [
    new LinkInfo('Мои заявки', '/waybills', [UserPermissions.WAYBILLS, UserPermissions.AUCTIONS], "box"),
    new LinkInfo('Импорт заявок', '/import', [UserPermissions.IMPORT_FORMS_AND_RULES_VIEW, UserPermissions.IMPORT_FORMS_AND_RULES_MANAGE], "download"),
    new LinkInfo('Мои адреса', '/points', [UserPermissions.COMPANY_POINTS], "map marker alternate"),
    new LinkInfo('Реестры', '/registries', [UserPermissions.REGISTRIES], "table"),
    new LinkInfo('Адреса', '/admin/points', [UserPermissions.POINTS], "map"),
	new LinkInfo('Клиенты', '/admin/companies', [UserPermissions.COMPANIES, UserPermissions.TIMELIMITS], "building"),
	new LinkInfo('Поставщики', '/admin/providers', [UserPermissions.PROVIDERS], "building outline"),
	new LinkInfo('Конструктор', '/autocomplete', [UserPermissions.STANDARD_AUTOCOMPLETE], "cogs"),
    new LinkInfo('Пользователи', '/admin/users', [UserPermissions.USERS], "users"),
    new LinkInfo('Доступы', '/admin/profiles', [UserPermissions.USERPROFILES], "key"),
    new LinkInfo('Управление баннерами', '/banners', [UserPermissions.BANNERS], "bullhorn"),
    new LinkInfo('Графики', '/schedules', [UserPermissions.SCHEDULES, UserPermissions.SCHEDULES_SETTINGS_VIEAW], "calendar alternate"),
    new LinkInfo('Управление уведомлениями', '/notifications', [UserPermissions.MANGING_NOTIFICATIONS], "bell outline"),
    new LinkInfo('Справочники', '/dictionaries', [UserPermissions.DICTIONARIES_EDIT], "book"),
];

export function getUserLinks(role) {
    if (!role)
        throw new Error('role is null');
    const permissions = role.permissions || [];
    return userLinks.filter(l => l.accessKey.some(i => permissions.includes(i)));
}

export const getRole = async () => {
    return await get("user/role");
};

export async function userLogin(login, password) {
    // scheme
    // send l & p over https
    // get token
    // apply token on subsequent requests
    const res = await post("user/login", {
        email: login,
        password: password
    });

    const context = res
        ? new UserContext(res)
        : null;

    return context;
}

export async function getUsers() {

    const users = await get('users');

    return users;
}

export async function getUserCurrent() {

    const user = await get(`user/current`);

    return user;
}

export async function getUserById(id) {

    const user = await get(`user/id/${id}`);

    return user;
}

export async function getUserByIdWithOptions(id) {

    const user = await get(`user/id/${id}/options`);

    return user;
}

export async function getUserCompanyOptions(isAuction) {

    const user = await get(`user/options/company${isAuction ? `?isForAuctions=true` : ''}`);

    return user;
}


/** @param {User} dto */
export async function updateUser(dto) {

    const result = await post(`user/update`, dto);

    return result;
}

/** @param {User} dto */
export async function createUser(dto) {

    const result = await post(`user/create`, dto);

    return result;
}

/** @param {User} dto */
export async function postUser(dto) {

    return dto.id ? await updateUser(dto) : await createUser(dto);
}

export async function getUserBySearch(searchValue, filters = {}) {
    const searchQuery = {
        value: searchValue,
        ...filters
    };
	const result = await post(`users/search`, searchQuery);
	return result;
}

export async function userPasswordReset(email) {

    var result = await get(`user/${email}/password/reset`);
    return result;
}

export async function userFirstRunClear() {
    var result = await get(`user/firstrun/clear`);
    return result;
}

export async function getCompanyOptionsBySearchUser(term, isAuction) {
    const searchResult = await get(`user/options/company/${term}${isAuction ? `?isForAuctions=true` : ''}`);

    return searchResult;
}

export async function getRegistriesNotification() {
    return await get('registries/notification');
}

export async function getWaybillsNotification() {
    return await get('waybills/notification');
}

export async function viewWaybillsNotification(id) {
    return await get(`waybills/${id}/notification/view`);
}

export async function downloadUserCompanies(id) {
    return await get(`userCompanies/excel/${id}`);
}

export async function importUserCompanies(id, formData) {
    return await upload(`userCompanies/excel/${id}`, formData);
}

export async function deleteUserCompanies(id) {
    return await get(`userCompanies/clear/${id}`);
}

export async function acceptAuctionRules() {
    return await post(`user/acceptAuctionRules`);
}


export async function exportExcel(searchValue, filters = {}) {
    const searchQuery = {
        value: searchValue,
        ...filters
    };
    return await post(`users/excel`, searchQuery);
}


