import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Modal} from "semantic-ui-react";
import InputDate from "../../components/inputs/InputDate";
import {rgUpdatePeriod} from "../../api/registries";

const RgEditPeriodModal = ({open, onClose, selectedRow}) => {
    const [updatePeriodProgress, setUpdatePeriodProgress] = useState(false);

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null,
    });

    useEffect(()=>{
        setDates({
            startDate: null,
            endDate: null,
        });
    }, [open]);

    const onChangeDate = (name, value) => {
        setDates(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    async function getUpdatePeriod() {
        setUpdatePeriodProgress(true);
        const param = {
            id: selectedRow.id,
            ...dates
        };
        await rgUpdatePeriod(param);
        await onClose();
        setUpdatePeriodProgress(false);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Modal.Header>Изменение периода реестра №{selectedRow && selectedRow.registryNumber}</Modal.Header>
            <Modal.Content>
                <Form>
                    <h5>Текущий период реестра {selectedRow && selectedRow.period}</h5>
                    <h5>Укажите новый период:</h5>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label>Дата начала периода</label>
                                    <InputDate
                                        icon="calendar outline"
                                        placeholder={'Дата начала периода'.t}
                                        position="bottom center"
                                        maxDate={dates.endDate}
                                        onChange={val => onChangeDate("startDate", val)}
                                        value={dates.startDate}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label>Дата окончания периода</label>
                                    <InputDate
                                        icon="calendar outline"
                                        placeholder={'Дата окончания периода'.t}
                                        position="bottom center"
                                        minDate={dates.startDate}
                                        onChange={val => onChangeDate("endDate", val)}
                                        value={dates.endDate}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Отменить</Button>
                <Button color="blue" disabled={!(dates.startDate && dates.endDate) || updatePeriodProgress} loading={updatePeriodProgress} onClick={getUpdatePeriod}>Изменить период</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default RgEditPeriodModal;
