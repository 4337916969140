import O from '../../components/Option';

export const CompanyStatusEnum = {
    ACTIVE: 0,
    BLOCKED: 1
};

export const CompanyStatusOptions = [
    new O(CompanyStatusEnum.ACTIVE, "Активна"),
    new O(CompanyStatusEnum.BLOCKED, "Заблокирована"),
];

export const LegalFormOptions = [
    new O('ООО', 'ООО'),
    new O('АО', 'АО'),
    new O('ОАО', 'ОАО'),
    new O('ЗАО', 'ЗАО'),
    new O('ПАО', 'ПАО'),
    new O('ИП', 'ИП'),
    new O('ПК', 'ПК'),
    new O('ТОО', 'ТОО'),
    new O('ЧП', 'ЧП'),
    new O('ОДО', 'ОДО'),
    new O('ФГУП', 'ФГУП'),
];

//здесь закомментировать те, которые еще не готовы
export const historyKeys = {
    props: "Property",
    oapi: "OpenApi",
    cutoffBlock: "TimeLimit",
    restrictions: "CapacityLimit",
    'field-config': "FieldSetting",
    'address-exceptions': "PointException",
    'calculating-fields-rules': "Autocomplete",
    'docs-management': "DocumentManagement",
    emailSendingDocuments: "ContactManagement"
};

export default class Company {

    constructor(dto) {

        /** @type {number} */
        this.fmid = dto.fmid;   // searchName клиента

        /** @type {string} */
        this.shipperName = dto.shipperName;

        /** @type {string} */
        this.name = dto.name;

        /** @enum {CompanyStatusEnum} */
        this.statusId = dto.statusId || CompanyStatusEnum.ACTIVE;

        /** @type {string} */
        this.INN = dto.INN;

        /** @type {string} */
        this.OGRN = dto.OGRN;

        /** @type {string} */
        this.regDate = dto.regDate;

        /** @type {string} */
        this.regAddress = dto.regAddress;

        /** @type {string} */
        this.regAddressIndex = dto.regAddressIndex;

        /** @type {string} */
        this.postAddress = dto.postAddress;

        /** @type {boolean} */
        this.postAddressEqRegAddress = dto.postAddressEqRegAddress;
    }
}