import React, {useEffect, useState} from 'react';
import {Checkbox, Form, Grid, Header, Table} from 'semantic-ui-react';
import {
    companyFieldConfigCreate,
    companyFieldConfigUpdate,
    getCompaniesIsMixAllowedUpdate,
    getCompanyFieldConfigs
} from '../../../../../api/companies';
import {WaybillFieldEnum, WaybillFieldOptions} from '../../../../../api/model/Enums';
import {WaybillTypeEnum} from '../../../../../api/model/Waybill';
import InputDropdown from '../../../../../components/inputs/InputDropdown';
import {dependencyMatrix, enumValueToCode, fieldNames, fieldValueCasters, valueOptionsDict} from './companyTabUtils';


function canHideField(field) {
    return WaybillFieldOptions.filter(({value}) => {
        return ![
            WaybillFieldEnum.ShippingType,
            WaybillFieldEnum.CarType,
            WaybillFieldEnum.CargoUnitType,
            WaybillFieldEnum.WaybillStepType,
            WaybillFieldEnum.StickerShippingType,
            WaybillFieldEnum.WaybillStepTypeOApi,
        ].includes(value);
    }).some(({value}) => {
        return field === value;
    });
};


function addEmptyOption(options) {
    return [{text: 'Все значения', key: '%%', value: null}, ...options];
};

function getDefaultDepsValues(targetFieldDeps) {
    const res = {};
    targetFieldDeps.forEach(dep => {
        res[dep] = null;
    });
    return res;
};

export default function CompanyTabFieldConfig({data, match, history, setData}) {

    const [loading, setLoading] = useState(false);
    const [configs, setConfigs] = useState([]);
    const [targetField, setTargetField] = useState(WaybillFieldEnum.ShippingType);

    const [mixAllowed, setMixAllowed] = useState(data.mixAllowed);
    const companyId = data.fmid;
    const targetFieldDeps = dependencyMatrix[targetField];
    const targetFieldValueOptions = valueOptionsDict[targetField];

    const depValuesDefault = getDefaultDepsValues(targetFieldDeps);
    const [depsValuesSelectedDict, setDepsValuesSelectedDict] = useState(depValuesDefault); // selecteced

    const targetFieldConfigs = configs.filter(c => c.targetField === targetField);
    const targetFieldAndModifiersConfigs = targetFieldConfigs.filter(config => {

        const result = targetFieldDeps.every(dep => {
            const depValueCaster = fieldValueCasters[dep];
            const depFieldName = fieldNames[dep];
            const depFieldConfig = config[depFieldName];

            const depValFromConfig = depFieldConfig && depValueCaster(depFieldConfig);
            return depValFromConfig === depsValuesSelectedDict[dep];
        });
        return result;
    });

    const currentConfigField = targetFieldAndModifiersConfigs
        .filter(c => c.targetFieldValue === null)[0] || {targetField, isVisible: true};

    function getDepsView() {
        if (depsValuesSelectedDict[WaybillFieldEnum.ShippingType] === WaybillTypeEnum.Rail) {
            if (targetField === WaybillFieldEnum.CargoUnitType) {
                return targetFieldDeps
                    .filter(d => d !== WaybillFieldEnum.ShippingTempCondition);
            }
        }
        return targetFieldDeps;
    }

    function canOnChangeMixAllowed() {
        return targetField === WaybillFieldEnum.CargoUnitType
    }

    function depValuesToNames() {
        const res = {};
        targetFieldDeps.forEach(dep => {
            res[fieldNames[dep]] = depsValuesSelectedDict[dep];
        });
        return res;
    };

    function getValueConfig(value) {
        const valueCaster = fieldValueCasters[targetField];
        const valueConfigsCurrent = targetFieldAndModifiersConfigs.filter(cfg => valueCaster(cfg.targetFieldValue) === value);
        const result = valueConfigsCurrent.pop();
        return result;
    };

    function isValueChecked(value) {
        const createdConfig = getValueConfig(value);
        return createdConfig ? createdConfig.isVisible : true;
    };

    function getDepAllowedValueOptions({targetField, dep, options}) {
        if (targetField === WaybillFieldEnum.VehicleCapacity &&
            dep === WaybillFieldEnum.ShippingType) {
            return options.filter(({value}) => value === WaybillTypeEnum.FTL);
        }
        if (targetField === WaybillFieldEnum.CarType &&
            dep === WaybillFieldEnum.ShippingType) {
            return options.filter(({value}) => value === WaybillTypeEnum.Rail);
        }
        if (targetField === WaybillFieldEnum.ShippingTempCondition &&
            dep === WaybillFieldEnum.ShippingType) {
            return options.filter(({value}) => value !== WaybillTypeEnum.Rail);
        }
        return options;
    };

    function onChangeDepValue(dep, val) {
        const newDepValues = {...depsValuesSelectedDict};
        newDepValues[dep] = val;
        setDepsValuesSelectedDict(newDepValues);
    }

    // show or hide field at all
    async function onChangeField(checked) {
        const dto = {
            ...depValuesToNames(),
            targetField,
            targetFieldValue: null,
            isVisible: checked
        };
        if (currentConfigField.id) {
            await companyFieldConfigUpdate(currentConfigField.id, dto);
        } else {
            await companyFieldConfigCreate(companyId, dto);
        }
        const result = await getCompanyFieldConfigs(companyId);
        setConfigs(result);
    };

    async function onChangeMixAllowed(checked) {
        await getCompaniesIsMixAllowedUpdate(data.fmid, {
            mixAllowed: checked,
            useFactPalletCount: data.useFactPalletCount
        }).then(() => {
            const dataNew = {...data, mixAllowed: checked};
            setData(dataNew);
            setMixAllowed(checked);
        });
    }

    async function onChangeUseFactPalletCount(checked) {
        await getCompaniesIsMixAllowedUpdate(data.fmid, {
            useFactPalletCount: checked,
            mixAllowed: data.mixAllowed
        }).then(() => {
            const dataNew = {...data, useFactPalletCount: checked};
            setData(dataNew);
        });
    }

    async function onChangeValue(value, checked) {
        const createdConfig = getValueConfig(value);
        const dto = {
            ...depValuesToNames(),
            targetField,
            targetFieldValue: enumValueToCode(targetField, value),
            isVisible: checked
        };
        if (createdConfig) {
            await companyFieldConfigUpdate(createdConfig.id, dto);
        } else {
            await companyFieldConfigCreate(companyId, dto);
        }
        const result = await getCompanyFieldConfigs(companyId);
        setConfigs(result);
    };

    useEffect(() => {
        (async () => {
            if (!companyId) {
                return;
            }
            setLoading(true);
            const result = await getCompanyFieldConfigs(companyId);
            setConfigs(result);
            setLoading(false);
        })();
    }, [companyId]);

    useEffect(() => {
        if (targetField) {
            setDepsValuesSelectedDict(getDefaultDepsValues(targetFieldDeps));
        }
    }, [targetField]);

    if (loading) {
        return null;
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Form>
                        <Header className="m-b-15" dividing>Настраиваемое поле</Header>
                        <Form.Group>
                            <Form.Field inline>
                                <InputDropdown
                                    placeholder={"Выберите поле".t}
                                    options={WaybillFieldOptions}
                                    value={targetField}
                                    onChange={val => setTargetField(val)}
                                    style={{width: '450px'}}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Header className="m-b-15" dividing>Значения поля</Header>
                        {/* <Form.Group widths="equal">
                {getDepsView().map((dep) => (
                    <Form.Field key={dep}>
                        <label>{fieldNamesLocale[dep]}</label>
                        <InputDropdown
                            placeholder={"Все значения".t}
                            options={
                                getDepAllowedValueOptions({
                                    options: addEmptyOption(valueOptionsDict[dep]),
                                    targetField,
                                    dep
                                })
                            }
                            value={depsValuesSelectedDict[dep]}
                            onChange={val => onChangeDepValue(dep, val)}
                        />
                    </Form.Field>
                ))}
            </Form.Group> */}
                        {canHideField(targetField) &&
                        <Checkbox
                            className=""
                            style={{fontSize: "13px"}}
                            checked={currentConfigField.isVisible}
                            onChange={(obj, e) => {
                                onChangeField(e.checked);
                            }}
                            label="Показывать поле"
                        />
                        }

                        {canOnChangeMixAllowed() && <Checkbox
                            className=""
                            style={{fontSize: "13px"}}
                            checked={mixAllowed}
                            onChange={(obj, e) => {
                                onChangeMixAllowed(e.checked);
                            }}
                            label="Разрешен микс"
                        />}

                        {canOnChangeMixAllowed() && <Checkbox
                            className="m-l-15"
                            style={{fontSize: "13px"}}
                            checked={data.useFactPalletCount}
                            onChange={(obj, e) => {
                                onChangeUseFactPalletCount(e.checked);
                            }}
                            label="Учет фактического количества ГЕ"
                        />}

                        {targetField !== WaybillFieldEnum.Provider &&
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={10}>
                                        Значение
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={6}>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {targetFieldValueOptions && targetFieldValueOptions.map(({value, text, key}) => {
                                    return <Table.Row key={key}>
                                        <Table.Cell>
                                            {text}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Checkbox
                                                type="checkbox"
                                                checked={isValueChecked(value)}
                                                onChange={(e, {checked}) => {
                                                    onChangeValue(value, checked);
                                                }}
                                            />
                                        </Table.Cell>
                                    </Table.Row>;
                                })}
                            </Table.Body>
                        </Table>
                        }
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
