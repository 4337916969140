import React from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { pointRegister } from '../../../../api/points';
import T from '../../../../components/Translate';

export default function PointModalAccept({ updateState, updateData, data, dataValidationErrors, close, loading, onOk }) {

    const getDataValidationErrors = () => {

        const dve = { ...dataValidationErrors };

        dve.fmid = (!data.fmid || data.fmid.length < 5)
            ? "Введите не менее 5 символов"
            : null;

        return dve;
    };

    const submit = async () => {

        const dve = getDataValidationErrors();

        const dataIsValid = Object.entries(dve).every(e => !e[1]);

        if (dataIsValid) {

            updateState({ dataValidationErrors: dve, loading: true });

			try {

				await pointRegister(data.id, { searchName: data.fmid, zone: data.zone });

				onOk();

			} catch (e) {

			}

            updateState({ open: false, loading: false });


        } else {
            updateState({ dataValidationErrors: dve });
        }
    };

    return (
        <React.Fragment>
            <Modal.Header><T>Регистрация адреса</T></Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Field required error={!!dataValidationErrors.fmid}>
                        <label><T>Введите search name (не менее 5 символов)</T></label>
                        <Input
                            placeholder={'search name'.t}
                            value={data.fmid || ''}
                            onChange={e => updateData({ fmid: e.target.value.toUpperCase() })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label><T>Введите зону</T></label>
                        <Input
                            type="number"
                            placeholder={'зона (от 1 до 4)'.t}
                            value={data.zone || ''}
                            onChange={e => updateData({ zone: e.target.value.toUpperCase() })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={loading}
                    onClick={close}
                >
                    <T>Отмена</T>
                </Button>
                <Button
                    disabled={loading}
                    positive
                    onClick={submit}
                >
                    <T>Зарегистрировать</T>
                </Button>
            </Modal.Actions>
        </React.Fragment>
    );
}
