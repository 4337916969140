import React, { useState, useRef } from 'react';
import { Card, Icon } from 'semantic-ui-react';
import { get } from '../../services/http';
import { downloadFilePod } from "../../api/waybillDocs";
import downloadFile from "../../services/downloadFile";

// import ga from './GalleryArtifact.txt';

export default function GalleryItem({
	header,
	meta,
	description,
	url,
	icon,
	isDirectDownload,
	id
}) {
	const [loading, setLoading] = useState(false);
	const downBlob = async (e) => {
		setLoading(true);
		e.preventDefault();
		await downloadFilePod(id).then(res => {
			downloadFile(res);
			setLoading(false);
		});

	};
	const down = async (e) => {
		setLoading(true);
		if (isDirectDownload)
			return true;

		e.preventDefault();
		const link = e.currentTarget;


		try {
			await get(url).then(fileDto => {
				link.pathname = fileDto.url;
				isDirectDownload = true;
				link.click();
				isDirectDownload = false;
				setLoading(false);
			});
		} catch (error) {
		}
	};

	return (
		<Card
			link
			download={!loading}
			href={!loading && url}
			target="_blank"
			onClick={loading ? null : (isDirectDownload ? downBlob : down)}
		>
			<Card.Content>
				<Icon size="large" className="f-r" name={icon} />
				<Card.Header>{header}</Card.Header>
				<Card.Meta>{meta}</Card.Meta>
				<Card.Description>{description}</Card.Description>
			</Card.Content>
			{/* <Card.Content extra>
				<div className='ui two buttons'>
					<Button basic color='green' onClick={down}>Cкачать</Button>
					<Button basic color='red'>Удалить</Button>
				</div>
				<div style={{ display: 'none' }}><a download href="##" ref={a => data.aElement = a}>file</a></div>
			</Card.Content> */}
		</Card>
		// <div style={{ display: 'none' }}><a download href="##" ref={a => aElement = a}>file</a></div>
	);
}
