import React, {useContext, useEffect, useState} from 'react';
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import {
    createNotification,
    deleteNotification,
    getAll,
    getEmails,
    getRecipients,
    getTypes,
    updateNotification
} from "../../api/notifications";
import {Checkbox, Confirm, Icon, Menu, Table} from "semantic-ui-react";
import Shield from "../../layout/page/shield/Shield";
import T from "../../components/Translate";
import qs from "query-string";
import WbGridFilterListMulti from "../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";
import {Oc} from "../../api/model/Waybill";
import {getRoles} from "../../api/usersRoles";
import {recipientTypesEnum} from "../../api/model/Enums";
import EditRow from "./editRow";
import {toast} from "../../services/toast";

const noRows = (
    <div className="no-rows-wrapper">
        <div className="no-rows">Нет уведомлений.</div>
    </div>
);

const headers = [
    {
        key: 'name',
        text: 'Наименование уведомления',
        sortable: true
    },
    {
        key: 'recipientType',
        text: 'Тип адресата'
    },
    {
        key: 'email',
        text: 'Email'
    },
    {
        key: 'roles',
        text: 'Роль'
    },
    {
        key: 'considerCompany',
        text: 'Учитывать привязку к клиенту'
    },
    {
        key: 'copy',
        text: 'В копию'
    }
];

const NotificationsList = ({location, history}) => {
    const contextNavi = useContext(ContextNavi);
    const [roles, setRoles] = useState([]);
    const [types, setTypes] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [emails, setEmails] = useState([]);
    const [greenRows, setGreenRows] = useState([]);
    const [deleteRow, setDeleteRow] = useState(null);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("Управление уведомлениями", "/notifications")]);
        let cleanupFunction = false;
        const loadData = async () => {
            setLoaded(false);
            try {
                const rolesData = await getRoles();
                const emailsData = await getEmails();
                const typesData = await getTypes();
                const recipientsData = await getRecipients();
                if (!cleanupFunction) {
                    setRoles(rolesData.map(row => new Oc(row.id, row.name, row.color)));
                    setEmails(emailsData.map(row => new Oc(row, row)));
                    setTypes(typesData.map(row => new Oc(row.key, row.value)));
                    setRecipients(recipientsData.map(row => new Oc(row.key, row.value)));
                }
            } catch (e) {
                console.error(e.message)
            }
        };
        loadData().then(() => setLoaded(true));
        return () => cleanupFunction = true;
    }, []);

    let [rows, setRows] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [loading, setLoading] = useState(true);

    const [newRows, setNewRows] = useState([]);
    const [editRows, setEditRows] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await getAll(location.search);
            setRows(result);
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, [location]);

    useEffect(()=>{
        setEditRows(newRows.map(r=>r.id));
    }, [newRows]);

    const getCell = (key, val, row) => {
        switch (key) {
            case 'email':
                return val || (row['recipientType'] === recipientTypesEnum.USER_EMAIL ? "Email пользователя" : '' )
            case "recipientType":
                return recipients.find(r => r.key === val) && recipients.find(r => r.key === val).text;
            case "considerCompany":
            case "copy":
                return <Checkbox disabled style={{cursor: 'pointer'}} checked={val}/>;
            case 'roles':
                return <div
                    className='notifications__roles'>{(val.length === roles.length && roles.length) ? 'Все роли' : val.map(v => v.name).join(', ')}</div>
            default:
                return val;
        }
    };

    const handleDelete = async (id) => {
        let row = !!newRows.find(row => row.id === id);
        setDeleteRow(null);
        if (row) setNewRows(rows => rows.filter(r => r.id !== id));
        else await deleteNotification(id).then(() => fetchData());
    };

    const handleEdit = (id) => {
        if (editRows.includes(id)) {
            setNewRows(rs => [...rs.filter(row => row.id !== id)]);
        }
        else {
            setNewRows(rs => [...rs, rows.find(row => row.id === id)]);
        }
    }

    const handleCopy = async (id) => {
        let data = {...rows.find(row => row.id === id)};
        data.id = null;
        data.isNew = null;
        const res = await createNotification(data);
        let idNew = res.id;
        if (idNew) {
            fetchData().then(() => {
                setNewRows(rows => rows.filter(r => r.id !== id));
                setGreenRows(rows => [...rows, idNew]);
                setTimeout(() => setGreenRows(rows => [...rows.filter(r => r !== idNew)]), 5000);
            });
        }
    }

    const requestEmails = async () => {
        const emailsData = await getEmails();
        setEmails(emailsData.map(row => new Oc(row, row)));
    }

    const handleSave = async (row) => {
        let id = row.id, idNew;
        let data = {...row};

        if (data.isNew) {
            data.id = null;
            data.isNew = null;
            const createRes = await createNotification(data);
            idNew = createRes && createRes.id;
        } else {
            let rowId = data.id;
            delete data.id;
            await updateNotification(rowId, data);
        }
        requestEmails();
        fetchData().then(() => {
            setNewRows(rows => rows.filter(r => r.id !== id))
            setGreenRows(rows => [...rows, idNew || id]);
            setTimeout(() => setGreenRows(rows => [...rows.filter(r => r !== (idNew || id))]), 5000);
        });
    };

    const handleNew = () => {
        setNewRows(rs => [
            {
                id: 'new'+Date.now(),
                isNew: true
            },
            ...rs
        ])
    };

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };

    let query = qs.parse(location.search);

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    function applyFilter(filter) {
        updateQuery({...query, ...filter});
    }

    function removeFilter(key) {
        updateQuery({...query, [key]: undefined});
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = '0';
        } else {
            sortDir = sortDir === '0' ? '1' : '0';
        }

        updateQuery({...query, sortBy, sortDir});
    };

    function sortData(x, y) {
        if (x.text < y.text) return -1;
        if (x.text > y.text) return 1;
        return 0;
    }

    const getFilter = (key) => {
        switch (key) {
            case 'considerCompany':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new Oc(true, 'Учитывать', "green"),
                        new Oc(false, 'Не учитывать', "red")
                    ]}/>;
            case 'copy':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new Oc(true, 'Активен', "green"),
                        new Oc(false, 'Не активен', "red")
                    ]}
                />;
            case 'recipientType':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={recipients}
                />;
            case 'email':
                return <WbGridFilterListMulti
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={emails.sort(sortData)}
                />;
            case 'name':
                return <WbGridFilterListMulti
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={types.sort(sortData)}
                />;
            case 'roles':
                return <WbGridFilterListMulti
                    hideOnScroll={false}
                    field={'role'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={roles}
                    allValue={'Все роли'}
                />;
            default:
                return null;
        }
    }

    const actions = (row, isEdit) => {
        const canSave = () => {
            switch (row.recipientType) {
                case recipientTypesEnum.EMAIL:
                case recipientTypesEnum.POOLING: {
                    !row.email && toast.error(`Заполните поле "Email"`, '');
                    return !!row.email;
                }
                case recipientTypesEnum.ROLE: {
                    (!row.roles || !row.roles.length > 0) && toast.error(`Заполните поле "Роль"`, '');
                    return row.roles && row.roles.length > 0;
                }
                case recipientTypesEnum.USER_EMAIL:
                    return true;
                default:
                    return false;
            }
        };
        return <Table.Cell textAlign='center' className="notifications__action-sticky">
            {(row.isNew || isEdit) && <Icon
                name="save"
                className='notifications__action'
                link
                title="Сохранить"
                size="large"
                disabled={!(row['notificationType'] && row['recipientType'])}
                color={(row['notificationType'] && row['recipientType']) ? "green" : "grey"}
                onClick={() => canSave() ? handleSave(row) : null}
            />}
            <Icon
                name="pencil"
                className='notifications__action'
                link
                title="Редактировать"
                size="large"
                color="blue"
                disabled={row.isNew}
                onClick={() => handleEdit(row.id)}
            />
            <Icon
                name="copy outline"
                className='notifications__action'
                link
                title="Копировать"
                size="large"
                color="grey"
                disabled={row.isNew || editRows.includes(row.id)}
                onClick={() => handleCopy(row.id)}
            />
            <Icon
                name="trash alternate"
                className='notifications__action'
                link
                title="Удалить"
                size="large"
                color="red"
                onClick={() => setDeleteRow(row.id)}
            />
        </Table.Cell>
    }

    const {sortBy, sortDir} = query;

    return (
        <Shield loading={loading || !loaded} loadingOver={loaded}>
            <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size='small' borderless>
                <Menu.Menu position='right'>
                    <Menu.Item onClick={handleNew}>
                        <Icon name="plus"/><T>Добавить уведомление</T>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <div className="table-wrapper-grid table-wrapper-grid-130">
                <div>
                    <Table celled sortable singleLine>
                        <Table.Header className="table-header-fixed">
                            <Table.Row>
                                {headers.map(item => (
                                    <Table.HeaderCell
                                        id={`header-th-${item.key}`}
                                        key={item.key}
                                        style={!item.sortable ? styleDisabledHeader : {}}
                                        sorted={item.sortable && sortBy === item.key ? (sortDir === '1' ? 'descending' : 'ascending') : null}
                                        onClick={item.sortable ? handleSort(item.key) : null}
                                        className="table-select-cell table-header-fixed__title"
                                    >
                                        {item.text}
                                    </Table.HeaderCell>

                                ))}
                                <Table.HeaderCell
                                    style={styleDisabledHeader}
                                    className="table-select-cell table-header-fixed__title notifications__action-sticky"
                                >
                                    Действия
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row className='notifications-filter'>
                                {headers.map(({key}) => (
                                    <Table.Cell
                                        id={`filter-td-${key}`}
                                        style={styleDisabledHeader}
                                        key={key}
                                        className={"table-select-cell notifications-filter-fixed__title"}
                                    >
                                        {getFilter(key)}
                                    </Table.Cell>
                                ))}
                                <Table.Cell
                                    className={"table-select-cell notifications-filter-fixed__title notifications__action-sticky"}/>
                            </Table.Row>
                        </Table.Header>
                        {
                            rows.length
                                ? <Table.Body>
                                    {
                                        newRows.filter(r => r.isNew).concat(rows).map(row => (
                                            (!row.isNew && !editRows.includes(row.id)) ?
                                                <Table.Row
                                                    key={row.id}
                                                    className={greenRows.includes(row.id) ? 'notifications__green-row' : ''}
                                                >
                                                    {
                                                        headers.map(column => (
                                                            <Table.Cell
                                                                textAlign={(column.key === 'considerCompany' || column.key === 'copy') ? 'center' : 'left'}
                                                                key={`${column.key}_${row.id}`}>
                                                                {
                                                                    getCell(column.key, row[column.key], row)
                                                                }
                                                            </Table.Cell>
                                                        ))
                                                    }
                                                    {
                                                        actions(row, false)
                                                    }
                                                </Table.Row>
                                                : <EditRow
                                                    key={row.id}
                                                    options={{types, recipients, roles}}
                                                    initialRow={newRows.find(r => r.id === row.id)}
                                                    actions={actions}
                                                />
                                        ))
                                    }
                                </Table.Body>
                                : <div className="table-empty"/>
                        }
                    </Table>
                </div>
                {!rows.length && noRows}
            </div>

            <Confirm
                open={!!deleteRow}
                cancelButton={'Отмена'.t}
                header="Вы уверены, что хотите удалить данное уведомление?"
                content={'Если да, то нажмите на кнопку “Ок”, если нет, то на кнопку “Отмена”'}
                onCancel={() => setDeleteRow(null)}
                onConfirm={() => handleDelete(deleteRow)}
            />
        </Shield>
    )
};

export default NotificationsList;
