import React from 'react';
import {Icon, Input} from "semantic-ui-react";
import './styles.css';

const InputClearable = ({className = '', value, onChange, icon, placeholder}) => {
    return (
        <span className={`input-clearable ${value ? 'input-clearable__with-clear' : ''} ${className}`}>
            <Input
                value={value}
                onChange={onChange}
                icon={icon}
                placeholder={placeholder}
            />
            <div className='input-clearable__icon'>
                <Icon
                    className="input-clearable__clear-btn"
                    name="delete"
                    onClick={() => onChange(null, {value: ''})}
                />
            </div>
        </span>
    );
};

export default InputClearable;