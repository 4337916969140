import React, { useState } from 'react';
import { Button, Dimmer, Loader, Message, Modal } from 'semantic-ui-react';
import { pointsExportExcel, pointsExportExcelClient, pointsExportJson } from '../../../api/points';
import T from '../../../components/Translate';

export default function PointsFormExportExcel({ children, isClient, selectedPoints, selectedExport = [] }) {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    async function onOpen(event) {
        
        const isCtrl = event && (event.ctrlKey || event.metaKey);
        
        setOpen(true);
        setLoading(true);

        const requestMethod = isClient
            ? pointsExportExcelClient
            : isCtrl 
                ? pointsExportJson 
                : pointsExportExcel;

        try {
            const fileRefDto = await requestMethod(window.location.search, selectedPoints, selectedExport);
            if (fileRefDto.error) {
                setError(fileRefDto.error);
            } else {
                setOpen(false);
                window.open(fileRefDto.url, '_blank');
            }
        } catch (error) {
            onClose();
            //setError(error);
        } finally {
            setLoading(false);
        }
    }

    function onClose() {
        setOpen(false);
        setError(null);
    }

    return (
        <Modal
            trigger={children}
            dimmer="inverted" size="small" closeIcon
            centered
            open={open}
            onOpen={onOpen}
            onClose={onClose}
        >
            <Modal.Header><T>Экспорт адресов в Excel</T></Modal.Header>
            <Modal.Content>
                <div style={{ minHeight: '150px' }}>
                    {loading && 
                        <Dimmer active inverted>
                            <Loader><T>Подготовка файла экспорта адресов...</T></Loader>
                        </Dimmer>
                    }
                    {error && 
                        <Message 
                            error 
                            icon="delete"
                            header={"Ошибка".t}
                            content={error} 
                        />
                    }
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={loading} onClick={() => setOpen(false)}><T>Закрыть</T></Button>
            </Modal.Actions>
        </Modal>
    );
}