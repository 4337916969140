import { download, get } from '../services/http';

export async function getWaybillDocsInitial(wbId) {

    return await get(`waybills/${wbId}/docs/initial`);
}

export async function getWaybillDocsClosing(wbId) {

    return await get(`waybills/${wbId}/docs/closing`);
}

export async function getSubscriptionDocsTest(clientId) {
    return await get(`subsciption/test/${clientId}/pod`);
}

export async function getSubscriptionStatusTest(clientId) {

    return await get(`subsciption/test/${clientId}/status`);
}

export async function downloadFilePod(id) {

    return await download(`waybills/${id}/docs/pod/download`);
}

export async function getSubscriptionFullStatusTest(clientId) {
    return await get(`subsciption/test/${clientId}/status/full`);
}

export async function getSubscriptionFullOCCStatusTest(clientId) {
    return await get(`subsciption/test/${clientId}/status/full/occ`);
}