import React from 'react';
import { Label } from 'semantic-ui-react';

export default function WaybillStatusOrder({statusOrder, className = ""}) {

    if (statusOrder && statusOrder.text) {
        return <Label color={statusOrder.color || null} className={className}><b>{statusOrder.text}</b></Label>;
    }
    return null;
}
