import React, {useCallback, useState} from 'react';
import './style.css';
import {Icon} from "semantic-ui-react";
import {categories, messageTypes} from "./const";


const ErrorItemsBlock = ({category, errors}) => {
    const [open, setOpen] = useState(true);
    const errorItems = useCallback(errors.filter(err => err.category === category.key), [category, errors]);

    return !!(errorItems || []).length && <div key={category.key} className="errors-item">
        <div
            className="errors-item__name"
            onClick={() => setOpen(open => !open)}
        >
            {category.name}
            <Icon
                size="large"
                name={open ? "angle down" : "angle right"}
            />
        </div>
        {
            open && (errorItems || []).map(({address, type, text}) => (
                <div className="errors-item__item">
                    {address &&
                        <><b>{address.cellName ?? "Строка №" + (address.rowNumber + 1)}</b>: </>
                    }
                    {type === messageTypes.WARNING && <Icon color="yellow" name="exclamation triangle"/>}{text}
                </div>
            ))
        }
    </div>;
};

const ErrorsBlock = ({height, errors}) => {
    return (
        <div className="errors-block">
            <div className="errors-block__header">
                <Icon color="red" name="exclamation circle"/> Ошибки
            </div>
            <div
                className="errors-block__item-blocks"
                style={{
                    height: `${height - 75}px`
                }}
            >
                {
                    categories.map(category => <ErrorItemsBlock
                        category={category}
                        errors={errors}
                    />)
                }
            </div>
        </div>
    );
};

export default ErrorsBlock;