import React from 'react';
import {Grid} from "semantic-ui-react";
import './styles.css';
import {RecordData, TableRecord} from "../record/Record";
import moment from "moment/moment";
import {PointTimeslot} from "../../../../api/model/PointTimeslot";
import {formatNumber} from "../../../../services/utils";

const OCard = ({point, isLoading}) => {
    const noData = "[нет данных]";

    const companyName = new RecordData(isLoading ? 'Грузоотправитель' : 'Грузополучатель', point.companyName || noData);
    const address = new RecordData(isLoading ? 'Адрес загрузки' : 'Адрес выгрузки', point.address || noData);
    const loadDate = new RecordData("Дата прибытия на адрес", moment(point.arrivalDatePlan).format("DD.MM.YYYY") || noData);
    const loadTime = new RecordData("Время прибытия на адрес", (point.arrivalTimeslotPlan && new PointTimeslot(point.arrivalTimeslotPlan).toString()) || noData);
    const loadUnitsCount = new RecordData("Общее кол-во грузовых единиц", point.loadUnitSets.reduce((result, us) => result + Number.parseInt(us.loadUnitsCount || 0), 0));
    const loadWeight = new RecordData("Общий вес отправки кг брутто", formatNumber(point.loadUnitSets.reduce((result, us) => result + Number.parseFloat(us.loadTotalWeigthBruttoKg || 0), 0)));
    const loadVolume = new RecordData("Общий объем, м3", formatNumber(point.loadUnitSets.reduce((result, us) => result + Number.parseFloat(us.loadTotalVolumeM3 || 0), 0)));
    const comment = new RecordData("Комментарий", point.comment);

    return (
        <Grid divided='vertically' className={`point-card point-card_${isLoading ? 'yellow' : 'green'}`}>
            <Grid.Row columns={2}>
                <Grid.Column width={7}>
                    <TableRecord data={companyName}/>
                </Grid.Column>
                <Grid.Column width={9}>
                    <TableRecord data={address}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={7}>
                    <TableRecord data={loadDate}/>
                </Grid.Column>
                <Grid.Column width={9}>
                    <TableRecord data={loadTime}/>
                </Grid.Column>
            </Grid.Row>
            {
                isLoading
                    ? <Grid.Row columns={3}>
                        <Grid.Column>
                            <TableRecord data={loadUnitsCount}/>
                        </Grid.Column>
                        <Grid.Column>
                            <TableRecord data={loadWeight}/>
                        </Grid.Column>
                        <Grid.Column>
                            <TableRecord data={loadVolume}/>
                        </Grid.Column>
                    </Grid.Row>
                    : <Grid.Row columns={2}>
                        <Grid.Column/>
                        <Grid.Column>
                            <TableRecord data={comment}/>
                        </Grid.Column>
                    </Grid.Row>
            }
        </Grid>
    );
};

export default OCard;