import PropTypes from 'prop-types';
import React from 'react';
import {Button, Form, Icon, Menu} from 'semantic-ui-react';
import InputDropdown from '../../../components/inputs/InputDropdown';
import InputDropdownSearchMultiple from '../../../components/inputs/InputDropdownSearchMultiple';
import O from '../../../components/Option';
import T from '../../../components/Translate';
import {getCompanyOptionsBySearch} from "../../../api/companies";
import AppToolbarButton from "../../../components/buttons/AppToolbarButton";
import {deleteUserCompanies, downloadUserCompanies, importUserCompanies} from "../../../api/users";
import FormImport from "../../../components/formImport/FormImport";

UserForm.propTypes = {
    update: PropTypes.func.isRequired,
    data: PropTypes.shape({
        lastName: PropTypes.string,
        firstName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        isAllCompanies: PropTypes.bool
    }),
    profileOptions: PropTypes.arrayOf(PropTypes.shape(O)),
    companyOptions: PropTypes.arrayOf(PropTypes.shape(O))
};

export default function UserForm({ update, data = {}, profileOptions = [], companyOptions, isEdit, errors = [], loadData }) {

    if (!profileOptions.length)
        return null;

    const downloadExcel = async () => {
        await downloadUserCompanies(data.id).then(res => {
            if (!res.error) {
                window.open(res.url, '_blank');
            }
        });
    };

    const downloadTemplate = () => {
        let file = 'files/attach_companies.xlsx';
        window.open(file, '_blank');
    };

    const onImport = (data) => {
        update({
            companies: data ? data.map(d => d.fmid) : [],
            isAllCompanies: false,
            companyOptions: data.map(d => (new O(d.fmid, d.name, d.fmid)))
        });
    };

    const deleteCompanies = () => {
        update({companies: []});
    };

    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    label={'Имя'.t}
                    placeholder={'Имя'.t}
                    value={data.firstName}
                    onChange={e => update({ firstName: e.target.value })}
                />
                <Form.Input
                    label={'Фамилия'.t}
                    placeholder={'Фамилия'.t}
                    value={data.lastName}
                    onChange={e => update({ lastName: e.target.value })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Input
                    required
                    error={errors.includes('email')}
                    label={'Email'.t}
                    placeholder={'Email'.t}
                    value={data.email}
                    onChange={e => update({ email: e.target.value })}
                />
                <Form.Input
                    label={'Телефон'.t}
                    placeholder={'Телефон'.t}
                    value={data.phone}
                    onChange={e => update({ phone: e.target.value })}
                />
            </Form.Group>
            <Form.Field>
                <label><T>Привязка к клиентам</T></label>
                <InputDropdownSearchMultiple
                    getOptionsBySearch={getCompanyOptionsBySearch}
                    placeholder={"Введите 3 или более букв для поиска".t}
                    options={companyOptions}
                    disabled={data.isAllCompanies}
                    value={data.companies}
                    onChange={(values, options) => update({ companies: values })}
                />
            </Form.Field>
            <Form.Group className='form-btns'>
                <Form.Checkbox
                    label={"Привязать всех клиентов".t}
                    checked={data.isAllCompanies}
                    onChange={(e, item) => update({ isAllCompanies: item.checked })} />
                {isEdit && <AppToolbarButton icon="arrow down" onClick={downloadExcel}>Выгрузить клиентов в Excel</AppToolbarButton>}
                <FormImport
                    header='Загрузить клиентов из Excel'
                    saveBtnName='Загрузить и сохранить'
                    id={data.id}
                    importMethod={importUserCompanies}
                    callbackFunction={onImport}
                    payload={{
                        companies: (data.companies || []).join(';')
                    }}
                >
                    <AppToolbarButton icon='arrow up'>Загрузить клиентов из Excel</AppToolbarButton>
                </FormImport>
                <AppToolbarButton icon="delete" color="black" onClick={deleteCompanies}>Удалить все привязки</AppToolbarButton>
                {isEdit && <AppToolbarButton icon="table" onClick={downloadTemplate}>Выгрузить шаблон Excel файла</AppToolbarButton>}
            </Form.Group>

            <Form.Field required>
                <label><T>Роль</T></label>
                <InputDropdown
                    placeholder={"Выберите роль".t}
                    options={profileOptions}
                    value={data.roleId}
                    onChange={val => update({ roleId: val })}
                />
            </Form.Field>

            {isEdit
                ? <Form.Checkbox
                    label={"Заблокирован".t}
                    checked={data.isBlocked}
                    onChange={(e, item) => update({ isBlocked: item.checked })} />
                : <Form.Checkbox
                    label={"Выслать пароль пользователю на email после создания".t}
                    checked={data.sendPassword}
                    onChange={(e, item) => update({ sendPassword: item.checked })} />}
        </Form>
    );

}
