import React, {useContext, useState} from 'react';
import {Form} from 'semantic-ui-react';
import T from "../../../../../components/Translate";
import InputDropdown from "../../../../../components/inputs/InputDropdown";
import O from "../../../../../components/Option";
import {LegalFormOptions} from "../../../../../api/model/Company";
import UserPermissions from "../../../../../api/model/UserPermissions";
import {ContextUser} from "../../../../../services/context";
import PointTypeRadioButton from "../PointTypeRadioButton";
import InputDropdownMultiple from "../../../../../components/inputs/InputDropdownMultiple";
import {LoadCapacityOptions} from "../../../../../api/model/Point";
import MaskInput from "../../../../../components/inputs/MaskInput";

function PointFormAddressAdditionalFields({ ve, data, setData }) {

    const [legalFormOptions, setLegalFormOptions] = useState(LegalFormOptions);
    function handleLegalFormOptionAdd(e, { value }) {
        setLegalFormOptions([new O(value, value), ...legalFormOptions]);
    }

    const contextUser = useContext(ContextUser);
    const hasPermissions = contextUser.current.permissions.includes(UserPermissions.POINTS);

    const unloadingAreaName = 'zone';
    const innName = 'inn';

    return <div>
        <Form.Group widths='3'>
            <Form.Field required error={ve.includes("companyLegalForm")}>
                <label><T>Юр. форма грузополучателя</T></label>
                <InputDropdown
                    id='companyLegalForm'
                    allowAdditions
                    placeholder={"Юр. форма".t}
                    options={legalFormOptions}
                    value={data.companyLegalForm}
                    onChange={(value) => setData({
                        companyLegalForm: value,
                    })}
                    handleAdd={handleLegalFormOptionAdd}
                />
            </Form.Field>
            <Form.Input
                id='input_companyName'
                required
                error={ve.includes("companyName")}
                label={'Название грузополучателя'.t}
                placeholder={'Название грузополучателя'.t}
                value={data.companyName || ''}
                onChange={e => setData({ companyName: e.target.value })}
            />
            <MaskInput
                regex={/^.{1,17}$/}
                name={innName}
                onChange={setData}
                label='ИНН'
                error={ve.includes(innName)}
                value={data[innName]}
                placeholder='ИНН'
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Field>
                <PointTypeRadioButton
                    data={data}
                    update={setData}
                    hasPermissions={hasPermissions}
                />
            </Form.Field>
        </Form.Group>
        <Form.Group widths='3'>
            <Form.Field required error={ve.includes("dockCapacity")}>
                <label><T>Грузоподъемность</T></label>
                <InputDropdownMultiple
                    id='dockCapacity'
                    placeholder={"Грузоподъемность".t}
                    options={LoadCapacityOptions}
                    value={data.dockCapacity || []}
                    onChange={val => setData({ dockCapacity: val })}
                />
            </Form.Field>
            <MaskInput
                regex={/^[А-Яа-яA-Za-z0-9]{1,5}$/}
                name={unloadingAreaName}
                onChange={setData}
                label='Зона выгрузки'
                error={ve.includes(unloadingAreaName)}
                value={data[unloadingAreaName]}
                placeholder='Зона выгрузки'
            />
        </Form.Group>
    </div>;
};

export default PointFormAddressAdditionalFields;

