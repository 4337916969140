import React from 'react';
import {
    Container,
    Header,
} from 'semantic-ui-react';

export default function ErrorNotAuthorized() {
    return (
        <>
            <Container text style={{ marginTop: '7em' }}>
                <Header as='h1'>Ошибка доступа :(</Header>
                <p>У вас недостаточно прав на доступ к данной странице</p>
            </Container>
        </>
    );
}