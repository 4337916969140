import React, {useContext} from 'react';
import {Divider, Form, Icon, Label, List} from 'semantic-ui-react';
import {
    CargoTypeOptions,
    PackTypeDisplayOptions,
    PackTypeEnum,
    PackTypeOptions,
    WaybillFieldEnum
} from '../../../api/model/Enums';
import LoadUnitSet from '../../../api/model/LoadUnitSet';
import AppButtonLink from '../../../components/buttons/AppButtonLink';
import T from '../../../components/Translate';
import {ContextFieldConfigs, ContextWb} from '../../../services/context';
import PointCardTabsPositionsLoadSetForm from './WbPointCardTabsPositionsLoadSetForm';
import {showValue} from "../../admin/companies/companyTabs/companyTabFieldConfig/companyTabUtils";
import {WaybillStatusEnum} from "../../../api/model/Waybill";
import InputDropdown from "../../../components/inputs/InputDropdown";

function PositionSetItem({p, from, to}) {

    const spanStyle = {
        minWidth: "60px",
        marginRight: "10px",
        display: "inline-block"
    };
    const spanStyleMini = {
        minWidth: "20px",
        marginRight: "10px",
        display: "inline-block"
    };

    return (
        <>
            <Label color="pink" style={{minWidth: '75px'}} horizontal>c {from} по {to}</Label>
            {'Тип'.t}: <span style={spanStyle}>{PackTypeDisplayOptions.find(o => o.key === p.loadUnitType).text},</span>
            {'Вес'.t}: <span style={spanStyleMini}>{p.loadTotalWeigthBruttoKg},</span>
            {p.loadTotalVolumeM3 && <>{'Объём'.t}: <span style={spanStyle}>{p.loadTotalVolumeM3}</span></>}
        </>
    );
}

export default function PointCardTabsPositionsLoad({
                                                       isMixAllowed,
                                                       id,
                                                       pointId,
                                                       positions = [],
                                                       handleDataChange,
                                                       isClient,
                                                       pointsAll,
                                                       disabled,
                                                       isPoolingCanEdit,
                                                       isPoolingEdit,
                                                       isRoute,
                                                       fieldConfigs,
                                                       data,
                                                       updateWb,
                                                       isPoolingCanEditCompletely
                                                   }) {
    const contextWb = useContext(ContextWb);
    const configContext = useContext(ContextFieldConfigs);

    const wb = data || contextWb.wb;

    async function positionAdd(position) {

        // need to connect position to point
        position.pointFromId = pointId;
        position.pointFromId = pointId;
        position.id = Math.random();

        const newPositions = [...positions]; // copy of loadUnitSets

        newPositions.push(new LoadUnitSet(position));

        await handleDataChange("loadUnitSets", newPositions);
    }

    async function positionRemove(idx) {

        const newPositions = [...positions];

        newPositions.splice(idx, 1);

        await handleDataChange("loadUnitSets", newPositions);
    }

    async function positionUpdate(position) {

        const newPositions = [...positions];

        const target = newPositions.find(p => p.id === position.id);

        Object.assign(target, position);

        await handleDataChange("loadUnitSets", newPositions);
    }

    function getStartIndex() {
        const points = [...wb.pointsLoading].sort((a, b) =>
            b.positionNumber - a.positionNumber);
        let currentPointIndex = 0;

        points.forEach((item, index) => {
            if (item.id === id) {
                currentPointIndex = index;
            }
        });

        const prevPoints = points.slice(currentPointIndex + 1);

        return prevPoints.reduce((sum, {loadUnitSets}) => {
            return sum + loadUnitSets.reduce((sum, {loadUnitsCount}) => {
                return loadUnitsCount + sum;
            }, 0);
        }, 0);
    }

    let posidx = 0;
    let posIndexCurrent = getStartIndex();

    function isAddButton() {
        if (isMixAllowed) {
            return true;
        }
        const loadUnitTypeOptions = PackTypeOptions.filter(o => showValue(WaybillFieldEnum.CargoUnitType, o.value, wb, configContext));
        const loadUnitTypeOptionsValues = loadUnitTypeOptions.map(o => o.value);

        return positions.length === 0
            ||
            (!!positions.find(i => i.loadUnitType === PackTypeEnum.BX || i.loadUnitType === PackTypeEnum.PP) && positions.length < 2
                && (loadUnitTypeOptionsValues.includes(PackTypeEnum.BX) && loadUnitTypeOptionsValues.includes(PackTypeEnum.PP)));
    }

    function classNameListPoint() {
        let cls = ['point-form'];
        if (isClient && !isPoolingCanEdit) {
            cls.push('pointer-events-none');
        }

        return cls.join(' ');
    }

    const isPoolingDraft = wb.status === WaybillStatusEnum.DRAFT && wb.isPooling;

    return (
        <>
            <div style={{marginTop: '0', textAlign: "left", marginBottom: '4px'}}>
                {
                    isRoute && <Form>
                        <Form.Field className='point-input' inline>
                            <label>Тип груза</label>
                            <InputDropdown
                                options={CargoTypeOptions.filter(({value}) =>
                                    showValue(WaybillFieldEnum.CargoType, value, wb, fieldConfigs)
                                )}
                                placeholder={'Тип товара'}
                                value={data.cargoType}
                                onChange={cargoType => updateWb({cargoType}, {})}
                                disabled={isClient || isPoolingDraft || disabled}
                            />
                        </Form.Field>
                    </Form>
                }

                <label style={{fontSize: '13px', fontWeight: "bold"}}>
                    <T>Грузовые единицы</T>
                </label>

                {isAddButton() && <PointCardTabsPositionsLoadSetForm
                    submit={positionAdd}
                    title="Добавить грузовые единицы"
                    loadUnitSets={positions}
                    isMixCargoUnitsConfig={isMixAllowed}
                    pointsAll={pointsAll}
                    positions={positions}
                    disabled={(isClient || disabled) && !isPoolingCanEditCompletely}
                    isPoolingCanEditCompletely={isPoolingCanEditCompletely}
				>
                    <span className="f-r">
                        {!isPoolingDraft &&
                            <AppButtonLink
                                style={{ fontSize: '12px' }}
                                disabled={(isClient || disabled) && !isPoolingCanEditCompletely}>
                                добавить
                            </AppButtonLink>
                        }
                    </span>
                </PointCardTabsPositionsLoadSetForm>}
            </div>
            <Divider fitted/>
            <div className="f-c" style={{paddingTop: '0'}}>
                <List divided selection className={classNameListPoint()}>
                    {positions.map(p => (
                        <List.Item key={p.id}>
                            <PointCardTabsPositionsLoadSetForm
                                submit={positionUpdate}
                                isMixCargoUnitsConfig={isMixAllowed}
                                value={p}
                                pointsAll={pointsAll}
                                disabled={(isClient || isPoolingDraft || disabled) && !isPoolingCanEditCompletely}
                                positions={positions}
                                loadUnitSets={positions}
                                isPoolingCanEdit={isPoolingCanEdit}
                                isPoolingCanEditCompletely={isPoolingCanEditCompletely}
							>
                                <span id={`position-item_${id}-${p.id}`}>
                                    <PositionSetItem
                                        p={p}
                                        from={posIndexCurrent + 1}
                                        to={posIndexCurrent = (posIndexCurrent + p.loadUnitsCount)}/>
                                </span>
                            </PointCardTabsPositionsLoadSetForm>
                            {!isPoolingDraft && <Icon
                                id={`position-item-load-del_${id}-${p.id}`}
                                name="trash"
                                disabled={(isClient || disabled) && !isPoolingCanEditCompletely}
                                className="f-r"
                                link style={{marginTop: '2px'}}
                                onClick={positionRemove.bind(null, posidx++)}/>}
                        </List.Item>
                    ))}
                </List>
            </div>
        </>
    );
}
