import {del, get, post, put} from '../services/http';

export const getAll = async (search) => {
    return await get(`notifications${search}`);
};

export const getTypes = async () => {
    return await get(`notifications/types`);
};

export const getEmails = async () => {
    return await get(`notifications/emails`);
};

export const getRecipients = async () => {
    return await get(`notifications/recipients`);
};

export const createNotification = async (data) => {
    return await post(`notifications/create`, data);
};

export const updateNotification = async (id, data) => {
    return await put(`notifications/update/${id}`, data);
};

export const deleteNotification = async (id) => {
    return await del(`notifications/delete/${id}`);
};