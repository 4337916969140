import React from 'react';
import ObjectRows from "./objectRows";
import {
    fieldTypes,
    nameTrimType,
    nameTrimValue,
    TrimTypesEnum,
    TrimTypesOptions
} from "../../../../api/model/ImportForms";
import FormField from "../formField";
import {Form} from "semantic-ui-react";


const TrimRow = ({i, v, changeValue, allValues, ...props}) => {
    const trimTypeValue = (v || {})[nameTrimType];

    return <Form className="trim__row">
        <FormField
            {...props}
            required
            label={i === 0 && 'Тип условия'}
            inputType={fieldTypes.DROPDOWN}
            value={trimTypeValue}
            name={nameTrimType}
            onChange={(e, p) => changeValue(i, p)}
            options={TrimTypesOptions.filter(v => (
                    v.value === trimTypeValue)
                || (!(allValues || []).map(a => a[nameTrimType]).includes(v.value))
            )}
        />
        <FormField
            {...props}
            required
            label={i === 0 && 'Значение'}
            inputType={fieldTypes.NUMBER}
            value={(v || {})[nameTrimValue]}
            name={nameTrimValue}
            onChange={(e, p) => changeValue(i, p)}
        />
    </Form>;
};

const Trim = ({value, onChange, delBtn, icon, disabled, name}) => {
    const startValue = (value || []).find(v => v[nameTrimType] === TrimTypesEnum.START);
    const endValue = (value || []).find(v => v[nameTrimType] === TrimTypesEnum.END);

    const valueText = `${startValue ? `${TrimTypesOptions[0].text}: ${startValue[nameTrimValue] || ''}` : ''}${(startValue && endValue) ? ', ' : ''}${endValue ? `${TrimTypesOptions[1].text}: ${endValue[nameTrimValue] || ''}` : ''}`;

    return <ObjectRows
        value={value}
        onChange={onChange}
        delBtn={delBtn}
        icon={icon}
        disabled={disabled}
        name={name}
        valueText={valueText}
        component={TrimRow}
        maxCount={2}
        className="trim"
    />;
};

export default Trim;