import {debounce} from 'awesome-debounce-promise';
import React, {useState} from 'react';
import {Button, Divider, Form, Grid, GridColumn, Header} from 'semantic-ui-react';
import {companyUpdateOApi} from '../../../../api/companies';
import { getSubscriptionDocsTest, getSubscriptionStatusTest, getSubscriptionFullStatusTest, getSubscriptionFullOCCStatusTest } from '../../../../api/waybillDocs';

const saveData = debounce((fmid, co) => companyUpdateOApi(fmid, co), 1200);

const validateData = data => {
    return !!(data.authLogin && data.authPassword && (data.urlStatus || data.urlDocs || data.urlFullStatus));
};

export default function CompanyTabOApi({data = {}, setData}) {

    const oapi = data.companyOApi || {};
    const [dataIsValid, setDataIsValid] = useState(validateData(oapi));
    const hasAuth = oapi.authLogin && oapi.authPassword;
    const testDocsDisabled = !(hasAuth && isUrlValid(oapi.urlDocs));
    const testStatusDisabled = !(hasAuth && isUrlValid(oapi.urlStatus));
    const testFullStatusDisabled = !(hasAuth && isUrlValid(oapi.urlFullStatus));
    const testFullStatusOCCDisabled = !(hasAuth && isUrlValid(oapi.urlFullStatusOCC));

    function update(oapiChanged) {
        const dataChanged = {...data};
        dataChanged.companyOApi = {...dataChanged.companyOApi, ...oapiChanged};

        setData(dataChanged);
        setDataIsValid(validateData(dataChanged.companyOApi));
        saveData(dataChanged.fmid, dataChanged.companyOApi);
    }

    function isUrlValid(url) {
        return /^(http|https):\/\/[^ "]+$/.test(url);
    }

    return (
        <Grid>
            <Grid.Row>
                <GridColumn width={10}>
                    <Form className="p-l-5">
                        <Header>API приема и регистрации заявок</Header>
                        <Form.Group widths="equal">
                            <Form.Checkbox
                                toggle
                                label={'API TMS'.t}
                                checked={oapi.apiWaybillsActive}
                                onChange={(e, item) => update({apiWaybillsActive: item.checked})}
                            />
                            <Form.Checkbox
                                toggle
                                label={'API WMS'.t}
                                checked={oapi.apiWMSWaybillsActive}
                                onChange={(e, item) => update({apiWMSWaybillsActive: item.checked})}
                            />
                            <Form.Checkbox
                                toggle
                                label={'API Pooling'.t}
                                checked={oapi.apiPoolingWaybillsActive}
                                onChange={(e, item) => update({apiPoolingWaybillsActive: item.checked})}
                            />
                        </Form.Group>
                        <Divider/>
                        <Header>API отсылки событий (статусы и POD)</Header>
                        <Form.Group widths="equal">
                            <Form.Input
                                disabled={oapi.isActive}
                                required
                                label={'Логин api клиента'.t}
                                placeholder={'Логин'.t}
                                value={oapi.authLogin}
                                onChange={e => update({authLogin: e.target.value})}
                            />
                            <Form.Input
                                disabled={oapi.isActive}
                                required
                                label={'Пароль api клиента'.t}
                                placeholder={'Пароль'.t}
                                value={oapi.authPassword}
                                onChange={e => update({authPassword: e.target.value})}
                            />
                        </Form.Group>
                        {/* <Form.Field disabled={oapi.isActive}>
				<label>URL приема событий изменения статуса заявки</label>
				<Input
					placeholder='https://status_url'
					value={oapi.urlStatus}
					onChange={e => update({ urlStatus: e.target.value })}
				>
					<input />
					<Button
						primary
						content={'Тест'.t}
						style={{color: 'pink !important'}}
						disabled={testStatusDisabled}
						onClick={ () => getSubscriptionStatusTest(oapi.companyFMID) }
					/>
				</Input>
			</Form.Field> */}
                        <Form.Input
                            action={<Button
                                primary
                                content={'Тест'.t}
                                disabled={testStatusDisabled}
                                onClick={() => getSubscriptionStatusTest(oapi.companyFMID)}/>}
                            disabled={oapi.isActive}
                            label={'Url точки приема Статус заявки и плеч (о-с-с/d):'.t}
                            placeholder={'https://status_url'.t}
                            value={oapi.urlStatus}
                            onChange={e => update({urlStatus: e.target.value})}
                        />
                        <Form.Input
                            action={<Button primary
                                            content={'Тест'.t}
                                            disabled={testDocsDisabled}
                                            onClick={() => getSubscriptionDocsTest(oapi.companyFMID)}/>}
                            disabled={oapi.isActive}
                            label={'Url точки приема POD (о-с-с/d):'.t}
                            placeholder={'https://docs url'.t}
                            value={oapi.urlDocs}
                            onChange={e => update({urlDocs: e.target.value})}
                        />
                        <Form.Input
                            action={<Button primary
                                content={'Тест'.t}
                                disabled={testFullStatusDisabled}
                                onClick={() => getSubscriptionFullStatusTest(oapi.companyFMID)} />}
                            disabled={oapi.isActive}
                            label={'Url точки приема Информации о статусах, водителе и ТС в заявке (d):'.t}
                            placeholder={'https://full_status_url url'.t}
                            value={oapi.urlFullStatus}
                            onChange={e => update({ urlFullStatus: e.target.value })}
                        />
                        <Form.Input
                            action={<Button primary
                                content={'Тест'.t}
                                disabled={testFullStatusOCCDisabled}
                                onClick={() => getSubscriptionFullOCCStatusTest(oapi.companyFMID)} />}
                            disabled={oapi.isActive}
                            label={'Url точки приема Информации о статусах, водителе и ТС в заявке (o-c-c):'.t}
                            placeholder={'https://full_status_url url'.t}
                            value={oapi.urlFullStatusOCC}
                            onChange={e => update({ urlFullStatusOCC: e.target.value })}
                        />
                        <Divider hidden/>
                        <Form.Checkbox
                            toggle
                            disabled={!dataIsValid}
                            label={'API отсылки событий (статусы и POD) включено'.t}
                            checked={oapi.apiEventsActive}
                            onChange={(e, item) => update({apiEventsActive: item.checked})}
                        />
                    </Form>
                </GridColumn></Grid.Row>
        </Grid>
    );

}
