import React from 'react';
import {Label} from "semantic-ui-react";

const WaybillStatusAvisation = ({statusAvisation, className}) => {
    if (statusAvisation && statusAvisation.text) {
        return <Label color={statusAvisation.color || null} className={className}><b>{statusAvisation.text}</b></Label>;
    }
    return null;
};

export default WaybillStatusAvisation;
