import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import T from '../../../../components/Translate';
import { getPointStatusColor, getPointStatusName } from '../../../../api/model/Point';
import PointViewFooterExportStatusLabel from './PointViewFooterExportStatusLabel';

export default function PointViewFooter({point}) {
    const stlMarker = {
        fontSize: "12px"
    };

    const incompleteMarker =
        <span style={stlMarker}>
            <Icon className="m-l-10" name="exclamation triangle" color="orange" title={"Нестандартизированный адрес".t} />
            <T>Нестандартизированный адрес</T>
        </span>;

    const completeMarker =
        <span style={stlMarker}>
            <Icon className="m-l-10" name="checkmark" color="green" title={"Cтандартизированный адрес".t} />
            <T>Cтандартизированный адрес</T>
        </span>;

    return (
        <span>
            <Label
                style={{ margin: '0', marginRight: "10px" }}
                color={getPointStatusColor(point.statusId)}>
                {getPointStatusName(point.statusId)}
            </Label>
            <PointViewFooterExportStatusLabel value={point.exportStatus} />
            {!point.address.fias ? incompleteMarker : completeMarker}
        </span>
    );
}