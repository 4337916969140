import React from 'react';

import { Icon, Visibility, GridColumn, Grid } from 'semantic-ui-react';

const ScrollPreloader = (props) => {
    const { onVisible, continuous } = props;
    return <Visibility
        continuous={continuous}
        once={false}
        context={undefined}
        onTopVisible={onVisible}
        style={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            marginTop: "-442px",
            height: "600px",
            width: "calc(100vw - 73px)",
            zIndex: 1
        }}
    >
        <div style={{
            background: 'white'
        }}>
            <Grid columns={3} textAlign="center" style={{height: '200px', margin: 0}} verticalAlign="middle">
                <GridColumn><Icon name="spinner" size="huge" loading color="blue"/></GridColumn>
                <GridColumn><Icon name="spinner" size="huge" loading color="blue"/></GridColumn>
                <GridColumn><Icon name="spinner" size="huge" loading color="blue"/></GridColumn>
            </Grid>
        </div>
    </Visibility>;
};

export default ScrollPreloader;