import React, {useEffect, useState} from 'react';
import {Feed, Grid} from "semantic-ui-react";
import HistoryItem from "../../../_shared/HistoryItem";
import {getCompanyHistory} from "../../../../api/companies";

const CompanyTabHistory = ({name, id}) => {
    const [historyArray, setHistoryArray] = useState([]);

    useEffect(() => {
        const getHistory = async () => {
            await getCompanyHistory(id, name)
                .then(data => setHistoryArray(data))
                .catch(e => console.log(e));
        };
        name && getHistory();
    }, [name]);

    return (
        <Grid>
            <Feed>
                {historyArray.map((i, index) => <HistoryItem key={index} {...i} />)}
            </Feed>
        </Grid>
    );
};

export default CompanyTabHistory;