import React, {useEffect, useState} from 'react';
import {Button, Grid, Icon, Table} from "semantic-ui-react";
import {WaybillCutoffShipmentRegionsEnumOptions, WeekDayOptions} from '../../../../../api/model/Enums';
import T from '../../../../../components/Translate';
import RowDeleteAction from './_shared/RowDeleteAction';
import {companyCutoffDateRailLTLDelete, getCompanyCutoffs} from '../../../../../api/companyCutoffs';
import {CutoffsRailLTLDateAdd} from "./CutoffsRailLTLDateAdd";

export default function CutoffsRailLTLDate({match}) {
    const [rows, setRows] = useState([]);

    function onAdd(row) {
        setRows([...rows, row]);
    }

    async function removeAsync(row) {
        await companyCutoffDateRailLTLDelete(match.params.id, row);
    }

    function Row({row}) {
        return (
            <Table.Row>
                <Table.Cell>{WeekDayOptions.getText(row.weekdaySubmitting)}</Table.Cell>
                <Table.Cell>{WeekDayOptions.getText(row.weekdayLoading)}</Table.Cell>
                <Table.Cell>{WaybillCutoffShipmentRegionsEnumOptions.getText(row.region)}</Table.Cell>
                <Table.Cell textAlign="center"><RowDeleteAction {...{row, rows, setRows, removeAsync}} /></Table.Cell>
            </Table.Row>
        );
    }

    useEffect(() => {
        async function getCompanyCutoffsLocal() {
            const res = await getCompanyCutoffs(match.params.id, "railLTL/date");
            setRows(res || []);
        }

        getCompanyCutoffsLocal();
    }, [match.params.id]);

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column align="right">
                        <CutoffsRailLTLDateAdd onAdd={onAdd} companyId={match.params.id}>
                            <Button primary>
                                <Icon name="plus"/>
                                <T>Добавить правило</T>
                            </Button>
                        </CutoffsRailLTLDateAdd>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={5}><T>День недели отправки заявки</T></Table.HeaderCell>
                        <Table.HeaderCell width={5}><T>День передачи груза ФМ</T></Table.HeaderCell>
                        <Table.HeaderCell width={5}><T>Регион доставки</T></Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign="center" />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((r, i) => <Row key={i} row={r}/>)}
                </Table.Body>
            </Table>
        </>
    );
}
