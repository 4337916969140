import React, {useContext, useEffect, useState} from "react";
import DayPicker from 'react-day-picker';
import {Form, Popup} from "semantic-ui-react";
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import {ContextLocale} from "../../../../services/context";
import moment from "moment";

const FilterInputDatePeriod = ({ name, value, onChange, sort, setSort, onClose, minWidth, field }) => {
    const contextLocale = useContext(ContextLocale);

    const [disabledDaysListEnd, setDisabledDaysListEnd] = useState([
        {
            before: null,
            after: null
        }]);

    const [disabledDaysListStart, setDisabledDaysListStart] = useState([
        {
            before: null,
            after: null
        }]);

    useEffect(() => {
        setDisabledDaysListStart({
            before: null,
            after: !!getStringItem(1) ? parseDate(getStringItem(1), 'DD.MM.YYYY') : null
        });
        setDisabledDaysListEnd({
            before: !!getStringItem(0) ? parseDate(getStringItem(0), 'DD.MM.YYYY') : null,
            after: null
        })
    }, [value]);

    const getStringItem = (i) => {
        const parts = (value || "").split("-");
        return (parts[i] || null);
    };
    const getDateItem = (i) => {
        let s = getStringItem(i);
        if (s)
            return parseDate(s, 'DD.MM.YYYY');
        return null;
    };

    const callbackOnChange = (start, end) => {
        let value = start || end
            ? [start, end].join("-")
            : "";
        onChange && onChange({ name, value });
    };

    const toggleStart = (value) => {
        let start =  formatDate(value, "L", contextLocale.locale)
        if (start === getStringItem(0)) start = null;
        callbackOnChange(start, getStringItem(1));
    };

    const toggleEnd = (value) => {
        let end = moment(value).format('DD.MM.YYYY');
        if (end === getStringItem(1)) end = null;
        callbackOnChange(getStringItem(0), end);
    };

    const input = (
        <div className={`ui input transparent ${minWidth ? '' : 'filter-date-period'}`}>
            <input
                id={`filter-input-${field}`}
                className="filter-date-input"
                name={name}
                autoComplete="off"
                value={value}
                readOnly
                placeholder={name.t}
                onChange={onChange}
            />
        </div>
    );

    const content = (
        <Form className="filter-popup">
            <Form.Group>
                <Form.Field width={8}>
                    <DayPicker
                        onDayClick={toggleStart}
                        month={getDateItem(0) || null}
                        selectedDays={getDateItem(0) || null}
                        todayButton={'Сегодня'.t}
                        disabledDays={disabledDaysListStart}
                        firstDayOfWeek={1}
                        localeUtils={MomentLocaleUtils}
                        locale={contextLocale.locale} />
                </Form.Field>
                <Form.Field width={8}>
                    <DayPicker
                        onDayClick={toggleEnd}
                        month={getDateItem(1) || null}
                        selectedDays={getDateItem(1) || null}
                        todayButton={'Сегодня'.t}
                        disabledDays={disabledDaysListEnd}
                        firstDayOfWeek={1}
                        localeUtils={MomentLocaleUtils}
                        locale={contextLocale.locale} />
                </Form.Field>
            </Form.Group>
        </Form>
    );

    return (
        <div className="facet-input">
            <Popup
                trigger={input}
                onClose={onClose}
                content={content}
                on='click'
                hideOnScroll
                position='bottom center'
            />
        </div>
    );
};

export default FilterInputDatePeriod;
