import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import ActionMenu from '../../../components/buttons/ActionMenu';
import {Button, Confirm, Dropdown, Icon} from 'semantic-ui-react';
import T from '../../../components/Translate';
import {ContextUser, ContextWb} from '../../../services/context';
import {cancelWaybills, copyWaybill, waybillDeleteSoftById, waybillExport, wbIsEdit} from '../../../api/waybills';
import {
    nonDeleteErrorAvisStatusSet,
    nonDeleteErrorStatusSet,
    WaybillStatusEnum,
    WaybillTypeEnum
} from '../../../api/model/Waybill';
import UserPermissions from '../../../api/model/UserPermissions';
import {reservationCancel} from '../../../api/waybillAviz';
import WbEditAvisTimeslot from "../wbViewAvisation/WbEditAvisTimeslot";
import {toast} from "../../../services/toast";
import {UserRolesEnum} from "../../../api/usersRoles";

function WbViewHeaderActions({ history, isAvisation }) {
    const contextWb = useContext(ContextWb);
    const contextUser = useContext(ContextUser);
    const wb = contextWb.wb;
    const wbId = wb.id;
    const isFmDeleted = wb.status === WaybillStatusEnum.FM_DELETED;

    let [confirm, setConfirm] = useState({open: false});

    let [isEdit, setIsEdit] = useState(true);
    let [editTimeslotData, setEditTimeslotData] = useState(null);

    const cancelledStates = [
        WaybillStatusEnum.CANCELLED,
        WaybillStatusEnum.FM_CANCELLED,
        ];

    const isArchived = wb.isArchive;

    const editable = !!wb.__canEdit
        && !wb.isPooling
        && isEdit
        && !cancelledStates.includes(wb.status)
        && !isArchived;

    useEffect(() => {
        getIsEdit();
    }, []);

    const getIsEdit = async () => {
        const result = await wbIsEdit(wbId);

        setIsEdit(result && result.value)
    };

    const nonCancellableStatusSet = [
        WaybillStatusEnum.EXECUTING,
        WaybillStatusEnum.DRAFT,
        WaybillStatusEnum.DOCS_SENT,
        WaybillStatusEnum.CARGO_DELIVERED,
        WaybillStatusEnum.CANCELLED,
        WaybillStatusEnum.FM_CANCELLED,
    ];

    const editClientWaybillStatusSet = [
        WaybillStatusEnum.SUBMITTED,
        WaybillStatusEnum.EXECUTING,
        WaybillStatusEnum.ON_APPROVAL,
    ];

    const cancellable = !nonCancellableStatusSet.includes(wb.status) && !wb.isPooling && wb.shippingType !== WaybillTypeEnum.Return || (
        wb.shippingType === WaybillTypeEnum.Return && contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT)
            && [...editClientWaybillStatusSet, WaybillStatusEnum.CARGO_DELIVERED].includes(wb.status)
    );

    const canDeleteErroneous = !wb.isPooling && !isArchived &&
        contextUser.current.permissions.includes(
            wb.shippingType === WaybillTypeEnum.Return ? UserPermissions.RETURN_WAYBILLS_EDIT : UserPermissions.WAYBILL_SOFT_DELETE
        ) &&
        nonDeleteErrorStatusSet.includes(wb.status) &&
        nonDeleteErrorAvisStatusSet.includes(wb.avisationStatus)

    const canCopy = wb.canCopy;

    function gotoEditor() {
        history.push(`/waybills/${wbId}/edit`);
    }

    function gotoClientEditor() {
        history.push(`/waybills/client/${wbId}/edit`, {isEditTorg12: true});
    }

    function gotoPoolingEditor() {
        history.push(`/waybills/pooling/${wbId}/edit`);
    }

    async function waybillExportClick(event, data) {
        if (event.ctrlKey) {
            window.open(`/api/waybill/${wbId}/export/json`, '_blank');
        } else {
            var fileInfo = await waybillExport([wbId]);
            if (!fileInfo.error) {
                window.open(`/api/file/${fileInfo.id}`, '_blank');
            }
        }
    }

    async function gotoClientDeleteErroneous() {
        await waybillDeleteSoftById(wbId);
        history.push(`/waybills/`);
    }

    async function createCopy() {
        contextWb.setLoading(true);

        try {
            const newId = await copyWaybill(wbId);

            contextWb.setLoading(false);

            history.push(`/waybills/${newId}/edit`);
        } catch (err) {
            contextWb.setLoading(false);
        }
    }

    async function cancel() {
        try {
            contextWb.setLoading(true);
            const data = await cancelWaybills([wbId]);
            if (data && data.newId) {
                history.push(`/waybills/${data.newId}/edit`);
            }
            if (data.isUpdated) {
                setEditTimeslotData(data);
            }
            if (data.isAvisationCanceled) {
                if (!data.reservationWaybillIds || !data.reservationWaybillIds.length)
                {
                    toast.success(`Бронь № ${data.canceledReservation.visitNumber} отменена`);
                }
            }
            await contextWb.fetchWb();
        } catch (error) {
            contextWb.setLoading(false);
        }
    }

    const editClientWaybill = ((editClientWaybillStatusSet.includes(wb.status)
            || ([WaybillStatusEnum.CARGO_DELIVERED, WaybillStatusEnum.DOCS_SENT].includes(wb.status) && isEdit)) && wb.shippingType !== WaybillTypeEnum.Return)
        || (wb.shippingType === WaybillTypeEnum.Return && [...editClientWaybillStatusSet, WaybillStatusEnum.CARGO_DELIVERED].includes(wb.status) && contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT));

    const editClientWaybillByState = editClientWaybill && !cancelledStates.includes(wb.status) && !isArchived;

    const roleUser = ((contextUser.current || {}).role || {}).id;
    const isPooling = contextWb.wb.isPooling;
    const isPoolingCanEdit = isPooling && !isArchived && [
        UserRolesEnum.COORDINATOR_CS,
        UserRolesEnum.SPECIALIST_CS,
        UserRolesEnum.ADMIN
    ].includes(roleUser);

    const onClickCancelReservation = () => {
        setConfirm({
            open: true,
            onConfirm: () => {
                setConfirm({open: false});
                confirmCancelReservation();
            },
            content: 'Вы уверены, что хотите отменить бронь? Если да, то нажмите "Ок".'
        })
    };

    const confirmCancelReservation = async () => {
        contextWb.setLoading(true);
        try {
            await reservationCancel(wb.reservationId);
            history.push('/waybills');
        } finally {
            contextWb.setLoading(false);
        }
    };

    return (
        <>
            {isAvisation ? (
                (contextWb.wb.canCancelReservation && !isArchived) && <Button onClick={onClickCancelReservation}>
                    <Icon name="dont" />
                    <T>Отменить бронь</T>
                </Button>
            ) : (
                isFmDeleted ? <ActionMenu>
                    <Dropdown.Item onClick={waybillExportClick}>
                        <T>Экспорт</T>
                    </Dropdown.Item>
                </ActionMenu> : <ActionMenu>
                    {/* {canHaveAvisation && <Dropdown.Item onClick={avizo}><T>Авизовать</T></Dropdown.Item>}*/}
                    <Dropdown.Item onClick={waybillExportClick}>
                        <T>Экспорт</T>
                    </Dropdown.Item>
                    <Dropdown.Item disabled={!canCopy} onClick={createCopy}>
                        <T>Копировать</T>
                    </Dropdown.Item>
                    {editable && (
                        <Dropdown.Item onClick={gotoEditor}>
                            <T>Редактировать</T>
                        </Dropdown.Item>
                    )}
                    {editClientWaybillByState && (
                        <Dropdown.Item onClick={gotoClientEditor}>
                            <T>Редактировать номер заказа/документа</T>
                        </Dropdown.Item>
                    )}
                    {isPoolingCanEdit && (
                        <Dropdown.Item onClick={gotoPoolingEditor}>
                            <T>Редактировать</T>
                        </Dropdown.Item>
                    )}
                    {!isArchived && <Dropdown.Item disabled={!cancellable} onClick={cancel}>
                        <T>Отменить</T>
                    </Dropdown.Item>}
                    {canDeleteErroneous && (
                        <Dropdown.Item onClick={gotoClientDeleteErroneous}>
                            <T>Удалить ошибочные</T>
                        </Dropdown.Item>
                    )}
                </ActionMenu>
            )}
            <Confirm
                open={confirm.open}
                cancelButton={'Отмена'.t}
                content={confirm.content}
                onCancel={() => setConfirm({open: false})}
                onConfirm={confirm.onConfirm}
            />

            <WbEditAvisTimeslot
                onClose={()=>setEditTimeslotData(null)}
                editTimeslotData={editTimeslotData}
            />
        </>
    );
}

export default withRouter(WbViewHeaderActions);
