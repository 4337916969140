import React from 'react';
import { List } from "semantic-ui-react";
import T from '../../../../components/Translate';

export class RecordData {
    constructor(name, value, isRequired = true) {
        this.key = name;
        this.name = name;
        this.value = value;
        this.isRequired = isRequired;
    }
}

export function Record({ data }) {
    const emptyMsg = "нет данных";
    const errorLabel = <span style={{ color: '#cccccc' }}>{emptyMsg.t}</span>;
    

    return (
        <List.Item>
            <List.Header>{data.name.t}</List.Header>
            <List.Description>{data.value || (data.isRequired ? errorLabel : emptyMsg.t)}</List.Description>
        </List.Item>
    );
}

export function TableRecord({ data }) {
    const emptyMsg = "нет данных";
    const isEmpty = !!!data.value;


    return (
        <div>
            <div style={{fontWeight: 'bold'}}><T>{data.name}</T></div>
            <div style={{color: isEmpty ? "#cccccc" : "inherit"}} className="ellipsis-base" title={data.value}>{data.value || emptyMsg}</div>
        </div>
    );
}

export function TableRecordElement({label, children}) {
    return (
        <div>
            <div style={{ fontWeight: 'bold' }}><T>{label}</T></div>
            <div>{children}</div>
        </div>
    );
}



