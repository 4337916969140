import React from 'react';
import { Label } from 'semantic-ui-react';
import T from '../../../../components/Translate';
 
export default function PointViewFooterExportStatusLabel({value}) {

    let exportStatusColor, exportStatusName;

    switch (value) {
        case 1:
            exportStatusColor = "teal";
            exportStatusName = "Зарегистрирован";
            break;
        case 2:
            exportStatusColor = "red";
            exportStatusName = "Ошибка регистрации";
            break;
        default:
            exportStatusColor = null;
            exportStatusName = "Не зарегистрирован";
            break;
    }

    return (
        <Label
            key={value}
            color={exportStatusColor}>
            <T>{exportStatusName}</T>
        </Label>
    );
}