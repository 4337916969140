import {useEffect, useRef, useState} from "react";

const HOUR = 3600000; // 1 час = 3600000 миллисекунд

export const usePageIsActive = (
    func, //func выполняется, когда юзер вернулся на страницу после того, как прошло time
    time = HOUR
) => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    const [isInactive, setIsInactive] = useState(false);
    const timer = useRef(null);

    const resetTimer = () => {
        clearTimeout(timer.current);
        setIsInactive(false);
        timer.current = setTimeout(() => {
            setIsInactive(true);
        }, time);
    };

    useEffect(() => {
        const handleActivity = () => {
            resetTimer();
        };
        events.forEach(event => window.addEventListener(event, handleActivity));
        resetTimer();
        return () => {
            events.forEach(event => window.removeEventListener(event, handleActivity));
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        const remove = () => {
            func && events.forEach(event => window.removeEventListener(event, func));
        };
        if (isInactive && func) {
            events.forEach(event => window.addEventListener(event, func));
        } else {
            remove();
        }
        return remove;
    }, [isInactive]);

    return !isInactive;
};