import React, {useContext, useEffect, useState} from 'react';
import {ContextFooter} from "../../services/context";
import {Button} from "semantic-ui-react";
import Indicator from "./formRules/indicator";
import DictionariesTable from "../dictionaries/table";
import {DictionariesSettings} from "../../api/model/Dictionaries";
import CopyModal from "./copyModal";

const ImportDictionary = ({dictionary, customDeletedText, goToForm, height, formId, customActions, rowOnClick, fmid, customCheckError, errorFlag, onErrorFlag, disabled, customCheckRequired}) => {
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const contextFooter = useContext(ContextFooter);

    const dictionarySettings = DictionariesSettings.find(h => h.key.toString() === dictionary) || {};

    const {
        customText,
        exampleFile
    } = dictionarySettings;

    const menuItems = [
        {
            name: customText.copyBtnName,
            disabled,
            icon: 'copy',
            onClick: (callback) => setOpenModal({callback})
        }
    ];

    const btn = () => {
        return <div className="import-dictionary__btn">
            <Button
                color="blue"
                onClick={goToForm}
                size="mini"
            >
                Вернуться в форму
            </Button>
        </div>;
    };

    const setError = (error) => {
        setErrorText(error);
    };

    const setDuplicateText = (flag, text) => {
        contextFooter.setIndicator(flag && <Indicator
            errors={{
                duplicateExists: {
                    text
                }
            }}
        />);
    };

    useEffect(() => {
        setDuplicateText(
            !!errorText,
            errorText
        );

        return () => {
            contextFooter.setIndicator(null);
        };
    }, [errorText]);

    const errorCheck = (...data) => customCheckError(dictionarySettings, ...data);

    return (
        <div className="import-dictionary">
            <DictionariesTable
                exampleBtnText={customText.exampleBtn}
                exampleFile={exampleFile}
                isOnlyView={disabled}
                customDeletedText={customDeletedText}
                onErrorFlag={(flag) => onErrorFlag && onErrorFlag(flag, setDuplicateText)}
                errorFlag={errorFlag}
                rowOnClick={(row) => rowOnClick && rowOnClick(row, setDuplicateText)}
                dictionary={dictionary}
                setLoading={setLoading}
                menuItems={menuItems}
                customBtns={btn}
                style={{
                    height: `${height - 37}px`
                }}
                customActions={customActions}
                preFilters={{
                    waybillImportFormId: formId
                }}
                setError={setError}
                customCheckError={customCheckError && errorCheck}
                customCheckRequired={customCheckRequired}
            />
            <CopyModal
                fmid={fmid}
                dictionarySettings={dictionarySettings}
                id={formId}
                onClose={() => setOpenModal(false)}
                open={!!openModal}
                onSave={(openModal || {}).callback}
            />
        </div>
    );
};

export default ImportDictionary;