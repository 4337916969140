import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Divider, Form, Icon, Message, Modal} from "semantic-ui-react";
import T from "../../../components/Translate";
import {toast} from "../../../services/toast";
import {downloadZip, sendEmail} from "../../../api/waybills";
import downloadFile from "../../../services/downloadFile";
import {getDocsContact} from "../../../api/companies";

const DocsModal = ({open, onClose, isDownload, rows}) => {
    const [form, setForm] = useState({dto: []});
    const [loading, setLoading] = useState(false);
    const [openContactManagement, setOpenContactManagement] = useState(false);

    const onChange = (e, {name, checked, value}) => {
        setForm(f => ({
            ...f,
            [name]: checked !== undefined ? checked : value
        }));
    };

    const defaultZipFileName = (form.palletBox || form.forwardingReceipt)
        ? `${form.palletBox ? 'ПЭ' : ''}${form.palletBox && form.forwardingReceipt ? ' и ' : ''}${form.forwardingReceipt ? 'ЭР' : ''} ${(rows[0] || {}).clientName}`
        : '';

    const download = async () => {
        setLoading(true);
        if (!(form.palletBox || form.forwardingReceipt)) toast.error('Пожалуйста, выберите тип документов для скачивания');
        else {
            let type = [];
            form.palletBox && type.push(1);
            form.forwardingReceipt && type.push(2);
            await downloadZip({type, id: rows.map(r => r.id), fileName: form.zipFileName || defaultZipFileName})
                .then(file => {
                    downloadFile(file);
                    file && onClose();
                })
                .catch(() => setLoading(false));
        }
        setLoading(false);
    };

    const update = (type, mails) => {
        const element = {type, mails};
        let newDto = [...form.dto];
        const index = newDto.findIndex(d => d.type === type);
        if (index !== -1) newDto[index] = element;
        else newDto.push(element);
        onChange(null, {
            name: 'dto',
            value: newDto
        });
    };

    const emailsByType = (type) => {
        return ({...form}.dto.find(d => d.type === type) || {}).mails;
    };

    const send = async () => {
        setLoading(true);
        if (!(form.palletBox || form.forwardingReceipt)) toast.error('Пожалуйста, выберите тип документов для отправки на почту');
        else {
            let type = [];
            form.palletBox && type.push(1);
            form.forwardingReceipt && type.push(2);
            await sendEmail({
                id: rows.map(r => r.id).toString(),
                type: type.toString(),
                companyId: rows[0].clientId,
                companyDocsContact: form.dto,
                replaceContact: form.replaceContact,
                doNotCopy: form.doNotCopy,
                fileName: form.zipFileName
            })
                .then(() => onClose())
                .catch(() => setLoading(false));
        }
        setLoading(false);
    };

    useEffect(() => {
        const getData = async (rows) => {
            await getDocsContact(rows[0].clientId).then(d => {
                onChange(null, {
                    name: 'dto',
                    value: d.data
                });
            });
        };
        (open && !isDownload) && getData(rows).catch(e => console.error(e));
        if (!open) {
            setForm({dto: []});
            setOpenContactManagement(false);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            size="small"
            onClose={onClose}
        >
            <Modal.Header>
                <T>{isDownload ? 'Скачать документы' : 'Отправка документов в письме'}</T>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <div className='m-b-15'>
                        Пожалуйста, укажите, какие документы требуется {isDownload ? 'скачать' : 'отправить в письме'}:
                    </div>

                    <Form.Field>
                        <Checkbox
                            checked={form.palletBox}
                            name={'palletBox'}
                            onChange={onChange}
                            label={'Паллетную/покоробочную этикетку'}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            checked={form.forwardingReceipt}
                            name={'forwardingReceipt'}
                            onChange={onChange}
                            label={'Экспедиторскую расписку'}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            value={form.zipFileName || ''}
                            placeholder={defaultZipFileName}
                            name={'zipFileName'}
                            label={`Наименование zip-файла${isDownload ? '' : ' для отправки на почту'}:`}
                            onChange={onChange}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            {!isDownload && <>
                <div className='docs-modal__btn' onClick={()=>setOpenContactManagement(!openContactManagement)}>
                    <Icon size={'large'} color={'grey'} name={openContactManagement ? 'angle down' : 'angle right'}/> <b>Управление контактами</b> <Icon size={'large'} color={'blue'} name={'at'}/>
                </div>
                {
                    openContactManagement && <>
                        <Divider className="m-0"/>
                        <Modal.Content>
                            <Form>
                                {form.palletBox && !form.forwardingReceipt && <Form.Field>
                                    <Form.Input
                                        label={'Адресат для получения паллетной/покоробочной этикетки'.t}
                                        placeholder={'Пожалуйста, укажите адресатов уведомления'.t}
                                        value={emailsByType(0) || ''}
                                        onChange={e => update(0, e.target.value)}
                                    />
                                </Form.Field>}
                                {!form.palletBox && form.forwardingReceipt && <Form.Field>
                                    <Form.Input
                                        label={'Адресат для получения экспедиторской расписки'.t}
                                        placeholder={'Пожалуйста, укажите адресатов уведомления'.t}
                                        value={emailsByType(1) || ''}
                                        onChange={e => update(1, e.target.value)}
                                    />
                                </Form.Field>}
                                {form.palletBox && form.forwardingReceipt && <Form.Field>
                                    <Form.Input
                                        label={'Адресат для получения экспедиторской расписки и паллетной/покоробочной этикетки'.t}
                                        placeholder={'Пожалуйста, укажите адресатов уведомления'.t}
                                        value={emailsByType(2) || ''}
                                        onChange={e => update(2, e.target.value)}
                                    />
                                </Form.Field>}
                                <Message
                                    info
                                    icon="info"
                                    content={<div>
                                        <div>
                                            {'Пожалуйста, укажите адресатов в формате <имя_пользователя@почтовый_домен>.'}
                                        </div>
                                        <div>
                                            В качестве разделителя между адресатами требуется использовать запятую.
                                        </div>
                                    </div>}
                                />
                                <Form.Field>
                                    <Checkbox
                                        checked={form.replaceContact}
                                        name={'replaceContact'}
                                        onChange={onChange}
                                        label={'Заменить контакты в настройках адресатов уведомления'}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Content>
                    </>
                }
                <Divider className="m-0"/>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Checkbox
                                checked={form.doNotCopy}
                                name={'doNotCopy'}
                                onChange={onChange}
                                label={'Не добавлять меня в копию письма'}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
            </>}

            <Modal.Actions>
                <Button onClick={onClose}>
                    <T>Отмена</T>
                </Button>
                <Button
                    primary
                    positive
                    onClick={isDownload ? download : send}
                    disabled={loading}
                    loading={loading}
                >
                    <T>{isDownload ? 'Скачать' : 'Отправить письмо'}</T>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DocsModal;