import React from 'react';
import {fieldTypes, latinLettersValue, ProcessingTypesEnum} from "../../../api/model/ImportForms";
import RuleInput from "./ruleInput";
import {Icon} from "semantic-ui-react";
import Replace from "./ruleInputBlocks/replace";
import Splitters from "./ruleInputBlocks/splitters";
import Template from "./ruleInputBlocks/template";
import CustomReplace from "./ruleInputBlocks/customReplace";
import Trim from "./ruleInputBlocks/trim";
import Constant from "./ruleInputBlocks/constant";
import Position from "./ruleInputBlocks/position";

const RuleValueBlock = ({companyId, type, processingType, disabled, value, onChange, delBtn, dictionaryType, dataType}) => {
    const clearBtn = <Icon
        className={`cursor-pointer ${value && 'pointer-events'}`}
        name={value && "close"}
        onClick={() => value && onChange(null, {name: 'value', value: null})}
    />;

    const getBlockByType = (type) => {
        const props = {
            icon: clearBtn,
            name: "value",
            disabled,
            inputType: fieldTypes.STRING,
            value,
            onChange,
            delBtn,
            dictionaryType,
            dataType,
            companyId
        };

        switch (processingType) {
            case ProcessingTypesEnum.CONSTANT:
                return <Constant
                    type={type}
                    {...props}
                />;
            case ProcessingTypesEnum.SPLITTERS:
                return <Splitters {...props}/>;
            case ProcessingTypesEnum.REPLACE_BY_TEMPLATE:
                return <Template
                    type={type}
                    {...props}
                />;
            case ProcessingTypesEnum.REPLACE:
                return <Replace {...props}/>;
            case ProcessingTypesEnum.TRIM:
                return <Trim {...props}/>;
            case ProcessingTypesEnum.POSITION:
                return <Position {...props}/>;
            case ProcessingTypesEnum.REPLACE_CUSTOM:
                return <CustomReplace
                    type={type}
                    {...props}
                />;
            case ProcessingTypesEnum.CELL:
                return <div className="rule-value__input">
                    <RuleInput
                        {...props}
                        onChange={(e, {name, value}) => {
                            if (!value || latinLettersValue(value)) {
                                onChange(e, {name, value: value.toUpperCase()});
                            }
                        }}
                    />
                    {delBtn}
                </div>;
            case ProcessingTypesEnum.AUTOMATIC:
            default:
                return null;
        }
    };

    return (
        getBlockByType(type)
    );
};

export default RuleValueBlock;