import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextFooter, ContextNavi, ContextUser} from "../../services/context";
import {Button, Checkbox, Icon, Label, Menu, Message, Modal, Popup, Table} from "semantic-ui-react";
import Shield from "../../layout/page/shield/Shield";
import {exportToExcel, getAll, rgCompleted, rgDelete} from "../../api/registries";
import {RegistrieStatusOptions, RegistrieStatusOptionsFromFilter} from "../../api/model/registries";
import useReactRouter from 'use-react-router';
import {dateUtc} from "../../api/banners";
import T from "../../components/Translate";
import UserPermissions from "../../api/model/UserPermissions";
import qs from "query-string";
import iconClearFilters from "../waybills/wbGrid/WbGridHeadersClearFiltersIcon.png";
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import WbGridFilterDatePeriod from "../waybills/wbGrid/wbGridFilters/WbGridFilterDatePeriod";
import WbGridFilterListMulti from "../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";
import RgEditPeriodModal from "./RgEditPeriodModal";
import ScrollPreloader from "../../components/miscs/ScrollPreloader";

const headers = [
    {
        key: "state",
        text: "Статус",
        sources: RegistrieStatusOptionsFromFilter,
        sortable: true,
    },
    {
        key: "clientCompany",
        text: "Клиент",
        sortable: true,
    },
    {
        key: "specialistCS",
        text: "Специалист CS",
        sortable: true,
    },
    {
        key: "registryNumber",
        text: "Номер реестра",
        sortable: true,
    },
    {
        key: "registrySumm",
        text: "Общая стоимость, без НДС",
        sortable: true,
    },
    {
        key: "registrySummVAT",
        text: "Общая стоимость, с НДС",
        sortable: true,
    },
    {
        key: "period",
        text: "Период реестра",
        sortable: true,
    },
    {
        key: "createdOn",
        text: "Дата отправки реестра (“Новый”)",
        sortable: true,
    },
    {
        key: "planDateAgreement",
        text: "Плановая дата согласования",
        sortable: true,
    },
    {
        key: "stateInProcessClientDate",
        text: "Дата статуса “На согласовании: Клиент”",
        sortable: true,
    },
    {
        key: "stateInProcessFMDate",
        text: "Дата статуса “На согласовании: FM“",
        sortable: true,
    },
    {
        key: "stateCompletedDate",
        text: "Дата статуса “Согласован“",
        sortable: true,
    },
    {
        key: "stateAwaitingEditFMDate",
        text: "Дата статуса “Ожидает корректировки FM”",
        sortable: true,
    },
    {
        key: "factDateAgreement",
        text: "Фактическая дата согласования",
        sortable: true,
    }
];

const noRows = (
    <div className="no-rows-wrapper">
        <div className="no-rows">Нет реестров.</div>
    </div>
);

const RgGrid = ({location}) => {
    const contextNavi = useContext(ContextNavi);
    const contextUser = useContext(ContextUser);
    const contextFooter = useContext(ContextFooter);

    const {history} = useReactRouter();

    let [rows, setRows] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [loading, setLoading] = useState(true);
    let [selectedIds, setSelectedIds] = useState(new Set());
    let [completeOpenModal, setCompleteOpenModal] = useState(false);
    let [editPeriodOpenModal, setEditPeriodOpenModal] = useState(false);
    let [completeProgress, setCompleteProgress] = useState(false);

    let [deleteOpenModal, setDeleteOpenModal] = useState(false);
    let [deleteProgress, setDeleteProgress] = useState(false);

    const [scrollLoading, setScrollLoading] = useState(false);
    const [isMaxRows, setIsMaxRows] = useState(false);

    const page = useRef(0);
    const scrollComponent = useRef(null);
    const pageSize = 20;
    const needScroll = rows.length >= pageSize && rows.length % pageSize === 0 && !isMaxRows;

    const getList = async () => {
        const result = await getAll(location.search);

        setRows(result);
    };

    const fetchData = (noLoader) => {
        setLoading(!noLoader);
        try {
            getList();
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    useEffect(() => {
        contextFooter.setIndicator(
            () => <div className="table-footer">
                {selectedIds.size
                    ? `Выбрано: ${selectedIds.size} / `
                    : null}
                {rows.length} записей
            </div>
        );
        return (() => {
            contextFooter.setIndicator(null);
        });
    }, [selectedIds, rows]);

    async function onBottomVisible() {
        if (scrollLoading) return;
        const nextPage = page.current + 1;
        const filter = qs.parse(location.search);
        filter.page = nextPage;
        const filterString = qs.stringify(filter);

        setScrollLoading(true);
        const nextRows = await getAll("?" + filterString);

        if (nextRows && nextRows.length) {
            const allRows = rows.concat(nextRows);
            setRows(allRows);
            page.current = nextPage;
        } else setIsMaxRows(true);
        setScrollLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("Реестры", "/registries")]);
    }, []);

    useEffect(() => {
        fetchData();
    }, [location.search]);

    let query = qs.parse(location.search);

    const {sortBy, sortDir} = query;

    function updateQuery(q) {
        scrollTop();
        query = q;
        setIsMaxRows(false);
        history.push("?" + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = "ascending";
        } else {
            sortDir = sortDir === "ascending" ? "descending" : "ascending";
        }

        updateQuery({...query, sortBy, sortDir});
    };

    function applyFilter(filter) {
        updateQuery({...query, ...filter});
        selectedIds.size && setSelectedIds(new Set());
    }

    function removeFilter(key) {
        updateQuery({...query, [key]: undefined});
        selectedIds.size && setSelectedIds(new Set());
    }

    function clearFilters() {
        updateQuery({});
        selectedIds.size && setSelectedIds(new Set());
    }

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{paddingTop: "5px", display: "inline-block", width: "17px"}}
        >
      <img src={iconClearFilters} alt="icf" style={{height: "13px"}}/>
    </span>
    );

    const getFilter = (key, sources) => {
        switch (key) {
            case "clientCompany":
            case "registryNumber":
            case "registrySumm":
            case "registrySummVAT":
            case "specialistCS":
                return (
                    <WbGridFilterStringContains
                        width={key === 'registryNumber' ? 127 : null}
                        field={key}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                );
            case 'state':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={sources || []}/>;
            case "period":
            case "createdOn":
            case "planDateAgreement":
            case "stateInProcessClientDate":
            case "stateInProcessFMDate":
            case "stateCompletedDate":
            case "stateAwaitingEditFMDate":
            case "factDateAgreement":
                return (
                    <WbGridFilterDatePeriod
                        minWidth
                        field={key}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />
                );
            default:
                return <div className="filter-flex-container"/>;
        }
    };

    const notMassComplete = useMemo(() => {
        if (!selectedIds.size) {
            return true;
        } else {
            return !(
                rows.filter(
                    item => selectedIds.has(item.id) && item.isAvailableComplete
                ).length &&
                contextUser.current.permissions.includes(
                    UserPermissions.COMPLETION_REGISTRIES
                )
            );
        }
    }, [selectedIds, rows]);

    const handleRowClick = id => {
        history.push(`/registries/${id}`);
    };

    const deselect = () => {
        if (selectedIds.size) {
            setSelectedIds(new Set());
        } else {
            setSelectedIds(new Set(rows.map(item => item.id)));
        }
    };

    const handleRowSelection = id => {
        const newSelectedIds = new Set(selectedIds);

        newSelectedIds[!selectedIds.has(id) ? "add" : "delete"](id);

        setSelectedIds(newSelectedIds);
    };

    const handleComplete = () => {
        setCompleteOpenModal(true);
    };

    const handleDelete = () => {
        setDeleteOpenModal(true);
    };

    const handleConfirmComplete = async () => {
        setCompleteProgress(true);
        try {
            await rgCompleted(Array.from(selectedIds));
            setCompleteOpenModal(false);
            await fetchData();
        } finally {
            setCompleteProgress(false);
        }
    };

    const handleConfirmDelete = async () => {
        setDeleteProgress(true);
        try {
            await rgDelete(Array.from(selectedIds));
            setDeleteOpenModal(false);
            await fetchData();
        } finally {
            setDeleteProgress(false);
        }
    };

    const scrollTop = () => {
        page.current = 0;
        scrollComponent.current.scrollTop = 0;
    };

    const getCell = (key, val) => {
        switch (key) {
            case "state":
                const o = RegistrieStatusOptions.find(o => o.key === val);

                return o ? (
                    <Label
                        color={o.color}
                        style={{textAlign: "center", marginRight: "5px"}}
                        title={o.text.t}
                    >
                        {o.text.t}
                    </Label>
                ) : (
                    <Label style={{textAlign: "center", marginRight: "5px"}}>
                        {val}
                    </Label>
                );
            /*
                              case "planDateAgreement":
                              case 'factDateAgreement':
                                  return val ? moment(val).format("DD.MM.YYYY HH:mm") : null;*/
            case "createdOn":
                return dateUtc(val);
            case "specialistCS":
                return <div>{val && val.split(',').map(v => <div key={v}>{v}</div>)}</div>;
            default:
                return val;
        }
    };

    const styleDisabledHeader = {
        background: "#f9fafb",
        cursor: "default"
    };

    const handleExportToExcel = async () => {
        const fileInfo = await exportToExcel(Array.from(selectedIds), query);
        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    const onCloseModal = async () => {
        setEditPeriodOpenModal(false);
        await fetchData();
    };

    const isDate = (key) => {
        const dateKeys = ["period", "createdOn", "planDateAgreement", "stateInProcessClientDate", "stateInProcessFMDate", "stateCompletedDate", "stateAwaitingEditFMDate", "factDateAgreement"];
        return dateKeys.includes(key);
    };

    const heightTableHeader = () => {
        let header = document.getElementById('tableHeader');
        return header && header.clientHeight;
    };

    const backlights = (row) => {
        if (row.isPlanDateAgreementExpired) {
            return {
                backgroundColor: 'rgba(255,0,0,0.33)'
            };
        }
        return {};
    };

    return (
        <Shield loading={loading} loadingOver={loaded}>
            <Menu
                className="waybills-toolbar shd-inset"
                style={{marginBottom: "0"}}
                size="small"
                borderless
            >
                <Menu.Menu position="right">
                    <Menu.Item disabled={notMassComplete} onClick={handleComplete}>
                        <Icon color="green" name="chevron circle down"/>
                        <T>Перевести на статус Согласован</T>
                    </Menu.Item>
                    <Menu.Item
                        disabled={
                            !(
                                contextUser.current.permissions.includes(
                                    UserPermissions.REGISTRIES_DELETE
                                ) && selectedIds.size > 0
                            )
                        }
                        onClick={handleDelete}
                    >
                        <Icon color="green" name="close"/>
                        <T>Удалить реестры</T>
                    </Menu.Item>
                    <Menu.Item
                        disabled={
                            !(
                                contextUser.current.permissions.includes(
                                    UserPermissions.REGISTRIES_EDIT_PERIOD
                                ) && selectedIds.size === 1
                            )
                        }
                        onClick={() => setEditPeriodOpenModal(true)}
                    >
                        <Icon color="blue" name="calendar alternate outline"/>
                        <T>Изменить период реестра</T>
                    </Menu.Item>
                    <Menu.Item onClick={handleExportToExcel}>
                        <Icon name="arrow up"/>
                        <T>Экспорт реестров в Excel</T>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <div className="table-wrapper-grid table-wrapper-grid-130 table-registry" ref={scrollComponent}>
                <Table celled sortable singleLine>
                    <Table.Header className="table-header-fixed">
                        <Table.Row id="tableHeader">
                            <Table.HeaderCell
                                className="table-first-col table-item-sticky table-registry-select-all"
                                style={{zIndex: "6"}}
                            >
                                <Checkbox
                                    checked={selectedIds.size === rows.length}
                                    indeterminate={
                                        selectedIds.size && selectedIds.size !== rows.length
                                    }
                                    onChange={deselect}
                                />
                            </Table.HeaderCell>
                            {headers.map(item => (
                                <Table.HeaderCell
                                    id={`header-th-${item.key}`}
                                    title={item.title}
                                    sorted={item.sortable && sortBy === item.key ? sortDir : null}
                                    style={item.sortable ? null : styleDisabledHeader}
                                    onClick={item.sortable ? handleSort(item.key) : null}
                                    className={`table-select-cell table-header-fixed__title ${isDate(item.key) ? "style-date" : ""}`}
                                    key={item.key}
                                >
                                    {item.text}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                        <Table.Row key="filters" style={{height: "37px"}}>
                            <Table.Cell
                                className="table-first-col table-item-sticky table-registry-filter-clear"
                                style={{
                                    backgroundColor: "#f9fafb",
                                    textAlign: "center",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    top: heightTableHeader() + 'px'
                                }}
                            >
                                <Popup
                                    content={"Очистить все фильтры".t}
                                    trigger={clearFilterButton}
                                />
                            </Table.Cell>
                            {headers.map(({key, sources}) => (
                                <Table.Cell
                                    id={`filter-td-${key}`}
                                    key={key}
                                    sorted={sortBy === key ? sortDir : null}
                                    style={{background: "#fff;", top: heightTableHeader() + 'px'}}
                                    className={"table-select-cell table-filter-fixed__title"}
                                >
                                    {getFilter(key, sources)}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    {rows && rows.length ? (
                        <Table.Body>
                            {rows.map(row => (
                                <Table.Row key={row.id} style={{cursor: "pointer", ...backlights(row)}}>
                                    <Table.Cell className="table-first-col table-item-sticky">
                                        <Checkbox
                                            id={row.id}
                                            checked={selectedIds.has(row.id)}
                                            onChange={e => {
                                                e.stopPropagation();
                                                handleRowSelection(row.id);
                                            }}
                                        />
                                    </Table.Cell>
                                    {headers.map(column => (
                                        <Table.Cell
                                            key={`${column.key}_${row.id}`}
                                            onClick={() => handleRowClick(row.id)}
                                        >
                                            {getCell(column.key, row[column.key])}
                                        </Table.Cell>
                                    ))}
                                </Table.Row>
                            ))}
                        </Table.Body>
                    ) : (
                        <div className="table-empty"/>
                    )}
                </Table>

                {!rows.length && noRows}
                {needScroll && <ScrollPreloader onVisible={onBottomVisible} continuous={true}/>}
            </div>
            <Modal open={completeOpenModal}>
                <Modal.Header>Перевести на статус Согласован</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={
                            <div>
                                <div>
                                    Выбранные вами реестры будут переведены на статус Согласован.
                                </div>
                                <div>
                                    Чтобы подтвердить действие нажмите на кнопку "Согласовать",
                                    если не хотите подтверждать действие, то нажмите на кнопку
                                    "Отмена".
                                </div>
                            </div>
                        }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setCompleteOpenModal(false)}>Отмена</Button>
                    <Button
                        disabled={completeProgress}
                        color="green"
                        loading={completeProgress}
                        onClick={handleConfirmComplete}
                    >
                        Согласовать
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={deleteOpenModal}>
                <Modal.Header>Удаление реестров</Modal.Header>
                <Modal.Content>
                    <Message
                        icon="info"
                        info
                        content={
                            <div>
                                <div>Выбранные вами реестры будут удалены.</div>
                                <div>
                                    Чтобы подтвердить действие нажмите на кнопку "Удалить", если
                                    не хотите подтверждать действие, то нажмите на кнопку
                                    "Отмена".
                                </div>
                            </div>
                        }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setDeleteOpenModal(false)}>Отмена</Button>
                    <Button
                        disabled={deleteProgress}
                        color="green"
                        loading={deleteProgress}
                        onClick={handleConfirmDelete}
                    >
                        Удалить
                    </Button>
                </Modal.Actions>
            </Modal>
            <RgEditPeriodModal
                open={editPeriodOpenModal}
                selectedRow={rows.find(row => selectedIds.has(row.id))}
                onClose={onCloseModal}
            />
        </Shield>
    );
};

export default RgGrid;
