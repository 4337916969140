import React, {useEffect, useState} from 'react';
import PointFormAddressManual from "../../admin/points/pointForm/PointFormAddress/PointFormAddressManual";
import PointAddress from "../../../api/model/PointAddress";
import {dadataSearchAddress, pointCustomCreate, pointGetSimilar} from "../../../api/points";
import PointFormSimilarModal from "../../admin/points/pointForm/PointFormSimilarModal";
import PointFormAddressAdditionalFields
    from "../../admin/points/pointForm/PointFormAddress/PointFormAddressAdditionalFields";
import {isEmpty} from "../../../services/utils";

const WbPointAddCreateAddress = ({saveTrigger, setDisabledSaveBtn, setPointAddress}) => {
    const [address, setAddress] = useState({});
    const [data, setData] = useState({pointType: 0});
    const [isChanged, setIsChanged] = useState(false);
    const [isSimilarModalOpen, setIsSimilarModalOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [similarPoints, setSimilarPoints] = useState([]);

    const requiredFields = ['country', 'region', 'settlement', 'postalCode', 'companyLegalForm', 'companyName', 'dockCapacity', 'pointType'];

    const mergeToAddress = (d) => {
        let newAddress = {...address, ...d};
        const value = new PointAddress(newAddress).toString();
        newAddress = {...newAddress, value};
        setAddress(newAddress);
        setIsChanged(true);
        const newAddrData = {
            ...newAddress,
            ...data
        };
        const requiredFieldsIsNotEmpty = requiredFields.every(i => !isEmpty(newAddrData[i]));
        setDisabledSaveBtn(!requiredFieldsIsNotEmpty);
    };

    const setAddressData = (newData) => {
        let newAddressData = {...data, ...newData};
        setData(newAddressData);
        setIsChanged(true);
        const newAddrData = {
            ...address,
            ...newAddressData
        };
        const requiredFieldsIsNotEmpty = requiredFields.every(i => !isEmpty(newAddrData[i]));
        setDisabledSaveBtn(!requiredFieldsIsNotEmpty);
    };

    const dadataToAddress = (dds) => {
        const adr = dds.data;

        return new PointAddress({
            ...address,
            ...adr,
            country: adr.country,
            region: adr.region_with_type,
            area: adr.area_with_type,
            postalCode: adr.postal_code,
            streetName: adr.street_with_type,
            appt: adr.flat,
            kladr: adr.kladr_id,
            fias_level: adr.fias_level,
            fias: adr.fias_id,
            geoLat: adr.geo_lat,
            geoLon: adr.geo_lon,
            value: dds.value,
            timezone: adr.timezone
        });
    };

    const addressVerification = (suggestions) => {
        let addressData = address;
        if (suggestions && suggestions.length) {
            addressData = dadataToAddress(suggestions[0]);
        }

        pointGetSimilar({
            country: address.country,
            region: address.region,
            settlement: address.settlement,
            postalCode: address.postalCode,
            streetName: address.streetName,
            streetNo: address.streetNo,
            building: address.building,
            office: address.office
        })
            .then(d => {
                if (d.length) {
                    setIsSimilarModalOpen(true);
                    d && setSimilarPoints(d);
                    setAddress(addressData);
                } else save(addressData);
            });
    };

    const save = (address) => {
        pointCustomCreate({
            address,
            ...data
        })
            .then(d => {
                d && setPointAddress(d);
            })
            .catch(e => console.log(e));
    };

    const onSave = () => {
        let xhr = new XMLHttpRequest();
        dadataSearchAddress(xhr, {
            count: 1,
            query: `${address.postalCode}, ${address.country}, ${address.value}`
        }, null, addressVerification);
    };

    useEffect(() => {
        isChanged && onSave();
    }, [saveTrigger]);

    const submitSimilar = (data) => {
        setPointAddress(data);
        setIsSimilarModalOpen(false);
    };

    return (
        <>
            <PointFormAddressManual
                mini
                ve={errors}
                address={address}
                mergeToAddress={mergeToAddress}
            />
            <PointFormAddressAdditionalFields
                ve={errors}
                data={data}
                setData={setAddressData}
            />
            <PointFormSimilarModal
                addAddress
                onSimilarSubmit
                isOpen={isSimilarModalOpen}
                onOpen={() => setIsSimilarModalOpen(true)}
                submitSimilar={submitSimilar}
                similarPoints={similarPoints}
                onCancel={() => {
                    setIsSimilarModalOpen(false);
                    save(address);
                }}
            />
        </>
    );
};

export default WbPointAddCreateAddress;