import React from 'react';
import { withRouter } from 'react-router-dom';
import { Feed } from 'semantic-ui-react';
import Page from '../../../layout/page/Page';
import { getWaybillLogs } from '../../../api/waybills';
import HistoryItem from '../../_shared/HistoryItem';

class WbHistory extends Page {

    async fetchData() {
        const wbId = this.props.match.params.id;
        const isAuction = this.props.isAuction;
        const result = await getWaybillLogs(wbId, isAuction);
        this.setState({ data: result || [] });
    }

    renderData() {
        return (
            <Feed>
                {this.state.data.map(i => <HistoryItem {...i} />)}
            </Feed>
        );
    }
}

export default withRouter(WbHistory);