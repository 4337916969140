import React from 'react';
import ObjectRows from "./objectRows";
import {Form} from "semantic-ui-react";
import FormField from "../formField";
import {fieldTypes} from "../../../../api/model/ImportForms";
import PositionInfoMessage from "./positionInfoMessage";


const PositionRow = ({className, withLabel, name, value, changeValue, disabled, ...props}) => {
    return <Form className={`trim__row ${className}`}>
        <FormField
            disabled={disabled}
            className='position__input'
            required
            label={withLabel && 'Тип условия'}
            value={name}
            readOnly
        />
        <FormField
            {...props}
            disabled={disabled}
            name='value'
            required
            label={withLabel && 'Значение'}
            inputType={fieldTypes.NUMBER}
            value={value}
            onChange={(e, p) => changeValue(p)}
        />
    </Form>;
};

const Positions = ({changeValue, allValues: value, ...props}) => {
    const value1 = value && value[0] ? value[0].value : '';
    const value2 = value && value[1] ? value[1].value : '';

    return <div className='p-t-10'>
        <PositionRow
            {...props}
            withLabel
            value={value1}
            name='Номер первого символа'
            changeValue={(p) => changeValue(0, p)}
            className='p-b-10'
        />
        <PositionRow
            {...props}
            value={value2}
            name='Номер последнего символа'
            changeValue={(p) => changeValue(1, p)}
        />
    </div>;
};


const Position = ({value, onChange, delBtn, icon, disabled, name}) => {
    const value1 = value && value[0] ? value[0].value : '';
    const value2 = value && value[1] ? value[1].value : '';

    const valueText = (value1 || value2) ? `c ${value1 || '<?>'} по ${value2 || '<?>'} символ` : '';

    const handleChange = (e, {name, value}) => {
        onChange(null, {
            name,
            value
        });
    };

    return <ObjectRows
        value={value}
        onChange={handleChange}
        delBtn={delBtn}
        icon={icon}
        disabled={disabled}
        name={name}
        valueText={valueText}
        component={Positions}
        maxCount={1}
        className="position"
        withoutItems
        infoBlock={<PositionInfoMessage/>}
    />;
};

export default Position;