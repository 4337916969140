import React from 'react';
import PointForm from '../../admin/points/pointForm/PointForm';
import { PointStatusEnum } from '../../../api/model/Point';

export default   function ActionPointForm({
    companyOptions,
    selectedCompany,
    setSelectedCompany,
    handleAddToList,
    createItem,
    children
}) {
    return (
        <PointForm
            key="newPointForm"
            searchName
            title={"Новый адрес FM".t}
            size="small"
            data={{ statusId: PointStatusEnum.ON_APPROVAL }}
            submit={createItem}
            onSimilarSubmit={(data) => {
                console.log('onSimilarSubmit');
                handleAddToList(selectedCompany, data.id);
            }}
            needFindSimilar={true}
            isClient={true}
            companyOptions={companyOptions}
            setSelectedCompany={setSelectedCompany}
            selectedCompany={selectedCompany}
        >
            {children}
        </PointForm>
    );
}
    
