import React from 'react';
import ModalFile from "../../components/modals/ModalFile";
import {Button} from "semantic-ui-react";
import CreateEditModal from "../dictionaries/createEditModal";
import {DictionariesEnum} from "../../api/model/Dictionaries";

const Buttons = ({onDrop, disabled, isValid, processFile, uploadWaybills, uploadWaybillsDisabled, addPoint, openErrors, initData}) => {
    return <>
        {onDrop && <ModalFile
            name="Загрузить новый файл"
            onDrop={onDrop}
        >
            <Button
                disabled={disabled}
                size="mini"
                color="blue"
                content={'Загрузить новый файл'}
            />
        </ModalFile>}

        {addPoint && <CreateEditModal
            initData={initData}
            dictionary={DictionariesEnum.POINTS}
        >
            <Button
                disabled={disabled}
                size="mini"
                color="blue"
                content={'Добавить адрес в справочник "Адреса"'}
            />
        </CreateEditModal>}

        {processFile && <Button
            disabled={!isValid || disabled}
            size="mini"
            color="blue"
            content={'Обработать файл'}
            onClick={processFile}
        />}

        {uploadWaybills && uploadWaybills(<Button
            disabled={uploadWaybillsDisabled || disabled}
            size="mini"
            color="green"
            content={'Загрузить заявки'}
        />)}

        {openErrors && <Button
            size="mini"
            color="red"
            icon='exclamation'
            content='Ошибки'
            onClick={openErrors}
        />}
    </>;
};

export default Buttons;