import {get, del, post, put} from "../services/http";

export async function getCompanyCutoffs(companyId, type) {
	const result = await get(`companies/${companyId}/cutoffs/${type}`);
	return result;
}

export async function getCompanyCutoffsUpdate(companyId, type) {
	const result = await get(`companies/${companyId}/cutoffs/update?shippingType=${type}`);
	return result;
}

export async function companyCutoffDateFTLAdd(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	region
}) {
    const result = await post(`company/${companyId}/cutoffs/add/date/FTL`, {
		weekdaySubmitting,
		weekdayLoading,
		region
	});
    return result;
}

export async function companyCutoffDateRailLTLAdd(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	region
}) {
	const result = await post(`company/${companyId}/cutoffs/add/date/railltl`, {
		weekdaySubmitting,
		weekdayLoading,
		region
	});
	return result;
}

export async function companyCutoffDateRailAdd(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	region
}) {
    const result = await post(`company/${companyId}/cutoffs/add/date/rail`, {
		weekdaySubmitting,
		weekdayLoading,
		region
	});
    return result;
}

export async function companyCutoffDateLTLAdd(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	cargoType
}) {
    const result = await post(`company/${companyId}/cutoffs/add/date/LTL`, {
		weekdaySubmitting,
		weekdayLoading,
		cargoType
	});
    return result;
}

export async function companyCutoffTimeFTLAdd(companyId, {
	cutoffHour,
	cutoffMinutes,
	region
}) {
    const result = await post(`company/${companyId}/cutoffs/add/time/FTL`, {
		cutoffHour,
		cutoffMinutes,
		region
	});
    return result;
}

export async function companyCutoffTimeRailLTLAdd(companyId, {
	cutoffHour,
	cutoffMinutes,
	region
}) {
	const result = await post(`company/${companyId}/cutoffs/add/time/railltl`, {
		cutoffHour,
		cutoffMinutes,
		region
	});
	return result;
}

export async function companyCutoffTimeRailAdd(companyId, {
	cutoffHour,
	cutoffMinutes,
	region
}) {
    const result = await post(`company/${companyId}/cutoffs/add/time/rail`, {
		cutoffHour,
		cutoffMinutes,
		region
	});
    return result;
}

export async function companyCutoffTimeLTLAdd(companyId, {
	cutoffHour,
	cutoffMinutes,
	cargoType,
	isHubLoading
}) {
    const result = await post(`company/${companyId}/cutoffs/add/time/LTL`, {
		cutoffHour,
		cutoffMinutes,
		cargoType,
		isHubLoading
	});
    return result;
}


export async function companyCutoffDateFTLDelete(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	region
}) {
    const result = await del(`company/${companyId}/cutoffs/date/FTL`, {
		weekdaySubmitting,
		weekdayLoading,
		region
	});
    return result;
}

export async function companyCutoffDateRailLTLDelete(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	region
}) {
	const result = await del(`company/${companyId}/cutoffs/date/railltl`, {
		weekdaySubmitting,
		weekdayLoading,
		region
	});
	return result;
}

export async function companyCutoffDateRailDelete(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	region
}) {
    const result = await del(`company/${companyId}/cutoffs/date/rail`, {
		weekdaySubmitting,
		weekdayLoading,
		region
	});
    return result;
}

export async function companyCutoffDateLTLDelete(companyId, {
	weekdaySubmitting,
	weekdayLoading,
	cargoType
}) {
    const result = await del(`company/${companyId}/cutoffs/date/LTL`, {
		weekdaySubmitting,
		weekdayLoading,
		cargoType
	});
    return result;
}

export async function companyCutoffTimeFTLDelete(companyId, {
	cutoffHour,
	cutoffMinutes,
	region
}) {
    const result = await del(`company/${companyId}/cutoffs/time/FTL`, {
		cutoffHour,
		cutoffMinutes,
		region
	});
    return result;
}

export async function companyCutoffTimeRailLTLDelete(companyId, {
	cutoffHour,
	cutoffMinutes,
	region
}) {
	const result = await del(`company/${companyId}/cutoffs/time/railltl`, {
		cutoffHour,
		cutoffMinutes,
		region
	});
	return result;
}

export async function companyCutoffTimeRailDelete(companyId, {
	cutoffHour,
	cutoffMinutes,
	region
}) {
    const result = await del(`company/${companyId}/cutoffs/time/rail`, {
		cutoffHour,
		cutoffMinutes,
		region
	});
    return result;
}

export async function companyCutoffTimeLTLDelete(companyId, {
	cutoffHour,
	cutoffMinutes,
	cargoType,
	isHubLoading
}) {
    const result = await del(`company/${companyId}/cutoffs/time/LTL`, {
		cutoffHour,
		cutoffMinutes,
		cargoType,
		isHubLoading
	});
    return result;
}

export const companyCutoffUpdateRuleAdd = async (companyId, data) => {
    const result = await post(`companies/${companyId}/cutoffs/update/add`, data);
    return result;
};

export const companyCutoffUpdateRuleEdit = async (companyId, data, id) => {
    const result = await put(`companies/${companyId}/cutoffs/update/${id}/edit`, data);
    return result;
};

export const getCutoffUpdateRule = async (companyId, id) => {
    const result = await get(`companies/${companyId}/cutoffs/update/${id}`);

    return result
};

export const deleteCompanyCutoffUpdateRule = async (companyId, id) => {
	const result = await del(`companies/${companyId}/cutoffs/update/${id}/remove`);

	return result;
}
