import React from 'react';
import {Form} from "semantic-ui-react";
import {PointTypeEnum} from "../../../../api/model/Point";

const PointTypeRadioButton = ({hasPermissions, data, update}) => {

    return (
        <React.Fragment>
            <Form.Group inline>
                <Form.Radio
                    id={`radio-btn_${PointTypeEnum.SHOP}`}
                    label={"Магазин".t}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.SHOP}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.SHOP })}
                />
                <Form.Radio
                    id={`radio-btn_${PointTypeEnum.DISTRIBUTION_CENTER}`}
                    label={"РЦ".t}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.DISTRIBUTION_CENTER}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.DISTRIBUTION_CENTER})}
                />
                <Form.Radio
                    id={`radio-btn_${PointTypeEnum.RECEPIENT}`}
                    label={"Контрагент".t}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.RECEPIENT}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.RECEPIENT })}
                />
                <Form.Radio
                    id={`radio-btn_${PointTypeEnum.CLIENT_WAREHOUSE}`}
                    label={"Склад клиента".t}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.CLIENT_WAREHOUSE}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.CLIENT_WAREHOUSE })}
                />
                {hasPermissions && <Form.Radio
                    id={`radio-btn_${PointTypeEnum.FM}`}
                    label={"Кросс-док".t}
                    value={data.pointType}
                    checked={data.pointType === PointTypeEnum.FM}
                    onChange={(e, el) => update({ pointType: PointTypeEnum.FM })}
                />}
            </Form.Group>
        </React.Fragment>
    );
};

export default PointTypeRadioButton;