import React from 'react';
import {Message} from "semantic-ui-react";

const ExampleText = ({text, topLabel, min, max}) => {
    return <span className='info-example'>
        <span>
            {topLabel}
        </span>
        <span className='info-example__text'>{text}</span>
        <span>
            <span>{min}</span>
            <span>{max}</span>
        </span>
    </span>;
};

const PositionInfoMessage = () => {
    return (
        <Message
            className="position__message"
            info
            icon="info"
            content={
                <span className='info-examples'>
                    Пожалуйста, укажите номер первого и последнего символа параметра в объединенной ячейке.
                    <div>
                        <span>Например,</span>
                        <ExampleText text={'11.04.2024'} topLabel={'Дата'} min={1} max={10}/>
                        <ExampleText text={'10:30'} topLabel={'Время (с)'} min={12} max={16}/>
                        <span>-</span>
                        <ExampleText text={'12:30'} topLabel={'Время (по)'} min={18} max={22}/>
                    </div>
                </span>
            }
        />
    );
};

export default PositionInfoMessage;