import React from 'react';
import {Form} from "semantic-ui-react";
import RuleInput from "./ruleInput";

const FormField = ({required, label, ...props}) => {
    return (
        <Form.Field required={required}>
            {label && <label>{label}</label>}
            <RuleInput {...props}/>
        </Form.Field>
    );
};

export default FormField;