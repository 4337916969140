import React from 'react';
import { withRouter } from 'react-router-dom';
import { Feed } from 'semantic-ui-react';
import {getScheduleHistory} from "../../api/schedules";
import HistoryItem from "../_shared/HistoryItem";
import Page from "../../layout/page/Page";

class ScheduleHistory extends Page {

    async fetchData() {
        const id = this.props.id;
        const result = await getScheduleHistory(id);
        this.setState({ data: result || [] });
    }

    renderData() {

        return (
            <Feed>
                {this.state.data.map(i => <HistoryItem {...i} />)}
            </Feed>
        );
    }
}

export default withRouter(ScheduleHistory);