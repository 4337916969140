import React from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { InputDadata } from '../../../../../components/inputs/inputDadata_/InputDadata';
import T from '../../../../../components/Translate';
import { PopupTitle } from '../../../../../components/popupTitle/PopupTitle';

const PointFormAddressDadata = ({ address, count, query, onChangeDD, onDadataInputChange, isDatataInvalid, ve }) => {
	const tip = "если в подсказках вы не видите вашего адреса - воспользуйтесь ручным вводом";
	const tipEl = <PopupTitle title={tip}><Icon name='info circle' color="blue" style={{ fontSize: "1.1em" }} /></PopupTitle>;

	return <div style={{ marginTop: 20 }} className="point-form-custom-opacity">
        <Form.Field error={isDatataInvalid} required>
            <label><T>Введите адрес одной строкой, например Инженерная 6А</T> {tipEl}</label>
            <InputDadata
                query={query}
                count={count}
                autoload={!!query}
                placeholder={"Введите адрес по частям: улица, номер дома и выберите из списка".t}
                onChange={onChangeDD}
                onInputChange={onDadataInputChange}
            />
        </Form.Field>

        <Form.Group widths='equal'>
            <Form.Input
                required
                error={ve.includes("country")}
                label={'Страна'.t}
                placeholder={'Страна'.t}
                value={address.country || ''}
                disabled
            />
            <Form.Input
                required
                error={ve.includes("region")}
                label={'Регион'.t}
                placeholder={'Регион'.t}
                value={address.region || ''}
                disabled
            />
            <Form.Input
                label={'Район'.t}
                placeholder={'Район'.t}
                value={address.area || ''}
                disabled
            />
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Input
                required
                error={ve.includes("settlement")}
                label={'Населенный пункт (с типом)'.t}
                placeholder={'Населенный пункт'.t}
                value={address.settlement || ''}
                disabled
            />
            <Form.Input
                required
                error={ve.includes("postalCode")}
                label={'Индекс'.t}
                placeholder={'Индекс'.t}
                value={address.postalCode || ''}
                disabled
            />
            <Form.Input
                label={'Название улицы (с типом)'.t}
                placeholder={'Название улицы включая тип'.t}
                value={address.streetName || ''}
                disabled
            />
        </Form.Group>

        <Form.Group widths='equal' devided="">
            <Form.Input
                label={'Номер дома (с типом)'.t}
                placeholder={'например д 1 или влд 3'.t}
                value={address.streetNo || ''}
                disabled
            />
            <Form.Input
                label={'Номер строения (с типом)'.t}
                placeholder={'например стр 1 или корпус 8'.t}
                value={address.building || ''}
                disabled
            />
            <Form.Input
                label={'Офис'.t}
                placeholder={'Офис или квартира'.t}
                value={address.office || ''}
                disabled
            />
        </Form.Group>
    </div>;
};

export default PointFormAddressDadata;
