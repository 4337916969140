import React from 'react';
import {Form, Grid} from "semantic-ui-react";
import InputDropdownSearch from "../../inputs/InputDropdownSearch";
import {getClientsForInputWithSearch, getFormsForInputWithSearch} from "../../../api/importForms";

const ImportFormSelect = ({form, onChange, fmid, isActive, ignoreFormId, status}) => {
    return (
        <Form>
            <Grid>
                <Grid.Row>
                    {!fmid && <Grid.Column width={8}>
                        <Form.Field required>
                            <label>Клиент, наименование</label>
                            <InputDropdownSearch
                                filters={{
                                    isActive
                                }}
                                minLetters={1}
                                getOptionsBySearch={getClientsForInputWithSearch}
                                placeholder="Клиент, наименование"
                                value={form.clientName}
                                onChange={clientName => {
                                    onChange('clientName', clientName);
                                    onChange('formName', '');
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>}
                    <Grid.Column width={fmid ? 16 : 8}>
                        <Form.Field required>
                            <label>Наименование формы импорта</label>
                            <InputDropdownSearch
                                loadInitially
                                filters={{
                                    clientFmid: form.clientName || fmid,
                                    ignoreFormId,
                                    status
                                }}
                                minLetters={1}
                                getOptionsBySearch={getFormsForInputWithSearch}
                                placeholder="Наименование формы импорта"
                                disabled={!form.clientName && !fmid}
                                value={form.formName}
                                onChange={formName => onChange('formName', formName)}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default ImportFormSelect;