import qs from 'query-string';
import React, {forwardRef, useContext, useRef, useState} from 'react';
import 'moment/locale/ru';
import {withRouter} from 'react-router-dom';
import {Checkbox, Popup, Table} from 'semantic-ui-react';
import ScrollPreloader from '../../../components/miscs/ScrollPreloader';
import WbGridRowsElement from './WbGridRowsElement';
import {ContextUser} from '../../../services/context';
import UserPermissions from '../../../api/model/UserPermissions';
import {getAll} from '../../../api/waybills';
import {
    AuctionStatusOptions,
    VehicleCapacityOptions,
    WaybillStatusAnomalyOptions,
    WaybillStatusAvizationOptions,
    WaybillStatusEnum,
    WaybillStatusOptions,
    WaybillStatusTsunamiSendingOptions,
    WaybillTypeOptions,
    WaybillTypesOptions
} from '../../../api/model/Waybill';
import T from "../../../components/Translate";
import WbGridFilterStringContains from "./wbGridFilters/WbGridFilterStringContains";
import WbGridFilterDatePeriod from "./wbGridFilters/WbGridFilterDatePeriod";
import WbGridFilterString from "./wbGridFilters/WbGridFilterString";
import WbGridFilterList from "./wbGridFilters/WbGridFilterList";
import O from "../../../components/Option";
import iconClearFilters from "./WbGridHeadersClearFiltersIcon.png";
import WbGridFilterListMulti from "./wbGridFilters/WbGridFilterListMulti";
import {viewWaybillsNotification} from "../../../api/users";
import WbGridFilterDateTime from "./wbGridFilters/WbGridFilterDateTime";
import {AuctionTypeOptions, CargoTypeOptions, PickupTypeOptions, ThermoModeOptions} from "../../../api/model/Enums";
import {PointServiceOptions} from "../../../api/model/PointService";
import {styleTh} from "../../../services/utils";

const WbGridTable = forwardRef(({
                                    gridIdKey = 'id',
                                    gridName,
                                    setLoading,
                                    headers,
                                    rows,
                                    setRows,
                                    selectedIds,
                                    selectIds,
                                    location,
                                    history,
                                    getRowsIds,
                                    fixed,
                                    scrollComponent,
                                    fetchData
                                }, ref = {}) => {

    const page = useRef(0);

    const pageSize = 70;

    const [scrollLoading, setScrollLoading] = useState(false);

    const contextUser = useContext(ContextUser);

    const canEdit = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);

    function handleRowSelection(id, rows, e, {checked}) {

        if (e.nativeEvent.shiftKey && selectedIds.length > 0 && checked) {

            const lastSelectedId = selectedIds[selectedIds.length - 1];

            const previousIndex = rows.findIndex(r => r.id === lastSelectedId) || 0;
            const currentIndex = rows.findIndex(r => r.id === id) || 0;

            const idsScope = [];

            if (currentIndex > previousIndex) {
                for (let index = previousIndex; index <= currentIndex; index++) {

                    idsScope.push(rows[index].id);
                }
            } else {
                for (let index = previousIndex; index >= currentIndex; index--) {

                    idsScope.push(rows[index].id);
                }
            }

            selectIds([...idsScope]);
        } else {
            if (checked) {
                selectIds([...selectedIds, id]);
            } else {
                selectIds([...selectedIds.filter(i => i !== id)]);
            }
        }

    }

    async function handleRowClick(r) {
        const isEditable = r.status === WaybillStatusEnum.DRAFT || gridName;
        const suffix = isEditable // (isEditable || canEdit)
            ? "edit"
            : "view/info";
        history.push(`/waybills/${gridName ? gridName + '/' : ''}${r[gridIdKey]}/${suffix}`);
        await viewWaybillsNotification(r[gridIdKey]);
    };

    async function onBottomVisible() {
        if (scrollLoading) {
            return;
        }
        const nextPage = page.current + 1;

        const filter = qs.parse(location.search);

        filter.page = nextPage;

        const filterString = qs.stringify(filter);

        setScrollLoading(true);
        const nextRows = await getAll("?" + filterString);

        if (nextRows && nextRows.length) {

            const allRows = rows.concat(nextRows);

            setRows(allRows);

            page.current = nextPage;
        }
        setScrollLoading(false);
    }

    const noRows = (
        <div className="no-rows-wrapper">
            <div className="no-rows">Нет {gridName ? 'аукционов' : 'заявок'}.</div>
        </div>
    );

    const keys = headers.map(h => h.key);

    const needScroll = rows.length >= pageSize && rows.length % pageSize === 0;

    const firstLoad = false;

    let query = qs.parse(location.search);

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({...query, sortBy, sortDir});
    };

    const scrollTop = () => {
        page.current = 0;
        scrollComponent.current.scrollTop = 0;
    };


    function applyFilter(filter) {
        scrollTop();
        updateQuery({...query, ...filter});
        selectionExist && selectIds([]);

    }

    function removeFilter(key) {
        scrollTop();
        updateQuery({...query, [key]: undefined});
        selectionExist && selectIds([]);
    }

    function clearFilters() {
        scrollTop();
        updateQuery({});
        selectionExist && selectIds([]);
    }

    function getFilter(key) {
        if (firstLoad)
            return null;

        switch (key) {
            case 'fmid':
            case 'parentPortalId':
            case 'id':
            case 'waybillId':
            case 'clientFMID':
            case "returnWaybillId":
            case 'clientId':
            case 'clientName':
            case 'shipper':
            case 'sender':
            case 'refs':
            case 'warehouseOrderNo':
            case 'orderNumbers':
            case 'carType':
            case 'transportNumber':
            case 'torg12No':
            case 'torg12':
            case 'consignee':
            case 'receiver':
            case 'shipto':
            case 'deliveryAddress':
            case 'slotId':
            case 'reservationId':
            case 'visitId':
            case 'providerName':
            case 'reservationDriver':
            case 'reservationDriverPhoneNumber':
            case 'driver':
            case 'initialOffer':
            case 'termInsuranceWithoutVATCost':
            case 'cargoInsuranceWithoutVATCost':
            case 'bestBet':
            case 'loadingAddress':
            case 'pointLoadingAddressSettlement':
            case 'pointLoadingAddress':
            case 'pointUnloadingAddressSettlement':
            case 'pointUnloadingAddress':
            case 'driverName':
            case 'truckLicensePlate':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'reservation':
                return <WbGridFilterDateTime
                    fieldDate="visitDate"
                    fieldTimeFrom="timeslotStart"
                    fieldTimeTo="timeslotEnd"
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'deadline':
            case 'auctionStart':
            case 'auctionEnd':
            case 'dateCreated':
            case 'loadingDate':
            case 'deliveryDate':
            case 'loadingDateTimeArrival':
            case 'loadingDateTimeDeparture':
            case 'unloadingDateTimeArrival':
            case 'unloadingDateTimeDeparture':
            case 'loadingDatePlan':
            case 'loadingDateFact':
            case 'dispatchDateFact':
            case 'unloadingDatePlan':
            case 'unloadingDateFact':
            case 'unloadingLeaveDateFact':
                return <WbGridFilterDatePeriod
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}/>;
            case 'cost':
            case 'cargoCost':
            case 'totalWeight':
            case 'totalWeightKgBrutto':
            case 'totalUnits':
            case 'unitsCount':
            case 'unitsType':
            case 'cargoUnits':
                return <WbGridFilterString
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}/>;
            case 'waybillType':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillTypesOptions}/>;
            case 'type':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillTypeOptions}/>;
            case 'status':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusOptions}/>;
            case 'pickupType':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={PickupTypeOptions}/>;
            case 'avisationStatus':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusAvizationOptions}/>;
            case 'auctionStatus':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={AuctionStatusOptions}/>;
            case 'statusAnomaly':
                return <WbGridFilterListMulti
                    hideOnScroll={false}
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusAnomalyOptions}/>;
            case 'tsunamiSendingMessage':
                return <WbGridFilterListMulti
                    field={'tsunamiMessageTypes'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    position={'bottom right'}
                    options={WaybillStatusTsunamiSendingOptions}/>;
            case 'document':
            case 'services':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new O('false', 'Нет'),
                        new O('true', 'Есть')
                    ]}/>;
            case 'isArchive':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new O('true', 'Да'),
                        new O('false', 'Нет')
                    ]}/>;
            case 'vehicleCapacity':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={VehicleCapacityOptions}/>;
            case 'cargoType':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={CargoTypeOptions}/>;
            case 'auctionType':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={AuctionTypeOptions}/>;
            case 'loadingServices':
            case 'unloadingServices':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={PointServiceOptions.map(pS => ({
                        text: pS.title,
                        key: pS.key,
                        value: pS.key,
                    }))}/>;
            case 'shippingTemperatureCondition':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={ThermoModeOptions}/>;
            case 'carTypeName':
                return <WbGridFilterListMulti
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    dictionaryName={'carType'}/>;
            case 'loadingTimePlanFrom':
            case 'loadingTimePlanTo':
            case 'loadingTimeFact':
            case 'dispatchTimeFact':
            case 'unloadingTimePlanFrom':
            case 'unloadingTimePlanTo':
            case 'unloadingTimeFact':
            case 'unloadingLeaveTimeFact':
                return <WbGridFilterStringContains
                    field={key}
                    isTime
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            default:
                return <div className="filter-flex-container"/>;
        }
    }

    function deselect() {
        if (selectionExist) {
            selectIds([]);
        } else {
            selectIds(getRowsIds());
        }
    }

    const {sortBy, sortDir} = query;

    const selectionExist = selectedIds.length > 0;

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{paddingTop: '5px', display: 'inline-block', width: '17px'}}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{height: '13px'}}/>
        </span>
    );

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };

    const selectorDisabled = selectionExist
        ? false
        : !Object.keys(query).length;

    const isSticky = (key) => {
        return ['fmid', 'returnWaybillId', 'waybillId'].includes(key);
    };

    const highlited = {
        backgroundColor: '#ffabab'
    };

    return (
        <>
            <Table className="grid-table" celled sortable fixed={fixed} singleLine>
                <Table.Header className="table-header-fixed">
                    <Table.Row key="headers" style={{fontSize: 'smaller'}}>
                        <Table.HeaderCell className="table-first-col table-select-all-cell table-item-sticky">
                            <Checkbox
                                checked={false}
                                //disabled={!selectionExist}
                                disabled={selectorDisabled}
                                indeterminate={selectionExist}
                                onChange={(e, data) => deselect()}/>
                        </Table.HeaderCell>
                        {headers.map(({text, key, sortable}) => (
                            <Table.HeaderCell
                                id={`header-th-${key}`}
                                key={key}
                                className={isSticky(key) ? 'table-select-cell table-header-fixed__title-fmid' : 'table-select-cell table-header-fixed__title'}
                                sorted={sortable && sortBy === key ? sortDir : null}
                                style={sortable ? null : styleDisabledHeader}
                                onClick={sortable ? handleSort(key) : null}
                            >
                                <div style={styleTh(text)}><T>{text}</T></div>
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                    <Table.Row key="filters" style={{height: "37px"}}>
                        <Table.Cell
                            width={1}
                            className="table-filter-cell table-select-clear-filter table-item-sticky"
                            style={{
                                backgroundColor: '#f9fafb',
                                textAlign: 'center',
                                paddingTop: '5px',
                                paddingBottom: '5px'
                            }}>
                            <Popup content={"Очистить все фильтры".t} trigger={clearFilterButton}/>
                        </Table.Cell>
                        {headers.map(({key, filter}) => (
                            <Table.Cell
                                id={`filter-td-${key}`}
                                width={key === 'shipto' ? 1 : null}
                                key={key}
                                sorted={sortBy === key ? sortDir : null}
                                className={isSticky(key) ? 'table-filter-cell table-header-fixed__filter-fmid' : 'table-filter-cell table-header-fixed__filter'}
                            >
                                {getFilter(key)}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                </Table.Header>
                {rows.length ? <Table.Body>
                    {rows.map(r => (
                        <Table.Row key={r.id}
                                   style={{cursor: 'pointer'}}
                                   active={selectedIds.includes(r.id)}
                        >
                            <Table.Cell className="table-first-col table-item-sticky">
                                <Checkbox id={r.id}
                                          checked={selectedIds.includes(r.id)}
                                          onChange={handleRowSelection.bind(null, r.id, rows)}/>
                            </Table.Cell>
                            {keys.map(key => <Table.Cell
                                style={r.isHighlited ? highlited : {}}
                                className={isSticky(key) ? 'table-item-fmid-sticky' : null}
                                onClick={() => handleRowClick(r)}
                                key={key}
                            >
                                <WbGridRowsElement key={key} el={key} data={r} canEdit={canEdit}/>
                            </Table.Cell>)}
                        </Table.Row>
                    ))}
                </Table.Body> : <div className="table-empty"></div>}

            </Table>
            {!rows.length && noRows}
            {needScroll && <ScrollPreloader onVisible={fetchData || onBottomVisible} continuous={true}/>}
        </>

    );
});

export default withRouter(WbGridTable);
