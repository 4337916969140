import React, {useState} from 'react';
import RuleInput from "../ruleInput";
import {Icon} from "semantic-ui-react";

const RuleWithOpenBlock = ({delBtn, children, disabled, className, infoBlock, ...props}) => {
    const [open, setOpen] = useState(true);

    return (
        <div className={`rule-open ${className || ''}`}>
            <div>
                <div className="rule-value__input">
                    <RuleInput
                        {...props}
                        disabled={disabled}
                    />
                    {!disabled && delBtn}
                    <Icon
                        color='grey'
                        className="cursor-pointer del-btn p-l-10"
                        name={open ? 'angle double down' : 'angle double right'}
                        onClick={() => setOpen(open => !open)}
                    />
                </div>
                {open && <div>
                    {children}
                </div>}
            </div>
            {open && infoBlock}
        </div>
    );
};

export default RuleWithOpenBlock;