import O from "../../components/Option";

export const ProviderStatusEnum = {
    ACTIVE: 0,
    BLOCKED: 1
};
export const ProviderStatusOptions = [
    new O(ProviderStatusEnum.ACTIVE, "Активный"),
    new O(ProviderStatusEnum.BLOCKED, "Заблокирован")
];

export function getProviderStatusName(status) {

    const option = ProviderStatusOptions.find(o => o.key === status);

    return option ? option.text : status;
}

export function getProviderStatusColor(status) {
    switch (status) {
        case ProviderStatusEnum.ACTIVE: return "blue";
        case ProviderStatusEnum.BLOCKED: return null;
        default: return null;
    }
}

