import React, {useContext, useState} from 'react';
import {Button, Item} from 'semantic-ui-react';
import {WaybillStatusEnum, WaybillTypeEnum} from '../../../api/model/Waybill';
import WaybillPoint from '../../../api/model/WaybillPoint';
import T from '../../../components/Translate';
import WbPointAddForm from './WbPointAddForm';
import WbPointCardShell from './WbPointCardShell';
import {ContextWb} from '../../../services/context';

const POINTS_LOADING = 'pointsLoading';

export default function WbPointCards({
                                         auctionHeight = 0,
                                         profile,
                                         positions,
                                         updateWb,
                                         wbId,
                                         wbType,
                                         wbClientId,
                                         points,
                                         updatePoints,
                                         unitsLoadedTotal,
                                         activeTab,
                                         handleTabChange,
                                         oppositeDate,
                                         pointsAll,
                                         isClient = false,
                                         isEdit,
                                         disabled,
                                         isPoolingEdit,
                                         isRoute,
                                         header,
                                         fieldConfigs,
                                         route,
                                         title
                                     }) {
    const [isFormOpen, setIsFormOpen] = useState(false);

    const contextWb = useContext(ContextWb);

    const canEdit = contextWb.wb.__canEdit;

    const isPoolingDraft = contextWb.wb.status === WaybillStatusEnum.DRAFT && contextWb.wb.isPooling;

    function pointAdd(point) {

        const newItems = [...points, {...point, index: pointsAll.length + 1}];

        if (profile === POINTS_LOADING && point.hubLoading) {

            updateWb({ hubLoading: true, pointsLoading: newItems }, {});

        } else {

            updatePoints(newItems); // shortcut for updateWb
        }

        handleTabChange();
    }

    function getPointRemoveUpdateScope(index) {

        const pointsCloned = [...points];

        const deletable = pointsCloned.splice(index, 1)[0];

        const updateScope = {[profile]: pointsCloned};

        if (profile === POINTS_LOADING) {

            const hubLoading = deletable.isHub;

            if (hubLoading) {

                updateScope.hubLoading = false;

                //updateScope.needPickup = false;
            }

            const validPositions = positions.filter(p => p.pointFromId !== deletable.id);

            if (validPositions.length !== positions.length) {

                updateScope.loadUnitSets = [...validPositions];
            }

        } else {

            const validPositions = positions.filter(p => p.pointToId !== deletable.id);

            if (validPositions.length !== positions.length) {

                updateScope.loadUnitsToOrderMappings = [...validPositions];
            }
        }

        return updateScope;
    }

    function pointRemove(index, skipUpdate) {

        const updateScope = getPointRemoveUpdateScope(index);

        updateWb(updateScope, {skipUpdate, isUpdatePickupType: profile === POINTS_LOADING});
    }

    function pointReplace(index, newPoint) {

        const updateScope = getPointRemoveUpdateScope(index);

        updateScope[profile] = [newPoint, ...updateScope[profile]];

        if (profile === POINTS_LOADING && newPoint.hubLoading) {

            updateScope.hubLoading = true;
        }

        updateWb(updateScope, {isUpdatePickupType: profile === POINTS_LOADING});
    }

    async function pointUpdate(index, point, loadingsChanged) {

        const pointsCloned = [...points];

        pointsCloned.splice(index, 1, point);

        updatePoints(pointsCloned, loadingsChanged);
    }

    const wrapperHeightStyle = {
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: '0',
        marginBottom: '0',
        width: '100%'
    };

    const isAuction = auctionHeight > 0;

    const isAddEnabled = () => {
        let result = true;

        if (canEdit && !isRoute) {
            result = false;
        }

        const onePointTypes = [WaybillTypeEnum.FTL, WaybillTypeEnum.LTL, WaybillTypeEnum.Rail, WaybillTypeEnum.RailLTL, WaybillTypeEnum.PlanReturn];

        if ((result && onePointTypes.includes(wbType)) || isAuction) {
            result = (points || []).length === 0;
        }

        if (isClient) {
            result = false;
        }

        return result;
    };

    const needCalc = (index) => {
        return ((profile === POINTS_LOADING && index === points.length - 1) ||
                (profile === POINTS_LOADING && index === 0))
            && !isPoolingDraft;
    };

    const onFormClose = () => {
        setIsFormOpen(false);
    };

    const addBtnView = !(!isAddEnabled() || isPoolingDraft || disabled);

    return (
        <>
            {header}
            <div className={`point-card-header ${(addBtnView && isRoute) ? 'point-card-header__border' : ''}`}>
                <div className="f-r">
                    {addBtnView && <Button
                        id={`btn_${profile}`}
                        size="small"
                        disabled={!isAddEnabled() || isPoolingDraft || disabled}
                        primary
                        style={{ marginBottom: '10px', marginRight: '0' }}
                        onClick={() => setIsFormOpen(true)}
                    >
                        <T>Добавить адрес</T>
                    </Button>}
                    <WbPointAddForm
                        wbId={wbId}
                        profile={profile}
                        wbClientId={wbClientId}
                        pointsExistingIds={(points || []).map(p => p.id)}
                        submit={pointAdd}
                        value={new WaybillPoint()}
                        open={isFormOpen}
                        onClose={onFormClose}
                    >
                    </WbPointAddForm>
                </div>
            </div>
            <Item.Group
                id={`item-group_${profile}`}
                divided
                style={wrapperHeightStyle}
            >
                {points.map((p, idx) => <WbPointCardShell
                    disabled={disabled}
                    key={p.id}
                    isClient={isClient}
                    pointsAll={pointsAll}
                    positions={positions}
                    unitsLoadedTotal={unitsLoadedTotal}
                    updateWb={updateWb}
                    wbId={wbId}
                    wbClientId={wbClientId}
                    wbPoint={p}
                    oppositeDate={oppositeDate}
                    wbType={wbType}
                    profile={profile}
                    remove={pointRemove.bind(null, idx)}
                    pointReplace={pointReplace.bind(null, idx)}
                    update={(point, loadingsChanged) => pointUpdate(idx, point, loadingsChanged)}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    needCalc={needCalc(idx)}
                    isEdit={isEdit}
                    isPoolingEdit={isPoolingEdit}
                    isRoute={isRoute}
                    fieldConfigs={fieldConfigs}
                    route={route}
                />)}
            </Item.Group>
        </>
    );
}
